import React, { useState, createContext } from "react";

export const InstallationContext = createContext<any>([
  {},
  () => {
    return;
  },
]);

export const InstallationProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [createInstallation, setCreateInstallation] = useState({});
  const [updateInstallation, setUpdateInstallation] = useState({});
  const [loadInstallation, setloadInstallation] = useState({});
  const [modalInstallation, setModalInstallation] = useState({});

  return (
    <InstallationContext.Provider
      value={{
        createInstallationContext: [createInstallation, setCreateInstallation],
        updateInstallationContext: [updateInstallation, setUpdateInstallation],
        loadInstallationContext: [loadInstallation, setloadInstallation],
        modalInstallationContext: [modalInstallation, setModalInstallation],
      }}
    >
      {children}
    </InstallationContext.Provider>
  );
};
