import React, { useEffect, useState, useContext, ReactNode } from "react";
import { useForm, ErrorMessage, ValidateResult } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { Form } from "react-bootstrap";

import SaveIcon from "@material-ui/icons/Save";
import { Box, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

import { AssetType, FormErrorMessages } from "constants/enum";
import { getOptions } from "utils";

import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import NewTextField from "components/TextField";
import Dropdown from "components/MUIDropdown";
import PaperHeading from "components/PaperHeading";
import { IDropdown, IApplication, IHierarchy } from "components/type";

import { getAccountAndSection } from "../../../../actions/installationsPageActions";
import { getAccount } from "../../../../actions/accountsPageActions";
import { editSection } from "../../../../actions/sectionsPageActions";

import { CountriesContext } from "provider/CountriesProvider/CountriesProvider";
import { AccountContext } from "provider/AccountProvider";
import { SpinnerContext } from "provider/SpinnerProvider";
import { ApplicationsContext } from "provider/ApplicationsProvider";
import { ModalContext } from "provider/ModalProvider";

import { CreateSection as CreateSectionConst } from "constants/constant";

import {
  isValid,
  updateMetadata as updateSectionMetadata,
} from "../../../../utils/utils";
import MetadataTable from "components/MetadataTable";
import { MetadataProps } from "components/MetadataTable/MetadataTable";
import {
  getHierarchyMetadata,
  saveBatchHierarchyMetadata,
} from "actions/generalActions";
import { coordinatesValidation } from "pages/Installation/InstallationDetails/Form/utils";

const errorMessageComponent = (
  messages: Record<string, ValidateResult> | undefined
): ReactNode =>
  messages &&
  Object.entries(messages).map(([type, message]) => (
    <p style={{ color: "red" }} key={type}>
      {message}
    </p>
  ));

const UpdateSection = ({
  children,
  sectionId = "",
}: {
  children?: React.ReactNode;
  sectionId: string;
}) => {
  const [hierarchiesList, setHierarchyList] = useState<IDropdown[]>([]);
  const [customerNumber, setCustomerNumber] = useState("");
  const [immediateParentId, setParentId] = useState("");
  const [complete, setComplete] = useState(false);

  const [applications] = useContext(ApplicationsContext);

  const { updateAccountContext } = useContext(AccountContext);
  const [account, setAccount] = updateAccountContext;

  const [countries, getCountries] = useContext(CountriesContext);

  const { sectionModalContext } = useContext(ModalContext);
  const [, setShowSectionModal] = sectionModalContext;
  const [isSpinnerVisible, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [metadata, setMetadata] = useState<MetadataProps[]>([]);
  const [initialStateMetadata, setInitialStateMetadata] = useState<
    MetadataProps[]
  >([]);
  const [hasPreviousMetadata, setHasPreviousMetadata] = useState(false);
  const [isModifyingMetadata, setIsModifyingMetadata] = useState(false);

  const { register, handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      sectionName: "",
      accountId: "",
      rootAssetName: "",
      countryId: "",
      streetName: "",
      postalCode: "",
      cityName: "",
      stateName: "",
      latitude: "",
      longitude: "",
    },
    validateCriteriaMode: "all",
  });

  const getImmediateParent = (): IDropdown[] => {
    return hierarchiesList.filter((e) => e.value === immediateParentId);
  };

  const isImmediateParentAnAccount = () => {
    const immediateParentContainer = getImmediateParent();

    if (immediateParentContainer.length > 0) {
      return immediateParentContainer[0].label.endsWith(
        ` (${AssetType.account})`
      );
    }

    return false;
  };

  const parentIdValue =
    getImmediateParent().length > 0 && isEmpty(immediateParentId)
      ? getImmediateParent()[0].value
      : immediateParentId;

  const rootIdValue = () => {
    if (isImmediateParentAnAccount()) {
      return getImmediateParent()[0].value;
    } else {
      return getImmediateParent()[0].rootId;
    }
  };

  const initDataToBeUpdated = (): void => {
    setValue("sectionName", account.assetName ? account.assetName : "");
    setValue("countryId", account.countryId ? account.countryId : "");
    setValue("streetName", account.street ? account.street : "");
    setValue("postalCode", account.postalCode ? account.postalCode : "");
    setValue("cityName", account.city ? account.city : "");
    setValue("stateName", account.state ? account.state : "");
    setValue("accountId", account.rootAssetName ? account.customerNumber : "");
    setValue(
      "rootAssetName",
      account.rootAssetName ? account.rootAssetName : ""
    );
    setValue("latitude", account.latitude ? account.latitude : "");
    setValue("longitude", account.longitude ? account.longitude : "");
    setParentId(account.parentId ? account.parentId : "");
    setCustomerNumber(account.customerNumber ? account.customerNumber : "");
    setComplete(false);
  };

  const updateMetadata = (hierarchyId = ""): Promise<void> => {
    if (hasPreviousMetadata) {
      return updateSectionMetadata(
        metadata,
        initialStateMetadata,
        hierarchyId,
        true
      );
    } else {
      return metadata.length > 0
        ? saveBatchHierarchyMetadata(hierarchyId, metadata)
        : new Promise<void>((resolve) => resolve());
    }
  };

  const isValidSection = () =>
    isEmpty(account) || account.id.toLowerCase() !== sectionId.toLowerCase();

  const loadSectionDataFromParamId = (): void => {
    if (isValidSection()) {
      setIsSpinnerVisible(true);
      getAccount(sectionId)
        .then((e) => {
          setAccount(e);
          setValue("accountId", e.rootAssetName);
          setValue("rootAssetName", e.rootAssetName);
          setParentId(e.parentId);
          setComplete(false);
          return e;
        })
        .then((s) => {
          const secId = s.id;
          return getHierarchyMetadata(secId);
        })
        .then((m) => {
          setHasPreviousMetadata(m.length > 0);
          setInitialStateMetadata(m);
          setMetadata(m);
          setIsSpinnerVisible(false);
        })
        .catch((e) => {
          setIsSpinnerVisible(false);
          console.error(e);
        });
    }
  };

  const onSubmit = (data: any): void => {
    if (isModifyingMetadata) {
      return;
    }

    setShowSectionModal(false);
    setIsSpinnerVisible(true);

    const sectionDetails: IHierarchy = {
      id: account.id,
      rootId: rootIdValue(),
      parentId: parentIdValue,
      typeId: process.env.REACT_APP_TYPE_SECTION_ID as string,
      typeName: AssetType.section,
      assetName: data.sectionName,
      ownerAccountId: "",
      customerNumber: customerNumber,
      street: data.streetName,
      city: data.cityName,
      state: data.stateName,
      postalCode: data.postalCode,
      countryId:
        data.countryId === "00000000-0000-0000-0000-000000000000"
          ? ""
          : data.countryId,
      latitude: data.latitude,
      longitude: data.longitude,
    };

    editSection(account.id, sectionDetails)
      .then(() => getAccount(sectionId))
      .then((e) => {
        setAccount(e);
        setValue("accountId", e.rootAssetName);
        setValue("rootAssetName", e.rootAssetName);
        setParentId(e.parentId);
        setComplete(false);
      })
      .then(() => updateMetadata(sectionDetails.id))
      .then(() => setIsSpinnerVisible(false))
      .catch((e) => {
        console.error(e);
        setIsSpinnerVisible(false);
      });
  };

  useEffect(() => {
    getCountries();
    loadSectionDataFromParamId();
    getAccountAndSection(sectionId).then((h) => {
      setHierarchyList(h.hierarchies);
      setComplete(true);
    });
    initDataToBeUpdated();
  }, [account, sectionId]);

  const emptyApp: IApplication[] = [
    {
      id: "",
      abbreviation: "",
      applicationName: "",
      description: "",
    },
  ];

  useEffect(() => {
    return () => {
      setAccount("");
    };
  }, []);

  const displaySelectOptions = (
    <Autocomplete
      id="immediateParent"
      defaultValue={getImmediateParent()[0]}
      disabled={isValid(account.parentId)}
      disableClearable={true}
      options={hierarchiesList}
      getOptionLabel={(option) => (!isEmpty(option) ? option.label : "")}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Immediate Parent"
          margin="normal"
          fullWidth
          name="parentId"
        />
      )}
      onChange={(event: object, value: any) => setParentId(value.value)}
    />
  );

  const metadataTableComponent = (
    <Paper>
      {isSpinnerVisible ? (
        <></>
      ) : (
        <MetadataTable
          metadata={metadata}
          search={true}
          onChangeMetadata={(data: MetadataProps[]) => setMetadata(data)}
          onClickActionButton={(name) => {
            if (name === "Add" && !isModifyingMetadata) {
              setIsModifyingMetadata(true);
            } else {
              setIsModifyingMetadata(false);
            }
          }}
        />
      )}
    </Paper>
  );

  const sectionHierarchyComponent = (
    <Grid item xs={12} md={8}>
      {children}
      {metadataTableComponent}
    </Grid>
  );

  return (
    <Form id="update-section-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box mb={3}>
            <Paper>
              <Box p={3}>
                <PaperHeading label="General" />

                <NewTextField
                  error={errors.sectionName}
                  label=" Section Name"
                  name="sectionName"
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired,
                  })}
                />
                <NewTextField
                  label="Account"
                  name="rootAssetName"
                  inputRef={register}
                  readOnly={true}
                />
                {!isEmpty(hierarchiesList) && complete ? (
                  displaySelectOptions
                ) : (
                  <div>
                    <Skeleton variant="rect" height={40} />
                  </div>
                )}
                <Autocomplete
                  disabled
                  multiple
                  id="application"
                  options={applications}
                  getOptionLabel={(option) => option.abbreviation}
                  renderTags={(value: IApplication[]) =>
                    value?.map((option: IApplication, index: any) => (
                      <Chip key={index} label={option.abbreviation} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      key={params.id}
                      {...params}
                      label="Digital Product"
                      margin="normal"
                      fullWidth
                    />
                  )}
                  value={
                    !isEmpty(account)
                      ? account?.applications.map((e: any) => {
                          return {
                            id: e.id,
                            abbreviation: e.abbreviation,
                          };
                        })
                      : emptyApp
                  }
                />

                <Dropdown
                  label="Country"
                  name="countryId"
                  control={control}
                  data={getOptions(countries, "id", "countryName")}
                />
                <NewTextField
                  label="Street"
                  name="streetName"
                  inputRef={register}
                />
                <NewTextField
                  label="City"
                  name="cityName"
                  inputRef={register}
                />
                <NewTextField
                  label="State"
                  name="stateName"
                  inputRef={register}
                />
                <NewTextField
                  label="Postal Code"
                  name="postalCode"
                  inputRef={register}
                />

                <Grid container spacing={3}>
                  <Grid item md={6} xs={6}>
                    <NewTextField
                      label={CreateSectionConst.LATITUDE_LABEL}
                      name={CreateSectionConst.LATITUDE_NAME}
                      inputRef={register({
                        required: false,
                        validate: coordinatesValidation("lat"),
                      })}
                    />
                    <ErrorMessage errors={errors} name="latitude">
                      {({ messages }): ReactNode =>
                        errorMessageComponent(messages)
                      }
                    </ErrorMessage>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <NewTextField
                      label={CreateSectionConst.LONGITUDE_LABEL}
                      name={CreateSectionConst.LONGITUDE_NAME}
                      inputRef={register({
                        required: false,
                        validate: coordinatesValidation("long"),
                      })}
                    />
                    <ErrorMessage errors={errors} name="longitude">
                      {({ messages }): ReactNode =>
                        errorMessageComponent(messages)
                      }
                    </ErrorMessage>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        </Grid>
        {sectionHierarchyComponent}
      </Grid>

      <FloatingActionButtonList>
        <FloatingActionButton type="submit">
          <SaveIcon />
          Save
        </FloatingActionButton>
      </FloatingActionButtonList>
    </Form>
  );
};
export default UpdateSection;
