import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { grey } from "@material-ui/core/colors";

import { IBreadcrumbsProps, IBreadcrumbProps } from "./type";

const Breadcrumb = ({ breadcrumbs }: IBreadcrumbsProps): JSX.Element => {
  const history = useHistory();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        "& li": {
          left: 0,
        },
        "& .MuiBreadcrumbs-li": {
          margin: 0,
        },
        "& .MuiBreadcrumbs-separator": {
          marginRight: 8,
        },
      },
      separator: {
        color: grey[500],
      },
      link: {
        color: theme.palette.primary.main,
        "&:hover": {
          color: theme.palette.primary.light,
          textDecoration: "underline",
        },
      },
      activeLink: {
        fontWeight: "bold",
        cursor: "default",
        color: grey[500],
        "&:hover": {
          color: grey[500],
          textDecoration: "none",
        },
      },
    })
  );
  const classes = useStyles();

  const redirectTo = (url: string | null = "") => {
    if (url && !url.includes("/:")) {
      history.push(url);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const href = (event.target as HTMLInputElement).getAttribute("href");
    redirectTo(href);
  };

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={
        <NavigateNextIcon className={classes.separator} fontSize="small" />
      }
      className={classes.root}
    >
      {breadcrumbs &&
        breadcrumbs.map((breadcrumb: IBreadcrumbProps, index: number) => (
          <Link
            key={`link_breadcrumb_${index}`}
            className={
              breadcrumbs.length === index + 1
                ? classes.activeLink
                : classes.link
            }
            href={breadcrumb.href ? breadcrumb.href : ""}
            onClick={handleClick}
          >
            {breadcrumb.label}
          </Link>
        ))}
    </Breadcrumbs>
  );
};
export default Breadcrumb;
