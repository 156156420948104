import React, { ReactElement, useState, useEffect, useContext } from "react";
import MainBarDashboard from "../../../components/MainBarDashboard";
import SubscriptionsTable from "../Table";
import { getAllSubscriptions } from "../Actions";
import { GetAllSubscriptionsProps } from "../type";
import { SpinnerContext } from "provider/SpinnerProvider";
import { SUBSCRIPTION_TYPE } from "constants/constant";

import SubscriptionTypeFilter from "./FilterComponents/SubscriptionTypeFilter";

const FIRST_PAGE = 0;

const SubscriptionsOverview = (): ReactElement => {
  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [
    subscriptionsData,
    setSubscriptionsData,
  ] = useState<GetAllSubscriptionsProps>();
  const [currentPage, setCurrentPage] = useState<number>(FIRST_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [subscriptionFilterType, setSubscriptionFilterType] = React.useState<
    string[]
  >([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const breadcrumbs = [
    {
      label: "Subscriptions",
    },
  ];

  const initSubscriptions = (
    page = 1,
    limit = 10,
    keyword = "",
    isSubscribed: boolean | undefined = undefined
  ): void => {
    getAllSubscriptions(page, limit, isSubscribed, keyword).then(
      (res: GetAllSubscriptionsProps) => {
        setSubscriptionsData(res);
        setIsSpinnerVisible(false);
      }
    );
  };

  const filteredInit = (
    page = 1,
    limit = 10,
    keyword = "",
    filters: string[] = []
  ) => {
    if (filters.length === 1) {
      initSubscriptions(
        page,
        limit,
        keyword,
        filters[0] === SUBSCRIPTION_TYPE.WITH_SUBSCRIPTION
      );
    } else {
      initSubscriptions(page, limit, keyword);
    }
  };

  const onSearch = (keyword: string): void => {
    const searchTerm = keyword.trim();
    setIsSpinnerVisible(true);
    setSearchKeyword(searchTerm);
    setCurrentPage(FIRST_PAGE);
    filteredInit(1, rowsPerPage, searchTerm, subscriptionFilterType);
  };

  const pageChange = (page: number): void => {
    setCurrentPage(page);
    filteredInit(page + 1, rowsPerPage, searchKeyword, subscriptionFilterType);
  };

  const rowPerPageChange = (numOfRows: number): void => {
    setRowsPerPage(numOfRows);
    filteredInit(
      currentPage + 1,
      numOfRows,
      searchKeyword,
      subscriptionFilterType
    );
  };

  useEffect(() => {
    initSubscriptions();
  }, []);

  const handleSubscriptionFilterTypeChange = (values: string[]) => {
    setIsSpinnerVisible(true);
    setSubscriptionFilterType(values);
    setCurrentPage(FIRST_PAGE);
    filteredInit(1, rowsPerPage, searchKeyword, values);
  };

  const filterComponents = [
    <SubscriptionTypeFilter
      key={0}
      onFilterChange={handleSubscriptionFilterTypeChange}
      selectedSubscriptionFilter={subscriptionFilterType}
    />,
  ];

  return (
    <MainBarDashboard
      search
      filter
      filterComponents={filterComponents}
      searchPlaceholder="Search Installation"
      onSearch={onSearch}
      breadcrumbs={breadcrumbs}
    >
      <SubscriptionsTable
        subscriptionsData={subscriptionsData}
        onUpdateRow={(p: number, l: number): void => {
          filteredInit(p + 1, l, searchKeyword, subscriptionFilterType);
        }}
        onPageChange={pageChange}
        onChangeRowsPerPage={rowPerPageChange}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
      />
    </MainBarDashboard>
  );
};

export default SubscriptionsOverview;
