import { adalApiFetch } from "../../clientApp/configAdal";
import { endPoints } from "../../clientApp/endPoints";
import Toaster from "components/Toaster";
import { SubscriptionUpdateData, GetAllSubscriptionsProps } from "./type";

const headerValues = { "content-type": "application/json" };
const TOASTR_ERROR_MESSAGE = "Error occured while processing your request!";

export const getAllSubscriptions = async (
  page: number,
  limit: number,
  isSubscribed: boolean | undefined = undefined,
  searchKeyword = ""
): Promise<GetAllSubscriptionsProps> => {
  return await adalApiFetch(
    fetch,
    `${endPoints.subscriptions}/GetAll?Page=${page}&Limit=${limit}&installationName=${searchKeyword}&isSubscribed=${isSubscribed}`,
    {
      method: "GET",
      headers: headerValues,
    }
  )
    .then((res) => res.json())
    .then((subscriptions) => {
      return subscriptions;
    })
    .catch((err) => {
      Toaster["error"](
        TOASTR_ERROR_MESSAGE,
        "Error Loading"
      );
      throw err;
    });
};

export const createSubscription = async (
  subscription: SubscriptionUpdateData
): Promise<void> => {
  return await adalApiFetch(fetch, `${endPoints.subscriptions}/Create`, {
    method: "POST",
    headers: headerValues,
    body: JSON.stringify(subscription),
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](
          TOASTR_ERROR_MESSAGE,
          "Error Saving"
        );
      } else {
        Toaster["success"]("Successfully Added!");
      }
      return response;
    })
    .then((response) => response.json())
    .catch((err) => {
      console.error(err);
    });
};
