import React, { useEffect, useContext, ReactNode, useState } from "react";
import { useForm, ErrorMessage, ValidateResult } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { Form } from "react-bootstrap";

import SaveIcon from "@material-ui/icons/Save";
import { TextField, Grid, Paper, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { FormErrorMessages } from "constants/enum";

import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import NewTextField from "components/TextField";
import Dropdown from "components/MUIDropdown";
import PaperHeading from "components/PaperHeading";
import { IApplication, ILabelValue } from "components/type";
import { IAccountUpdateForm, UpdateAccountFormFields } from "./type";

import { CountriesContext } from "provider/CountriesProvider";
import { ApplicationsContext } from "provider/ApplicationsProvider";
import { AccountContext } from "provider/AccountProvider";
import { TypesContext } from "provider/TypesProvider";
import { SpinnerContext } from "provider/SpinnerProvider";

import {
  updateAccount as updateAccountAction,
  getAccount,
} from "../../../../actions/accountsPageActions";

import {
  Accounts as AccountsConst,
  CreateAccount as UpdateAccountConst,
} from "constants/constant";
import {
  saveMetadataIfNotEmpty,
  updateMetadata as updateAccountMetadata,
} from "../../../../utils/utils";
import MetadataTable, { MetadataProps } from "components/MetadataTable";
import { getHierarchyMetadata } from "actions/generalActions";
import { coordinatesValidation } from "pages/Installation/InstallationDetails/Form/utils";

const errorMessageComponent = (
  messages: Record<string, ValidateResult> | undefined
): ReactNode =>
  messages &&
  Object.entries(messages).map(([type, message]) => (
    <p style={{ color: "red" }} key={type}>
      {message}
    </p>
  ));

const UpdateAccount = ({
  children,
  accountId = "",
}: {
  children?: React.ReactNode;
  accountId: string;
}): JSX.Element => {
  const [countries, getCountries] = useContext(CountriesContext);
  const [applications, getApplications] = useContext(ApplicationsContext);
  const [, getTypes] = useContext(TypesContext);
  const { updateAccountContext } = useContext(AccountContext);
  const [isSpinnerVisible, setIsSpinnerVisible] = useContext(SpinnerContext);

  const [account, setAccount] = updateAccountContext;
  const [metadata, setMetadata] = useState<MetadataProps[]>([]);
  const [initialStateMetadata, setInitialStateMetadata] = useState<
    MetadataProps[]
  >([]);
  const [isModifyingMetadata, setIsModifyingMetadata] = useState(false);
  const [stateInvoice, setInvoiceState] = useState({
    differentEmail: false,
    invoiceEmail: "",
  });

  const [hasPreviousMetadata, setHasPreviousMetadata] = useState(false);
  const { differentEmail, invoiceEmail } = stateInvoice;
  const { register, handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      assetName: "",
      sapLocalSoldToId: "",
      sapCentralSoldToId: "",
      sapCentralSoldToName: "",
      salesOrganization: "",
      countryId: "",
      street: "",
      postalCode: "",
      city: "",
      state: "",
      latitude: "",
      longitude: "",
      invoiceEmail: "",
    },
    validateCriteriaMode: "all",
  });

  const initDataToBeUpdated = ({
    assetName = "",
    sapLocalSoldToId = "",
    sapCentralSoldToId = "",
    sapCentralSoldToName = "",
    salesOrganization = "",
    countryId = "",
    street = "",
    postalCode = "",
    city = "",
    state = "",
    latitude = "",
    longitude = "",
    cvrVat = "",
    accountResponsibleContactName = "",
    accountResponsibleContactNumber = "",
    accountResponsibleEmailAddress = "",
    invoiceToDifferentEmail = false,
    accountResponsibleInvoiceEmail = "",
  }: UpdateAccountFormFields): void => {
    setValue(AccountsConst.ASSETNAME_NAME, assetName);
    setValue(AccountsConst.SAP_LOCAL_SOLD_TO_NUMBER_NAME, sapLocalSoldToId);
    setValue(AccountsConst.SAP_CENTRAL_SOLD_TO_NUMBER_NAME, sapCentralSoldToId);
    setValue(AccountsConst.SAP_CENTRAL_SOLD_TO_NAME_NAME, sapCentralSoldToName);
    setValue(AccountsConst.SALES_ORGANIZATION_NAME, salesOrganization);
    setValue(AccountsConst.COUNTRY_NAME, countryId);
    setValue(AccountsConst.STREET_NAME, street);
    setValue(AccountsConst.POSTAL_NAME, postalCode);
    setValue(AccountsConst.CITY_NAME, city);
    setValue(AccountsConst.STATE_NAME, state);
    setValue(AccountsConst.LATITUDE_NAME, latitude);
    setValue(AccountsConst.LONGITUDE_NAME, longitude);
    setValue(AccountsConst.CVR_VAT_NAME, cvrVat);
    setValue(AccountsConst.CONTACT_NAME_NAME, accountResponsibleContactName);
    setValue(
      AccountsConst.TELEPHONE_NUMBER_NAME,
      accountResponsibleContactNumber
    );
    setValue(AccountsConst.EMAIL_ADDRESS_NAME, accountResponsibleEmailAddress);
    setInvoiceState({
      ...stateInvoice,
      differentEmail: invoiceToDifferentEmail,
      invoiceEmail: invoiceToDifferentEmail
        ? FormErrorMessages.fieldRequired
        : "",
    });
    setValue(AccountsConst.INVOICE_EMAIL_NAME, accountResponsibleInvoiceEmail);
  };

  const handleChange = (e: any) => {
    setInvoiceState({
      ...stateInvoice,
      differentEmail: e.target.checked,
      invoiceEmail: e.target.checked ? FormErrorMessages.fieldRequired : "",
    });
  };

  const isValidAccount = () =>
    isEmpty(account) || account.id.toLowerCase() !== accountId.toLowerCase();

  useEffect(() => {
    getCountries();
    getApplications();
    getTypes();

    if (isValidAccount()) {
      setIsSpinnerVisible(true);

      getAccount(accountId)
        .then((res) => {
          setAccount(res);
          return getHierarchyMetadata(accountId);
        })
        .then((m: MetadataProps[]) => {
          setHasPreviousMetadata(m.length > 0);
          setInitialStateMetadata(m);
          setMetadata(m);
          setIsSpinnerVisible(false);
        })
        .catch((e) => {
          console.error(e);
          setIsSpinnerVisible(false);
        });
    }
    initDataToBeUpdated(account as UpdateAccountFormFields);
  }, [account, accountId]);

  useEffect(() => {
    return () => {
      setAccount("");
    };
  }, []);

  const getCountryIdForSubmit = (id: string) => {
    if (id === AccountsConst.EMPTY_UUID) {
      return "";
    }

    return id;
  };

  const updateMetadata = (hierarchyId = ""): Promise<void> => {
    if (hasPreviousMetadata) {
      return updateAccountMetadata(
        metadata,
        initialStateMetadata,
        hierarchyId,
        true
      );
    } else {
      return saveMetadataIfNotEmpty(metadata, hierarchyId);
    }
  };

  const onSubmit = (data: any): void => {
    if (isModifyingMetadata) {
      return;
    }

    const sendData = data as IAccountUpdateForm;

    setIsSpinnerVisible(true);
    sendData.ownerAccountId = "";
    sendData.typeId = process.env.REACT_APP_TYPE_ACCOUNT_ID;
    sendData.id = account.id;
    sendData.countryId = getCountryIdForSubmit(sendData.countryId);

    updateAccountAction(sendData)
      .then(() => getAccount(account.id as string))
      .then((acc) => setAccount(acc))
      .then(() => updateMetadata(account.id as string))
      .then(() => setIsSpinnerVisible(false))
      .catch((e) => {
        console.error(e);
        setIsSpinnerVisible(false);
      });
  };

  const getOptions = (
    items: any[],
    id: string,
    label: string
  ): ILabelValue[] => {
    const data: ILabelValue[] = [];
    if (items) {
      items.forEach((i) => {
        data.push({
          label: i[label],
          value: i[id],
        });
      });
    }

    return data;
  };

  const emptyApp: IApplication[] = [
    {
      id: "",
      abbreviation: "",
      applicationName: "",
      description: "",
    },
  ];

  const metadataTableComponent = (
    <Paper>
      {isSpinnerVisible ? (
        <></>
      ) : (
        <MetadataTable
          metadata={metadata}
          search={true}
          onChangeMetadata={(data: MetadataProps[]) => setMetadata(data)}
          onClickActionButton={(name) => {
            if (name === "Add" && !isModifyingMetadata) {
              setIsModifyingMetadata(true);
            } else {
              setIsModifyingMetadata(false);
            }
          }}
        />
      )}
    </Paper>
  );

  const invoiceToDifferntEmailComponent = (
    <Box marginTop={2} mb={3}>
      <Paper>
        <Box p={3}>
          <Box mb={3}>
            <PaperHeading label={UpdateAccountConst.INVOICE} />
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={differentEmail}
                  onChange={handleChange}
                  name={AccountsConst.INVOICE_TO_DIFFERENT_EMAIL_NAME}
                  inputRef={register}
                />
              }
              label={AccountsConst.INVOICE_TO_DIFFERENT_EMAIL_LABEL}
            />
            <NewTextField
              error={errors.invoiceEmail}
              label={AccountsConst.INVOICE_EMAIL_LABEL}
              name={AccountsConst.INVOICE_EMAIL_NAME}
              inputRef={register({
                required: invoiceEmail,
              })}
            />
            <ErrorMessage
              errors={errors}
              name={AccountsConst.INVOICE_EMAIL_NAME}
            >
              {({ messages }): ReactNode => errorMessageComponent(messages)}
            </ErrorMessage>
          </Box>
        </Box>
      </Paper>
    </Box>
  );

  const accountResponsibleComponent = (
    <Box marginTop={2} mb={3}>
      <Paper>
        <Box p={3}>
          <Box mb={3}>
            <PaperHeading label={UpdateAccountConst.ACCOUNT_RESPONSIBLE} />
            <Divider />
            <NewTextField
              label={AccountsConst.CONTACT_NAME_LABEL}
              name={AccountsConst.CONTACT_NAME_NAME}
              inputRef={register}
            />
            <NewTextField
              label={AccountsConst.TELEPHONE_NUMBER_LABEL}
              name={AccountsConst.TELEPHONE_NUMBER_NAME}
              inputRef={register}
            />
            <NewTextField
              label={AccountsConst.EMAIL_ADDRESS_LABEL}
              name={AccountsConst.EMAIL_ADDRESS_NAME}
              inputRef={register}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );

  const accountHierarchyComponent = (
    <Grid item xs={12} md={8}>
      {children}
      {metadataTableComponent}
      {invoiceToDifferntEmailComponent}
    </Grid>
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box mb={3}>
            <Paper>
              <Box p={3}>
                <PaperHeading label={AccountsConst.GENERAL_LABEL} />
                <NewTextField
                  error={errors.assetName}
                  label={AccountsConst.ASSETNAME_LABEL}
                  name={AccountsConst.ASSETNAME_NAME}
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired,
                  })}
                />
                <NewTextField
                  label={AccountsConst.CVR_VAT_LABEL}
                  name={AccountsConst.CVR_VAT_NAME}
                  inputRef={register}
                />
                <NewTextField
                  error={errors.sapLocalSoldToId}
                  label={AccountsConst.SAP_LOCAL_SOLD_TO_NUMBER_LABEL}
                  name={AccountsConst.SAP_LOCAL_SOLD_TO_NUMBER_NAME}
                  maxLength={10}
                  inputRef={register}
                />
                <NewTextField
                  error={errors.sapCentralSoldToId}
                  label={AccountsConst.SAP_CENTRAL_SOLD_TO_NUMBER_LABEL}
                  name={AccountsConst.SAP_CENTRAL_SOLD_TO_NUMBER_NAME}
                  maxLength={10}
                  inputRef={register}
                />
                <NewTextField
                  error={errors.sapCentralSoldToName}
                  label={AccountsConst.SAP_CENTRAL_SOLD_TO_NAME_LABEL}
                  name={AccountsConst.SAP_CENTRAL_SOLD_TO_NAME_NAME}
                  inputRef={register}
                />
                <NewTextField
                  error={errors.salesOrganization}
                  label={AccountsConst.SALES_ORGANIZATION_LABEL}
                  name={AccountsConst.SALES_ORGANIZATION_NAME}
                  maxLength={3}
                  inputRef={register}
                />

                <Autocomplete
                  disabled
                  multiple
                  id="application"
                  options={applications}
                  getOptionLabel={(option) => option.abbreviation}
                  renderTags={(value: IApplication[]) =>
                    value?.map((option: IApplication, index: any) => (
                      <Chip label={option.abbreviation} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Digital Product"
                      margin="normal"
                      fullWidth
                    />
                  )}
                  value={
                    !isEmpty(account)
                      ? account?.applications.map((e: any) => {
                          return {
                            id: e.id,
                            abbreviation: e.abbreviation,
                          };
                        })
                      : emptyApp
                  }
                />

                <Dropdown
                  label={AccountsConst.COUNTRY_LABEL}
                  name={AccountsConst.COUNTRY_NAME}
                  control={control}
                  data={getOptions(countries, "id", "countryName")}
                />

                <NewTextField
                  label={AccountsConst.STATE_LABEL}
                  name={AccountsConst.STATE_NAME}
                  inputRef={register}
                />
                <NewTextField
                  label={AccountsConst.CITY_LABEL}
                  name={AccountsConst.CITY_NAME}
                  inputRef={register}
                />
                <NewTextField
                  label={AccountsConst.STREET_LABEL}
                  name={AccountsConst.STREET_NAME}
                  inputRef={register}
                />
                <NewTextField
                  label={AccountsConst.POSTAL_LABEL}
                  name={AccountsConst.POSTAL_NAME}
                  inputRef={register}
                />

                <Grid container spacing={3}>
                  <Grid item md={6} xs={6}>
                    <NewTextField
                      label={AccountsConst.LATITUDE_LABEL}
                      name={AccountsConst.LATITUDE_NAME}
                      inputRef={register({
                        required: false,
                        validate: coordinatesValidation("lat"),
                      })}
                    />
                    <ErrorMessage errors={errors} name="latitude">
                      {({ messages }): ReactNode =>
                        errorMessageComponent(messages)
                      }
                    </ErrorMessage>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <NewTextField
                      label={AccountsConst.LONGITUDE_LABEL}
                      name={AccountsConst.LONGITUDE_NAME}
                      inputRef={register({
                        required: false,
                        validate: coordinatesValidation("long"),
                      })}
                    />
                    <ErrorMessage errors={errors} name="longitude">
                      {({ messages }): ReactNode =>
                        errorMessageComponent(messages)
                      }
                    </ErrorMessage>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
          {accountResponsibleComponent}
        </Grid>
        {accountHierarchyComponent}
      </Grid>

      <FloatingActionButtonList>
        <FloatingActionButton type="submit">
          <SaveIcon />
          Save
        </FloatingActionButton>
      </FloatingActionButtonList>
    </Form>
  );
};

export default UpdateAccount;
