//#region "Device View"
export const EMPTY_UUID = "00000000-0000-0000-0000-000000000000";

export const DeviceView = {
  COMMUNICATION_DEVICE: "Communication Device",
  EQUIPMENT: "Equipment",
  SYSTEM: "System",
  DEFAULT_PARENT_DEVICE_SELECT: "Select Parent Device",
  DEFAULT_INSTALLATION_SELECT: "Select Installation",
  FORM_TAB: {
    NEW_COMM_DEV_TAB: 0,
    NEW_SUB_DEV_TAB: 1,
    NEW_SYSTEM_TAB: 2,
  },
};

export const AddressType = {
  MAC: "1",
  IMEI: "2",
  AssignedId: "3",
};

//#endregion

//#region "Devices Table"
export const DevicesTable = {
  DIALOG_TITLE: "Delete",
  DIALOG_MESSAGE_DEVICE: "This device and all devices attached to it will be deleted. Are you sure you want to delete:",
};
//#endregion

//#region "Heirarchy Modal View"
export const HierarchyModalView = {
  DIALOG_TITLE: "Hierarchy"
};
//#endregion

//#region "Account Overview"
export const AccountOverview = {
  SEARCH_PLACEHOLDER: "Search Asset",
  BREADCRUMBS_LABEL: "Assets",
};
//#endregion

//#region "Hierarchies Table"
export const HierarchiesTable = {
  DIALOG_TITLE: "Delete",
  DIALOG_MESSAGE_ACCOUNT_SECTION:
    "will be detached to its parent, but the sub-section/installations attached to this will still remain.",
  DIALOG_MESSAGE_INSTALLATION: "Are you sure you want to delete",
};

//#endregion

//#region "Create Account"
export const CreateAccount = {
  BREADCRUMBS_CREATE_LABEL: "Create Account",
  BREADCRUMBS_LABEL: "Assets",
  PAPER_HEADING_LABEL: "Account Details",
  DIALOG_TITLE: "Account Confirmation",
  DIALOG_MESSAGE: "Do you want to add child assets?",
  ACCOUNT_RESPONSIBLE: "Account Responsible",
  INVOICE: "Invoice"
};
//#endregion

//#region "Accounts"
export const Accounts = {
  EMPTY_UUID: EMPTY_UUID,
  ASSETNAME_LABEL: "Account Name",
  ASSETNAME_NAME: "assetName",
  COUNTRY_LABEL: "Country",
  COUNTRY_NAME: "countryId",
  STATE_LABEL: "State",
  STATE_NAME: "state",
  CITY_LABEL: "City",
  CITY_NAME: "city",
  STREET_LABEL: "Street",
  STREET_NAME: "street",
  POSTAL_LABEL: "Postal Code",
  POSTAL_NAME: "postalCode",
  SAP_LOCAL_SOLD_TO_NUMBER_LABEL: "SAP Local Sold-to Number",
  SAP_LOCAL_SOLD_TO_NUMBER_NAME: "sapLocalSoldToId",
  SAP_CENTRAL_SOLD_TO_NUMBER_LABEL: "SAP Central Sold-to Number",
  SAP_CENTRAL_SOLD_TO_NUMBER_NAME: "sapCentralSoldToId",
  SAP_CENTRAL_SOLD_TO_NAME_LABEL: "SAP Central Sold-to Name",
  SAP_CENTRAL_SOLD_TO_NAME_NAME: "sapCentralSoldToName",
  SALES_ORGANIZATION_LABEL: "Sales Organization",
  SALES_ORGANIZATION_NAME: "salesOrganization",
  LATITUDE_LABEL: "Latitude",
  LATITUDE_NAME: "latitude",
  LONGITUDE_LABEL: "Longitude",
  LONGITUDE_NAME: "longitude",
  CONTACT_NAME_LABEL: "Contact Name",
  CONTACT_NAME_NAME: "accountResponsibleContactName",
  TELEPHONE_NUMBER_LABEL: "Contact Number",
  TELEPHONE_NUMBER_NAME: "accountResponsibleContactNumber",
  EMAIL_ADDRESS_LABEL: "Email Address",
  EMAIL_ADDRESS_NAME: "accountResponsibleEmailAddress",
  CVR_VAT_LABEL: "CVR/VAT",
  CVR_VAT_NAME: "cvrVat",
  INVOICE_TO_DIFFERENT_EMAIL_LABEL: "Invoice to a different e-mail",
  INVOICE_TO_DIFFERENT_EMAIL_NAME: "invoiceToDifferentEmail",
  INVOICE_EMAIL_LABEL: "Invoice Email",
  INVOICE_EMAIL_NAME: "accountResponsibleInvoiceEmail",
  GENERAL_LABEL: "General"
};
//#endregion

//#region "Subscriptions"
export const SUBSCRIPTION_TYPE = {
  WITH_SUBSCRIPTION: "With Subscription",
  WITHOUT_SUBSCRIPTION: "Without Subscription",
};
//#endregion

//#region "Installations"
export const LAT_LONG_MAX_DECIMALS = 7;

export const VALIDATION_ERROR_MESSAGES = {
  SEVEN_DECIMALS_MAX: "Should only contain up to 7 decimals",
  PLUS_OR_MINUS_90: "Value should be between -90 and 90",
  PLUS_OR_MINUS_180: "Value should be between -180 and 180",
  NUMBER_ONLY: "Should be a number",
  DIGITS_ONLY: "Should only contain digits",
  REASON_REQUIRED: "Reason required",
  USER_EXISTS: "User exists already",
};
//#endregion

//#region
export const CreateSection = {
  BREADCRUMBS_CREATE_LABEL: "Create Section",
  BREADCRUMBS_LABEL: "Assets",
  ASSET_NAME: "assetName",
  CUSTOMER_NUMBER: "customerNumber",
  STREET: "street",
  POSTAL_CODE: "postalCode",
  CITY: "city",
  STATE: "state",
  PAPER_HEADING_LABEL: "Section Details",
  ASSETNAME_LABEL: "Section Name",
  ASSETNAME_NAME: "assetName",
  SAP_NUMBER_LABEL: "SAP Customer Number",
  SAP_NUMBER_NAME: "customerNumber",
  COUNTRY_LABEL: "Country",
  COUNTRY_NAME: "countryId",
  STATE_LABEL: "State",
  STATE_NAME: "state",
  CITY_LABEL: "City",
  CITY_NAME: "city",
  STREET_LABEL: "Street",
  STREET_NAME: "street",
  POSTAL_LABEL: "Postal Code",
  POSTAL_NAME: "postalCode",
  LATITUDE_LABEL: "Latitude",
  LATITUDE_NAME: "latitude",
  LONGITUDE_LABEL: "Longitude",
  LONGITUDE_NAME: "longitude",
  DIALOG_TITLE: "Section Confirmation",
  DIALOG_MESSAGE: "Do you want to add child assets?",
};
//#endregion

//#region
export const InternalRoles = {
  ADMIN_MANAGER: "AdminManager",
  APPLICATION_SUPPORT: "ApplicationSupport",
  GIC_ADMIN: "GICAdmin",
};
//#endregion

//#region
export const InternalRolePermissions = {
  MANAGE_CONFIG_USER: "ManageConfigUser",
  VIEW_CONFIG_USER: "ViewConfigUser",
};
//#endregion

//#region
export const HttpStatusCode = {
  OK: 200,
  FORBIDDEN: 403,
};
//#endregion
