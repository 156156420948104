import React from "react";

import { renderRoutes } from "react-router-config";
import Layout from "layouts";
import { IElementRouteAttribute } from "routes/type";
import { DeviceProvider } from "provider/DeviceProvider";

const Devices = ({ route }: IElementRouteAttribute) => {
  return (
    <DeviceProvider>
      <Layout>{renderRoutes(route.routes)}</Layout>
    </DeviceProvider>
  );
};

export default Devices;
