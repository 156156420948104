import React from "react";
import Subscriptions from "./Subscriptions";
import { SubscriptionsProvider } from "provider/SubscriptionsProvider";

const SubscriptionsIndex = (props: any): JSX.Element => {
  return (
    <SubscriptionsProvider>
      <Subscriptions {...props} />
    </SubscriptionsProvider>
  );
};

export default SubscriptionsIndex;
