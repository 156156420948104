import React, { useState, createContext } from "react";
import { getAdminUser } from "components/Actions";
import { PERMISSION_CACHE_EXPIRATION } from "constants/config";

export const AuthContext = createContext<any>([
  {},
  () => {
    return;
  },
]);

export const AuthProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentPermissions, setCurrentPermissions] = useState<string[]>([]);
  const [azureId, setAzureId] = useState("");
  const [dateTimeRefresh, setDateTimeRefresh] = useState(0);
  const refreshPermissions = (refreshTime = 0) => {
    getAdminUser(azureId).then((adminUser) => {
      setCurrentPermissions(adminUser.permissions);
    });
  };

  const hasPermission = (permissions: string[]) => {
    const currentDate = new Date();
    const dateTime = currentDate.getTime();

    if (dateTimeRefresh < dateTime) {
      const nemCurrentDate = new Date();
      const newDateTime = nemCurrentDate.getTime();
      setDateTimeRefresh(newDateTime + PERMISSION_CACHE_EXPIRATION);
      getAdminUser(azureId).then((adminUser) => {
        setCurrentPermissions(adminUser.permissions);
      });
    }

    let hasPerm = false;
    if (!currentPermissions) {
      return false;
    }

    if (permissions) {
      permissions.forEach((permission) => {
        if (currentPermissions && currentPermissions.includes(permission)) {
          hasPerm = true;
        }
      });
    } else {
      hasPerm = true;
    }
    return hasPerm;
  };
  return (
    <AuthContext.Provider
      value={{
        isAuthenticatedContext: [isAuthenticated, setIsAuthenticated],
        permissionContext: [
          currentPermissions,
          setCurrentPermissions,
          hasPermission,
          refreshPermissions,
        ],
        azureIdContext: [azureId, setAzureId],
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
