import { pullCountries } from "provider/CountriesProvider/Actions";
import { pullLanguages } from "provider/LanguagesProvider/Actions";
import { pullTimeFormats } from "provider/TimeFormatsProvider/Actions";
import { pullTimeZones } from "provider/TimeZonesProvider/Actions";
import { pullUnitSystems } from "provider/UnitSystemsProvider/Actions";
import { useEffect, useState } from "react";
import { pullDateFormats } from "../provider/DateFormatsProvider/Actions";

export function useDateFormats(cb: () => void | undefined) {
  const [dateFormats, setDateFormats] = useState<any[]>();

  useEffect(() => {
    pullDateFormats().then((df) => {
      if (cb) {
        cb();
      }
      setDateFormats(df);
    });
  }, []);

  return dateFormats;
}

export function useCountries(cb: () => void | undefined) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    pullCountries().then((c) => {
      if (cb) {
        cb();
      }
      setCountries(c);
    });
  }, []);

  return countries;
}

export function useLanguages(cb: () => void | undefined) {
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    pullLanguages().then((l) => {
      if (cb) {
        cb();
      }
      setLanguages(l);
    });
  }, []);

  return languages;
}

export function useTimeFormats(cb: () => void | undefined) {
  const [timeFormats, setTimeFormats] = useState([]);

  useEffect(() => {
    pullTimeFormats().then((tf) => {
      if (cb) {
        cb();
      }
      setTimeFormats(tf);
    });
  }, []);

  return timeFormats;
}

export function useTimeZones(cb: () => void | undefined) {
  const [timeZones, setTimeZones] = useState([]);

  useEffect(() => {
    pullTimeZones().then((tz) => {
      if (cb) {
        cb();
      }
      setTimeZones(tz);
    });
  }, []);

  return timeZones;
}

export function useUnitSystems(cb: () => void | undefined) {
  const [unitSystems, setUnitSystems] = useState([]);

  useEffect(() => {
    pullUnitSystems().then((us) => {
      if (cb) {
        cb();
      }
      setUnitSystems(us);
    });
  }, []);

  return unitSystems;
}
