import React, { useContext } from "react";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router";
import CreateUpdateTreeView from "components/CreateUpdateTreeView";
import UpdateSectionForm from "./Form";
import NewSectionModal from "pages/Section/SectionModal";
import NewInstallationModal from "pages/Installation/InstallationModal";
import MainBarDashboard from "components/MainBarDashboard";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PaperHeading from "components/PaperHeading";
import PageHeader from "components/PageHeader";
import { SectionUpdatePath, AssetsRootPath } from "routes/paths";
import { SectionContext } from "provider/SectionProvider";
import { AccountOverview as AccountsOverviewConst } from "constants/constant";

const UpdateAccount = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();

  const breadcrumbs = [
    {
      label: AccountsOverviewConst.BREADCRUMBS_LABEL,
      href: AssetsRootPath,
    },
    {
      label: `Section Details (${id})`,
      href: SectionUpdatePath,
    },
  ];

  const { loadSectionContext } = useContext(SectionContext);
  const [loadSection] = loadSectionContext;

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <PageHeader
        title={!isEmpty(loadSection) ? loadSection!.assetName : ""}
        subTitle={!isEmpty(loadSection) ? loadSection!.typeName : ""}
      >
        {" "}
      </PageHeader>
      <Grid container spacing={3}>
        <UpdateSectionForm sectionId={id}>
          <Box mb={3}>
            <Paper>
              <Box p={3}>
                <PaperHeading label="Section Hierarchy" />
                <CreateUpdateTreeView />
              </Box>
            </Paper>
          </Box>
        </UpdateSectionForm>
      </Grid>

      <NewSectionModal />
      <NewInstallationModal />
    </MainBarDashboard>
  );
};
export default UpdateAccount;
