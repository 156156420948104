import React from "react";
import MainBarDashboard from "components/MainBarDashboard";
import CreateAccountForm from "./Form";
import { CreateAccount as CreateAccountConst } from "constants/constant";

import { AccountsCreatePath, AssetsRootPath } from "routes/paths";
const CreateAccount = (): JSX.Element => {
  const breadcrumbs = [
    {
      label: CreateAccountConst.BREADCRUMBS_LABEL,
      href: AssetsRootPath,
    },
    {
      label: CreateAccountConst.BREADCRUMBS_CREATE_LABEL,
      href: AccountsCreatePath,
    },
  ];

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <CreateAccountForm />
    </MainBarDashboard>
  );
};
export default CreateAccount;
