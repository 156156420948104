import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

import { ISearchProps } from "./type";

const Search = ({ searchPlaceholder, onSearch }: ISearchProps): JSX.Element => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 350,
        boxShadow: "none",
        backgroundColor: theme.palette.common.white,
      },
      input: {
        marginLeft: theme.spacing(1),
        flex: 1,
      },
      icon: {
        color: theme.palette.secondary.main,
      },
    })
  );
  const classes = useStyles();
  const [searchKeyword, setSearchKeyword] = useState("");
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (onSearch) {
      onSearch(encodeURIComponent(searchKeyword));
    }
  };

  return (
    <Paper component="form" className={classes.root} onSubmit={onSubmit}>
      <SearchIcon className={classes.icon} />
      <InputBase
        className={classes.input}
        placeholder={searchPlaceholder ? searchPlaceholder : "Search"}
        name="searchKeyword"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchKeyword(e.target.value);
        }}
      />
    </Paper>
  );
};
export default Search;
