import React, { useState, createContext } from "react";

export const DrawerContext = createContext<any>([
  {},
  () => {
    return;
  },
]);

export const DrawerProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [open, setOpen] = useState(true);
  return (
    <DrawerContext.Provider value={[open, setOpen]}>
      {children}
    </DrawerContext.Provider>
  );
};
