import React, { Fragment, useState } from "react";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PaperHeading from "components/PaperHeading";

import { IExpansionPanelProps } from "./type";

const MUIExpansionPanel = ({
  children,
  label,
  defaultExpanded,
  expansionActionButtons,
  hasCheckbox,
  checked,
  onChangeChecked,
}: IExpansionPanelProps): JSX.Element => {
  const [, setcBcheck] = useState<boolean>();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      expansionPanel: {
        flexDirection: "column",
      },
    })
  );
  const classes = useStyles();
  const handleClick = (cb: any) => {
    setcBcheck(cb.target.checked);
    if (onChangeChecked) {
      onChangeChecked(cb.target.checked);
    }
  };
  return (
    <Accordion
      defaultExpanded={defaultExpanded != null ? defaultExpanded : true}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <label>
          {" "}
          {hasCheckbox ? (
            <input
              type="checkbox"
              checked={checked || false}
              onChange={(e: any) => handleClick(e)}
            />
          ) : (
            ""
          )}{" "}
          <PaperHeading label={label} />
        </label>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionPanel}>
        {children}
      </AccordionDetails>
      {expansionActionButtons && (
        <Fragment>
          <Divider />
          <AccordionActions>
            {expansionActionButtons.map((button) => (
              <Button
                size="small"
                key={button.buttonLabel}
                variant={button.variant ? button.variant : "text"}
                color={button.color ? button.color : "primary"}
                onClick={button.action}
              >
                {button.buttonLabel}
              </Button>
            ))}
          </AccordionActions>
        </Fragment>
      )}
    </Accordion>
  );
};
export default MUIExpansionPanel;
