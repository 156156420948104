import React from "react";
import { AccountProvider } from "../../provider/AccountProvider";
import { SectionProvider } from "../../provider/SectionProvider";
import { InstallationProvider } from "../../provider/InstallationProvider";
import Accounts from "./Accounts";

const AccountIndex = (props: any) => {
  return (
    <AccountProvider>
      <SectionProvider>
        <InstallationProvider>
          <Accounts {...props} />
        </InstallationProvider>
      </SectionProvider>
    </AccountProvider>
  );
};
export default AccountIndex;
