import { adalApiFetch } from "../../clientApp/configAdal";
import { endPoints } from "../../clientApp/endPoints";

export const pullDeviceCharacteristics = async () => {
  return await adalApiFetch(
    fetch,
    `${endPoints.deviceCharacteristics}/GetAll`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json"
      }
    }
  )
    .then(res => res.json())
    .then(posts => {
      return posts.deviceCharacteristics;
    });
};
