import { adalApiFetch } from "../../clientApp/configAdal";
import { endPoints } from "../../clientApp/endPoints";

export const pullLanguages = async () => {
  return await adalApiFetch(fetch, `${endPoints.languages}/GetAll`, {
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  })
    .then(res => res.json())
    .then(posts => {
      return posts.languages;
    });
};
