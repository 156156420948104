import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

const Footer = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        position: "absolute",
        bottom: 0,
        left: 0,
        marginBottom: "20px",
        width: "100%"
      }
    })
  );
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.root}
    >
      {"be think innovate. Copyright © "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export default Footer;
