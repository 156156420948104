import React, { Fragment } from "react";
import isEmpty from "lodash/isEmpty";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { default as MUIList } from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { grey } from "@material-ui/core/colors";
import Skeleton from "@material-ui/lab/Skeleton";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";

import { IListProps, ICheckedItemList, IDisableListLogic } from "./type";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: "100%",
      maxWidth: "100%",
      backgroundColor: theme.palette.background.paper
    },
    horizontalList: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      padding: 0
    },
    horizontalListItem: {
      flex: "1 0 21%"
    },
    actionableListItem: {
      minHeight: theme.spacing(7)
    },
    listLabel: {
      fontSize: 12
    },
    nested: {
      paddingLeft: (props: any) =>
        props.checkable ? theme.spacing(10) : theme.spacing(4)
    },
    nestedText: {
      fontSize: 10,
      color: grey[500]
    }
  })
);

const getFilteredNestedItems = (items: any[] = []) =>
items.filter((ni) => ni.name || ni.permissionid);

export const generateListData = (
  items: any[],
  id: string,
  label: string,
  checked: string,
  nested: string,
  nestedItem: string,
  disabledField: string
) => {
  const data: ICheckedItemList[] = [];

  if (items) {
    items.forEach((i) => {
      const item: any = {
        label: i[label],
        value: i[id],
        checked: i[checked]
      };

      if (disabledField) {
        item.disabled = i[disabledField];

        if(i[disabledField])
        {
          item.checked = i[disabledField];
        }

      } else {
        item.disabled = false;
      }
      if (i[nested] && i[nested].length > 0) {
        const nestedItems = getFilteredNestedItems(i[nested] as any[]).map(
          (ni) => ni[nestedItem]
        );

        item.nested = nestedItems;
      }

      data.push(item);
    });
  }

  return data;
};

export const getDisabledListItems = (
  disableLogic: IDisableListLogic[] = [],
  data: ICheckedItemList[]
) => {
  if (disableLogic && !isEmpty(disableLogic)) {
    let newData: ICheckedItemList[] = data.map((listItem: ICheckedItemList) => {
      listItem.disabled = false;
      return listItem;
    });

    disableLogic.forEach((disableLogicItem: IDisableListLogic) => {
      const checkIfChecked = data.filter(
        (listItem: ICheckedItemList) =>
          listItem.label === disableLogicItem.label && listItem.checked
      );
      if (checkIfChecked.length > 0) {
        newData = data.map((listItem: ICheckedItemList) => {
          if (disableLogicItem.disabledLabels.includes(listItem.label)) {
            listItem.disabled = true;
          }
          return listItem;
        });
      }
    });
    return newData;
  }
  return data;
};

const List = ({
  data,
  setData,
  label,
  horizontal,
  checkable,
  actions,
  changedOnlyData,
  setChangedOnlyData,
  showSkeleton,
  disableLogic
}: IListProps) => {
  const classes = useStyles({ checkable });

  const handleCheckList = (value: string) => () => {
    let changedData = {
      label: "",
      value: "",
      checked: false
    };
    let newData: ICheckedItemList[] = [];
    if (setData) {
      newData = data.map((listItem: ICheckedItemList) => {
        if (listItem.value === value) {
          listItem.checked = !listItem.checked;
          changedData = listItem;
        }
        return listItem;
      });
      newData = getDisabledListItems(disableLogic, newData);
      setData(newData);
    }
    if (setChangedOnlyData && changedOnlyData) {
      const filteredChangedOnlyData = changedOnlyData.filter(
        (listItem) => listItem.value === value
      );
      if (filteredChangedOnlyData.length > 0) {
        setChangedOnlyData(
          changedOnlyData.filter((listItem) => listItem.value !== value)
        );
      } else {
        setChangedOnlyData([...changedOnlyData, changedData]);
      }
    }
  };

  return (
    <Box>
      {label && (
        <Typography className={classes.listLabel} color="textSecondary">
          {label}
        </Typography>
      )}
      {showSkeleton ? (
        <Fragment>
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
        </Fragment>
      ) : (
        <MUIList className={horizontal ? classes.horizontalList : classes.list}>
          {data.map((listItem: ICheckedItemList, index: number) => {
            const labelId = `checkbox-list-label-${listItem.value}`;

            return (
              <div key={`listKey${index}`}>
                <ListItem
                  key={listItem.value}
                  role={undefined}
                  dense
                  button
                  onClick={
                    checkable && !listItem.disabled
                      ? handleCheckList(listItem.value)
                      : undefined
                  }
                  classes={{
                    root: actions ? classes.actionableListItem : "",
                    container: horizontal ? classes.horizontalListItem : ""
                  }}
                >
                  {checkable && (
                    <ListItemIcon>
                      <Checkbox
                        disabled={listItem.disabled}
                        color="primary"
                        edge="start"
                        checked={listItem.checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                  )}
                  <ListItemText id={labelId} primary={listItem.label} />
                  <ListItemSecondaryAction>
                    {actions &&
                      actions.map((action: any, key: number) => (
                        <IconButton
                          key={key}
                          edge="end"
                          onClick={() => action.onSubmit(listItem.value)}
                        >
                          <action.icon />
                        </IconButton>
                      ))}
                  </ListItemSecondaryAction>
                </ListItem>
                {listItem.nested && (
                  <MUIList component="div" disablePadding>
                    {listItem.nested.map(
                      (nestedItem: string, nestedIndex: number) => (
                        <ListItem
                          key={`listNestedKey${index}_${nestedIndex}`}
                          dense
                          button
                          className={classes.nested}
                        >
                          <ListItemIcon>
                            <SubdirectoryArrowRightIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={nestedItem}
                            className={classes.nestedText}
                          />
                        </ListItem>
                      )
                    )}
                  </MUIList>
                )}
              </div>
            );
          })}
        </MUIList>
      )}
    </Box>
  );
};

export default List;
