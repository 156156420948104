import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { SubscriptionTypeFilterProps } from "./type";
import { SUBSCRIPTION_TYPE } from "constants/constant";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
    margin: "0 10px",
    padding: 0,
  },
}));

const SubscriptionTypeFilter = ({
  onFilterChange,
  selectedSubscriptionFilter,
}: SubscriptionTypeFilterProps): JSX.Element => {
  const classes = useStyles();

  const subscriptionTypes = [
    SUBSCRIPTION_TYPE.WITH_SUBSCRIPTION,
    SUBSCRIPTION_TYPE.WITHOUT_SUBSCRIPTION,
  ];

  const handleSubscriptionFilterTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    onFilterChange(event.target.value as string[]);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        multiple
        displayEmpty
        value={selectedSubscriptionFilter}
        onChange={handleSubscriptionFilterTypeChange}
        input={<Input />}
        renderValue={(selected) => {
          if ((selected as string[]).length === 0) {
            return "Subscription Type";
          }

          return (selected as string[]).join(", ");
        }}
      >
        {subscriptionTypes.map((subscriptionType) => (
          <MenuItem key={subscriptionType} value={subscriptionType}>
            <Checkbox
              checked={
                selectedSubscriptionFilter.indexOf(subscriptionType) > -1
              }
            />
            <ListItemText primary={subscriptionType} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SubscriptionTypeFilter;
