import React, { useState, createContext } from "react";
import { pullDeviceCharacteristics } from "./Actions";
import { IDeviceCharacteristics } from "components/type";

export const DeviceContext = createContext<any>([
  {},
  () => {
    return;
  },
]);

export const DeviceProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [deviceCharacteristics, setDeviceCharacteristics] = useState<
    IDeviceCharacteristics[]
  >([]);

  const getAllDeviceCategory = (deviceCharsProp?: IDeviceCharacteristics[]) => {
    let deviceC = deviceCharacteristics;
    if (deviceCharsProp) {
      deviceC = deviceCharsProp;
    }
    const deviceCategories = deviceC.map((item) => {
      return item["assetCategory"];
    });
    const uniqueAssetCategories = deviceCategories
      .filter((item, index) => {
        return deviceCategories.indexOf(item) === index;
      })
      .map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    return uniqueAssetCategories;
  };

  const getParentDeviceModalName = (
    deviceCharsProp?: IDeviceCharacteristics[]
  ) => {
    let deviceC = deviceCharacteristics;
    if (deviceCharsProp) {
      deviceC = deviceCharsProp;
    }
    const deviceCategories = deviceC
      .filter((item) => item["assetCategory"] === "Communication Device")
      .map((item) => {
        return item["assetModelName"];
      });
    const uniqueAssetCategories = deviceCategories
      .filter((item, index) => {
        return deviceCategories.indexOf(item) === index;
      })
      .map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    return uniqueAssetCategories;
  };

  const getSubDeviceModalName = (
    deviceCharsProp?: IDeviceCharacteristics[]
  ) => {
    let deviceC = deviceCharacteristics;
    if (deviceCharsProp) {
      deviceC = deviceCharsProp;
    }
    const deviceCategories = deviceC
      .filter((item) => item["assetCategory"] === "Equipment")
      .map((item) => {
        return item["assetModelName"];
      });
    const uniqueAssetCategories = deviceCategories
      .filter((item, index) => {
        return deviceCategories.indexOf(item) === index;
      })
      .map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    return uniqueAssetCategories;
  };

  const getSystemDeviceModalName = (
    deviceCharsProp?: IDeviceCharacteristics[]
  ) => {
    let deviceC = deviceCharacteristics;
    if (deviceCharsProp) {
      deviceC = deviceCharsProp;
    }
    const deviceCategories = deviceC
      .filter((item) => item["assetCategory"] === "System")
      .map((item) => {
        return item["assetModelName"];
      });
    const uniqueAssetCategories = deviceCategories
      .filter((item, index) => {
        return deviceCategories.indexOf(item) === index;
      })
      .map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    return uniqueAssetCategories;
  };

  const getAllDeviceModalName = (
    deviceCharsProp?: IDeviceCharacteristics[]
  ) => {
    let deviceC = deviceCharacteristics;
    if (deviceCharsProp) {
      deviceC = deviceCharsProp;
    }
    const deviceCategories = deviceC.map((item) => {
      return item["assetModelName"];
    });
    const uniqueAssetCategories = deviceCategories
      .filter((item, index) => {
        return deviceCategories.indexOf(item) === index;
      })
      .map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    return uniqueAssetCategories;
  };

  const getAllDeviceType = (deviceCharsProp?: IDeviceCharacteristics[]) => {
    let deviceC = deviceCharacteristics;
    if (deviceCharsProp) {
      deviceC = deviceCharsProp;
    }
    const deviceCategories = deviceC.map((item) => {
      return item["assetType"];
    });
    const uniqueAssetCategories = deviceCategories
      .filter((item, index) => {
        return deviceCategories.indexOf(item) === index;
      })
      .map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    return uniqueAssetCategories;
  };

  const getAllVendor = (deviceCharsProp?: IDeviceCharacteristics[]) => {
    let deviceC = deviceCharacteristics;
    if (deviceCharsProp) {
      deviceC = deviceCharsProp;
    }
    const deviceCategories = deviceC.map((item) => {
      return item["vendor"];
    });
    const uniqueAssetCategories = deviceCategories
      .filter((item, index) => {
        return deviceCategories.indexOf(item) === index;
      })
      .map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    return uniqueAssetCategories;
  };

  const getDeviceCategoryByProductName = (modelName: string) => {
    const deviceCategories = deviceCharacteristics.filter(
      (item) => item["assetModelName"] === modelName
    );
    return deviceCategories;
  };

  const getDeviceCharacteristics = async (cb: (callbackReturn: {}) => void) => {
    await pullDeviceCharacteristics().then((deviceChars) => {
      setDeviceCharacteristics(deviceChars);
      const callbackReturn = {
        getAllDeviceCategory: getAllDeviceCategory(deviceChars),
        getParentDeviceModalName: getParentDeviceModalName(deviceChars),
        getSubDeviceModalName: getSubDeviceModalName(deviceChars),
        getSystemDeviceModalName: getSystemDeviceModalName(deviceChars),
        getAllDeviceModalName: getAllDeviceModalName(deviceChars),
        getAllDeviceType: getAllDeviceType(deviceChars),
        getAllVendor: getAllVendor(deviceChars),
      };
      cb(callbackReturn);
    });
  };

  return (
    <DeviceContext.Provider
      value={{
        deviceCharacteristicsContext: [
          deviceCharacteristics,
          getDeviceCharacteristics,
        ],
        getAllDeviceCategory: getAllDeviceCategory,
        getParentDeviceModalName: getParentDeviceModalName,
        getSubDeviceModalName: getSubDeviceModalName,
        getSystemDeviceModalName: getSystemDeviceModalName,
        getAllDeviceModalName: getAllDeviceModalName,
        getAllDeviceType: getAllDeviceType,
        getAllVendor: getAllVendor,
        getDeviceCategoryByProductName: getDeviceCategoryByProductName,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
