import React, { useState, createContext } from "react";

import { AccountTabKeys } from "../../constants/enum";

export const AccountContext = createContext<any>([
  {},
  () => {
    return;
  },
]);

export const AccountProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [updateAccount, setUpdateAccount] = useState({});
  const [openHierarchy, setOpenHierarchy] = useState({});
  const [accountTabKey, setAccountTabKey] = useState(
    AccountTabKeys.accountOverview
  );
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [toggleItem, setUpdateToggleItem] = useState();
  return (
    <AccountContext.Provider
      value={{
        updateAccountContext: [updateAccount, setUpdateAccount],
        openHierarchyContext: [openHierarchy, setOpenHierarchy],
        accountTabKeyContext: [accountTabKey, setAccountTabKey],
        accountOverviewCurrentPageContext: [currentPageNum, setCurrentPageNum],
        treeViewToggleItemContext: [toggleItem, setUpdateToggleItem],
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
