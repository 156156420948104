import React, { useState, createContext } from "react";

export const SectionContext = createContext<any>([
  {},
  () => {
    return;
  },
]);

export const SectionProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [createSection, setCreateSection] = useState({});
  const [updateSection, setUpdateSection] = useState({});
  const [loadSection, setloadSection] = useState({});

  return (
    <SectionContext.Provider
      value={{
        createSectionContext: [createSection, setCreateSection],
        updateSectionContext: [updateSection, setUpdateSection],
        loadSectionContext: [loadSection, setloadSection],
      }}
    >
      {children}
    </SectionContext.Provider>
  );
};
