import React, { useState, createContext } from "react";
import isEmpty from "lodash/isEmpty";

import { pullIOTSystems } from "./Actions";

import { CONTEXT_CACHE_EXPIRATION } from "constants/config";

export const IOTSystemsContext = createContext<any>(null);

export const IOTSystemsProvider = ({
  children
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [iotSystems, setIOTSystems] = useState([]);
  const [dateTimeRefresh, setDateTimeRefresh] = useState(0);
  const getIOTSystems = async () => {
    const currentDate = new Date();
    const dateTime = currentDate.getTime();
    if (isEmpty(iotSystems) || dateTimeRefresh < dateTime) {
      await pullIOTSystems().then(iotSystems => {
        const currentDate = new Date();
        const dateTime = currentDate.getTime();
        setDateTimeRefresh(dateTime + CONTEXT_CACHE_EXPIRATION);
        setIOTSystems(iotSystems);
      });
    }
  };

  return (
    <IOTSystemsContext.Provider value={[iotSystems, getIOTSystems]}>
      {children}
    </IOTSystemsContext.Provider>
  );
};
