import React, { useEffect, useState, useContext, Fragment } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "provider/UserProvider";

import isEmpty from "lodash/isEmpty";

import { UsersRootPath, UsersUpdatePath } from "routes/paths";
import {
  getUser,
  getRolesByUserHierarchy,
  saveRoles,
  getAssociatedAsset,
} from "../../../actions/usersPageActions";

import PageHeader from "components/PageHeader";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import PageHeaderDetailItem from "components/PageHeaderDetailItem";
import PageHeaderRightItem from "components/PageHeaderRightItem";
import MainBarDashboard from "components/MainBarDashboard";
import ViewTreeView from "components/ViewTreeView";
import DialogModal from "components/DialogModal";
import PaperHeading from "components/PaperHeading";
import List, { generateListData, ICheckedItemList } from "components/List";
import { IAsset } from "components/type";
import { IAssociatedAssets, IRoleAction, IUserHierarchy } from "./type";

import { Paper, Grid, Box } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import WarningIcon from "@material-ui/icons/Warning";

const UserAssetRoles = () => {
  const { associateUserContext } = useContext(UserContext);
  const [associateUser, setAssociateUser] = associateUserContext;

  const { userId, hierarchyId } = useParams<{
    userId: string;
    hierarchyId: string;
  }>();

  const [userAsset, setUserAsset] = useState<IAssociatedAssets>();
  const [roles, setRoles] = useState<ICheckedItemList[]>([]);
  const [changedOnlyRoles, setChangedOnlyRoles] = useState<ICheckedItemList[]>(
    []
  );
  const [isRolesLoading, setIsRolesLoading] = useState(false);
  const [showChangeConfirmation, setShowChangeConfirmation] = useState(false);
  const [isDefaultLoaded, setIsDefaultLoaded] = useState(false);
  const [hierarchyIdToBeChanged, setHierarchyIdToBeChanged] = useState("");
  const [overrideSingleSelected, setOverrideSingleSelected] = useState("");

  const fetchAssociatedAssets = (uId: string, hId: string) => {
    getAssociatedAsset(uId, hId).then((d) => {
      setUserAsset(d);
    });
  };

  useEffect(() => {
    if (userId && hierarchyId) {
      if (isEmpty(associateUser)) {
        getUser(userId).then((d) => {
          setAssociateUser(d);
          fetchAssociatedAssets(userId, hierarchyId);
        });
      } else {
        fetchAssociatedAssets(userId, hierarchyId);
      }
    }
  }, [userId, hierarchyId]);

  const breadcrumbs = [
    {
      label: "Users",
      href: UsersRootPath,
    },
    {
      label: `${associateUser.fullName}`,
      href: userId ? UsersUpdatePath.replace(":userId", userId) : "",
    },
    {
      label: "User Asset Roles",
    },
  ];

  const getHierarchyLabel = (item: IAsset) => {
    return item ? `${item.assetName} (${item.typeName})` : "";
  };
  const getId = (item: IAsset) => {
    return item ? item.hierarchyId : "";
  };
  const getChecked = (item: IAsset) => {
    return item ? item.selected : false;
  };

  const generateRoleActions = (checkedItems: ICheckedItemList[]) => {
    const data: IRoleAction[] = [];

    if (checkedItems) {
      checkedItems.forEach((i) => {
        const checkedItem = {
          roleId: i.value,
          action: i.checked ? 1 : 2
        };
        data.push(checkedItem);
      });
    }

    return data;
  };

  const getRoles = (hierarchyId: string) => {
    if (associateUser) {
      setIsRolesLoading(true);
      getRolesByUserHierarchy(associateUser.id, hierarchyId)
        .then((res) => {
          setIsRolesLoading(false);
          setRoles(
            generateListData(
              res.roles,
              "roleId",
              "name",
              "isAssigned",
              "permissions",
              "name",
              "isAssignedInherited"
            )
          );
          
          setChangedOnlyRoles([]);
        })
        .catch((error) => {
          setIsRolesLoading(false);
        });
    }
  };

  const saveUsetAssetRoles = () => {
    if (associateUser && changedOnlyRoles.length > 0) {
      saveRoles(
        associateUser.id,
        hierarchyIdToBeChanged,
        generateRoleActions(changedOnlyRoles)
      ).then((res) => {
        setChangedOnlyRoles([]);
        getRoles(hierarchyIdToBeChanged);
      });
    }
  };

  const onSingleSelect = (hierarchyId: string) => {
    return new Promise((resolve, reject) => {
      setHierarchyIdToBeChanged(hierarchyId);
      if (!isDefaultLoaded) {
        setIsDefaultLoaded(true);
        getRoles(hierarchyId);
        setOverrideSingleSelected("");
        resolve(true);
      } else {
        if (changedOnlyRoles.length > 0) {
          setShowChangeConfirmation(true);
          resolve(false);
        } else {
          getRoles(hierarchyId);
          setOverrideSingleSelected("");
          resolve(true);
        }
      }
    });
  };

  const getIsSingleSelectedDisabled = (item: IUserHierarchy) => {
    return item ? item.selected : false;
  };

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <PageHeader
        title={`${associateUser.firstName} ${associateUser.lastName}`}
        subTitle={
          associateUser && associateUser.company ? associateUser.company : ""
        }
        details={
          <Fragment>
            <PageHeaderDetailItem
              label="ID"
              value={!isEmpty(associateUser) ? associateUser.tamId : ""}
            />
          </Fragment>
        }
      >
        <PageHeaderRightItem
          label="Status"
          value={
            !isEmpty(associateUser)
              ? associateUser.status
                ? "Active"
                : "Inactive"
              : ""
          }
        />
        <PageHeaderRightItem
          label="Email"
          value={!isEmpty(associateUser) ? associateUser.primaryEmail : ""}
        />
      </PageHeader>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Paper>
            <Box pl={2} pt={2} pb={1}>
              <PaperHeading label="Assets" />
            </Box>
            <Box pl={4}>
              <ViewTreeView
                data={userAsset}
                renderLabel={getHierarchyLabel}
                getId={getId}
                getChecked={getChecked}
                onSingleSelect={onSingleSelect}
                overrideSingleSelected={overrideSingleSelected}
                getIsSingleSelectedDisabled={getIsSingleSelectedDisabled}
              ></ViewTreeView>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper>
            <Box pl={2} pt={2} pb={1}>
              <PaperHeading label="Roles & Permissions" />
            </Box>
            <Box px={3} py={1}>
              {isRolesLoading || roles.length > 0 ? (
                <List
                  checkable
                  data={roles}
                  setData={setRoles}
                  changedOnlyData={changedOnlyRoles}
                  setChangedOnlyData={setChangedOnlyRoles}
                  showSkeleton={isRolesLoading}
                />
              ) : hierarchyIdToBeChanged === "" ? (
                <h6>Please select an asset first</h6>
              ) : (
                <h6>No Roles</h6>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {!isEmpty(userAsset) && (
        <FloatingActionButtonList>
          <FloatingActionButton
            disabled={changedOnlyRoles.length === 0}
            onClick={saveUsetAssetRoles}
          >
            <SaveIcon />
            Save
          </FloatingActionButton>
        </FloatingActionButtonList>
      )}
      <DialogModal
        showCancelButton
        title="Role Changes Not Saved"
        message="You are about to view a different Asset/Roles. Current role changes will not be saved. Do you wish to proceed?"
        showModal={showChangeConfirmation}
        onOk={() => {
          getRoles(hierarchyIdToBeChanged);
          setOverrideSingleSelected(hierarchyIdToBeChanged);
          setShowChangeConfirmation(false);
        }}
        onHide={(): void => setShowChangeConfirmation(false)}
        confirmButtonLabel="Proceed"
        icon={<WarningIcon fontSize="large" color="secondary" />}
      />
    </MainBarDashboard>
  );
};

export default UserAssetRoles;
