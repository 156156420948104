import React, { useState, useEffect, useContext } from "react";
import { authContext } from "clientApp/configAdal";
import Denied from "pages/Denied";
import { getAdminUser } from "./Actions";
import Loader from "../components/Loader";

import { AuthContext } from "provider/AuthProvider";

const PrivateRoute = ({
  component,
  props,
}: {
  component: any;
  props: any;
}): JSX.Element => {
  const Component = component;
  const context: any = authContext;
  const {
    isAuthenticatedContext,
    permissionContext,
    azureIdContext,
  } = useContext(AuthContext);
  const [isAuthenticated, setIsAuthenticated] = isAuthenticatedContext;
  const [, setCurrentPermissions] = permissionContext;
  const [, setAzureId] = azureIdContext;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchData = async (): Promise<void> => {
      if (context.account.accountIdentifier) {
        getAdminUser(context.account.accountIdentifier).then((adminUser) => {
          setIsAuthenticated(adminUser.isValid || false);
          setCurrentPermissions(adminUser.permissions);
          setAzureId(context.account.accountIdentifier);
          if (context.account) {
            setLoading(false);
          }
        });
      }
    };
    fetchData();
  }, [isAuthenticated]);

  if (loading) {
    return <Loader />;
  } else {
    if (context.account && isAuthenticated !== undefined && isAuthenticated) {
      return <Component {...props} />;
    } else {
      return <Denied />;
    }
  }
};

export default PrivateRoute;
