import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

import IconButton from "@material-ui/core/IconButton";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { getOptions } from "utils";
import { FormErrorMessages } from "constants/enum";
import { SectionCreatePath } from "routes/paths";

import Dropdown from "components/MUIDropdown";
import NewTextField from "components/TextField";
import SkeletonLine from "components/Skeleton/SkeletonPulse";

import { SectionContext } from "provider/SectionProvider";
import { CountriesContext } from "provider/CountriesProvider/CountriesProvider";
import { AccountContext } from "provider/AccountProvider";
import { ModalContext } from "provider/ModalProvider";
import { SpinnerContext } from "provider/SpinnerProvider";

import {
  getSections,
  getSectionById,
  patchSection,
} from "../../../actions/sectionsPageActions";
import { getAccount } from "../../../actions/accountsPageActions";

const CreateSectionModal = (props: any) => {
  const history = useHistory();
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [complete, setComplete] = useState(false);
  const { updateAccountContext, treeViewToggleItemContext } = useContext(
    AccountContext
  );
  const {
    updateSectionContext,
    loadSectionContext,
    createSectionContext,
  } = useContext(SectionContext);
  const { sectionModalContext } = useContext(ModalContext);

  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [countries, getCountries] = useContext(CountriesContext);

  const [section] = updateSectionContext;
  const [, setUpdateExpandedIds] = treeViewToggleItemContext;
  const [account, setAccount] = updateAccountContext;
  const [loadSection, setLoadSection] = loadSectionContext;
  const [, setShowSectionModal] = sectionModalContext;
  const [, setCreateSection] = createSectionContext;
  const { register, handleSubmit, errors, control, setValue } = useForm();

  const createObjects = {
    rootId: section.rootId,
    parentId: section.parentId,
    isQuickCreate: true,
    isCreate: false,
  };

  const goToSectionForm = (): void => {
    setShowSectionModal(false);
    setCreateSection(createObjects);
    history.push(SectionCreatePath);
  };

  const onSubmit = (data: any) => {
    setIsSpinnerVisible(true);
    setShowSectionModal(false);

    const attachDetails = {
      hierarchyId: section.parentId,
      sectionId: selectedSectionId,
    };
    patchSection(attachDetails).then((e) => {
      setIsSpinnerVisible(false);
      setUpdateExpandedIds(section.parentId);
      getAccount(account.id).then((e) => {
        setAccount(e);
      });
    });
  };

  const handleSelectSection = (id: string) => {
    setIsSpinnerVisible(true);
    setSelectedSectionId(id);
    getSectionById(id).then((e) => {
      setValue("state", e.state);
      setValue("city", e.city);
      setValue("street", e.street);
      setValue("postal", e.postalCode);
      setValue("countryId", e.countryId);
      setIsSpinnerVisible(false);
    });
  };

  const displaySelectOptions = (
    <Autocomplete
      id="sectionName"
      disableClearable={true}
      options={loadSection}
      getOptionLabel={(option) => option.assetName}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Section Name"
          fullWidth
          name="sectionId"
          inputRef={register({
            required: FormErrorMessages.fieldRequired,
          })}
        />
      )}
      onChange={(event: object, value: any) => handleSelectSection(value.id)}
    />
  );

  useEffect(() => {
    getCountries();
    getSections().then((e) => {
      setLoadSection(e.availableSections);
      setComplete(true);
    });
    setValue("account", account.assetName);
  }, []);

  return (
    <Form id="createSection-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="col-sm-12">
        {complete ? (
          isEmpty(section) && complete ? (
            <div className="form-control">No available section</div>
          ) : (
            <div className="row">
              <div className="col-sm-11">{displaySelectOptions}</div>
              <div className="col-sm-1" style={{ padding: "15px" }}>
                <Tooltip title="Create new section">
                  <IconButton
                    aria-label="Create new section"
                    onClick={() => goToSectionForm()}
                  >
                    <AddOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )
        ) : (
          <div className="form-control">
            <SkeletonLine />
          </div>
        )}
      </div>
      <div className="col-sm-12">
        <NewTextField
          readOnly={true}
          label="Account"
          name="account"
          inputRef={register({
            required: false,
          })}
        />
      </div>

      <div className="col-sm-12">
        <Dropdown
          label="Country"
          readOnly={true}
          name="countryId"
          control={control}
          data={getOptions(countries, "id", "countryName")}
        />
      </div>
      <div className="col-sm-12">
        <NewTextField
          readOnly={true}
          error={errors.state}
          label="State"
          name="state"
          inputRef={register({
            required: false,
          })}
        />
      </div>

      <div className="col-sm-12">
        <NewTextField
          error={errors.city}
          label="City"
          name="city"
          readOnly={true}
          inputRef={register({
            required: false,
          })}
        />
      </div>
      <div className="col-sm-12">
        <NewTextField
          readOnly={true}
          error={errors.street}
          label="Street"
          name="street"
          inputRef={register({
            required: false,
          })}
        />
      </div>
      <div className="col-sm-12">
        <NewTextField
          readOnly={true}
          error={errors.postal}
          label="Postal Code"
          name="postal"
          inputRef={register({
            required: false,
          })}
        />
      </div>
    </Form>
  );
};

export default CreateSectionModal;
