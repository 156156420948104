export class EnvConfig {
	private _hostname: string;
	private _assetApiUrl: string;

	get hostname() {
		return this._hostname;
	}
	set hostname(value) {
		this._hostname = value;
	}

	get assetApiUrl() {
		return this._assetApiUrl;
	}
	set assetApiUrl(value) {
		this._assetApiUrl = value;
	}
	constructor(hostname = "", assetApiUrl = "") {
		this._hostname = hostname;
		this._assetApiUrl = assetApiUrl;
	}

	protected readonly getValue = (envUrl = "") => envUrl;

	public getAssetEndpoint() {
		return "";
	}
}
