import { DeviceReference, GetDeviceProps } from "./type";
import { ILabelValue } from "../../../../components/type";
import {
  validateRangeLatitude,
  validateRangeLongitude,
  validateIsNaN,
  validateLatLongDecimal
} from "../../../../utils";
import { VALIDATION_ERROR_MESSAGES } from "constants/constant";

export const getOptions = (
  items: any[],
  id: string,
  label: string,
  typeName?: string
): ILabelValue[] => {
  const data: ILabelValue[] = [];
  items.forEach((i) => {
    const typeNameContainer = typeName ? `(${i[typeName]})` : "";
    const optionLabel = `${i[label]} ${typeNameContainer}`;

    data.push({
      label: optionLabel,
      value: i[id]
    });
  });

  return data;
};

export const getDisplayDeviceHash = (selectedDevice?: GetDeviceProps) => {
  return selectedDevice?.computedHash || "";
};

export const getDisplayDeviceId = (selectedDevice?: GetDeviceProps) => {
  return selectedDevice?.deviceId || "";
};

export const getStatusToAddInSubmitData = (status = "") => {
  if (status !== "") {
    return status === "1";
  }

  return undefined;
};

export const getReasonToAddInSubmitData = (reason: string, status: string) => {
  let s = "";
  if (reason !== "") {
    s = reason;
  }

  if (reason === "" && status === "1") {
    s = "";
  }

  return s;
};

const latitudeUpperLimit = (value: string): any =>
  validateRangeLatitude(value) || VALIDATION_ERROR_MESSAGES.PLUS_OR_MINUS_90;

const longitudeUpperLimit = (value: string): any =>
  validateRangeLongitude(value) || VALIDATION_ERROR_MESSAGES.PLUS_OR_MINUS_180;

const getUpperlimitValidation = (coord: "lat" | "long" = "lat") => {
  if (coord === "lat") {
    return latitudeUpperLimit;
  } else {
    return longitudeUpperLimit;
  }
};

const coordinateIsNumberValidation = (value: string): any =>
  validateIsNaN(value) || VALIDATION_ERROR_MESSAGES.NUMBER_ONLY;

const coordinateDecimalsLimitValidation = (value: string): any =>
  validateLatLongDecimal(value) || VALIDATION_ERROR_MESSAGES.SEVEN_DECIMALS_MAX;

export const coordinatesValidation = (coord: "lat" | "long" = "lat") => {
  return {
    isNumber: coordinateIsNumberValidation,
    upperlimit: getUpperlimitValidation(coord),
    decimalsLimit: coordinateDecimalsLimitValidation
  };
};

export const isDeviceReferenceInList = (
  item: DeviceReference,
  list: DeviceReference[]
) => list.findIndex((d) => d.id === item.id) === -1;
