import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PeopleIcon from "@material-ui/icons/People";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import LockIcon from "@material-ui/icons/Lock";
import CardMembershipIcon from "@material-ui/icons/CardMembership";

import {
  AssetsRootPath,
  DigitalOfferingRootPath,
  SubscriptionsRootPath,
  DeviceRootPath,
  UsersRootPath,
  ServiceGroupRootPath,
  AuthorizationRootPath,
  AuthorizationOverviewPath
} from "routes/paths";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "8px 16px",
      left: "0 !important",
      margin: "0 !important",
      "&:hover": {
        backgroundColor: `${theme.palette.secondary.light} !important`,
        "& .MuiListItemText-root": {
          color: theme.palette.background.paper
        }
      },
      "&.Mui-selected": {
        backgroundColor: `${theme.palette.common.white} !important`,
        "& .MuiListItemText-root": {
          "& span": {
            color: theme.palette.secondary.dark,
            fontWeight: "bold"
          }
        }
      }
    },
    text: {
      color: theme.palette.background.paper
    }
  })
);

const MainListItems = () => {
  const history = useHistory();
  const classes = useStyles();
  const { url } = useRouteMatch();
  return (
    <div>
      <MenuItem
        className={classes.root}
        selected={url === AssetsRootPath}
        button
        onClick={() => {
          history.push(AssetsRootPath);
        }}
      >
        <ListItemIcon>
          <AccountTreeIcon color="secondary" />
        </ListItemIcon>
        <ListItemText className={classes.text} primary="Assets" />
      </MenuItem>
      <MenuItem
        className={classes.root}
        selected={url === DigitalOfferingRootPath}
        button
        onClick={() => {
          history.push(DigitalOfferingRootPath);
        }}
      >
        <ListItemIcon>
          <LocalOfferIcon color="secondary" />
        </ListItemIcon>
        <ListItemText className={classes.text} primary="Digital Offering" />
      </MenuItem>
      <MenuItem
        className={classes.root}
        selected={url === DeviceRootPath}
        button
        onClick={() => {
          history.push(DeviceRootPath);
        }}
      >
        <ListItemIcon>
          <DeviceHubIcon color="secondary" />
        </ListItemIcon>
        <ListItemText className={classes.text} primary="Devices" />
      </MenuItem>
      <MenuItem
        className={classes.root}
        selected={url === UsersRootPath}
        button
        onClick={() => {
          history.push(UsersRootPath);
        }}
      >
        <ListItemIcon>
          <PeopleIcon color="secondary" />
        </ListItemIcon>
        <ListItemText className={classes.text} primary="Users" />
      </MenuItem>
      <MenuItem
        className={classes.root}
        selected={url === ServiceGroupRootPath}
        button
        onClick={() => {
          history.push(ServiceGroupRootPath);
        }}
      >
        <ListItemIcon>
          <SupervisedUserCircleIcon color="secondary" />
        </ListItemIcon>
        <ListItemText className={classes.text} primary="Service Group" />
      </MenuItem>
      <MenuItem
        className={classes.root}
        selected={url.startsWith(AuthorizationRootPath)}
        button
        onClick={() => {
          history.push(AuthorizationOverviewPath);
        }}
      >
        <ListItemIcon>
          <LockIcon color="secondary" />
        </ListItemIcon>
        <ListItemText className={classes.text} primary="Authorization" />
      </MenuItem>
      <MenuItem
        className={classes.root}
        selected={url === SubscriptionsRootPath}
        button
        onClick={() => {
          history.push(SubscriptionsRootPath);
        }}
      >
        <ListItemIcon>
          <CardMembershipIcon color="secondary" />
        </ListItemIcon>
        <ListItemText className={classes.text} primary="Subscriptions" />
      </MenuItem>
    </div>
  );
};

export default MainListItems;
