import React, { useContext } from "react";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router";
import CreateUpdateTreeView from "components/CreateUpdateTreeView";
import UpdateAccountForm from "./Form";
import NewSectionModal from "pages/Section/SectionModal";
import NewInstallationModal from "pages/Installation/InstallationModal";
import MainBarDashboard from "components/MainBarDashboard";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PaperHeading from "components/PaperHeading";
import PageHeader from "components/PageHeader";
import { AccountContext } from "provider/AccountProvider";
import { AssetsRootPath, AccountsUpdatePath } from "routes/paths";
import { AccountOverview as AccountsOverviewConst } from "constants/constant";

const UpdateAccount = () => {
  const { updateAccountContext } = useContext(AccountContext);
  const [account] = updateAccountContext;
  const { id } = useParams<{ id: string }>();

  const breadcrumbs = [
    {
      label: AccountsOverviewConst.BREADCRUMBS_LABEL,
      href: AssetsRootPath,
    },
    {
      label: `Account Details (${id})`,
      href: AccountsUpdatePath,
    },
  ];

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <PageHeader
        title={!isEmpty(account) ? account!.assetName : ""}
        subTitle={!isEmpty(account) ? account!.typeName : ""}
      >
        {" "}
      </PageHeader>
      <Grid container spacing={3}>
        <UpdateAccountForm accountId={id}>
          <Box mb={3}>
            <Paper>
              <Box p={3}>
                <PaperHeading label="Account Hierarchy" />
                <CreateUpdateTreeView />
              </Box>
            </Paper>
          </Box>
        </UpdateAccountForm>
      </Grid>
      <NewSectionModal />
      <NewInstallationModal />
    </MainBarDashboard>
  );
};
export default UpdateAccount;
