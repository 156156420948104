import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { ConfigChecker } from "./envConfig";

const hostname = window && window.location && window.location.hostname;
const protocol = window.location.protocol;
const port = process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : "";
export const uri = `${protocol}//${hostname}${port}`;
const checker = new ConfigChecker(hostname);
const envConfig = checker.getEnvConfig();

export const apiUrl = envConfig.getAssetEndpoint();

const azureAd = {
  instance: "https://login.microsoftonline.com",
  domain: "grundfos.onmicrosoft.com",
  clientId: "963e8e70-fcf9-44e3-8806-8ed078a10def",
  tenantId: "dabd5d90-87c2-44c9-93cd-783e03236e40",
  resourceId: "def2667f-a58b-4165-9ad1-e6db545697d8"
};

const config = {
  auth: {
    authority: `${azureAd.instance}/${azureAd.tenantId}`,
    clientId: azureAd.clientId,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "sessionStorage" as "sessionStorage",
    storeAuthStateInCookie: true
  }
};

const authenticationParameters = {
  scopes: [`${azureAd.resourceId}/user_impersonation`]
};

const typeParameter = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: uri
};

export const authContext = new MsalAuthProvider(
  config,
  authenticationParameters,
  typeParameter
);

export const adalApiFetch = async (fetch: any, url: string, options: any) => {
  const token = await authContext.getAccessToken();
  const o = options || {};
  if (!o.headers) {
    o.headers = {};
  }

  o.headers.Authorization = `Bearer ${token.accessToken}`;
  return fetch(url, o);
};
