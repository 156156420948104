import React, { useState, createContext } from "react";
import isEmpty from "lodash/isEmpty";

import { pullApplications } from "./Actions";

import { CONTEXT_CACHE_EXPIRATION } from "constants/config";

export const ApplicationsContext = createContext<any>(null);

export const ApplicationsProvider = ({
  children
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [applications, setApplications] = useState([]);
  const [dateTimeRefresh, setDateTimeRefresh] = useState(0);
  const getApplications = async () => {
    const currentDate = new Date();
    const dateTime = currentDate.getTime();
    if (isEmpty(applications) || dateTimeRefresh < dateTime) {
      await pullApplications().then(apps => {
        const currentDateForRefresh = new Date();
        const dateTimeForRefresh = currentDateForRefresh.getTime();
        setDateTimeRefresh(dateTimeForRefresh + CONTEXT_CACHE_EXPIRATION);
        setApplications(apps);
      });
    }
  };

  return (
    <ApplicationsContext.Provider value={[applications, getApplications]}>
      {children}
    </ApplicationsContext.Provider>
  );
};
