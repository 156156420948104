import React, { ReactElement, useEffect, useState } from "react";

import MainBarDashboard from "components/MainBarDashboard";
import EnhancedTable from "components/EnhancedTable";
import EnhancedPaginatedTable from "components/EnhancedPaginatedTable";
import AutoComplete from "components/AutoComplete";
import DialogModal from "components/DialogModal";
import { IHeadCell as IHeadCellNonpaginated } from "components/EnhancedTable/type";
import { IHeadCell } from "components/EnhancedPaginatedTable/type";
import { IMainTabsProps } from "components/MainTabs/type";
import { IPermission } from "./type";

import { Delete, Edit } from "@material-ui/icons";

import { getAuthorizations, getAssetNames, getPermissions } from "./Actions";

const AuthorizationOverview = ({ tabs }: IMainTabsProps): ReactElement => {
  const [showRolesModal, setShowRolesModal] = useState(false);
  const [modalRolesData, setModalRolesData] = useState<IPermission[]>([]);
  const [roleHeader, setRoleHeader] = useState("");
  const [query, setQuery] = useState("");

  const breadcrumbs = [
    {
      label: "Authorization Overview",
    },
  ];

  const viewRole = (obj: { [k: string]: any }) => {
    let roleId;
    if (obj.roles && obj.roles.length > 0) {
      obj.roles.forEach((role: { name: string; roleId: string }) => {
        if (role.name === obj.fieldValue) {
          roleId = role.roleId;
        }
      });
    }
    getPermissions(obj.hierarchyId, obj.subClaim, roleId).then((res) => {
      if (res) {
        setShowRolesModal(true);
        const filteredPermissions = res.filter(
          (permission: { assigned: boolean; inherited: boolean }) =>
            permission.assigned || permission.inherited
        );
        setModalRolesData(filteredPermissions);
      }
      setRoleHeader(obj.fieldValue);
    });
  };

  const headCells: IHeadCell[] = [
    {
      id: "policyPath",
      disablePadding: false,
      label: "policyPath",
      fieldName: "policyPath",
      hidden: true,
    },
    {
      id: "user",
      disablePadding: false,
      label: "User",
      fieldName: "user",
    },
    {
      id: "name",
      disablePadding: false,
      label: "Roles",
      fieldName: "name",
      isClickable: true,
      onClick: viewRole,
      referenceIds: ["hierarchyId", "subClaim", "roles"],
      isArray: true,
      arrayReference: "roles",
      arrayReferenceField: "name",
    },
    {
      id: "assetType",
      disablePadding: false,
      label: "Asset Type",
      fieldName: "assetType",
    },
    {
      id: "assetName",
      disablePadding: false,
      label: "Asset Name",
      fieldName: "assetName",
    },
    {
      id: "actionId",
      disablePadding: false,
      label: "Actions",
      hasAction: true,
      actions: [
        {
          onSubmit: () => null,
          icon: Edit,
          label: "Edit",
          referenceIds: ["user"],
        },
        {
          onSubmit: () => null,
          icon: Delete,
          label: "Delete",
          referenceIds: ["user"],
        },
      ],
    },
  ];

  const headCellsModalRoles: IHeadCellNonpaginated[] = [
    {
      id: "name",
      disablePadding: false,
      label: "Permission",
      fieldName: "name",
    },
    {
      id: "description",
      disablePadding: false,
      label: "Description",
      fieldName: "description",
    },
  ];

  const filterAssetName = (assetName: string) => {
    setQuery(assetName);
  };

  const filterComponents = [
    <AutoComplete
      isFilterStyle
      key={0}
      size="small"
      label="Asset Name"
      name="asset"
      labelName="assetName"
      valueName="assetName"
      getData={getAssetNames}
      inputRef={null}
      onChange={filterAssetName}
    />,
  ];

  return (
    <MainBarDashboard
      filter
      filterComponents={filterComponents}
      breadcrumbs={breadcrumbs}
      tabs={tabs}
    >
      <EnhancedPaginatedTable
        headCells={headCells}
        getData={getAuthorizations}
        query={query}
      />
      <DialogModal
        title={`Role: ${roleHeader}`}
        content={
          modalRolesData.length > 0 ? (
            <EnhancedTable
              data={modalRolesData}
              headCells={headCellsModalRoles}
            />
          ) : (
            <h5>No Permssions</h5>
          )
        }
        showModal={showRolesModal}
        formId={"createSection-form"}
        onOk={(): void => {
          return;
        }}
        onHide={(): void => {
          setRoleHeader("");
          setShowRolesModal(false);
        }}
      />
    </MainBarDashboard>
  );
};

export default AuthorizationOverview;
