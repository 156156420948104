import { ICheckedItemList } from "components/List/type";
import { ILabelValue } from "components/type";
import {
  AddressType as AddressTypeConst,
  LAT_LONG_MAX_DECIMALS,
  HttpStatusCode
} from "constants/constant";
import { FormErrorMessages } from "constants/enum";
import isEmpty from "lodash/isEmpty";
import _ from "lodash";
import { MetadataProps } from "components/MetadataTable";
import {
  saveBatchHierarchyMetadata,
  updateBatchHierarchyMetadata
} from "actions/generalActions";

export const getOptions = (
  items: any[],
  id: string,
  label: string
): ILabelValue[] => {
  const data: ILabelValue[] = [];

  if (items) {
    items.forEach((i) => {
      data.push({
        label: i[label],
        value: i[id]
      });
    });
  }

  return data;
};

export const getOptionsWithMultipleLabels = (
  items: any[],
  id: string,
  label: string[]
): ILabelValue[] => {
  const data: ILabelValue[] = [];

  if (items) {
    items.forEach((i) => {
      data.push({
        label: label.map((l) => i[l]).join(" "),
        value: i[id]
      });
    });
  }

  return data;
};

export const getCheckedOptions = (
  items: any[],
  id: string,
  label: string,
  checked = false,
  checkedItems: string[] = []
): ICheckedItemList[] => {
  const data: ICheckedItemList[] = [];

  if (items) {
    items.forEach((i: any) => {
      let isChecked = checked;
      if (!isEmpty(checkedItems)) {
        isChecked = checkedItems.includes(i[id]);
      }
      data.push({
        label: i[label],
        value: i[id],
        checked: isChecked
      });
    });
  }

  return data;
};

export const getCheckedOptionsWithMisc = (
  items: any[],
  id: string,
  label: string,
  checked = false,
  checkedItems: string[] = []
): ICheckedItemList[] => {
  const data: ICheckedItemList[] = [];

  if (items) {
    items.forEach((i) => {
      let isChecked = checked;
      if (!isEmpty(checkedItems)) {
        isChecked = checkedItems.includes(i[id]);
      }
      const item: any = {
        label: i[label],
        value: i[id],
        checked: isChecked
      };
      data.push(item);
    });
  }

  return data;
};

export const getAllChecked = (items: any[], field = "") => {
  const checkedItems = items.filter((item) => item.checked === true);
  if (field !== "") {
    return checkedItems.map((item) => item[field]);
  }
  return checkedItems;
};

export const getPropertyAsArray = (items: any[], field = "") => {
  if (field !== "") {
    return items.map((item) => item[field]);
  }
  return [];
};

export const objectPropertyConversion = (
  items: any[],
  conversion: { from: string; to: string }[]
) => {
  const itemsClone = _.cloneDeep(items);
  return itemsClone.map((obj) => {
    conversion.forEach((converObj) => {
      obj[converObj.to] = obj[converObj.from];
      delete obj[converObj.from];
    });
    return obj;
  });
};

export const hierarchyContainer = (data: {}) => {
  return { hierarchy: data };
};

export const validateEmail = (email: string) => {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const sumDigits = (value: number) => {
  let sum = 0;
  while (value > 0) {
    sum += parseInt(`${value % 10}`);
    value = parseInt(`${value / 10}`);
  }
  return sum;
};

export const validateMACAddress = (address: string): boolean => {
  if (
    address.match(
      /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$|^([0-9A-Fa-f]{2}[-]){5}([0-9A-Fa-f]{2})$|^([0-9A-Fa-f]{2}[.]){5}([0-9A-Fa-f]{2})$|^([0-9A-Fa-f]{4}[-]){2}([0-9A-Fa-f]{4})$|^[0-9A-Fa-f]{12}$/i
    )
  ) {
    return true;
  }
  return false;
};

export const validateIMEIAddress = (address: string) => {
  if (address.length !== 15) {
    return false;
  }

  let sum = 0;
  let addressInt = parseInt(address);
  for (let i = address.length; i >= 1; i--) {
    let currentValue = parseInt(`${addressInt % 10}`);

    // Multiple itself every other digit
    if (i % 2 == 0) {
      currentValue = 2 * currentValue;
    }

    // Find sum of the digits
    sum += sumDigits(currentValue);
    addressInt /= 10;
  }
  return sum % 10 == 0;
};

const validateAssignedIdIdentifierAndLength = (assignedId: string): boolean => {
  const identifierAndLengthSubstring = assignedId.substring(0, 4);

  if (identifierAndLengthSubstring.match(/^(0[1-3])(0[0-9aA])$/i)) {
    return true;
  }
  return false;
};

const validateAssignedIdAddress = (assignedId: string) => {
  const lengthSubstring = assignedId.substring(2, 4);
  const length = parseInt(lengthSubstring, 16);

  const addressSubstring = assignedId.substring(4);

  if (length * 2 === addressSubstring.length) {
    if (addressSubstring.match(/^[0-9a-fA-F]/)) {
      return true;
    }
  }

  return false;
};

export const validateAssignedId = (assignedId: string): boolean => {
  if (assignedId.length < 6) {
    return false;
  }

  return (
    validateAssignedIdIdentifierAndLength(assignedId) &&
    validateAssignedIdAddress(assignedId)
  );
};

const addressTypeAndValidationCollection = [
  {
    addressType: AddressTypeConst.MAC,
    validation: (value: string) =>
      validateMACAddress(value) || FormErrorMessages.invalidMACAddress
  },
  {
    addressType: AddressTypeConst.IMEI,
    validation: (value: string) =>
      validateIMEIAddress(value) || FormErrorMessages.invalidIMEIAddress
  },
  {
    addressType: AddressTypeConst.AssignedId,
    validation: (value: string) =>
      validateAssignedId(value) || FormErrorMessages.invalidAssignedId
  }
];

export const validateAddressByAddressType = (
  value: string,
  addressType: string
) => {
  const selectedAddressTypeAndValidation =
    addressTypeAndValidationCollection.find(
      (x) => x.addressType === addressType
    );

  return selectedAddressTypeAndValidation?.validation(value) || "Error";
};

export const validateRangeLatitude = (value: string) => {
  if (value) {
    if (parseFloat(value) <= 90 && parseFloat(value) >= -90) {
      return true;
    }
  } else {
    return true;
  }

  return false;
};

export const validateRangeLongitude = (value: string) => {
  if (value) {
    if (parseFloat(value) <= 180 && parseFloat(value) >= -180) {
      return true;
    }
  } else {
    return true;
  }

  return false;
};

export const validateIsNaN = (value: string) => {
  if (value) {
    const checkNumber = Number(value);

    if (!isNaN(checkNumber)) {
      return true;
    }
  } else {
    return true;
  }

  return false;
};

export const toMonth = (m: number) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  if (m > 11 || m < 0) {
    return months[0];
  }

  return months[m];
};

export const prependZeroToSingleDigits = (d: number | string) => {
  if (d < 10) {
    return `0${d}`;
  }
  return `${d}`;
};

const decimalPlaces = (num: number): number => {
  const match = `${num}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(
    0,
    (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)
  );
};

export const validateLatLongDecimal = (value: string) => {
  if (value) {
    return decimalPlaces(parseFloat(value)) <= LAT_LONG_MAX_DECIMALS;
  }
  return true;
};

export const getDateString = (date: Date | string = "", isUtc = false) => {
  const d = new Date(date);
  const utcDate = new Date(
    Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0)
  );

  const newDate = isUtc ? utcDate.toUTCString() : d.toString();
  return newDate === "Invalid Date" ? "" : newDate;
};

export const sortArrayAlphabetically = (data: any[], field: string) => {
  return data.sort((a, b) => {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });
};

export const convertResponseToJSON = (response: {
  status: number;
  json?: any;
}) => {
  if (response.status !== HttpStatusCode.OK) {
    return response;
  }
  return response.json();
};

export const compare = (a: string | number, b: string | number) => {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
};

export const saveMetadataIfNotEmpty = (
  metadata: MetadataProps[],
  hierarchyId = ""
) => {
  return metadata.length > 0
    ? saveBatchHierarchyMetadata(hierarchyId, metadata)
    : new Promise<void>((resolve) => {
        resolve();
      });
};

const hasDifferentContents = (
  metadata: MetadataProps[],
  prevMetadata: MetadataProps[]
) => {
  let returnValue = false;

  metadata.forEach((i) => {
    const res = prevMetadata.findIndex(
      (p) => i.code === p.code && i.tag === p.tag && i.value === p.value
    );

    if (res === -1) {
      returnValue = returnValue || true;
    }
  });

  return returnValue;
};

const hasDifference = (
  metadata: MetadataProps[],
  prevMetadata: MetadataProps[]
) => {
  let returnValue = false;
  if (metadata.length !== prevMetadata.length) {
    return true;
  }

  returnValue = returnValue || hasDifferentContents(metadata, prevMetadata);

  return returnValue;
};

const saveMetadataIfHasChanges = (
  metadata: MetadataProps[],
  prevMetadata: MetadataProps[],
  hierarchyId = ""
) => {
  if (hasDifference(metadata, prevMetadata)) {
    return updateBatchHierarchyMetadata(hierarchyId, metadata);
  }

  return new Promise<void>((resolve) => {
    resolve();
  });
};

export const updateMetadata = (
  metadata: MetadataProps[],
  prevMetadata: MetadataProps[],
  hierarchyId = "",
  hasPreviousMetadata = false
): Promise<void> => {
  if (hasPreviousMetadata) {
    return saveMetadataIfHasChanges(metadata, prevMetadata, hierarchyId);
  }

  return saveMetadataIfNotEmpty(metadata, hierarchyId);
};

export const isValid = (item: any): boolean => {
  return (item && !isEmpty(item)) == true;
};

export const getValidArrayValue = (item: any[] | undefined) => {
  const returnValue = isValid(item) ? item : [];
  return returnValue ? returnValue : [];
};
