import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InstallationDetailsForm from "./Form";
import { InstallationContext } from "provider/InstallationProvider";
import MainBarDashboard from "components/MainBarDashboard";
import { InstallationsCreatePath, AssetsRootPath } from "routes/paths";
import { getInstallationById } from "../../../actions/installationsPageActions";
import { RawInstallationData } from "./Form/type";
import { SpinnerContext } from "provider/SpinnerProvider";
import { CountriesContext } from "provider/CountriesProvider";
import { AccountOverview as AccountsOverviewConst } from "constants/constant";

const InstallationDetails = (): JSX.Element => {
  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const { updateInstallationContext } = useContext(InstallationContext);
  const [countries, getCountries] = useContext(CountriesContext);
  const [installation, setInstallation] = updateInstallationContext;
  const [isInit, setIsInit] = useState(true);
  const { installationId } = useParams<{ installationId: string }>();

  const breadcrumbs = [
    {
      label: AccountsOverviewConst.BREADCRUMBS_LABEL,
      href: AssetsRootPath,
    },
    {
      label: installationId ? "Update" : "Create Installation",
      href: InstallationsCreatePath,
    },
  ];

  useEffect(() => {
    if (installationId) {
      setIsSpinnerVisible(true);
      // NOTE: currently 1:1 relationship between Installation and Application
      getInstallationById(installationId).then((ins: RawInstallationData) => {
        setInstallation(ins);
        setIsSpinnerVisible(false);
        setIsInit(false);
      });
    }
  }, []);

  useEffect(() => {
    getCountries();
  }, []);

  const editComponent = isInit ? (
    <div></div>
  ) : (
    <InstallationDetailsForm
      installationData={installation}
      countries={countries}
    />
  );

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      {installationId ? (
        editComponent
      ) : (
        <InstallationDetailsForm countries={countries} />
      )}
    </MainBarDashboard>
  );
};

export default InstallationDetails;
