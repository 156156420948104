import React from "react";

import Layout from "layouts";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ServiceGroup = () => {
  return (
    <Layout>
      <Container>
        <Row className="mb-5">
          <Col>
            <h1>Service Group</h1>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ServiceGroup;
