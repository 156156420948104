import { adalApiFetch } from "clientApp/configAdal";
import { endPoints } from "clientApp/endPoints";
import { IDigitalOffering } from "components/type";
import Toaster from "components/Toaster";

export const getDigitalOffering = async (id: string) => {
  return await adalApiFetch(fetch, `${endPoints.digitalOfferings}/Get/${id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  }).then(res => {
    return res.json();
  });
};

export const updateDigitalOffering = async (
  data: IDigitalOffering
): Promise<void> => {
  return await adalApiFetch(
    fetch,
    `${endPoints.digitalOfferings}/Update/${data.id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }
  )
    .then(function(response) {
      if (!response.ok) {
        response.json()
          .then((body: any) => {
            throw new Error(body)
          })
          .catch((err: string) => {
            Toaster["error"](err, "Error Saving");
          });
      } else {
        Toaster["success"]("Successfully Updated!");
        return response;
      }
    })
    .catch(err => {
      console.error(err);
    });
};
