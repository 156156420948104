import React, { useEffect, useState, useContext, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { UserContext } from "provider/UserProvider";

import isEmpty from "lodash/isEmpty";

import { UsersRootPath, UsersUpdatePath } from "routes/paths";
import {
  getUser,
  getAssociatedAsset,
  updateAssociatedAsset,
  createAssociateAsset,
  getAssetAccounts,
} from "../../../actions/usersPageActions";

import PageHeader from "components/PageHeader";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import PageHeaderDetailItem from "components/PageHeaderDetailItem";
import PageHeaderRightItem from "components/PageHeaderRightItem";
import MainBarDashboard from "components/MainBarDashboard";
import ViewTreeView, { getCheckedValues } from "components/ViewTreeView";
import AutoComplete from "components/AutoComplete";
import { ICheckedItem } from "components/ViewTreeView/type";
import { IAsset } from "components/type";
import { IUserAssetAssociationForm, IAssociatedAssets } from "./type";

import { Paper, Grid, Box } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import ExpansionPanel from "components/ExpansionPanel";
import DialogModal from "components/DialogModal";
import PaperHeading from "components/PaperHeading";

const UserAssetAssociation = () => {
  const { associateUserContext } = useContext(UserContext);
  const [associateUser, setAssociateUser] = associateUserContext;

  const { userId, hierarchyId } = useParams<{
    userId: string;
    hierarchyId: string;
  }>();

  const [userAsset, setUserAsset] = useState<IAssociatedAssets>();
  const [checkedItemRefs, setCheckedItemRefs] = useState<ICheckedItem>();

  const history = useHistory();

  useEffect(() => {
    if (userId && isEmpty(associateUser)) {
      getUser(userId).then((d) => {
        setAssociateUser(d);
      });
    }
    if (hierarchyId && userId) {
      getAssociatedAsset(userId, hierarchyId).then((d) => {
        setUserAsset(d);
      });
    }
  }, []);

  const breadcrumbs = [
    {
      label: "Users",
      href: UsersRootPath,
    },
    {
      label: `${associateUser.fullName}`,
      href: userId ? UsersUpdatePath.replace(":userId", userId) : "",
    },
    {
      label: hierarchyId ? "Update User Assets" : "Create User Assets",
    },
  ];

  const getHierarchyLabel = (item: IAsset) => {
    return item ? `${item.assetName} (${item.typeName})` : "";
  };
  const getId = (item: IAsset) => {
    return item ? item.hierarchyId : "";
  };
  const getChecked = (item: IAsset) => {
    return item ? item.selected : false;
  };

  const createUserAssoc = () => {
    if (checkedItemRefs && userId) {
      const hierarchyIds: string[] = [];
      getCheckedValues(checkedItemRefs, hierarchyIds);
      const data: IUserAssetAssociationForm = {
        globalUserId: userId,
        hierarchyIds: hierarchyIds,
      };
      createAssociateAsset(data).then(() => {
        history.push(UsersUpdatePath.replace(":userId", userId));
      });
    }
  };

  const [unCheckedNode, setUnCheckedNode] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState("");
  const [assetData, setAssetData] = useState<IUserAssetAssociationForm>();

  const validataSelectedAssets = (): void => {
    if (checkedItemRefs && userId) {
      const hierarchyIds: string[] = [];
      getCheckedValues(checkedItemRefs, hierarchyIds);
      const data: IUserAssetAssociationForm = {
        globalUserId: userId,
        rootId: hierarchyId,
        hierarchyIds: hierarchyIds,
      };

      setAssetData(data);

      if (isEmpty(hierarchyIds)) {
        setDialogErrorMessage("No assets selected on this user.");
      } else {
        setDialogErrorMessage("Modifiying existing assets.");
      }
      setUnCheckedNode(true);
    }
  };

  const selectedAsset = () => {
    if (userAsset) {
      return userAsset.hierarchy.assetName;
    }
    return "";
  };

  const updateAccount = (hierarchyId: string) => {
    if (userId && hierarchyId) {
      getAssociatedAsset(userId, hierarchyId).then((d) => {
        setUserAsset(d);
      });
    }
  };

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <PageHeader
        title={`${associateUser.firstName} ${associateUser.lastName}`}
        subTitle={
          associateUser && associateUser.company ? associateUser.company : ""
        }
        details={
          <Fragment>
            <PageHeaderDetailItem
              label="ID"
              value={!isEmpty(associateUser) ? associateUser.tamId : ""}
            />
          </Fragment>
        }
      >
        <PageHeaderRightItem
          label="Status"
          value={
            !isEmpty(associateUser)
              ? associateUser.status
                ? "Active"
                : "Inactive"
              : ""
          }
        />
        <PageHeaderRightItem
          label="Email"
          value={!isEmpty(associateUser) ? associateUser.primaryEmail : ""}
        />
      </PageHeader>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {hierarchyId && (
            <Paper>
              <Box px={3} py={2}>
                <PaperHeading label={selectedAsset()} />
              </Box>
            </Paper>
          )}
          {!hierarchyId && userId && (
            <ExpansionPanel label="Search Asset">
              <AutoComplete
                outlined
                label="Account Name"
                name="accountName"
                labelName="assetName"
                valueName="id"
                getData={getAssetAccounts}
                onChange={updateAccount}
                additionalGetDataParam={{ userId: userId }}
                inputRef={null}
              />
            </ExpansionPanel>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper>
            <Box pl={4}>
              <ViewTreeView
                selectable
                checkedItemRefs={checkedItemRefs}
                setCheckedItemRefs={setCheckedItemRefs}
                data={userAsset}
                renderLabel={getHierarchyLabel}
                getId={getId}
                getChecked={getChecked}
              ></ViewTreeView>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {!isEmpty(userAsset) && (
        <FloatingActionButtonList>
          <FloatingActionButton
            onClick={hierarchyId ? validataSelectedAssets : createUserAssoc}
          >
            <SaveIcon />
            Save
          </FloatingActionButton>
        </FloatingActionButtonList>
      )}
      <DialogModal
        showCancelButton
        title="WARNING"
        message={`${dialogErrorMessage} Do you want to proceed ?`}
        showModal={unCheckedNode}
        onOk={(): void => {
          if (assetData && userId) {
            updateAssociatedAsset(assetData).then(() => {
              history.push(UsersUpdatePath.replace(":userId", userId));
            });
          }
          setUnCheckedNode(false);
        }}
        onHide={(): void => {
          setUnCheckedNode(false);
        }}
        confirmButtonLabel="Confirm"
        cancelButtonLabel="No"
      />
    </MainBarDashboard>
  );
};

export default UserAssetAssociation;
