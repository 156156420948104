import React, { useState, createContext } from "react";

export const ModalContext = createContext<any>([
  {},
  () => {
    return;
  },
]);

export const ModalProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [showInstallationModal, setShowInstallationModal] = useState(false);
  const [showHierarchyModal, setShowHierarchyModal] = useState(false);
  const [showUserAssocModal, setUserAssocModal] = useState(false);
  const [showOfferingModal, setOfferingModal] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        sectionModalContext: [showSectionModal, setShowSectionModal],
        installationModalContext: [
          showInstallationModal,
          setShowInstallationModal,
        ],
        hierarchyModalContext: [showHierarchyModal, setShowHierarchyModal],
        userAssocModalContext: [showUserAssocModal, setUserAssocModal],
        digitalOfferingModalContext: [showOfferingModal, setOfferingModal],
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
