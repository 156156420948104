import React from "react";

import { renderRoutes } from "react-router-config";
import Layout from "layouts";
import { IElementRouteAttribute } from "routes/type";
import { ModalProvider } from "provider/ModalProvider";
import { DigitalOfferingsProvider } from "provider/DigitalOfferingsProvider";

const DigitalOffering = ({ route }: IElementRouteAttribute) => {
  return (
    <DigitalOfferingsProvider>
      <ModalProvider>
        <Layout>{renderRoutes(route.routes)}</Layout>
      </ModalProvider>
    </DigitalOfferingsProvider>
  );
};

export default DigitalOffering;
