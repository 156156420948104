import { adalApiFetch } from "../clientApp/configAdal";
import { endPoints } from "../clientApp/endPoints";
import Toaster from "components/Toaster";

import {
  IDeviceForm,
  IUnassignedSubdevice
} from "../pages/Devices/DeviceForm/type";
import { IUnassignedInstallation, IDropdown } from "../components/type";
import { MetadataProps } from "components/MetadataTable/MetadataTable";

const headerValues = { "content-type": "application/json" };
const TOASTR_ERROR_MESSAGE = "Error occured while processing your request!";

export const getDevice = async (deviceId: string) => {
  return await adalApiFetch(fetch, `${endPoints.devices}/Get/${deviceId}`, {
    method: "GET",
    headers: headerValues
  }).then((res) => {
    return res.json();
  });
};

export const updateDevice = async (data: IDeviceForm) => {
  return await adalApiFetch(fetch, `${endPoints.devices}/Update/${data.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](TOASTR_ERROR_MESSAGE, "Error Saving");
      } else {
        Toaster["success"]("Successfully updated device!");
      }

      if (response.status === 204) {
        return;
      }

      return response.json();
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getAllDevicesDropdown = async (
  searchQuery = ""
): Promise<IDropdown[]> => {
  const injectSearchQuery =
    searchQuery !== "" ? `?searchquery=${searchQuery}` : "";
  return adalApiFetch(
    fetch,
    `${endPoints.devices}/GetAllDeviceName${injectSearchQuery}`,
    {
      method: "GET",
      headers: headerValues
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((posts) => {
      return posts.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const getUnassignedInstallations = async (
  searchQuery = ""
): Promise<IUnassignedInstallation[]> => {
  const injectSearchQuery =
    searchQuery !== "" ? `?searchquery=${searchQuery}` : "";
  return adalApiFetch(
    fetch,
    `${endPoints.installations}/GetInstallationsListByUnassignedDevice${injectSearchQuery}`,
    {
      method: "GET",
      headers: headerValues
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((posts) => {
      return posts.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const saveDevice = async (data: IDeviceForm) => {
  return adalApiFetch(fetch, `${endPoints.devices}/Create`, {
    method: "POST",
    headers: headerValues,
    body: JSON.stringify(data)
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"]("Error occured while saving the device");
      } else {
        Toaster["success"]("Successfully added device!");
      }
      return response;
    })
    .then((response) => response.json())
    .catch((err) => {
      console.error(err);
    });
};

export const getAllInstallations = async (
  searchQuery = ""
): Promise<IUnassignedInstallation[]> => {
  const injectSearchQuery =
    searchQuery !== "" ? `?searchquery=${searchQuery}` : "";
  return adalApiFetch(
    fetch,
    `${endPoints.installations}/GetAllInstallationName${injectSearchQuery}`,
    {
      method: "GET",
      headers: headerValues
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((posts) => {
      return posts.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const getSystemsByDeviceId = async (
  parentDeviceId: string
): Promise<IUnassignedSubdevice[]> => {
  return adalApiFetch(fetch, `${endPoints.devices}/${parentDeviceId}/Systems`, {
    method: "GET",
    headers: headerValues
  })
    .then((res) => {
      return res.json();
    })
    .then((posts) => {
      return posts.systems;
    })
    .catch((e) => {
      throw e;
    });
};

export const getAllUnassignedSubdevicesInInstallation = async (
  installationId: string
): Promise<IUnassignedSubdevice[]> => {
  return adalApiFetch(
    fetch,
    `${endPoints.devices}/GetUnassignedSubDevices/${installationId}`,
    {
      method: "GET",
      headers: headerValues
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((posts) => {
      return posts.unassignedSubDevices;
    })
    .catch((e) => {
      throw e;
    });
};

export const getDeviceCharacteristicsGENIInfo = async (
  deviceCharacteristicsId: string,
  unitType = ""
): Promise<any> => {
  const apiEndpoint =
    unitType === ""
      ? `${endPoints.deviceCharacteristics}/${deviceCharacteristicsId}`
      : `${endPoints.deviceCharacteristics}/${deviceCharacteristicsId}/${unitType}`;

  return adalApiFetch(fetch, apiEndpoint, {
    method: "GET",
    headers: headerValues
  })
    .then((res) => {
      return res.json();
    })
    .then((geniInfo) => {
      return geniInfo;
    })
    .catch((e) => {
      throw e;
    });
};

export const getAllDevices = (page = 1, limit = 10, query = "") => {
  let queryParam = "";
  if (query !== "") {
    queryParam = `&searchquery=${query}`;
  }
  return adalApiFetch(
    fetch,
    `${endPoints.devices}/GetAll?page=${page}&limit=${limit}${queryParam}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json"
      }
    }
  ).then((response) => response.json());
};

export const getMyDevices = (page: number, limit: number, query = "") => {
  let queryParam = "";
  if (query !== "") {
    queryParam = `&searchquery=${query}`;
  }
  return adalApiFetch(
    fetch,
    `${endPoints.devices}/GetDevicesByConfigurationUserId?page=${page}&limit=${limit}${queryParam}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json"
      }
    }
  ).then((response) => response.json());
};

export const getDeviceMetadata = async (deviceId: string) => {
  return await adalApiFetch(
    fetch,
    `${endPoints.devices}/${deviceId}/metadata`,
    {
      method: "GET",
      headers: headerValues
    }
  )
    .then((response) => {
      if (response.ok) {
        if (response.status === 204) {
          return { metadata: [] };
        }

        return response.json();
      } else {
        Toaster["error"]("Error fetching metadata");
        return [];
      }
    })
    .then((r) => r.metadata)
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });
};

export const saveBatchMetadata = async (
  deviceId: string,
  metadata: MetadataProps[]
): Promise<any> => {
  return adalApiFetch(fetch, `${endPoints.devices}/${deviceId}/batchmetadata`, {
    method: "POST",
    headers: headerValues,
    body: JSON.stringify(metadata)
  })
    .then((response) => {
      if (!response.ok) {
        Toaster["error"]("Error occured while saving the metadata");
      }

      return response;
    })
    .then((response) => response.json())
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });
};

export const updateBatchMetadata = async (
  deviceId: string,
  metadata: MetadataProps[]
): Promise<any> => {
  return adalApiFetch(fetch, `${endPoints.devices}/${deviceId}/batchmetadata`, {
    method: "PUT",
    headers: headerValues,
    body: JSON.stringify(metadata)
  })
    .then((response) => {
      if (!response.ok) {
        Toaster["error"]("Error occured while updating the metadata");
      }

      return response;
    })
    .then((response) => response.json())
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });
};

export const cascadeDeleteDevice = (deviceId = ""): Promise<boolean> => {
  return adalApiFetch(fetch, `${endPoints.devicesV2}/delete/${deviceId}`, {
    method: "DELETE",
    headers: headerValues
  })
    .then((res) => {
      if (res.ok) {
        Toaster["success"]("Successfully Deleted!");
      } else {
        if (res.status === 406) {
          Toaster["warning"]("Delete not allowed");
        } else {
          Toaster["error"](TOASTR_ERROR_MESSAGE, "Error deleting device");
        }
      }

      return res.ok;
    })
    .catch((e) => {
      Toaster["error"](TOASTR_ERROR_MESSAGE, "Error deleting device");
      throw e;
    });
};
