import React, { useEffect, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { makeStyles } from "@material-ui/core/styles";
import Toaster from "components/Toaster";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import SaveIcon from "@material-ui/icons/Save";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import { UserContext } from "provider/UserProvider";
import { SpinnerContext } from "provider/SpinnerProvider";

import TextField from "components/TextField";
import Dropdown from "components/MUIDropdown";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";

import { getOptions, isValid, getValidArrayValue } from "utils";

import { IDateFormat, ITimeZone } from "components/type";
import { FormErrorMessages, DateFormatType } from "constants/enum";

import {
  saveUser,
  getDefaultValuesByCountryId,
  getExternalUser
} from "../../../../actions/usersPageActions";
import {
  useCountries,
  useDateFormats,
  useLanguages,
  useTimeFormats,
  useTimeZones,
  useUnitSystems
} from "hooks/hooks";

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    marginBottom: theme.spacing(3)
  }
}));

const UserDetailsForm = () => {
  const classes = useStyles();
  const [tabValue] = useState(0);

  const { register, handleSubmit, errors, setValue, watch, control } =
    useForm();

  const { updateUserContext } = useContext(UserContext);
  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [user] = updateUserContext;

  const [countryOptions, setCountryOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [shortDateFormatOptions, setShortDateFormatOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [longDateFormatOptions, setLongDateFormatOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [userTimeZoneOptions, setUserTimeZoneOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [preferredTimeZoneOptions, setPreferredTimeZoneOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [timeFormatOptions, setTimeFormatOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [unitSystemsOptions, setUnitSystemsOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [languageOptions, setLanguageOptions] = useState([
    {
      label: "",
      value: ""
    }
  ]);

  const [isDateFormatsCompleted, setIsDateFormatsCompleted] = useState(false);
  const dateFormats = useDateFormats(() => setIsDateFormatsCompleted(true));

  const [isCountriesCompleted, setIsCountriesCompleted] = useState(false);
  const countries = useCountries(() => setIsCountriesCompleted(true));

  const [isLanguagesCompleted, setIsLanguagesCompleted] = useState(false);
  const languages = useLanguages(() => setIsLanguagesCompleted(true));

  const [isTimeFormatsCompleted, setIsTimeFormatsCompleted] = useState(false);
  const timeFormats = useTimeFormats(() => setIsTimeFormatsCompleted(true));

  const [isTimeZonesCompleted, setIsTimeZonesCompleted] = useState(false);
  const timeZones = useTimeZones(() => setIsTimeZonesCompleted(true));

  const [isUnitSystemsCompleted, setIsUnitSystemsCompleted] = useState(false);
  const unitSystems = useUnitSystems(() => setIsUnitSystemsCompleted(true));

  const values = watch();

  useEffect(() => {
    if (
      user &&
      !isEmpty(user) &&
      !isEmpty(countries) &&
      !isEmpty(dateFormats) &&
      !isEmpty(timeZones) &&
      !isEmpty(timeFormats) &&
      !isEmpty(unitSystems) &&
      !isEmpty(languages)
    ) {
      setValue("firstName", user?.firstName);
      setValue("lastName", user?.lastName);
      setValue("status", user?.status ? "true" : "false");
      setValue("tamId", user?.tamId);
      setValue("b2CObjectId", user?.b2CObjectId);
      setValue("hasPhoneAlert", user?.hasPhoneAlert);
      setValue("hasEmailAlert", user?.hasEmailAlert);
      setValue("primaryPhone", user?.primaryPhone);
      setValue("primaryEmail", user?.primaryEmail);
      setValue("company", user?.company);
      setValue("street", user?.street);
      setValue("city", user?.city);
      setValue("state", user?.state);
      setValue("postalCode", user?.postalCode);
      setValue("alertsPhone", user?.alertsPhone);
      setValue("alertsEmail", user?.alertsEmail);
      setValue("countryId", user?.countryId);
      setValue("shortDateFormatId", user?.shortDateFormatId);
      setValue("longDateFormatId", user?.longDateFormatId);
      setValue("userTimeZone", user?.userTimeZone);
      setValue("preferredTimeZone", user?.preferredTimeZone);
      setValue("languageId", user?.languageId);
      setValue("timeFormatId", user?.timeFormatId);
      setValue("unitSystemId", user?.unitSystemId);
    }
  }, [
    user,
    countries,
    dateFormats,
    languages,
    timeFormats,
    timeZones,
    unitSystems
  ]);

  useEffect(() => {
    if (
      !isCountriesCompleted ||
      !isDateFormatsCompleted ||
      !isLanguagesCompleted ||
      !isTimeFormatsCompleted ||
      !isTimeZonesCompleted ||
      !isUnitSystemsCompleted
    ) {
      setIsSpinnerVisible(true);
    } else {
      setIsSpinnerVisible(false);
    }

    if (isValid(dateFormats)) {
      setValue(
        "shortDateFormatId",
        getValidArrayValue(dateFormats).filter(
          (format: IDateFormat) =>
            format.dateFormatType === DateFormatType.short
        )[0].id
      );
    }
    if (isValid(dateFormats) && !values.longDateFormatId) {
      setValue(
        "longDateFormatId",
        dateFormats?.filter(
          (format: IDateFormat) => format.dateFormatType === DateFormatType.long
        )[0].id
      );
    }
    if (!isEmpty(countries) && !values.countryId) {
      setCountryOptions(getOptions(countries, "id", "countryName"));
      setValue("countryId", getValidArrayValue(countries)[0].id);
    }
    if (isValid(dateFormats) && !values.shortDateFormatId) {
      const shortDateOptions = getOptions(
        getValidArrayValue(dateFormats).filter(
          (format: IDateFormat) =>
            format.dateFormatType === DateFormatType.short
        ),
        "id",
        "dateFormatName"
      );
      setShortDateFormatOptions(shortDateOptions);
      setValue("shortDateFormatId", shortDateOptions[0].value);
    }
    if (isValid(dateFormats) && !values.longDateFormatId) {
      const longDateOptions = getOptions(
        getValidArrayValue(dateFormats).filter(
          (format: IDateFormat) => format.dateFormatType === DateFormatType.long
        ),
        "id",
        "dateFormatName"
      );
      setLongDateFormatOptions(longDateOptions);
      setValue("longDateFormatId", longDateOptions[0].value);
    }
    if (isValid(timeZones) && !values.userTimeZone) {
      setUserTimeZoneOptions(
        getOptions(timeZones, "timeZoneId", "description")
      );
      setValue("userTimeZone", getValidArrayValue(timeZones)[0].timeZoneId);
    }
    if (isValid(timeZones) && !values.preferredTimeZone) {
      setPreferredTimeZoneOptions(
        getOptions(timeZones, "timeZoneId", "description")
      );
      setValue(
        "preferredTimeZone",
        getValidArrayValue(timeZones)[0].timeZoneId
      );
    }
    if (isValid(timeFormats) && !values.timeFormatId) {
      setTimeFormatOptions(getOptions(timeFormats, "id", "timeFormatName"));
      setValue("timeFormatId", getValidArrayValue(timeFormats)[0].id);
    }
    if (isValid(unitSystems) && !values.unitSystemId) {
      setUnitSystemsOptions(getOptions(unitSystems, "id", "unitSystemName"));
      setValue("unitSystemId", getValidArrayValue(unitSystems)[0].id);
    }
    if (isValid(languages) && !values.languageId) {
      setLanguageOptions(getOptions(languages, "id", "languageName"));
      setValue("languageId", getValidArrayValue(languages)[0].id);
    }
  }, [countries, dateFormats, languages, timeFormats, timeZones, unitSystems]);

  const resetForm = () => {
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("status", "true");
    setValue("tamId", "");
    setValue("b2CObjectId", "");
    setValue("primaryPhone", "");
    setValue("primaryEmail", "");
    setValue("company", "");
    setValue("street", "");
    setValue("city", "");
    setValue("state", "");
    setValue("postalCode", "");
    setValue("hasPhoneAlert", false);
    setValue("hasEmailAlert", false);
    setValue("alertsPhone", "");
    setValue("alertsEmail", "");
    setValue("countryId", getValidArrayValue(countries)[0].id);
    setValue(
      "shortDateFormatId",
      getValidArrayValue(dateFormats).filter(
        (format: IDateFormat) => format.dateFormatType === DateFormatType.short
      )[0].id
    );
    setValue(
      "longDateFormatId",
      getValidArrayValue(dateFormats).filter(
        (format: IDateFormat) => format.dateFormatType === DateFormatType.long
      )[0].id
    );
    setValue("userTimeZone", getValidArrayValue(timeZones)[0].timeZoneId);
    setValue("preferredTimeZone", getValidArrayValue(timeZones)[0].timeZoneId);
    setValue("languageId", getValidArrayValue(languages)[0].id);
    setValue("timeFormatId", getValidArrayValue(timeFormats)[0].id);
    setValue("unitSystemId", getValidArrayValue(unitSystems)[0].id);
  };

  const onSubmit = (data: any) => {
    setIsSpinnerVisible(true);
    data.status = data.status === "true";
    saveUser(data)
      .then((e) => {
        if (e.PrimaryEmail) {
          Toaster["error"](e.PrimaryEmail, "Error Saving");
        }
        resetForm();
        setIsSpinnerVisible(false);
      })
      .catch((e) => {
        Toaster["error"](e, "Error Saving");
        setIsSpinnerVisible(false);
      });
  };

  const changeToDefaultByCountry = async (countryId: string) => {
    setIsSpinnerVisible(true);
    const defaultValues = await getDefaultValuesByCountryId(countryId);
    const ianaTimeZone = timeZones.filter(
      (tz: ITimeZone, index: number) =>
        tz.timeZoneId === defaultValues.ianaTimeZoneId
    );
    if (ianaTimeZone.length > 0) {
      setValue("userTimeZone", defaultValues.ianaTimeZoneId);
      setValue("preferredTimeZone", defaultValues.ianaTimeZoneId);
    } else {
      setValue("userTimeZone", defaultValues.timeZoneId);
      setValue("preferredTimeZone", defaultValues.timeZoneId);
    }
    setValue("shortDateFormatId", defaultValues.shortDateFormatId);
    setValue("longDateFormatId", defaultValues.longDateFormatId);
    setValue("timeFormatId", defaultValues.timeFormatId);
    setValue("unitSystemId", defaultValues.unitSystemId);
    setIsSpinnerVisible(false);
  };

  const getCountryIdFromExternalUser = (externalUser: { country: string }) => {
    const countriesContainer: {
      id: string;
      countryName: string;
      isO3166_2: string;
    }[] = countries ?? [];

    return isValid(externalUser.country)
      ? getValidArrayValue(countriesContainer).filter(
          (x: any) => x.isO3166_2 === externalUser.country
        )[0].id
      : countriesContainer[0]?.id;
  };

  const handleGetAzureAdUser = () => {
    const email = values.primaryEmail;
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setIsSpinnerVisible(true);
      getExternalUser(email)
        .then((e) => {
          if (!e.error) {
            const countryId = getCountryIdFromExternalUser(e);
            changeToDefaultByCountry(countryId);
            setValue("firstName", e.firstName);
            setValue("lastName", e.lastName);
            setValue("b2CObjectId", e.id);
            setValue("tamId", e.tamId);
            setValue("countryId", countryId);
          } else {
            Toaster["error"](e.error, "Error validating global user");
            resetForm();
          }
          setIsSpinnerVisible(false);
        })
        .catch((e) => {
          Toaster["error"](e.error, "Error validating global user");
          setIsSpinnerVisible(false);
        });
    } else {
      Toaster["error"](`Invalid email :${email}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.tabs}>
        <Tabs
          value={tabValue}
          onChange={() => null}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="New User" />
        </Tabs>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box my={3} mx={4}>
              <div className="row">
                <div className="col-sm-11">
                  <TextField
                    error={errors.primaryEmail}
                    label="Primary Email"
                    autoFocus={true}
                    name="primaryEmail"
                    inputRef={register({
                      required: FormErrorMessages.fieldRequired,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: FormErrorMessages.invalidEmail
                      }
                    })}
                  />
                </div>
                <div className="col-sm-1" style={{ paddingTop: "5px" }}>
                  <Tooltip title="Validate email">
                    <IconButton
                      aria-label="validate"
                      onClick={() => handleGetAzureAdUser()}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              <TextField
                error={errors.firstName}
                label="First Name"
                name="firstName"
                inputRef={register({
                  required: FormErrorMessages.fieldRequired
                })}
              />
              <TextField
                error={errors.lastName}
                label="Last Name"
                name="lastName"
                inputRef={register({
                  required: FormErrorMessages.fieldRequired
                })}
              />
              <Dropdown
                label="Status"
                name="status"
                rules={{ required: true }}
                data={[
                  {
                    label: "Active",
                    value: "true"
                  },
                  {
                    label: "Inactive",
                    value: "false"
                  }
                ]}
                control={control}
                error={errors.status}
              />
              <TextField
                error={errors.b2CObjectId}
                label="B2C Object Id"
                name="b2CObjectId"
                inputRef={register({
                  required: false
                })}
              />
              <TextField
                error={errors.tamId}
                label="TAM Id"
                name="tamId"
                inputRef={register({
                  required: false
                })}
              />
              <TextField
                error={errors.primaryPhone}
                label="Primary Phone"
                name="primaryPhone"
                inputRef={register({
                  required: false
                })}
              />

              <TextField
                error={errors.company}
                label="Company"
                name="company"
                inputRef={register({
                  required: false
                })}
              />

              <TextField
                error={errors.street}
                label="Street"
                name="street"
                inputRef={register({
                  required: false
                })}
              />

              <TextField
                error={errors.city}
                label="City"
                name="city"
                inputRef={register({
                  required: false
                })}
              />

              <TextField
                error={errors.state}
                label="State"
                name="state"
                inputRef={register({
                  required: false
                })}
              />

              <TextField
                error={errors.postalCode}
                label="Postal Code"
                name="postalCode"
                inputRef={register({
                  required: false
                })}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box my={3} mx={4}>
              <Dropdown
                label="Country"
                name="countryId"
                rules={{ required: FormErrorMessages.fieldRequired }}
                data={countryOptions}
                control={control}
                error={errors.countryId}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                  changeToDefaultByCountry(e.target.value as string);
                }}
              />
              <Dropdown
                label="Short Date Format"
                name="shortDateFormatId"
                rules={{ required: FormErrorMessages.fieldRequired }}
                data={shortDateFormatOptions}
                control={control}
                error={errors.shortDateFormatId}
              />
              <Dropdown
                label="Long Date Format"
                name="longDateFormatId"
                rules={{ required: FormErrorMessages.fieldRequired }}
                data={longDateFormatOptions}
                control={control}
                error={errors.longDateFormatId}
              />
              <Dropdown
                label="User Timezone"
                name="userTimeZone"
                rules={{ required: FormErrorMessages.fieldRequired }}
                data={userTimeZoneOptions}
                control={control}
                error={errors.userTimeZone}
              />
              <Dropdown
                label="Preferred Timezone"
                name="preferredTimeZone"
                rules={{ required: FormErrorMessages.fieldRequired }}
                data={preferredTimeZoneOptions}
                control={control}
                error={errors.preferredTimeZone}
              />
              <Dropdown
                label="Time Format"
                name="timeFormatId"
                rules={{ required: FormErrorMessages.fieldRequired }}
                data={timeFormatOptions}
                control={control}
                error={errors.timeFormatId}
              />
              <Dropdown
                label="Unit System"
                name="unitSystemId"
                rules={{ required: FormErrorMessages.fieldRequired }}
                data={unitSystemsOptions}
                control={control}
                error={errors.unitSystemId}
              />
              <Dropdown
                label="Language"
                name="languageId"
                rules={{ required: FormErrorMessages.fieldRequired }}
                data={languageOptions}
                control={control}
                error={errors.languageId}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box my={3} mx={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Notification Preference
                </FormLabel>
                <FormGroup aria-label="position" row>
                  <Controller
                    as={
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label="Phone Alert"
                        labelPlacement="end"
                      />
                    }
                    name="hasPhoneAlert"
                    control={control}
                    defaultValue={false}
                  />
                  <Controller
                    as={
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label="Email Alert"
                        labelPlacement="end"
                      />
                    }
                    name="hasEmailAlert"
                    control={control}
                    defaultValue={false}
                  />
                </FormGroup>
              </FormControl>
              <TextField
                error={errors.alertsPhone}
                label="Alerts Phone"
                name="alertsPhone"
                inputRef={register({
                  required: {
                    value: values.hasPhoneAlert && values.primaryPhone === "",
                    message: FormErrorMessages.fieldRequired
                  }
                })}
              />
              <TextField
                error={errors.alertsEmail}
                label="Alerts Email"
                name="alertsEmail"
                inputRef={register({
                  required: {
                    value: values.hasEmailAlert,
                    message: FormErrorMessages.fieldRequired
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: FormErrorMessages.invalidEmail
                  }
                })}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <FloatingActionButtonList>
        <FloatingActionButton type="submit">
          <SaveIcon />
          Save
        </FloatingActionButton>
      </FloatingActionButtonList>
    </form>
  );
};

export default UserDetailsForm;
