import React, {
  useEffect,
  useState,
  useContext,
  ReactElement,
  Fragment,
} from "react";
import { useHistory } from "react-router-dom";

import { Delete, DeviceHub, Warning } from "@material-ui/icons";

import { IHeadCell } from "components/EnhancedPaginatedTable/type";
import EnhancedPaginatedTable from "components/EnhancedPaginatedTable";
import DialogModal from "components/DialogModal";

import { AssetType } from "constants/enum";
import { HierarchiesTable as HierarchiesTableConst } from "constants/constant";

import { AccountContext } from "provider/AccountProvider";
import { SpinnerContext } from "provider/SpinnerProvider";
import { ModalContext } from "provider/ModalProvider";
import { InstallationContext } from "provider/InstallationProvider";

import {
  AccountsUpdatePath,
  SectionUpdatePath,
  InstallationsUpdatePath,
} from "routes/paths";

import {
  getAccount,
  deleteAccount,
  getHierarchies,
} from "../../../../actions/accountsPageActions";

const HierarchiesTable = (props: any): ReactElement => {
  const [selectedHierarchyId, setSelectedHierarchyId] = useState("");
  const { openHierarchyContext, treeViewToggleItemContext } = useContext(
    AccountContext
  );
  const { updateInstallationContext } = useContext(InstallationContext);

  const [, setOpenHierarchy] = openHierarchyContext;
  const [, setInstallation] = updateInstallationContext;
  const [, setUpdateExpandedIds] = treeViewToggleItemContext;

  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const { hierarchyModalContext } = useContext(ModalContext);
  const [, setShowHierarchyModal] = hierarchyModalContext;
  const history = useHistory();

  const [showDialog, setShowDialog] = useState(false);
  const [assetName, setAssetName] = useState<string | undefined>("");
  const [assetType, setAssetType] = useState<string | undefined>("");
  const [refresh, setRefresh] = useState<number>();

  const onConfirmDelete = (): void => {
    if (selectedHierarchyId === "") {
      return;
    }
    deleteAccount(selectedHierarchyId)
      .then(() => {
        setRefresh(Date.now());
      })
      .catch((e) => {
        console.error(e);
      });
    setShowDialog(false);
  };

  useEffect(() => {
    setRefresh(Date.now());
  }, []);

  const selectAccount = async (obj: { [k: string]: any }): Promise<void> => {
    const accountId = obj.id;
    const accountType = obj.typeName;
    if (!accountId) {
      return;
    }
    switch (accountType) {
      case AssetType.account:
        return history.push(AccountsUpdatePath.replace(":id", accountId));
      case AssetType.section:
        return history.push(SectionUpdatePath.replace(":id", accountId));
      case AssetType.installation:
        const acc = await getAccount(accountId);
        setInstallation(acc);
        if (acc.installationDetail) {
          const installationId = acc.installationDetail.installationId;
          return history.push(
            InstallationsUpdatePath.replace(":installationId", installationId)
          );
        }
        return;
    }
  };

  const selectAccountHierarchy = async (
    accountId: string | undefined
  ): Promise<void> => {
    if (!accountId) {
      return;
    }

    setIsSpinnerVisible(true);
    const acc = await getAccount(accountId);
    setIsSpinnerVisible(false);
    setShowHierarchyModal(true);
    setOpenHierarchy(acc);
    setUpdateExpandedIds(acc.id);
  };

  const openHierarchy = (obj: { [k: string]: any }): void => {
    selectAccountHierarchy(obj.id);
  };

  const deleteHierarchy = (obj: { [k: string]: any }): void => {
    setSelectedHierarchyId(obj.id);
    setAssetType(obj.typeName);
    setAssetName(obj.assetName);
    setShowDialog(true);
  };

  const headCells: IHeadCell[] = [
    {
      id: "itemId",
      disablePadding: false,
      label: "Id",
      fieldName: "id",
      hidden: true,
    },
    {
      id: "assetName",
      disablePadding: false,
      label: "Name",
      fieldName: "assetName",
      isClickable: true,
      onClick: selectAccount,
      referenceIds: ["id", "typeName"],
    },
    {
      id: "typeId",
      disablePadding: false,
      label: "Asset Type",
      fieldName: "typeName",
    },
    {
      id: "applicationListId",
      disablePadding: false,
      label: "Digital Product",
      fieldName: "applicationList",
      isList: true,
      arrayReferenceField: "applications",
    },
    {
      id: "actionId",
      disablePadding: false,
      label: "Actions",
      hasAction: true,
      actions: [
        {
          onSubmit: openHierarchy,
          icon: DeviceHub,
          label: "Hierarchy",
          referenceIds: ["id"],
        },
        {
          onSubmit: deleteHierarchy,
          icon: Delete,
          label: "Delete",
          referenceIds: ["id", "typeName", "assetName"],
        },
      ],
    },
  ];

  return (
    <Fragment>
      <EnhancedPaginatedTable
        getData={getHierarchies}
        headCells={headCells}
        refresh={refresh}
        query={props.keyword}
      />
      <DialogModal
        showCancelButton
        title={HierarchiesTableConst.DIALOG_TITLE}
        message={`${
          assetType === AssetType.account || assetType === AssetType.section
            ? `This ${assetType} ${HierarchiesTableConst.DIALOG_MESSAGE_ACCOUNT_SECTION} Do you want to continue?`
            : `${HierarchiesTableConst.DIALOG_MESSAGE_INSTALLATION} ${assetType}: ${assetName}?`
        }`}
        showModal={showDialog}
        onOk={(): void => onConfirmDelete()}
        onHide={(): void => setShowDialog(false)}
        icon={<Warning fontSize="large" color="secondary" />}
      />
    </Fragment>
  );
};

export default HierarchiesTable;
