import { adalApiFetch } from "../clientApp/configAdal";
import { endPoints } from "../clientApp/endPoints";
import { MetadataProps } from "../components/MetadataTable";
import Toaster from "components/Toaster";

const headerValues = { "content-type": "application/json" };

export const saveBatchHierarchyMetadata = async (
  hierarchyId: string,
  metadata: MetadataProps[]
): Promise<any> => {
  return adalApiFetch(
    fetch,
    `${endPoints.hierarchyMetadata}/${hierarchyId}/upsert`,
    {
      method: "POST",
      headers: headerValues,
      body: JSON.stringify(metadata)
    }
  )
    .then((response) => {
      if (!response.ok) {
        Toaster["error"]("Error occured while saving the metadata");
      }

      return response;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });
};

export const updateBatchHierarchyMetadata = async (
  hierarchyId: string,
  metadata: MetadataProps[]
): Promise<any> => {
  return adalApiFetch(
    fetch,
    `${endPoints.hierarchyMetadata}/${hierarchyId}/batchmetadata`,
    {
      method: "PUT",
      headers: headerValues,
      body: JSON.stringify(metadata)
    }
  )
    .then((response) => {
      if (!response.ok) {
        Toaster["error"]("Error occured while updating the metadata");
      }

      return response;
    })
    .then((response) => response.json())
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });
};

export const getHierarchyMetadata = async (hierarchyId: string) => {
  return await adalApiFetch(
    fetch,
    `${endPoints.hierarchyMetadata}/${hierarchyId}/getbyhierarchyid`,
    {
      method: "GET",
      headers: headerValues
    }
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 403) {
          Toaster["warning"]("Metadata access forbidden");
        }
        return [];
      }
    })
    .then((r) => r.metadata)
    .catch((err) => {
      console.error(err);
      return [];
    });
};
