import { adalApiFetch } from "clientApp/configAdal";
import { endPoints } from "clientApp/endPoints";


export const getAdminUser = async (oid: string) => {
   return await adalApiFetch(fetch, `${endPoints.configurationUsersV2}/ValidateByObjectId/${oid}`, {
      method: "GET",
      headers: {
        "content-type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        return res;
      });
  };