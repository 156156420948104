import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SaveIcon from "@material-ui/icons/Save";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import MainBarDashboard from "components/MainBarDashboard";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import NewTextField from "components/TextField";
import Dropdown from "components/MUIDropdown";
import { DigitalOfferingRootPath } from "routes/paths";
import { FormErrorMessages } from "constants/enum";

import { ApplicationsContext } from "provider/ApplicationsProvider";
import { SpinnerContext } from "provider/SpinnerProvider";
import isEmpty from "lodash/isEmpty";
import { getOptions } from "utils";

import { saveDigitalOffering } from "./Actions";

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
  },
}));

const DigitalOfferingForm = () => {
  const breadcrumbs = [
    {
      label: "Digital Offerings",
      href: DigitalOfferingRootPath,
    },
    {
      label: "Create",
    },
  ];
  const classes = useStyles();

  const { register, handleSubmit, errors, control, setValue } = useForm();

  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [applications, getApplications] = useContext(ApplicationsContext);

  useEffect(() => {
    getApplications();
    setIsSpinnerVisible(true);
  }, []);

  useEffect(() => {
    if (!isEmpty(applications)) {
      setIsSpinnerVisible(false);
      setValue("applicationId", applications[0].id);
    }
  }, [applications]);

  const resetForm = () => {
    setValue("applicationId", applications[0].id);
    setValue("digitalOfferingName", "");
    setValue("sapNumber", "");
    setValue("description", "");
  };

  const onSubmit = (data: any) => {
    setIsSpinnerVisible(true);
    saveDigitalOffering(data).then(() => {
      resetForm();
      setIsSpinnerVisible(false);
    });
  };

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.tabs}>
          <Tabs
            value={0}
            onChange={() => {
              return;
            }}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="New Digital Offering" />
          </Tabs>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box m={4}>
                <Dropdown
                  label="Digital Product"
                  name="applicationId"
                  rules={{ required: FormErrorMessages.fieldRequired }}
                  data={
                    !isEmpty(applications)
                      ? getOptions(applications, "id", "applicationName")
                      : [
                          {
                            label: "",
                            value: "",
                          },
                        ]
                  }
                  control={control}
                  error={errors.applicationId}
                />
                <NewTextField
                  error={errors.digitalOfferingName}
                  label="Digital Offering Name"
                  name="digitalOfferingName"
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired,
                  })}
                />
                <NewTextField
                  error={errors.sapNumber}
                  label="SAP Product #"
                  name="sapNumber"
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired,
                    maxLength: {
                      value: 8,
                      message: FormErrorMessages.invalidSapNumber,
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9]*$/i,
                      message: FormErrorMessages.invalidSapNumber,
                    },
                  })}
                />
                <NewTextField
                  error={errors.description}
                  label="Description"
                  name="description"
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired,
                  })}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <FloatingActionButtonList>
          <FloatingActionButton type="submit">
            <SaveIcon />
            Save
          </FloatingActionButton>
        </FloatingActionButtonList>
      </form>
    </MainBarDashboard>
  );
};

export default DigitalOfferingForm;
