import React, { Fragment, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { AuthContext } from "provider/AuthProvider";

import { HttpStatusCode } from "constants/constant";

import { IPrivateComponent } from "./type";

const useStyles = makeStyles((theme) => ({
  accessError: {
    marginTop: theme.spacing(2),
  },
}));

const PrivateComponent = ({
  children,
  data,
  errorMessage,
  permissions,
}: IPrivateComponent): JSX.Element => {
  const classes = useStyles();

  const { permissionContext } = useContext(AuthContext);
  const [, , hasPermission] = permissionContext;

  return (
    <Fragment>
      {(data && data?.status && data.status === HttpStatusCode.FORBIDDEN) ||
      (permissions && !hasPermission(permissions))
        ? errorMessage && (
            <Typography variant="h6" className={classes.accessError}>
              {errorMessage}
            </Typography>
          )
        : children}
    </Fragment>
  );
};

export default PrivateComponent;
