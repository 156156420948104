import React, { useState, useEffect } from "react";
import { renderRoutes } from "react-router-config";

import { ModalProvider } from "../../provider/ModalProvider";

import Layout from "layouts";

const Accounts = (props: any): JSX.Element => {
  const [refreshTimestamp, setRefreshTimestamp] = useState(0);

  useEffect(() => {
    setRefreshTimestamp(Date.now());
  }, []);

  return (
    <ModalProvider>
      <Layout>
        {renderRoutes(props.route.routes, {
          refreshTimestamp: refreshTimestamp,
        })}
      </Layout>
    </ModalProvider>
  );
};
export default Accounts;
