import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { IDatePicker as IDatePickerProps } from "./type";
import { Controller } from "react-hook-form";

export default function MaterialUIPickers(props: IDatePickerProps) {
  return (
    <Controller
      as={
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk={true}
            id="date-picker"
            disabled={props.readOnly}
            fullWidth={props.fullwith}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label={props.label}
            InputLabelProps={{
              shrink: true,
            }}
            value={props.value ? props.value : null}
            onChange={props.onChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            onFocus={() => {
              return;
            }}
            onBlur={() => {
              return;
            }}
          />
        </MuiPickersUtilsProvider>
      }
      name={props.name}
      control={props.control}
    />
  );
}
