import { adalApiFetch } from "clientApp/configAdal";
import { endPoints } from "clientApp/endPoints";
import Toaster from "components/Toaster";
import { IDigitalOffering } from "components/type";

export const saveDigitalOffering = async (
  data: IDigitalOffering
): Promise<void> => {
  return await adalApiFetch(fetch, `${endPoints.digitalOfferings}/Create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(function (response) {
      if (!response.ok) {
        response.json()
          .then((body: any) => {
            throw new Error(body)
          })
          .catch((err: string) => {
            Toaster["error"](err, "Error Saving");
          })
      } else {
        Toaster["success"]("Successfully Updated!");
        return response;
      }
    })
  .catch(err => {
    Toaster["error"](err, "Error Saving");
  });
};