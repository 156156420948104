import React, { useState, useEffect, Fragment } from "react";
import isEmpty from "lodash/isEmpty";
import AwesomeDebouncePromise from "awesome-debounce-promise";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { ILabelValue } from "components/type";
import { IAutoCompleteProps } from "./type";

import { getOptions, getOptionsWithMultipleLabels } from "utils";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 250,
    marginRight: (props: any) => (props.isFilterStyle ? 10 : 0)
  }
}));

const emptyLabelValue: ILabelValue = { label: "", value: "" };

const AutoComplete = ({
  label,
  name,
  labelName,
  labelNames,
  valueName,
  getData,
  inputRef,
  disabled,
  defaultValue,
  onChange,
  onChangeObjParam,
  reset,
  error,
  blacklist,
  additionalGetDataParam,
  outlined,
  size,
  isFilterStyle,
  disableClearable = true
}: IAutoCompleteProps): JSX.Element => {
  const classes = useStyles({ isFilterStyle });
  const [options, setOptions] = useState<ILabelValue[]>([]);
  const [query, setQuery] = useState("");
  const [value, setValue] = React.useState<ILabelValue | null>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasTyped, setHasTyped] = useState(false);
  const [data, setData] = useState<any>();

  const updateValue = (value: ILabelValue) => {
    setValue(value);
    if (onChange) {
      onChange(value.value);
    }
    if (onChangeObjParam) {
      const selectedObject = data.filter(
        (item: any) => item[valueName] === value.value
      );
      selectedObject.length > 0
        ? onChangeObjParam(selectedObject[0])
        : onChangeObjParam({});
    }
  };

  const clearValue = () => {
    setValue(null);
    if (onChange) {
      onChange("");
    }
    if (onChangeObjParam) {
      onChangeObjParam({});
    }
  };

  const optionWithDefault = (
    defaultValue: ILabelValue | null | undefined,
    options: ILabelValue[]
  ) => {
    let optionClone = [...options];
    if (blacklist) {
      optionClone = options.filter(
        (dropdown) => !blacklist.includes(dropdown.value)
      );
    }
    if (
      defaultValue &&
      options.filter((labelValue) => labelValue.value === defaultValue.value)
        .length === 0
    ) {
      return [defaultValue, ...optionClone];
    }
    return optionClone;
  };

  const removeValues = () => {
    if (onChange) {
      onChange("");
    }
    if (onChangeObjParam) {
      onChangeObjParam({});
    }
    setValue(null);
  };

  const initialLoad = () => {
    setIsLoaded(false);
    getData(query, additionalGetDataParam).then((res) => {
      setIsLoaded(true);
      setData(res);
      let opt = getOptions(res, valueName, labelName);
      if (labelNames) {
        opt = getOptionsWithMultipleLabels(res, valueName, labelNames);
      }
      setOptions(optionWithDefault(defaultValue, opt));
    });
  };

  useEffect(() => {
    initialLoad();
  }, [getData, defaultValue]);

  useEffect(() => {
    if (defaultValue && isLoaded) {
      updateValue(defaultValue);
    }
  }, [options, defaultValue]);

  useEffect(() => {
    initialLoad();
    removeValues();
    setHasTyped(false);
  }, [reset]);

  const searchAPI = (text: string) => getData(text, additionalGetDataParam);
  const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 500);

  const search = async (searchQuery: string) => {
    setIsLoaded(true);
    const result = await searchAPIDebounced(searchQuery);
    setData(result);
    let opt = getOptions(result, valueName, labelName);
    if (labelNames) {
      opt = getOptionsWithMultipleLabels(result, valueName, labelNames);
    }
    setOptions(optionWithDefault(defaultValue, opt));
    setQuery(searchQuery);
    setIsLoaded(false);
  };

  let isError = false;
  let errorMessage = "";
  if (error) {
    isError = true;
    errorMessage = error.message;
  }

  let hiddenValue = "";
  if (value && value.value) {
    hiddenValue = `${value.value}`;
  }

  return (
    <Fragment>
      <Autocomplete
        className={classes.root}
        disabled={disabled}
        loading={isLoaded}
        id="immediateParent"
        disableClearable={disableClearable}
        options={options}
        getOptionLabel={(option) => (!isEmpty(option) ? option.label : "")}
        value={value ?? emptyLabelValue}
        renderInput={(params) => (
          <TextField
            {...params}
            error={isError}
            helperText={errorMessage}
            label={label}
            margin={isFilterStyle ? "none" : "normal"}
            fullWidth
            name={`autocomplete_${name}`}
            size={size ? size : ("medium" as any)}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ): void => {
              if (!hasTyped) {
                setHasTyped(true);
              }
              search(e.target.value);
            }}
            variant={outlined ? ("outlined" as any) : ("standard" as any)}
          />
        )}
        onChange={(event: object, value: any) => {
          if (value) {
            updateValue(value);
          } else {
            clearValue();
          }
        }}
        onInputChange={(event: object, value: string, reason: string) => {
          if (value === "") {
            removeValues();
          }
        }}
      />
      <input hidden name={name} ref={inputRef} value={hiddenValue} />
    </Fragment>
  );
};
export default AutoComplete;
