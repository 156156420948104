import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { InstallationSubscriptionDetailsSectionProps } from "../type";
import { getDateString } from "utils";

const getTruthfulness = (
  status: string | boolean | undefined = false
): boolean => {
  return status === true || status === "true";
};

const extractDate = (date: Date | null = null): Date => {
  if (date === null) {
    return new Date();
  }

  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
};

const InstallationSubscriptionDetailsSection = ({
  data,
  onChangeData,
  disabled = false,
}: InstallationSubscriptionDetailsSectionProps): JSX.Element => {
  const [componentPropsState, setComponentPropsState] = useState<{
    [k: string]: string;
  }>({
    activationDate: "",
    deactivationDate: "",
    digitalOfferingStatus: "false",
    reason: "",
    timestamp: "",
  });

  useEffect(() => {
    if (data) {
      setComponentPropsState({
        activationDate: getDateString(data.activationDate, true) || "",
        deactivationDate: getDateString(data.deactivationDate, true) || "",
        digitalOfferingStatus: `${getTruthfulness(data.digitalOfferingStatus)}`,
        reason: data.reason || "",
        subscriptionStatus: data.subscriptionStatus || "",
        timestamp: data.timestamp || "",
      });
    }
  }, [data]);

  const triggerChange = (obj: { [k: string]: string }) => {
    onChangeData({
      activationDate: obj["activationDate"],
      deactivationDate: obj["deactivationDate"],
      digitalOfferingId: obj["digitalOfferingId"],
      digitalOfferingStatus: getTruthfulness(obj["digitalOfferingStatus"]),
      reason: obj["reason"],
    });
    setComponentPropsState({ ...obj });
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: unknown }
    >,
    prop: string
  ) => {
    const newObj: { [k: string]: string } = { ...componentPropsState };
    newObj[prop] = event.target.value as string;

    triggerChange(newObj);
  };

  const handleChangeDate = (date: Date | null, prop: string) => {
    const d = extractDate(date);
    const newObj: { [k: string]: string } = { ...componentPropsState };

    newObj[prop] = date !== null ? d.toString() : "";

    triggerChange(newObj);
  };

  const shouldDisableReason = () => {
    const deactivationDate = new Date(componentPropsState.deactivationDate);
    let res = false;

    if (deactivationDate.toString() !== "Invalid Date") {
      res = Date.now() > deactivationDate.valueOf();
    }

    return res;
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label">
              Digital Offering Status
            </InputLabel>
            <Select
              disabled={disabled}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={componentPropsState.digitalOfferingStatus}
              onChange={(e) => handleChange(e, "digitalOfferingStatus")}
            >
              <MenuItem value={"true"}>Active</MenuItem>
              <MenuItem value={"false"}>Inactive</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            margin="normal"
            disabled
            label="Subscription Status"
            value={componentPropsState.subscriptionStatus}
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            fullWidth
            margin="normal"
            disabled={disabled || shouldDisableReason()}
            label="Reason"
            value={componentPropsState.reason}
            InputProps={{
              readOnly: shouldDisableReason(),
            }}
            onChange={(e) => handleChange(e, "reason")}
          />

          <KeyboardDatePicker
            autoOk
            invalidDateMessage={""}
            invalidLabel={""}
            fullWidth
            disabled={disabled}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Activation Date"
            value={new Date(componentPropsState.activationDate)}
            onChange={(d: Date | null) => handleChangeDate(d, "activationDate")}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            onFocus={() => {
              return;
            }}
            onBlur={() => {
              return;
            }}
          />

          <KeyboardDatePicker
            autoOk
            invalidDateMessage={""}
            invalidLabel={""}
            fullWidth
            disabled={disabled}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Deactivation Date"
            value={new Date(componentPropsState.deactivationDate)}
            onChange={(d: Date | null) =>
              handleChangeDate(d, "deactivationDate")
            }
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            onFocus={() => {
              return;
            }}
            onBlur={() => {
              return;
            }}
          />

          <TextField
            fullWidth
            margin="normal"
            disabled={disabled}
            label="Timestamp"
            value={componentPropsState.timestamp}
            onChange={(e) => handleChange(e, "timestamp")}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default InstallationSubscriptionDetailsSection;
