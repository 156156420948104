import { adalApiFetch } from "clientApp/configAdal";
import { endPoints } from "clientApp/endPoints";
import { IPagination } from "components/type";

export const getDigitalOfferings = async (
  page = 1,
  limit = 10
): Promise<IPagination[]> => {
  return await adalApiFetch(
    fetch,
    `${endPoints.digitalOfferings}/GetAll?page=${page}&limit=${limit}`,
    {
      method: "GET",
      headers: { "content-type": "applicaiton/json" }
    }
  ).then(response => response.json());
};
