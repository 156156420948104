import React, { Fragment, useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

import ExpansionPanel from "components/ExpansionPanel";
import MainBarDashboard from "components/MainBarDashboard";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import PageHeader from "components/PageHeader";
import PageHeaderRightItem from "components/PageHeaderRightItem";
import PageHeaderDetailItem from "components/PageHeaderDetailItem";
import NewTextField from "components/TextField";
import Dropdown from "components/MUIDropdown";
import DatePicker from "components/DatePicker";
import ViewTreeView, { getHierarchyIds } from "components/ViewTreeView";
import PaperHeading from "components/PaperHeading";
import List, { ICheckedItemList } from "components/List";
import AutoComplete from "components/AutoComplete";
import {
  DeviceView as DeviceViewConst,
  AddressType as AddressTypeConst
} from "constants/constant";
import Moment from "moment";

import { IDevice, IAsset, ILabelValue } from "components/type";
import { FormErrorMessages } from "constants/enum";
import {
  DeviceRootPath,
  DeviceViewPath,
  DeviceSubDeviceFormPath
} from "routes/paths";

import {
  getDevice,
  updateDevice,
  getUnassignedInstallations,
  getAllDevicesDropdown,
  getSystemsByDeviceId,
  getAllUnassignedSubdevicesInInstallation,
  getAllInstallations,
  getDeviceCharacteristicsGENIInfo,
  getDeviceMetadata,
  updateBatchMetadata,
  saveBatchMetadata
} from "../../../actions/devicePageActions";
import { getAccount } from "../../../actions/accountsPageActions";
import { getInstallationById } from "../../../actions/installationsPageActions";

import { DeviceContext } from "provider/DeviceProvider";
import { SpinnerContext } from "provider/SpinnerProvider";

import {
  getOptions,
  getCheckedOptions,
  getAllChecked,
  validateAddressByAddressType,
  compare
} from "utils";
import { GENIInfo } from "../DeviceForm/type";
import MetadataTable, {
  MetadataProps
} from "../../../components/MetadataTable/MetadataTable";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 240
  },
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  expansionPanel: {
    flexDirection: "column"
  }
}));

const compareMetadata = (a: MetadataProps, b: MetadataProps): 1 | -1 | 0 => {
  return compare(a.tag, b.tag) || compare(a.code, b.code);
};

const DeviceView = (): JSX.Element => {
  const history = useHistory();
  const { deviceId } = useParams<{ deviceId: string }>();
  const breadcrumbs = [
    {
      label: "Devices",
      href: DeviceRootPath
    },
    {
      label: `View (${deviceId})`,
      href: DeviceViewPath
    }
  ];
  const classes = useStyles();

  const [isSpinnerVisible, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [device, setDevice] = useState<any>();
  const [hierarchy, setHierarchy] = useState();
  const [isUpdate, setIsUpdate] = useState(false);

  const {
    deviceCharacteristicsContext,
    getDeviceCategoryByProductName: getDeviceCategoryByProductName
  } = useContext(DeviceContext);
  const [, getDeviceCharacteristics] = deviceCharacteristicsContext;

  const [deviceProductNamesParent, setDeviceProductNamesParent] = useState([]);
  const [deviceProductNamesSubDevice, setDeviceProductNamesSubDevice] =
    useState([]);
  const [deviceProductNamesSystem, setDeviceProudctNamesSystem] = useState([]);
  const [deviceCharacteristicId, setDeviceCharacteristicId] = useState("");
  const [lastFirmwareUpdate, setLastFirmwareUpdate] =
    React.useState<Date | null>();
  const [systemSubDevices, setSystemSubDevices] = useState<ICheckedItemList[]>(
    []
  );
  const [systems, setSystems] = useState<ILabelValue[]>([]);

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    triggerValidation
  } = useForm();
  const values = watch();
  const [deviceProductNames, setDeviceProductNames] = useState([
    {
      label: "",
      value: ""
    }
  ]);

  const [deviceProductName, setDeviceProductName] = useState([
    {
      label: "",
      value: ""
    }
  ]);

  const [deviceType, setDeviceType] = useState([
    {
      label: "",
      value: ""
    }
  ]);

  const [deviceCategories, setDeviceCategories] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [deviceTypes, setDeviceTypes] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [vendors, setVendors] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [unitTypes, setUnitTypes] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [unitVersions, setUnitVersions] = useState([
    {
      label: "",
      value: ""
    }
  ]);

  const statuses = [
    {
      label: "Connected",
      value: "Connected"
    },
    {
      label: "Disconnected",
      value: "Disconnected"
    },
    {
      label: "Not Commissioned",
      value: "NotCommissioned"
    }
  ];
  const addressTypes = [
    {
      label: "MAC",
      value: AddressTypeConst.MAC
    },
    {
      label: "IMEI",
      value: AddressTypeConst.IMEI
    },
    {
      label: "Assigned ID",
      value: AddressTypeConst.AssignedId
    }
  ];
  const [installationDefaultValue, setInstallationDefaultValue] =
    useState<ILabelValue | null>(null);
  const [parentDeviceIdDefaultValue, setParentDeviceIdDefaultValue] =
    useState<ILabelValue | null>(null);

  const [blacklistParentDevices, setBlacklistParentDevices] = useState<
    string[]
  >([]);

  const [addressErrorExists, setAddressErrorExists] = useState<string>("");
  const [deviceTwinIdErrorExists, setDeviceTwinIdErrorExists] =
    useState<string>("");
  const [guaErrorExists, setGuaErrorExists] = useState<string>("");
  const defaultInstallationSelect = DeviceViewConst.DEFAULT_INSTALLATION_SELECT;

  const [metadata, setMetadata] = useState<MetadataProps[]>([]);
  const [hasPreviousMetadata, setHasPreviousMetadata] = useState(false);
  const [isMetadataLoaded, setIsMetadataLoaded] = useState(false);
  const [isModifyingMetadata, setIsModifyingMetadata] = useState(false);

  const setFamilyCodeValue = (familyCode = 0): void => {
    const value = familyCode === 0 ? "" : familyCode.toString();

    setValue("familyCode", value);
  };

  const setUnitTypeValue = (
    unitTypesParam: { id: string; unitType: number }[] = []
  ): void => {
    const nullString = "";

    if (unitTypesParam.length === 1) {
      setValue("deviceCharacteristicUnitTypeId", unitTypesParam[0].id);
    } else if (unitTypesParam.length === 0) {
      setValue("deviceCharacteristicUnitTypeId", nullString);
    } else {
      setValue("deviceCharacteristicUnitTypeId", "");
    }
  };

  const setUnitTypeDropdownValues = (
    unitTypesParam: { id: string; unitType: number }[] = []
  ): void => {
    const value: { label: string; value: string }[] = [];
    const nullString = "null";

    unitTypesParam.forEach((x) =>
      value.push({ label: x.unitType.toString(), value: x.id })
    );

    if (unitTypesParam.length === 0) {
      value.push({ label: nullString, value: nullString });
    }

    setUnitTypes(value);
  };

  const setUnitVersionDropdownValues = (
    unitVersionsParam: {
      id: string;
      unitVersion: string;
    }[] = []
  ): void => {
    const nullString = "null";
    const value: { label: string; value: string }[] = [];

    unitVersionsParam.forEach((x) =>
      value.push({ label: x.unitVersion.toString(), value: x.id })
    );

    if (unitVersionsParam.length === 0) {
      value.push({ label: nullString, value: nullString });
    }

    setUnitVersions(value);
  };

  const setUnitVersionValue = (
    unitVersionsParam: {
      id: string;
      unitVersion: string;
    }[] = []
  ): void => {
    const nullString = "";

    if (unitVersionsParam.length === 1) {
      setValue(
        "deviceCharacteristicUnitTypeVersionId",
        unitVersionsParam[0].id
      );
    } else if (unitVersionsParam.length === 0) {
      setValue("deviceCharacteristicUnitTypeVersionId", nullString);
    } else {
      setValue("deviceCharacteristicUnitTypeVersionId", "");
    }
  };

  const setGENIValues = (
    selectedDeviceCharacteristicsId = "",
    unitType = ""
  ): Promise<void> =>
    getDeviceCharacteristicsGENIInfo(
      selectedDeviceCharacteristicsId,
      unitType
    ).then((geni: GENIInfo) => {
      setUnitTypeDropdownValues(geni.unitTypes);
      setUnitVersionDropdownValues(geni.unitVersions);
    });

  const setDeviceProductNameValue = (productName: string): void => {
    const nullString = "null";
    const value: { label: string; value: string }[] = [];

    value.push({ label: productName, value: productName });

    if (isEmpty(productName)) {
      value.push({ label: nullString, value: nullString });
    }

    setDeviceProductName(value);
  };

  const setDeviceTypeValue = (deviceType: string): void => {
    const nullString = "null";
    const value: { label: string; value: string }[] = [];

    value.push({ label: deviceType, value: deviceType });

    if (isEmpty(deviceType)) {
      value.push({ label: nullString, value: nullString });
    }

    setDeviceType(value);
  };

  useEffect(() => {
    setIsSpinnerVisible(true);

    getDeviceCharacteristics(
      ({
        getAllDeviceCategory,
        getParentDeviceModalName,
        getSubDeviceModalName,
        getSystemDeviceModalName,
        getAllDeviceType,
        getAllVendor
      }: any) => {
        setDeviceCategories(
          getAllDeviceCategory.filter(
            (item: ILabelValue) =>
              item.value !== DeviceViewConst.COMMUNICATION_DEVICE
          )
        );
        if (getParentDeviceModalName && !isEmpty(getParentDeviceModalName)) {
          setDeviceProductNamesParent(getParentDeviceModalName);
          setDeviceProductNames(getParentDeviceModalName);
          setValue("deviceProductName", getParentDeviceModalName[0].value);
        }
        if (getSubDeviceModalName && !isEmpty(getSubDeviceModalName)) {
          setDeviceProductNamesSubDevice(getSubDeviceModalName);
        }
        if (getSystemDeviceModalName && !isEmpty(getSystemDeviceModalName)) {
          setDeviceProudctNamesSystem(getSystemDeviceModalName);
        }
        if (getAllDeviceType && !isEmpty(getAllDeviceType)) {
          setDeviceTypes(getAllDeviceType);
          setValue("deviceType", getAllDeviceType[0].value);
        }
        if (getAllVendor && !isEmpty(getAllVendor)) {
          setVendors(getAllVendor);
          setValue("vendor", getAllVendor[0].value);
        }
        if (deviceId) {
          getDevice(deviceId)
            .then((dev) => {
              setDevice(dev);
              return dev;
            })
            .then((dev) => setGENIValues(dev.deviceCharacteristicId))
            .then(() => setIsSpinnerVisible(false));
        }
      }
    );
  }, [deviceId]);

  const getDeviceCategory = (d: any) => {
    if (d) {
      return d.deviceCategory;
    }

    return "";
  };

  const loadDefaultDeviceDetails = (productId = ""): void => {
    const deviceCategoriesByProductName = getDeviceCategoryByProductName(
      values.deviceProductName
    );

    if (deviceCategoriesByProductName && deviceCategoriesByProductName[0]) {
      if (getDeviceCategory(device) !== DeviceViewConst.COMMUNICATION_DEVICE) {
        getDeviceCharacteristicsGENIInfo(productId).then((geni: GENIInfo) => {
          setFamilyCodeValue(geni.familyCode);

          setUnitTypeDropdownValues(geni.unitTypes);
          setUnitTypeValue(geni.unitTypes);

          setUnitVersionDropdownValues(geni.unitVersions);
          setUnitVersionValue(geni.unitVersions);
        });
      }
    }
  };

  useEffect(() => {
    if (
      device &&
      getDeviceCategory(device) === DeviceViewConst.COMMUNICATION_DEVICE
    ) {
      if (deviceProductNamesParent && !isEmpty(deviceProductNamesParent)) {
        setDeviceProductNames(deviceProductNamesParent);
        setValue("deviceProductName", deviceProductNamesParent[0]["value"]);
      }
    } else if (device && getDeviceCategory(device) === DeviceViewConst.SYSTEM) {
      if (deviceProductNamesSystem && !isEmpty(deviceProductNamesSystem)) {
        setDeviceProductNames(deviceProductNamesSystem);
        setValue("deviceProductName", deviceProductNamesSystem[0]["value"]);
        setValue("status", "");
      }
    } else if (
      device &&
      getDeviceCategory(device) === DeviceViewConst.EQUIPMENT
    ) {
      if (
        deviceProductNamesSubDevice &&
        !isEmpty(deviceProductNamesSubDevice)
      ) {
        setDeviceProductNames(deviceProductNamesSubDevice);
        setValue("deviceProductName", deviceProductNamesSubDevice[0]["value"]);
        setValue("status", "");
      }
    }
  }, [
    device,
    deviceProductNamesParent,
    deviceProductNamesSubDevice,
    deviceProductNamesSystem
  ]);

  const setValueGENIFields = (): void => {
    if (device) {
      const familyCode = device.familyCode === 0 ? "null" : device.familyCode;
      const deviceCharacteristicUnitTypeId =
        device.deviceCharacteristicUnitTypeId === null
          ? "null"
          : device.deviceCharacteristicUnitTypeId;
      const deviceCharacteristicUnitTypeVersionId =
        device.deviceCharacteristicUnitTypeVersionId === null
          ? "null"
          : device.deviceCharacteristicUnitTypeVersionId;

      setValue("familyCode", familyCode);
      setValue(
        "deviceCharacteristicUnitTypeId",
        deviceCharacteristicUnitTypeId
      );
      setValue(
        "deviceCharacteristicUnitTypeVersionId",
        deviceCharacteristicUnitTypeVersionId
      );
    }
  };

  useEffect(() => {
    if (device && !isEmpty(device)) {
      setValue("deviceName", device.deviceName);
      setValue("deviceTwinId", device.deviceTwinId);
      setValue("deviceId", device.deviceId);
      setValue("deviceProductName", device.deviceProductName);
      setDeviceProductNameValue(device?.deviceProductName);
      setDeviceTypeValue(device?.deviceType);
      setValue("protocol", device.protocol);
      setValue("offering", device.offering);
      setValue("deviceType", device.deviceType);
      setValue("vendor", device.vendor);
      const isValidAddressType =
        addressTypes.filter(
          (addressType) => addressType.value == device.addressType
        ).length > 0;
      if (isValidAddressType) {
        setValue("addressType", `${device.addressType}`);
      } else {
        setValue("addressType", "");
      }
      setValue("address", device.address);
      setValue("sapProductNumber", device.sapProductNumber);
      setValue("gua", device.gua);
      setValue("iccId", device.iccId);
      setValue("status", device.status);
      setValue("firmwareVersion", device.firmwareVersion);
      setLastFirmwareUpdate(device.lastFirmwareUpdate);

      if (device && device.installationId && device.assetName) {
        setInstallationDefaultValue({
          value: device.installationId,
          label: device.assetName
        });
      } else {
        setInstallationDefaultValue({
          value: "",
          label: defaultInstallationSelect
        });
      }
      if (device.rootAssetName) {
        setValue("account", device.rootAssetName);
      }
      if (device.parentDeviceId) {
        setParentDeviceIdDefaultValue({
          value: device.parentDeviceId,
          label: device.parentDeviceName
        });
      }
      if (device.subDevices && device.subDevices.length > 0) {
        setSystemSubDevices(
          getCheckedOptions(device.subDevices, "id", "deviceName")
        );
      } else {
        setSystemSubDevices([]);
      }
      let hierarchyIds: string[] = [];
      if (device.hierarchy) {
        getHierarchyIds(device.hierarchy, "deviceHierarchyId", hierarchyIds);
        if (device.parentDeviceId) {
          hierarchyIds = hierarchyIds.filter(
            (id) => id !== device.parentDeviceId
          );
        }
      }
      setBlacklistParentDevices(hierarchyIds);
      if (device.hierarchyId) {
        getAccount(device.hierarchyId).then((h) => {
          if (h) {
            setHierarchy(h);
          }
        });
      }

      setValueGENIFields();
    }
  }, [device]);

  useEffect(() => {
    const deviceCategoriesByProductName = getDeviceCategoryByProductName(
      values.deviceProductName
    );
    if (
      deviceCategoriesByProductName &&
      !isEmpty(deviceCategoriesByProductName)
    ) {
      setValue(
        "deviceCategory",
        deviceCategoriesByProductName[0].assetCategory
      );
      setValue("deviceType", deviceCategoriesByProductName[0].assetType);
      setValue("vendor", deviceCategoriesByProductName[0].vendor);
      setDeviceCharacteristicId(deviceCategoriesByProductName[0].id);
    }
  }, [values.deviceProductName]);

  useEffect(() => {
    if (device && device.systemId) {
      setValue("systemId", device.systemId);
    } else {
      setValue("systemId", "");
    }
  }, [device, systems]);

  useEffect(() => {
    if (values.addressType && device) {
      if (values.addressType == device.addressType) {
        setValue("address", device.address);
      } else {
        setValue("address", "");
      }
      triggerValidation("address");
    }
  }, [values.addressType]);

  useEffect(() => {
    if (values.address) {
      triggerValidation("address");
      setAddressErrorExists("");
    }
  }, [values.address]);

  useEffect(() => {
    if (values.deviceTwinId) {
      triggerValidation("deviceTwinId");
      setDeviceTwinIdErrorExists("");
    }
  }, [values.deviceTwinId]);

  useEffect(() => {
    if (values.gua) {
      triggerValidation("gua");
      setGuaErrorExists("");
    }
  }, [values.gua]);

  useEffect(() => {
    setHasPreviousMetadata(false);
    setIsMetadataLoaded(false);
    getDeviceMetadata(deviceId)
      .then((m: MetadataProps[]) => {
        setMetadata(m.sort(compareMetadata));
        setHasPreviousMetadata(m.length > 0);
        setIsMetadataLoaded(true);
      })
      .catch(() => {
        setIsMetadataLoaded(true);
      });
  }, [deviceId]);

  const switchToUpdate = (): void => {
    setIsUpdate(true);
    if (
      device.installationId &&
      device.deviceCategory === DeviceViewConst.SYSTEM
    ) {
      getAllUnassignedSubdevicesInInstallation(device.installationId).then(
        (subDevices) => {
          let newSubDevices = getCheckedOptions(subDevices, "id", "deviceName");
          if (device.subDevices && device.subDevices.length > 0) {
            const checkedSubDevices = getCheckedOptions(
              device.subDevices,
              "id",
              "deviceName",
              true
            );
            newSubDevices = [...checkedSubDevices, ...newSubDevices];
          }
          setSystemSubDevices(newSubDevices);
        }
      );
    }
  };

  const getAdditionalSubdevicePropsToSubmit = (data: any) => {
    const nullString = "null";

    return {
      status: null,
      installationId: null,
      deviceType: 1,
      familyCode: data.familyCode === nullString ? null : data.familyCode,
      deviceCharacteristicUnitTypeId:
        data.deviceCharacteristicUnitTypeId === nullString
          ? null
          : data.deviceCharacteristicUnitTypeId,
      deviceCharacteristicUnitTypeVersionId:
        data.deviceCharacteristicUnitTypeVersionId === nullString
          ? null
          : data.deviceCharacteristicUnitTypeVersionId
    };
  };

  const saveMetadata = (deviceId = ""): Promise<any> => {
    if (hasPreviousMetadata) {
      return updateBatchMetadata(deviceId, metadata);
    }

    return saveBatchMetadata(deviceId, metadata);
  };

  const isUpdateDeviceSuccessful = (result: any): boolean => {
    let isSuccess = true;
    if (result && result.Errors && result.Errors.Address) {
      setAddressErrorExists(result.Errors.Address);
      triggerValidation("address");
      setIsSpinnerVisible(false);
      isSuccess = false;
    }

    if (result && result.DeviceTwinId) {
      setDeviceTwinIdErrorExists(result.DeviceTwinId[0]);
      triggerValidation("deviceTwinId");
      setIsSpinnerVisible(false);
      isSuccess = false;
    }

    if (result && result.Gua) {
      setGuaErrorExists(result.Gua[0]);
      triggerValidation("gua");
      setIsSpinnerVisible(false);
      isSuccess = false;
    }

    return isSuccess;
  };

  const onSubmit = (data: any): void => {
    if (isModifyingMetadata) {
      return;
    }

    setIsSpinnerVisible(true);

    if (lastFirmwareUpdate) {
      data.lastFirmwareUpdate = Moment(lastFirmwareUpdate?.toString()).format(
        "L"
      );
    } else {
      data.lastFirmwareUpdate = null;
    }

    data.deviceCharacteristicId = deviceCharacteristicId;
    if (
      device &&
      device.deviceCategory === DeviceViewConst.COMMUNICATION_DEVICE
    ) {
      data.parentDeviceId = null;
      data.deviceType = 2;
    } else if (device && device.deviceCategory === DeviceViewConst.SYSTEM) {
      data.status = null;
      data.deviceType = 3;
    } else {
      data = { ...data, ...getAdditionalSubdevicePropsToSubmit(data) };
    }
    if (data.installationId === defaultInstallationSelect) {
      data.installationId = null;
    }
    if (data.sapProductNumber === "") {
      data.sapProductNumber = null;
    }
    const subDeviceIds = getAllChecked(systemSubDevices, "value");
    if (subDeviceIds && subDeviceIds.length > 0 && data.installationId) {
      data.subDeviceIds = subDeviceIds;
    } else {
      data.subDeviceIds = [];
    }
    data.id = device.id;
    data.addressType = parseInt(data.addressType);
    if (data.address === device.address) {
      delete data.address;
    }

    updateDevice(data)
      .then((result) => isUpdateDeviceSuccessful(result))
      .then((isSuccess: boolean) => {
        if (isSuccess) {
          saveMetadata(device.id).then(() => {
            setIsUpdate(false);
            getDevice(device.id).then((d) => {
              setDevice(d);
              setIsSpinnerVisible(false);
            });
          });
        }
      })
      .catch(() => {
        setIsSpinnerVisible(false);
      });
  };

  const getDeviceHierarchyLabel = (item: IDevice): string => {
    return item.deviceName;
  };
  const getDeviceId = (item: IDevice): string | undefined => {
    return item.deviceHierarchyId;
  };

  const getHierarchyLabel = (item: IAsset): string => {
    return item.assetName;
  };
  const getHierarchyId = (item: IAsset): string => {
    return item.hierarchyId;
  };

  const goToSubDeviceForm = (): void => {
    history.push(
      `${DeviceSubDeviceFormPath}?parentDeviceId=${device.id}&deviceName=${device.deviceName}`
    );
  };

  const onInfoDevice = (id: string): void => {
    history.push(DeviceViewPath.replace(":deviceId", id));
  };

  const updateInstallation = (installationId: string): void => {
    if (device && installationId && installationId !== "") {
      if (isUpdate && device.deviceCategory === DeviceViewConst.SYSTEM) {
        if (installationId !== device.installationId) {
          getAllUnassignedSubdevicesInInstallation(`${installationId}`).then(
            (subDevices) => {
              setSystemSubDevices(
                getCheckedOptions(subDevices, "id", "deviceName")
              );
            }
          );
        } else if (device.subDevices && device.subDevices.length > 0) {
          switchToUpdate();
        }
      }
      getInstallationById(installationId).then((ins) => {
        setValue("account", ins.rootAssetName);
      });
    } else {
      setValue("account", "");
      setSystemSubDevices([]);
    }
  };

  const updateParentDevice = (parentDeviceId: string): void => {
    if (parentDeviceId && parentDeviceId !== "") {
      getSystemsByDeviceId(`${parentDeviceId}`).then((sys) => {
        if (sys) {
          setSystems(getOptions(sys, "id", "deviceName"));
        } else {
          setSystems([]);
        }
      });
    } else {
      setSystems([]);
    }
  };

  const onChangeDeviceProductName = (
    e: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    const deviceCategoriesByProductName = getDeviceCategoryByProductName(
      e.target.value
    );
    const item = deviceCategoriesByProductName[0];

    if (item) {
      loadDefaultDeviceDetails(item.id);
    }
  };

  const metadataTableComponent = (
    <Paper>
      {isSpinnerVisible ? (
        <></>
      ) : (
        <MetadataTable
          disabled={!isUpdate}
          metadata={metadata}
          onChangeMetadata={(data: MetadataProps[]): void => setMetadata(data)}
          onClickActionButton={(name): void => {
            if (name === "Add" && !isModifyingMetadata) {
              setIsModifyingMetadata(true);
            } else {
              setIsModifyingMetadata(false);
            }
          }}
        />
      )}
    </Paper>
  );

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <PageHeader
        title={!isEmpty(device) ? device!.deviceName : ""}
        subTitle={!isEmpty(device) ? device!.deviceCategory : ""}
        details={
          <Fragment>
            <PageHeaderDetailItem
              label="ID"
              value={!isEmpty(device) ? device!.deviceId : ""}
            />
          </Fragment>
        }
      >
        {device &&
          device.deviceCategory === DeviceViewConst.COMMUNICATION_DEVICE && (
            <PageHeaderRightItem
              label="Status"
              value={
                !isEmpty(device)
                  ? statuses.find((x) => x.value === device.status)?.label
                  : ""
              }
            />
          )}
        <PageHeaderRightItem
          label="Owner"
          value={!isEmpty(device) ? device!.ownerName : ""}
        />
      </PageHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <div className={classes.root}>
              <ExpansionPanel label="General">
                <NewTextField
                  readOnly={!isUpdate}
                  error={errors.deviceName}
                  label="Device Name"
                  name="deviceName"
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired
                  })}
                />
                {device &&
                  device.computedHash &&
                  device.deviceCategory ===
                    DeviceViewConst.COMMUNICATION_DEVICE && (
                    <TextField
                      disabled
                      InputProps={{
                        readOnly: true
                      }}
                      label="Communication Device ID"
                      name="communicationDeviceId"
                      style={{ margin: "8px 0" }}
                      fullWidth
                      margin="normal"
                      value={device.computedHash}
                    />
                  )}
                <NewTextField
                  readOnly
                  error={errors.deviceId}
                  label="Device ID"
                  name="deviceId"
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired
                  })}
                />
                <NewTextField
                  readOnly={!isUpdate}
                  error={errors.deviceTwinId}
                  label="Device Twin ID"
                  name="deviceTwinId"
                  inputRef={register({
                    required: false,
                    validate: () => {
                      if (deviceTwinIdErrorExists !== "") {
                        return deviceTwinIdErrorExists;
                      }
                    }
                  })}
                />

                {device && device.deviceProductName && (
                  <Dropdown
                    readOnly={device ? !isUpdate : true}
                    label="Device Product Name"
                    onChange={onChangeDeviceProductName}
                    name="deviceProductName"
                    rules={{ required: FormErrorMessages.fieldRequired }}
                    data={deviceProductNames}
                    control={control}
                    error={errors.deviceProductName}
                  />
                )}
                {device &&
                  device.deviceCategory !==
                    DeviceViewConst.COMMUNICATION_DEVICE && (
                    <Dropdown
                      readOnly
                      label="Device Category"
                      name="deviceCategory"
                      rules={{ required: FormErrorMessages.fieldRequired }}
                      data={deviceCategories}
                      control={control}
                      error={errors.deviceCategory}
                    />
                  )}
                {device && device.deviceType && (
                  <Dropdown
                    readOnly
                    label="Device Type"
                    name="deviceType"
                    rules={{ required: FormErrorMessages.fieldRequired }}
                    data={deviceTypes}
                    control={control}
                    error={errors.deviceType}
                  />
                )}
                <Dropdown
                  readOnly
                  label="Vendor"
                  name="vendor"
                  rules={{ required: false }}
                  data={vendors}
                  control={control}
                  error={errors.vendor}
                />

                {device && device.deviceCategory === DeviceViewConst.EQUIPMENT && (
                  <Grid item xs={12}>
                    <NewTextField
                      readOnly
                      error={errors.familyCode}
                      label="Family Code"
                      name="familyCode"
                      inputRef={register({ required: false })}
                    />

                    <Dropdown
                      readOnly={unitTypes.length < 2 || !isUpdate}
                      label="Unit Type"
                      name="deviceCharacteristicUnitTypeId"
                      data={unitTypes}
                      control={control}
                      error={errors.deviceCharacteristicUnitTypeId}
                    />

                    <Dropdown
                      readOnly={unitVersions.length < 2 || !isUpdate}
                      label="Unit Version"
                      name="deviceCharacteristicUnitTypeVersionId"
                      data={[...unitVersions].sort(
                        (a, b) => parseInt(a.label) - parseInt(b.label)
                      )}
                      control={control}
                      error={errors.deviceCharacteristicUnitTypeVersionId}
                    />
                  </Grid>
                )}

                {device &&
                  device.deviceCategory ===
                    DeviceViewConst.COMMUNICATION_DEVICE && (
                    <Dropdown
                      readOnly={!isUpdate}
                      label="Status"
                      name="status"
                      rules={{ required: FormErrorMessages.fieldRequired }}
                      data={statuses}
                      control={control}
                      error={errors.status}
                    />
                  )}

                {device && device.deviceCategory !== DeviceViewConst.SYSTEM && (
                  <Fragment>
                    <NewTextField
                      readOnly={!isUpdate}
                      error={errors.firmwareVersion}
                      label="Firmware Version"
                      name="firmwareVersion"
                      maxLength={8!}
                      inputRef={register({
                        required: false
                      })}
                    />

                    <DatePicker
                      readOnly={!isUpdate}
                      label="Last Firmware Update"
                      name="lastFirmwareUpdate"
                      fullwith={true}
                      control={control}
                      value={lastFirmwareUpdate!}
                      onChange={(date) => {
                        setLastFirmwareUpdate(date);
                      }}
                    />
                  </Fragment>
                )}
              </ExpansionPanel>
              <ExpansionPanel label="Details">
                {device?.deviceCategory ===
                  DeviceViewConst.COMMUNICATION_DEVICE && (
                  <div>
                    <NewTextField
                      readOnly={!isUpdate}
                      error={errors.protocol}
                      label="Protocol"
                      name="protocol"
                      inputRef={register({
                        required: false
                      })}
                    />
                    <NewTextField
                      readOnly={!isUpdate}
                      error={errors.offering}
                      label="Offering"
                      name="offering"
                      inputRef={register({
                        required: false
                      })}
                    />
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={9}>
                        <NewTextField
                          readOnly={!isUpdate}
                          error={errors.address}
                          label="Address"
                          name="address"
                          inputRef={register({
                            required: FormErrorMessages.fieldRequired,
                            validate: (value) => {
                              if (addressErrorExists !== "") {
                                return addressErrorExists;
                              }

                              return validateAddressByAddressType(
                                value,
                                values.addressType as string
                              );
                            }
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Dropdown
                          readOnly={!isUpdate}
                          label="Type"
                          name="addressType"
                          rules={{ required: FormErrorMessages.fieldRequired }}
                          data={addressTypes}
                          control={control}
                          error={errors.addressType}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}

                <NewTextField
                  readOnly={!isUpdate}
                  error={errors.sapProductNumber}
                  label="SAP Product Number"
                  name="sapProductNumber"
                  inputRef={register({
                    required: false,
                    maxLength: {
                      value: 8,
                      message: FormErrorMessages.invalidSapNumber
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9]*$/i,
                      message: FormErrorMessages.invalidSapNumber
                    }
                  })}
                />
                <NewTextField
                  readOnly={!isUpdate}
                  error={errors.gua}
                  label="GUA"
                  name="gua"
                  inputRef={register({
                    required: false,
                    validate: () => {
                      if (guaErrorExists !== "") {
                        return guaErrorExists;
                      }
                    }
                  })}
                />
                <NewTextField
                  readOnly={!isUpdate}
                  error={errors.iccId}
                  label="ICCID"
                  name="iccId"
                  inputRef={register({
                    required: false,
                    minLength: {
                      value: 20,
                      message: FormErrorMessages.invalidIccId
                    },
                    maxLength: {
                      value: 20,
                      message: FormErrorMessages.invalidIccId
                    }
                  })}
                />
              </ExpansionPanel>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            {device && device.deviceCategory !== DeviceViewConst.SYSTEM && (
              <Box mb={3}>
                <Paper>
                  <Box p={3}>
                    {device.deviceCategory !==
                      DeviceViewConst.COMMUNICATION_DEVICE && (
                      <Box mb={3}>
                        <PaperHeading label="Parent Details" />
                        <AutoComplete
                          disabled={!isUpdate}
                          error={errors.parentDeviceId}
                          label="Parent Device"
                          name="parentDeviceId"
                          labelName="deviceName"
                          valueName="id"
                          getData={getAllDevicesDropdown}
                          inputRef={register({ required: false })}
                          onChange={updateParentDevice}
                          defaultValue={parentDeviceIdDefaultValue}
                          blacklist={blacklistParentDevices}
                        />
                        {systems.length > 0 && (
                          <Dropdown
                            readOnly={!isUpdate}
                            label="System"
                            name="systemId"
                            rules={{ required: false }}
                            data={systems}
                            control={control}
                            error={errors.systemId}
                          />
                        )}
                      </Box>
                    )}
                    {(device.deviceCategory ===
                      DeviceViewConst.COMMUNICATION_DEVICE ||
                      (device.parentDeviceId &&
                        device.parentDeviceId === values.parentDeviceId)) && (
                      <Fragment>
                        <PaperHeading label="Device Hierarchy" />
                        <ViewTreeView
                          data={device}
                          renderLabel={getDeviceHierarchyLabel}
                          getId={getDeviceId}
                          onInfo={onInfoDevice}
                        />
                      </Fragment>
                    )}
                  </Box>
                </Paper>
              </Box>
            )}
            <Box mb={3}>
              <Paper>
                <Box p={3}>
                  <Box mb={3}>
                    <PaperHeading label="Asset Details" />
                    <NewTextField
                      error={errors.account}
                      label="Account"
                      name="account"
                      inputRef={register({
                        required: false
                      })}
                      readOnly
                    />
                    <AutoComplete
                      disabled={
                        device &&
                        (device.deviceCategory ===
                          DeviceViewConst.COMMUNICATION_DEVICE ||
                          device.deviceCategory === DeviceViewConst.SYSTEM)
                          ? !isUpdate
                          : true
                      }
                      error={errors.installationId}
                      label="Installation"
                      name="installationId"
                      labelName="installationName"
                      valueName="id"
                      getData={
                        device &&
                        device.deviceCategory ===
                          DeviceViewConst.COMMUNICATION_DEVICE
                          ? getUnassignedInstallations
                          : getAllInstallations
                      }
                      inputRef={register({ required: false })}
                      onChange={updateInstallation}
                      defaultValue={installationDefaultValue}
                    />
                  </Box>
                  {device &&
                    device.hierarchyId &&
                    device.deviceCategory ===
                      DeviceViewConst.COMMUNICATION_DEVICE && (
                      <Fragment>
                        <PaperHeading label="Asset Hierarchy" />
                        <ViewTreeView
                          data={hierarchy}
                          renderLabel={getHierarchyLabel}
                          getId={getHierarchyId}
                        />
                      </Fragment>
                    )}
                </Box>
              </Paper>
            </Box>
            {device &&
              device.deviceCategory === DeviceViewConst.SYSTEM &&
              systemSubDevices.length > 0 && (
                <Paper>
                  <Box p={3}>
                    <PaperHeading label="System Details" />
                    <br />
                    <List
                      checkable={isUpdate}
                      data={systemSubDevices}
                      setData={setSystemSubDevices}
                      label="Associated Sub Devices"
                    />
                  </Box>
                </Paper>
              )}
            {isMetadataLoaded && <Box mb={3}>{metadataTableComponent}</Box>}
          </Grid>
        </Grid>

        <FloatingActionButtonList>
          {device && device.deviceCategory !== DeviceViewConst.SYSTEM && (
            <FloatingActionButton onClick={goToSubDeviceForm}>
              <AddIcon />
              Sub Device
            </FloatingActionButton>
          )}
          {isUpdate && (
            <FloatingActionButton type="submit">
              <SaveIcon />
              Save
            </FloatingActionButton>
          )}
          {!isUpdate && (
            <FloatingActionButton onClick={switchToUpdate}>
              <EditIcon />
              Update
            </FloatingActionButton>
          )}
        </FloatingActionButtonList>
      </form>
    </MainBarDashboard>
  );
};

export default DeviceView;
