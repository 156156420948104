import React, { useContext, useState } from "react";

import { ModalContext } from "provider/ModalProvider";
import { AccountContext } from "provider/AccountProvider";

import ViewTreeView from "components/ViewTreeView";
import { ICheckedItem } from "components/ViewTreeView/type";
import DialogModal from "components/DialogModal";
import { IAsset } from "components/type";
import { HierarchyModalView } from "constants/constant";

const HierarchyModal: React.FC = () => {
  const { hierarchyModalContext } = useContext(ModalContext);
  const [showHierarchyModal, setShowHierarchyModal] = hierarchyModalContext;
  const { openHierarchyContext } = useContext(AccountContext);
  const [assets] = openHierarchyContext;
  const getHierarchyLabel = (item: IAsset) => {
    return item ? `${item.assetName} (${item.typeName})` : "";
  };
  const getId = (item: IAsset) => {
    return item ? item.hierarchyId : "";
  };

  const [checkedItemRefs, setCheckedItemRefs] = useState<ICheckedItem>();

  return (
    <DialogModal
      showCancelButton
      title={HierarchyModalView.DIALOG_TITLE}
      content={
        <ViewTreeView
          checkedItemRefs={checkedItemRefs}
          setCheckedItemRefs={setCheckedItemRefs}
          data={assets}
          renderLabel={getHierarchyLabel}
          getId={getId}
        />
      }
      showModal={showHierarchyModal}
      onOk={(): void => setShowHierarchyModal(false)}
      onHide={(): void => setShowHierarchyModal(false)}
    />
  );
};

export default HierarchyModal;
