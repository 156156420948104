import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ViewTreeItem from "./ViewTreeItem/ViewTreeItem";
import CloseSquare from "svg/CloseSquare";
import MinusSquare from "svg/MinusSquare";
import PlusSquare from "svg/PlusSquare";

import { IViewTreeView, ITreeItem, ICheckedItem } from "./type";

const useStyles = makeStyles(
  createStyles({
    root: {
      maxHeight: 400,
      overflow: "auto",
      flexGrow: 1,
    },
  })
);

export const getCheckedValues = (
  data: ICheckedItem,
  checkedValues: string[]
) => {
  if (data.checked) {
    checkedValues.push(data.id);
  } else {
    if (data.checkedItems) {
      data.checkedItems.forEach((childData: ICheckedItem) => {
        return getCheckedValues(childData, checkedValues);
      });
    }
  }
};

export const getHierarchyIds = (
  data: ITreeItem,
  idFieldName: string,
  hierarchyIds: string[]
) => {
  if (data.children) {
    data.children.map((childData: ITreeItem) => {
      return getHierarchyIds(childData, idFieldName, hierarchyIds);
    });
  }

  hierarchyIds.push((data as any)[idFieldName]);
};

const ViewTreeView = ({
  data,
  renderLabel,
  getId,
  getChecked,
  selectable,
  checkedItemRefs,
  setCheckedItemRefs,
  onInfo,
  onSingleSelect,
  overrideSingleSelected,
  getIsSingleSelectedDisabled,
}: IViewTreeView): JSX.Element => {
  const classes = useStyles();
  const [expandedList, setExpanded] = useState<string[]>([]);
  const [singleSelected, setSingleSelected] = useState<string>("");
  const nodeList: string[] = [];

  const insertExpandedNode = (items: any) => {
    const id = getId(items);
    nodeList.push(id);

    const itemVal = items?.children.map((items: any) => {
      nodeList.push(id);
      if (items && items.children?.length > 0) {
        insertExpandedNode(items);
      }
    });

    setExpanded([...expandedList, ...nodeList]);
    return itemVal;
  };

  const createCheckedTree = (treeProps: ITreeItem) => {
    const { children } = treeProps;
    const id = getId(treeProps);
    const selected = getChecked(treeProps);
    const parent: ICheckedItem = {
      id: id,
      checked: selected,
    };

    if (children) {
      const childrenCheckedItems: ICheckedItem[] = [];
      children.map((item: ITreeItem) => {
        const childId = getId(item);
        const childSelected = getChecked(item);
        const child: ICheckedItem = {
          id: childId,
          checked: childSelected,
        };
        if (item.children && item.children.length > 0) {
          return childrenCheckedItems.push(createCheckedTree(item));
        } else {
          return childrenCheckedItems.push(child);
        }
      });
      parent.checkedItems = childrenCheckedItems;
    }
    return parent;
  };

  useEffect(() => {
    if (data) {
      insertExpandedNode(data.hierarchy);
      if (data.hierarchy && selectable && setCheckedItemRefs) {
        setCheckedItemRefs(createCheckedTree(data.hierarchy));
      }
      if (
        onSingleSelect &&
        getIsSingleSelectedDisabled &&
        getIsSingleSelectedDisabled(data.hierarchy)
      ) {
        setSingleSelected(data.hierarchy.hierarchyId);
        onSingleSelect(data.hierarchy.hierarchyId);
      }
    }
  }, [data]);

  useEffect(() => {
    if (overrideSingleSelected && onSingleSelect) {
      setSingleSelected(overrideSingleSelected);
    }
  }, [overrideSingleSelected]);

  const nodeToggle = (event: object, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const createTree = (treeProps: ITreeItem) => {
    const { children, isParent } = treeProps;
    const id = getId(treeProps);

    return (
      <ViewTreeItem
        selectable={selectable}
        checkedItemRefs={checkedItemRefs}
        setCheckedItemRefs={setCheckedItemRefs}
        key={id}
        nodeId={id}
        label={renderLabel(treeProps)}
        isParent={isParent}
        selected={id === data?.id ? true : false}
        onInfo={onInfo}
        onSingleSelect={onSingleSelect}
        singleSelected={singleSelected}
        setSingleSelected={setSingleSelected}
        isSingleSelectedDisabled={
          getIsSingleSelectedDisabled
            ? !getIsSingleSelectedDisabled(treeProps)
            : false
        }
      >
        {children &&
          children.map((item: ITreeItem) => {
            const childId = getId(item);
            if (item.children && item.children.length > 0) {
              return createTree(item);
            } else {
              return (
                <ViewTreeItem
                  selectable={selectable}
                  checkedItemRefs={checkedItemRefs}
                  setCheckedItemRefs={setCheckedItemRefs}
                  key={childId}
                  nodeId={childId}
                  label={renderLabel(item)}
                  isParent={item.isParent}
                  selected={childId === data?.id ? true : false}
                  onInfo={onInfo}
                  onSingleSelect={onSingleSelect}
                  singleSelected={singleSelected}
                  setSingleSelected={setSingleSelected}
                  isSingleSelectedDisabled={
                    getIsSingleSelectedDisabled
                      ? !getIsSingleSelectedDisabled(item)
                      : false
                  }
                />
              );
            }
          })}
      </ViewTreeItem>
    );
  };
  return (
    <TreeView
      defaultExpanded={expandedList}
      expanded={expandedList}
      className={classes.root}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={<CloseSquare />}
      onNodeToggle={nodeToggle}
    >
      {data && data.hierarchy && createTree(data.hierarchy)}
    </TreeView>
  );
};
export default ViewTreeView;
