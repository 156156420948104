import React from "react";
import Layout from "layouts";
import { renderRoutes } from "react-router-config";
import { IElementRouteAttribute } from "routes/type";
import { UserProvider } from "provider/UserProvider";
// import UserProfile from "./UserProfile";

const Users = ({ route }: IElementRouteAttribute) => {
  return (
    <UserProvider>
      <Layout>
        {renderRoutes(route.routes)}
      </Layout>
    </UserProvider>
  );
};

export default Users;
