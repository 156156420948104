import React, { useContext } from "react";
import { ModalContext } from "provider/ModalProvider";
import { SectionContext } from "provider/SectionProvider";

import DialogModal from "components/DialogModal";
import CreateSectionModalForm from "./CreateModal";

const Section = () => {
  const { sectionModalContext } = useContext(ModalContext);
  const [showSectionModal, setShowSectionModal] = sectionModalContext;
  const { updateSectionContext } = useContext(SectionContext);
  const [section] = updateSectionContext;
  const handleOk = (): void => {
    return;
  };

  return (
    <DialogModal
      showCancelButton={section.isCreate ? true : false}
      showOkButton={section.isCreate ? true : false}
      title={section.isCreate ? "Creating Section" : "Section Details"}
      content={<CreateSectionModalForm />}
      showModal={showSectionModal}
      formId={"createSection-form"}
      onOk={(): void => handleOk()}
      onHide={(): void => setShowSectionModal(false)}
    />
  );
};

export default Section;
