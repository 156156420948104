import React from "react";
import styled from "styled-components";

import {
  mainStyle,
  sectionStyle,
  baseStyle,
  h1Style,
  h3Style,
  pStyle,
  aStyle
} from "./styles";

import image from "../../grundfos-logo.svg";
import { uri } from "clientApp/configAdal";

const StyledHeader = styled.div`
  display: flex;
  background: #11497b;
`;
StyledHeader.displayName = "StyledHeader";

const StyledImageContainer = styled.div`
  width: 200px;
  margin: 0px 15px 15px 0px;
  padding: 10px 15px 5px 15px;
`;
StyledImageContainer.displayName = "StyledImageContainer";

const Denied = () => {
  const nextLine = "%0D%0A%0D%0A";

  const href = `mailto:werewolves@grundfos.onmicrosoft.com?subject=Request Access for Account Service&body=Hi,${nextLine}Please grant access to Account Service UI (${uri}).${nextLine}Thank you.`;

  return (
    <div>
      <head>
        <title>404 - Access Denied | Grundfos</title>
      </head>

      <body>
        <header>
          <StyledHeader>
            <StyledImageContainer>
              <img src={image} title="Grundfos" alt="Grundfos" />
            </StyledImageContainer>
          </StyledHeader>
        </header>

        <main style={mainStyle}>
          <section style={sectionStyle}>
            <h1 style={{ ...baseStyle, ...h1Style }}>Sorry...</h1>
            <h3 style={{ ...baseStyle, ...h3Style }}>
              It seems like you don&rsquo;t have permission to access this page.
            </h3>
            <a
              href={href}
              title="Request access"
              style={{ ...baseStyle, ...aStyle }}
            >
              Request for access.
            </a>
          </section>
        </main>
      </body>
    </div>
  );
};
export default Denied;
