import React, { useState, useEffect } from "react";
import {
  fade,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import { TransitionProps } from "@material-ui/core/transitions";

import styled from "styled-components";

import { IAsset } from "../../type";
import { ICheckedItem } from "../type";

const TransitionComponent = (props: TransitionProps) => {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
};

const TreeItemContainer = styled.div`
  position: relative;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  float: right;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    iconContainer: {
      "& .close": {
        opacity: 0.3,
      },
    },
    label: {
      padding: 10,
      fontWeight: (props: IAsset) =>
        !props.isParent && props.selected ? 700 : 100,
      backgroundColor: (props: IAsset) =>
        props.selected ? "#c9c9c9" : "#fcfcfc",
      paddingLeft: (props: any) => (props.selectable ? 45 : 0),
    },
    group: {
      marginLeft: 12,
      paddingLeft: 12,
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
  })
);

const checkboxStyle = makeStyles({
  checkBoxContainer: { position: "absolute", left: 25, top: 1 },
});

const ViewTreeItem = (props: any): JSX.Element => {
  const classes = useStyles(props);
  const checkboxClass = checkboxStyle();
  const newProps = Object.keys(props).reduce((object: any, key: string) => {
    if (key !== "selected" && key !== "isParent") {
      object[key] = props[key];
    }

    return object;
  }, {});

  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  const findObject = (
    id: string,
    checkItem: ICheckedItem,
    cb: (checkItem: ICheckedItem) => void
  ) => {
    if (checkItem) {
      if (checkItem.id === id) {
        cb(checkItem);
      } else if (checkItem.checkedItems) {
        checkItem.checkedItems.forEach((checkItemChild: ICheckedItem) => {
          return findObject(id, checkItemChild, cb);
        });
      }
    }
  };

  const doToAllChildren = (
    id: string,
    checkItem: ICheckedItem,
    cb: (checkItem: ICheckedItem) => void
  ) => {
    if (checkItem) {
      cb(checkItem);
      if (checkItem.checkedItems) {
        checkItem.checkedItems.forEach((checkItemChild: ICheckedItem) => {
          return doToAllChildren(id, checkItemChild, cb);
        });
      }
    }
  };

  const checkedItems = (cb: ICheckedItem) => {
    if (cb.checkedItems) {
      return cb.checkedItems?.filter(
        (cbChild: ICheckedItem) => cbChild.checked
      );
    }
    return [];
  };

  const checkAllCheckedItems = (cb: ICheckedItem) => {
    let isAllChildrenChecked = false;
    if (cb.checkedItems) {
      cb.checkedItems.forEach((checkItemChild: ICheckedItem) => {
        return checkAllCheckedItems(checkItemChild);
      });

      const checkedItemsArray = checkedItems(cb);
      if (checkedItemsArray?.length === cb.checkedItems.length) {
        isAllChildrenChecked = true;
      }
      cb.checked = isAllChildrenChecked;
    }
  };

  const checkAllIndeterminateItems = (cb: ICheckedItem) => {
    let isAllChildrenIndeterminate = false;
    if (cb.checkedItems) {
      let childIndeterminate = false;
      cb.checkedItems.forEach((checkItemChild: ICheckedItem) => {
        if (checkAllIndeterminateItems(checkItemChild)) {
          childIndeterminate = true;
        }
        return childIndeterminate;
      });
      const checkedItemsArray = checkedItems(cb);
      if (
        childIndeterminate ||
        (checkedItemsArray?.length !== 0 &&
          checkedItemsArray?.length !== cb.checkedItems.length)
      ) {
        isAllChildrenIndeterminate = true;
      } else {
        isAllChildrenIndeterminate = false;
      }
    }
    return isAllChildrenIndeterminate;
  };

  useEffect(() => {
    if (props.selectable) {
      findObject(props.nodeId, props.checkedItemRefs, (cb: ICheckedItem) => {
        if (cb.checked) {
          setChecked(true);
        } else {
          setChecked(false);
        }
        setIndeterminate(checkAllIndeterminateItems(cb));
      });
    }
  }, [props.checkedItemRefs]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedItemRefsClone = { ...props.checkedItemRefs };
    findObject(props.nodeId, checkedItemRefsClone, (cb: ICheckedItem) => {
      if (cb.checked) {
        doToAllChildren(props.nodeId, cb, (cbChild: ICheckedItem) => {
          cbChild.checked = false;
        });
      } else {
        doToAllChildren(props.nodeId, cb, (cbChild: ICheckedItem) => {
          cbChild.checked = true;
        });
      }
    });
    checkAllCheckedItems(checkedItemRefsClone);
    if (props.setCheckedItemRefs) {
      props.setCheckedItemRefs(checkedItemRefsClone);
    }
  };

  return (
    <TreeItemContainer>
      <TreeItem
        {...newProps}
        classes={classes}
        TransitionComponent={TransitionComponent}
      />
      {props.selectable && (
        <Box className={checkboxClass.checkBoxContainer}>
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onChange={handleChange}
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </Box>
      )}
      <ButtonsContainer>
        {props.onInfo && (
          <Tooltip title="View details">
            <IconButton
              aria-label="add"
              color="default"
              aria-controls="customized-menu"
              aria-haspopup="true"
              size="small"
              onClick={() => props.onInfo(props.nodeId)}
            >
              <ListAltIcon />
            </IconButton>
          </Tooltip>
        )}
        {props.onSingleSelect && (
          <IconButton
            disabled={props.isSingleSelectedDisabled}
            aria-label="add"
            color="default"
            aria-controls="customized-menu"
            aria-haspopup="true"
            size="small"
            onClick={() => {
              if (!props.isSingleSelectedDisabled) {
                props
                  .onSingleSelect(props.nodeId)
                  .then((proceedToSelecttion: boolean) => {
                    if (proceedToSelecttion) {
                      props.setSingleSelected(props.nodeId);
                    }
                  });
              }
            }}
          >
            {props.singleSelected !== props.nodeId ||
            props.isSingleSelectedDisabled ? (
              <PlayCircleOutlineIcon />
            ) : (
              <PlayCircleFilledIcon color="primary" />
            )}
          </IconButton>
        )}
      </ButtonsContainer>
    </TreeItemContainer>
  );
};

export default ViewTreeItem;
