import { adalApiFetch } from "../clientApp/configAdal";
import { endPoints } from "../clientApp/endPoints";
import {
  IGlobalUsers,
  IAdditionalParams,
  IPagination,
} from "../components/type";
import { hierarchyContainer } from "utils";
import Toaster from "components/Toaster";
import { IUserAssetAssociationForm } from "pages/Users/UserAssetAssociation/type";
import { IUserForm } from "pages/Users/UserDetails/type";
import { IRoleAction } from "pages/Users/UserAssetRoles/type";

const headerValues = { "content-type": "application/json" };
const ERROR_NOTIF_MESSAGE = "Error occured while processing your request!";
const UPDATE_SUCCESS_MESSAGE = "Successfully Updated!";
const ERROR_SAVING_MESSAGE = "Error Saving";

export const getAssociatedAssets = async (userId: string): Promise<any> => {
  return adalApiFetch(
    fetch,
    `${endPoints.userHierarchies}/GetByUserId/${userId}`,
    {
      method: "GET",
      headers: headerValues,
    }
  )
    .then((response) => response.json())
    .then((data) => data.userHierarchies)
    .catch((e) => {
      console.error(e);
      return [];
    });
};

export const getUser = async (userId: string): Promise<IGlobalUsers> => {
  return adalApiFetch(fetch, `${endPoints.globalUsers}/Get/${userId}`, {
    method: "GET",
    headers: headerValues,
  }).then((res) => {
    return res.json();
  });
};

export const getAssetAccounts = async (
  searchQuery = "",
  additionalParam: IAdditionalParams = {}
): Promise<any> => {
  const injectSearchQuery =
    searchQuery !== "" ? `?searchquery=${searchQuery}` : "";
  let userId = "";
  if (additionalParam && additionalParam.userId) {
    userId = additionalParam.userId;
  }

  return adalApiFetch(
    fetch,
    `${endPoints.hierarchies}/unassociatedAccountsList/${userId}${injectSearchQuery}`,
    {
      method: "GET",
      headers: headerValues,
    }
  )
    .then((response) => response.json())
    .then((posts) => posts.data);
};

export const getAssociatedAsset = async (
  userId: string,
  hierarchyId: string
): Promise<any> => {
  return adalApiFetch(
    fetch,
    `${endPoints.userHierarchies}/GetByUserIdRootId/${userId}/${hierarchyId}`,
    {
      method: "GET",
      headers: headerValues,
    }
  )
    .then((response) => response.json())
    .then((data) => {
      return hierarchyContainer(data);
    });
};

export const updateAssociatedAsset = async (
  data: IUserAssetAssociationForm
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.userHierarchies}/UpdateUserHierarchies`,
    {
      method: "PUT",
      headers: headerValues,
      body: JSON.stringify(data),
    }
  )
    .then((response) => {
      if (!response.ok) {
        response.json().then((body: any) => {
          Toaster["error"](body.AssetName, ERROR_SAVING_MESSAGE);
          return response;
        });
      } else {
        Toaster["success"](UPDATE_SUCCESS_MESSAGE);
        return response;
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export const createAssociateAsset = async (data: IUserAssetAssociationForm) => {
  return adalApiFetch(
    fetch,
    `${endPoints.userHierarchies}/CreateUserHierarchies`,
    {
      method: "POST",
      headers: headerValues,
      body: JSON.stringify(data),
    }
  )
    .then((response) => {
      if (!response.ok) {
        response.json().then((body: any) => {
          Toaster["error"](body.AssetName, ERROR_SAVING_MESSAGE);
          return response;
        });
      } else {
        Toaster["success"](UPDATE_SUCCESS_MESSAGE);
        return response;
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export const saveUser = async (data: IUserForm) => {
  return adalApiFetch(fetch, `${endPoints.globalUsers}/Create`, {
    method: "POST",
    headers: headerValues,
    body: JSON.stringify(data),
  })
    .then(function (response) {
      if (response.ok) {
        Toaster["success"]("Successfully Added!");
      }
      return response;
    })
    .then((response) => response.json())
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });
};

export const updateUser = (data: IUserForm) => {
  return adalApiFetch(fetch, `${endPoints.globalUsers}/Update/${data.id}`, {
    method: "PUT",
    headers: headerValues,
    body: JSON.stringify(data),
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](ERROR_NOTIF_MESSAGE, ERROR_SAVING_MESSAGE);
      } else {
        Toaster["success"](UPDATE_SUCCESS_MESSAGE);
      }
      return response;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });
};

export const getDefaultValuesByCountryId = async (countryId: string) => {
  return adalApiFetch(
    fetch,
    `${endPoints.countries}/GetUserDefaultValueById/${countryId}`,
    {
      method: "GET",
      headers: headerValues,
    }
  ).then((res) => res.json());
};

export const getAzureAdUser = async (email: string) => {
  return await adalApiFetch(fetch, `${endPoints.azureAd}/B2CUsers/${email}`, {
    method: "GET",
    headers: headerValues,
  }).then((res) => res.json());
};

export const getExternalUser = async (email: string) => {
  return adalApiFetch(fetch, `${endPoints.externalUser}/${email}`, {
    method: "GET",
    headers: headerValues,
  }).then((res) => res.json());
};

export const deleteAssociatedAssets = (
  userId: string,
  hierarchyRootId: string
): Promise<any> => {
  return adalApiFetch(
    fetch,
    `${endPoints.userHierarchies}/DeleteUserHierarchies/${userId}/${hierarchyRootId}`,
    {
      method: "DELETE",
      headers: headerValues,
    }
  )
    .then((res) => {
      Toaster["success"]("Successfully Deleted!");
      return res.ok;
    })
    .catch((e) => {
      Toaster["error"](ERROR_NOTIF_MESSAGE, "Error deleting associated assets.");
      throw e;
    });
};

export const getGlobalUser = async (email: string) => {
  return adalApiFetch(fetch, `${endPoints.globalUser}/${email}`, {
    method: "GET",
    headers: headerValues,
  }).then((res) => res.json());
};

export const getRolesByUserHierarchy = async (
  userId: string,
  hierarchyId: string
): Promise<any> => {
  return adalApiFetch(
    fetch,
    `${endPoints.userHierarchyRolesV2}/${hierarchyId}/user/${userId}/UserRolePermissionAssignments`,
    {
      method: "GET",
      headers: headerValues,
    }
  ).then((response) => response.json());
};

export const saveRoles = async (
  userId: string,
  hierarchyId: string,
  roleActions: IRoleAction[]
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.userHierarchyRolesV2}/${hierarchyId}/user/${userId}/UserRolePermissionAssignments`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(roleActions),
    }
  )
    .then((response) => {
      if (!response.ok) {
        response.json().then((body: any) => {
          Toaster["error"](body.AssetName, ERROR_SAVING_MESSAGE);
          return response;
        });
      } else {
        Toaster["success"](UPDATE_SUCCESS_MESSAGE);
      }
      return response;
    })
    .catch((e) => {
      throw e;
    });
};

export const getUsers = async (
  page = 1,
  limit = 10,
  query?: any
): Promise<IPagination[]> => {
  return adalApiFetch(
    fetch,
    `${endPoints.globalUsers}/GetAll?page=${page}&limit=${limit}${
      query ? `&searchquery=${query}` : ""
    }`,
    {
      method: "GET",
      headers: headerValues,
    }
  ).then((response) => response.json());
};

export const deleteUser = async (globalUserId: string): Promise<boolean> => {
  return adalApiFetch(
    fetch,
    `${endPoints.globalUsers}/Delete/${globalUserId}`,
    {
      method: "DELETE",
      headers: headerValues,
    }
  )
    .then((res) => {
      Toaster["success"]("Successfully Deleted!");
      return res.ok;
    })
    .catch((e) => {
      Toaster["error"](ERROR_NOTIF_MESSAGE, "Error deleting global user.");
      throw e;
    });
};
