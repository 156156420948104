import React, {
  Fragment,
  ReactElement,
  useEffect,
  useState,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

import MainBarDashboard from "components/MainBarDashboard";
import TextField from "components/TextField";
import List, { ICheckedItemList, getDisabledListItems } from "components/List";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import PageHeader from "components/PageHeader";
import ExpansionPanel from "components/ExpansionPanel";
import PageHeaderDetailItem from "components/PageHeaderDetailItem";
import PageHeaderRightItem from "components/PageHeaderRightItem";
import DialogModal from "components/DialogModal";
import PrivateComponent from "components/PrivateComponent";
import { disableLogic } from "../Administration";

import { ApplicationsContext } from "provider/ApplicationsProvider";
import { AuthContext } from "provider/AuthProvider";
import { SpinnerContext } from "provider/SpinnerProvider";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";

import { getConfigUser, updateConfigUser } from "./Actions";
import { getInternalRoles } from "../Actions";

import {
  getCheckedOptions,
  getCheckedOptionsWithMisc,
  getAllChecked,
  getPropertyAsArray,
} from "utils";

import { IConfigurationUser, IConfigUserRoleAction } from "../type";

import { FormErrorMessages, AccessErrorMessages } from "constants/enum";
import { InternalRoles, InternalRolePermissions } from "constants/constant";

import { AdministrationPath } from "routes/paths";

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
  },
  tab: {
    minWidth: "auto",
  },
  root: {
    width: "100%",
  },
  listLabel: {
    fontSize: 12,
  },
}));

const AdministrationView = (): ReactElement => {
  const classes = useStyles();
  const { userId } = useParams();
  const { handleSubmit, register, errors, setValue } = useForm();

  const [digitalProducts, setDigitalProducts] = useState<ICheckedItemList[]>(
    []
  );
  const [internalRoles, setInternalRoles] = useState<ICheckedItemList[]>([]);
  const [applications, getApplications] = useContext(ApplicationsContext);
  const { permissionContext, azureIdContext } = useContext(AuthContext);
  const [, , , refreshPermissions] = permissionContext;
  const [azureId] = azureIdContext;
  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [isUpdate, setIsUpdate] = useState(false);
  const [
    isInternalRolesSkeleton,
    setIsInternalRolesSkeleton,
  ] = useState<boolean>(true);
  const [
    isDigitalProductsSkeleton,
    setIsDigitalProductsSkeleton,
  ] = useState<boolean>(true);
  const [changedOnlyRoles, setChangedOnlyRoles] = useState<ICheckedItemList[]>(
    []
  );
  const [user, setUser] = useState<IConfigurationUser>();
  const [showDigitalProducts, setShowDigitalProducts] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);

  useEffect(() => {
    setIsSpinnerVisible(true);
    getApplications();
    if (userId) {
      getConfigUser(userId).then((configUser) => {
        setUser(configUser);
        setValue("firstName", configUser.firstName);
        setValue("lastName", configUser.lastName);
        setValue("email", configUser.email);
        if (configUser.roles && configUser.roles.length > 0) {
          setIsInternalRolesSkeleton(false);
          setInternalRoles(
            getCheckedOptionsWithMisc(
              configUser.roles,
              "roleId",
              "name",
              true
            )
          );
        }
        setIsSpinnerVisible(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(applications) && !isEmpty(user)) {
      let newSubDevices: any = [];
      if (user?.digitalProducts && user?.digitalProducts.length > 0) {
        newSubDevices = applications.filter((app: { id: string }) => {
          if (user?.digitalProducts) {
            return user?.digitalProducts
              .map((digitalProduct) => digitalProduct.id)
              .includes(app.id);
          }
          return false;
        });
      }
      setIsDigitalProductsSkeleton(false);
      setDigitalProducts(
        getCheckedOptions(newSubDevices, "id", "abbreviation")
      );
    }
  }, [applications, user]);

  useEffect(() => {
    const checkedRoleLabels = getAllChecked(internalRoles, "label");
    if (
      checkedRoleLabels.length > 0 &&
      (checkedRoleLabels.includes(InternalRoles.APPLICATION_SUPPORT) ||
        checkedRoleLabels.includes(InternalRoles.GIC_ADMIN))
    ) {
      setShowDigitalProducts(true);
    } else {
      setShowDigitalProducts(false);
    }
  }, [internalRoles]);

  const breadcrumbs = [
    {
      label: "Administration",
      href: AdministrationPath,
    },
    {
      label: !isEmpty(user) ? `${user?.firstName} ${user?.lastName}` : "User",
    },
  ];

  const generateConfigUserRoleActions = (checkedItems: ICheckedItemList[]) => {
    const data: IConfigUserRoleAction[] = [];
    const updatedRolesIds = getPropertyAsArray(changedOnlyRoles, "value");

    if (checkedItems) {
      checkedItems.forEach((i) => {
        const roleId = i.value;
        const isRoleUpdate = updatedRolesIds.includes(roleId);
        const checkedItem = {
          roleId: roleId,
          action: isRoleUpdate ? (i.checked ? 1 : 2) : 0,
          name: i.label,
         subjectAssignmentId: i.misc
            ? parseInt(i.misc.subjectAssignmentId) || 0
            : 0,
        };
        if (isRoleUpdate || (!isRoleUpdate && i.checked)) {
          data.push(checkedItem);
        }
      });
    }

    return data;
  };

  const onSubmit = (data: any) => {
    setIsDigitalProductsSkeleton(true);
    setIsInternalRolesSkeleton(true);
    data.id = user?.id;
    data.objectId = user?.objectId;

    const checkedDigitalProducts = getAllChecked(digitalProducts, "value");
    if (showDigitalProducts && checkedDigitalProducts.length > 0) {
      data.applications = checkedDigitalProducts;
    } else if (showDigitalProducts) {
      setErrorDialog(true);
      setIsDigitalProductsSkeleton(false);
      setIsInternalRolesSkeleton(false);
      return;
    } else {
      data.applications = [];
    }
    data.roleActions = generateConfigUserRoleActions(internalRoles);
    updateConfigUser(data).then((res) => {
      if (data.objectId === azureId) {
        refreshPermissions();
      }
      getConfigUser(data.id).then((configUser) => {
        setUser(configUser);
        setChangedOnlyRoles([]);
        if (configUser.roles && configUser.roles.length > 0) {
          setInternalRoles(
            getCheckedOptionsWithMisc(
              configUser.roles,
              "roleId",
              "name",
              true
            )
          );
        } else {
          setInternalRoles([]);
        }
        setIsDigitalProductsSkeleton(false);
        setIsInternalRolesSkeleton(false);
      });
      setIsUpdate(false);
    });
  };

  const switchToUpdate = () => {
    setIsDigitalProductsSkeleton(true);
    setIsInternalRolesSkeleton(true);
    if (!isEmpty(applications)) {
      if (digitalProducts && digitalProducts.length > 0) {
        const checkedDigitalProductIds = digitalProducts.map(
          (digitalProduct) => digitalProduct.value
        );
        setDigitalProducts(
          getCheckedOptions(
            applications,
            "id",
            "abbreviation",
            false,
            checkedDigitalProductIds
          )
        );
      } else {
        setDigitalProducts(
          getCheckedOptions(applications, "id", "abbreviation")
        );
      }
    }
    getInternalRoles().then((roles) => {
      if (internalRoles && internalRoles.length > 0) {
        const checkedRoleIds = internalRoles.map(
          (internalRole) => internalRole.value
        );
        const internalRolesArray = getCheckedOptionsWithMisc(
          roles.roles,
          "roleId",
          "name",
          false,
          checkedRoleIds
        );
        const disabledList = getDisabledListItems(
          disableLogic,
          internalRolesArray
        );
        setInternalRoles(disabledList);
      } else {
        setInternalRoles(
          getCheckedOptionsWithMisc(roles, "roleId", "name", false)
        );
      }

      setIsDigitalProductsSkeleton(false);
      setIsInternalRolesSkeleton(false);
    });
    setIsUpdate(true);
  };

  return (
    <MainBarDashboard breadcrumbs={breadcrumbs}>
      <PrivateComponent
        data={user}
        errorMessage={AccessErrorMessages.noAccessToPage}
        permissions={[
          InternalRolePermissions.MANAGE_CONFIG_USER,
          InternalRolePermissions.VIEW_CONFIG_USER,
        ]}
      >
        <PageHeader
          title={`${user?.firstName} ${user?.lastName}`}
          details={
            <Fragment>
              <PageHeaderDetailItem
                label="Azure Object ID"
                value={user?.objectId ? user?.objectId : ""}
              />
            </Fragment>
          }
        >
          <PageHeaderRightItem
            label="Is Admin"
            value={user?.isAdmin ? "true" : "false"}
          />
        </PageHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <div className={classes.root}>
                <ExpansionPanel label="General">
                  <TextField
                    readOnly
                    error={errors.firstName}
                    label="First Name"
                    name="firstName"
                    inputRef={register({
                      required: FormErrorMessages.fieldRequired,
                    })}
                  />
                  <TextField
                    readOnly
                    error={errors.lastName}
                    label="Last Name"
                    name="lastName"
                    inputRef={register({
                      required: FormErrorMessages.fieldRequired,
                    })}
                  />
                  <TextField
                    readOnly
                    error={errors.email}
                    label="Email"
                    name="email"
                    inputRef={register({
                      required: FormErrorMessages.fieldRequired,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: FormErrorMessages.invalidEmail,
                      },
                    })}
                  />
                </ExpansionPanel>
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper className={classes.tabs}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box m={4}>
                      {internalRoles.length > 0 ? (
                        <List
                          checkable={isUpdate}
                          showSkeleton={isInternalRolesSkeleton}
                          horizontal
                          data={internalRoles}
                          setData={setInternalRoles}
                          changedOnlyData={changedOnlyRoles}
                          setChangedOnlyData={setChangedOnlyRoles}
                          disableLogic={disableLogic}
                          label="Roles"
                        />
                      ) : (
                        <Typography
                          className={classes.listLabel}
                          color="textSecondary"
                        >
                          No Assigned Roles
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {showDigitalProducts && (
                      <Box m={4}>
                        {digitalProducts.length > 0 ? (
                          <List
                            checkable={isUpdate}
                            showSkeleton={isDigitalProductsSkeleton}
                            horizontal
                            data={digitalProducts}
                            setData={setDigitalProducts}
                            label="Digital Products"
                          />
                        ) : (
                          <Typography
                            className={classes.listLabel}
                            color="textSecondary"
                          >
                            No Digital Products
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <FloatingActionButtonList>
            {isUpdate && (
              <PrivateComponent
                permissions={[InternalRolePermissions.MANAGE_CONFIG_USER]}
              >
                <FloatingActionButton type="submit">
                  <SaveIcon />
                  Save
                </FloatingActionButton>
              </PrivateComponent>
            )}
            {!isUpdate && (
              <PrivateComponent
                permissions={[InternalRolePermissions.MANAGE_CONFIG_USER]}
              >
                <FloatingActionButton onClick={switchToUpdate}>
                  <EditIcon />
                  Update
                </FloatingActionButton>
              </PrivateComponent>
            )}
          </FloatingActionButtonList>
        </form>
      </PrivateComponent>
      <DialogModal
        title="Error!"
        message={`Selection of Digital Product is required for ${InternalRoles.APPLICATION_SUPPORT} and ${InternalRoles.GIC_ADMIN} roles.`}
        showModal={errorDialog}
        onOk={(): void => {
          return;
        }}
        onHide={(): void => {
          setErrorDialog(false);
        }}
        confirmButtonLabel="Ok"
      />
    </MainBarDashboard>
  );
};

export default AdministrationView;
