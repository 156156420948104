import React, { useEffect, useState } from "react";
import ExpansionPanel from "../../../../../components/ExpansionPanel";
import AutoComplete from "../../../../../components/AutoComplete";
import { makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import { ILabelValue } from "../../../../../components/type";
import { getUnassignedDevices } from "../../../../../actions/installationsPageActions";
import { getDisplayDeviceHash, getDisplayDeviceId } from "../utils";
import { GetDeviceProps } from "../type";
import { getDevice } from "../../../../../actions/devicePageActions";
import { EMPTY_UUID } from "constants/constant";

export type DeviceSectionModel = {
  device: ILabelValue;
};

const useStyles = makeStyles(() => ({
  createDeviceButtonStyle: {
    background: "none",
    border: "none",
    padding: "0",
    textAlign: "left",
    fontSize: "smaller",
    color: "#069",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer"
    }
  }
}));

const InputField = (props: TextFieldProps) => (
  <TextField
    disabled
    fullWidth
    style={{ margin: "8px 0" }}
    InputProps={{ readOnly: true }}
    {...props}
  />
);

const isValidDevicedId = (deviceId = "") => deviceId && deviceId !== EMPTY_UUID;

const DeviceSection = ({
  deviceId = "",
  register,
  errors,
  setValue,
  isUpdate,
  isFromDeviceCreation,
  reload,
  onChange,
  submit
}: {
  deviceId: string;
  register: any;
  errors: any;
  isUpdate: boolean;
  isFromDeviceCreation: boolean;
  onChange: (deviceIdGfFormat: string | undefined) => void;
  setValue: any;
  reload: boolean;
  submit: (deviceIdGfFormat: string | undefined) => void;
}) => {
  const [autocompleteDeviceIdReset, setAutocompleteDeviceIdReset] =
    useState<number>();
  const [selectedDevice, setSelectedDevice] = useState<
    GetDeviceProps | undefined
  >();
  const [saveDeviceId, setSaveDeviceId] = useState("");
  const [deviceIdDefaultValue, setDeviceIdDefaultValue] =
    useState<ILabelValue | null>(null);
  const classes = useStyles();

  const setInitialDevice = (devId: string) => {
    if (isValidDevicedId(devId)) {
      getDevice(devId).then((device: GetDeviceProps) => {
        setSelectedDevice(device);
        setDeviceIdDefaultValue({
          label: device.deviceName,
          value: devId
        });
      });
    }
  };

  const initDeviceDataToBeUpdated = (): void => {
    if (deviceId) {
      setValue("deviceId", deviceId);
      setInitialDevice(deviceId);
    } else {
      setSelectedDevice(undefined);
      setValue("deviceId", "");
      setAutocompleteDeviceIdReset(Date.now());
    }
  };

  const resetForm = () => {
    setSelectedDevice(undefined);
    setAutocompleteDeviceIdReset(Date.now());
  };

  const getDeviceIdToSend = (devId: string | undefined) => {
    if (devId === "") {
      return undefined;
    }
    return saveDeviceId;
  };

  const setDeviceId = (devId: string) => {
    getDevice(devId).then((device: GetDeviceProps) => {
      setSelectedDevice(device);
      setSaveDeviceId(device.deviceId);
      onChange(device.deviceId);
    });
  };

  const updateDevice = (devId: string) => {
    if (devId && devId !== "") {
      setDeviceId(devId);
    } else {
      setSelectedDevice(undefined);
      setSaveDeviceId("");
      onChange(undefined);
    }
  };

  useEffect(() => {
    if (isUpdate) {
      initDeviceDataToBeUpdated();
    }

    setAutocompleteDeviceIdReset(Date.now());

    if (isFromDeviceCreation) {
      setInitialDevice(deviceId);
    }

    if (deviceId) {
      setValue("deviceId", deviceId);
      setInitialDevice(deviceId);
    }
  }, [deviceId, reload]);

  return (
    <ExpansionPanel label="Devices">
      <AutoComplete
        error={errors.deviceId}
        label="Device Name"
        name="deviceId"
        labelName="deviceName"
        valueName="id"
        getData={getUnassignedDevices}
        inputRef={register({ required: false })}
        reset={autocompleteDeviceIdReset}
        onChange={updateDevice}
        defaultValue={deviceIdDefaultValue}
        disableClearable={false}
      />

      <InputField
        InputProps={{ readOnly: true }}
        label="Communication Device ID"
        value={getDisplayDeviceHash(selectedDevice)}
      />
      <InputField
        InputProps={{ readOnly: true }}
        label="Device ID"
        value={getDisplayDeviceId(selectedDevice)}
      />
      <button
        className={classes.createDeviceButtonStyle}
        type="submit"
        onClick={() => {
          resetForm();
          submit(getDeviceIdToSend(saveDeviceId));
        }}
      >
        Create New Device
      </button>
    </ExpansionPanel>
  );
};

export default DeviceSection;
