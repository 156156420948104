import { DevConfig } from "./DevConfig";
import { EnvConfig } from "./envConfig";
import { OConfig } from "./OConfig";
import { ProdConfig } from "./ProdConfig";
import { QaConfig } from "./QaConfig";
import { SandboxConfig } from "./SandboxConfig";
import { TestConfig } from "./TestConfig";

export class ConfigChecker {
	private _hostname: string;

	get hostname() {
		return this._hostname;
	}
	set hostname(value) {
		this._hostname = value;
	}

	constructor(hostname = "") {
		this._hostname = hostname;
	}

	public isSandbox() {
		return this._hostname === process.env.REACT_APP_K_S_HOSTNAME;
	}

	public isDev() {
		return this._hostname === process.env.REACT_APP_K_D_HOSTNAME;
	}

	public isTest() {
		return this._hostname === process.env.REACT_APP_K_T_HOSTNAME;
	}

	public isQa() {
		return this._hostname === process.env.REACT_APP_K_Q_HOSTNAME;
	}

	public isO() {
		return this._hostname === process.env.REACT_APP_K_O_HOSTNAME;
	}

	public isProd() {
		return this._hostname === process.env.REACT_APP_K_P_HOSTNAME;
	}

	public getEnvConfig() {
		let config: EnvConfig = new DevConfig(this.hostname);

		if (this.isSandbox()) {
			config = new SandboxConfig(this.hostname);
		}
		if (this.isTest()) {
			config = new TestConfig(this.hostname);
		}
		if (this.isQa()) {
			config = new QaConfig(this.hostname);
		}
		if (this.isO()) {
			config = new OConfig(this.hostname);
		}
		if (this.isProd()) {
			config = new ProdConfig(this.hostname);
		}

		return config;
	}
}
