import React from "react";
import MainBarDashboard from "components/MainBarDashboard";

import CreateSectionForm from "./Form";
import { CreateSection as CreateSectionConst } from "constants/constant";

import { AccountsCreatePath, AssetsRootPath } from "routes/paths";
const CreateSection = (): JSX.Element => {
  const breadcrumbs = [
    {
      label: CreateSectionConst.BREADCRUMBS_LABEL,
      href: AssetsRootPath,
    },
    {
      label: CreateSectionConst.BREADCRUMBS_CREATE_LABEL,
      href: AccountsCreatePath,
    },
  ];

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <CreateSectionForm />
    </MainBarDashboard>
  );
};
export default CreateSection;
