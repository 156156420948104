import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

import IconButton from "@material-ui/core/IconButton";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { FormErrorMessages, AssetType } from "constants/enum";

import { IApplication, IHierarchy } from "components/type";
import SkeletonLine from "components/Skeleton/SkeletonPulse";
import NewTextField from "components/TextField";

import { ModalContext } from "provider/ModalProvider";
import { AccountContext } from "provider/AccountProvider";
import { SpinnerContext } from "provider/SpinnerProvider";
import { InstallationContext } from "provider/InstallationProvider";
import { ApplicationsContext } from "provider/ApplicationsProvider";

import {
  getInstallation,
  getInstallationById,
  patchInstallation,
} from "../../../actions/installationsPageActions";
import { getAccount } from "../../../actions/accountsPageActions";

import { InstallationsCreatePath } from "routes/paths";
interface InstallationModal {
  reload?: () => void;
}

const CreateInstallationModal = (props: InstallationModal) => {
  const history = useHistory();
  const { installationModalContext } = useContext(ModalContext);
  const [, setShowInstallationModal] = installationModalContext;
  const [applications, getApplications] = useContext(ApplicationsContext);
  const { updateAccountContext, treeViewToggleItemContext } = useContext(
    AccountContext
  );
  const [, setIsSpinnerVisible] = useContext(SpinnerContext);

  const [account, setAccount] = updateAccountContext;
  const [, setUpdateExpandedIds] = treeViewToggleItemContext;

  const [installations, getInstallations] = useState([]);
  const [digitalOfferings, setDigitalOfferings] = useState([]);

  const [name] = useState("");

  const [countryId] = useState("");
  const [customerNumber] = useState("");
  const [selectedInstallationId, setSelectedInstallationId] = useState("");
  const { modalInstallationContext, createInstallationContext } = useContext(
    InstallationContext
  );
  const [modalInstallation] = modalInstallationContext;
  const [, setCreateInstallation] = createInstallationContext;

  const [complete, setComplete] = useState(false);

  const { register, handleSubmit, errors, setValue } = useForm();
  const createObjects = {
    rootId: modalInstallation.rootId,
    parentId: modalInstallation.parentId,
    isQuickCreate: true,
    isCreate: false,
  };

  const uniqueBy = (arr: any, fn: any) => {
    const unique: any = {};
    const distinct: any = [];
    arr.forEach(function (x: any) {
      const key = fn(x);
      if (!unique[key]) {
        distinct.push(key);
        unique[key] = true;
      }
    });

    return distinct;
  };

  const goToInstallationForm = (): void => {
    setShowInstallationModal(false);
    setCreateInstallation(createObjects);
    history.push(InstallationsCreatePath);
  };
  useEffect(() => {
    getApplications();
    getInstallation().then((i) => {
      getInstallations(i);
      setComplete(true);
    });
  }, [modalInstallation, account]);

  const saveChanges = (data: any) => {
    setShowInstallationModal(false);
    setIsSpinnerVisible(true);
    const rootIdVal =
      account.typeName === AssetType.account ? account.id : null;
    const installationDetails: IHierarchy = {
      id: selectedInstallationId,
      rootId: rootIdVal,
      parentId: modalInstallation.parentId,
      ownerAccountId: "",
      typeId: process.env.REACT_APP_TYPE_INSTALLATION_ID as string,
      typeName: AssetType.installation,
      assetName: data.installationId,
      customerNumber: customerNumber,
      countryId: countryId,
    };

    patchInstallation(installationDetails).then((e) => {
      setIsSpinnerVisible(false);
      setUpdateExpandedIds(modalInstallation.parentId);
      getAccount(account.id).then((a) => {
        setAccount(a);
      });

      if (props.reload) {
        props.reload();
      }
    });
  };

  const handleSelectInstallation = (id: string) => {
    setIsSpinnerVisible(true);
    getInstallationById(id).then((e) => {
      setSelectedInstallationId(e.hierarchy.id);
      setDigitalOfferings(e.digitalOfferings);
      setValue("state", e.hierarchy.state);
      setValue("city", e.hierarchy.city);
      setValue("street", e.hierarchy.street);
      setValue("postal", e.hierarchy.postalCode);
      setValue("installationId", e.installationName);
      setValue("deviceId", e.deviceId);

      setValue(
        "applicationName",
        uniqueBy(e.digitalOfferings, function (x: any) {
          return x.applicationName;
        })
      );
      setIsSpinnerVisible(false);
    });
  };

  const displaySelectOptions = (
    <Autocomplete
      id="installationName"
      disableClearable={true}
      options={installations}
      getOptionLabel={(option: any) => option.installationName}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Installation Name"
          fullWidth
          name="installationId"
          inputRef={register({
            required: FormErrorMessages.fieldRequired,
          })}
        />
      )}
      onChange={(event: object, value: any) =>
        handleSelectInstallation(value.id)
      }
    />
  );

  const emptyApp: IApplication[] = [
    {
      id: "",
      abbreviation: "",
      applicationName: "",
      description: "",
    },
  ];

  const quickCreateInstallation = (
    <div className="col-sm-1">
      <Tooltip title="Create new installation">
        <IconButton
          aria-label="Create new installation"
          onClick={() => goToInstallationForm()}
        >
          <AddOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  const getAutoCompleteValues = (): IApplication[] => {
    let val: IApplication[] = [];
    val = !isEmpty(account)
      ? digitalOfferings?.map(
          (e: any): IApplication => {
            return {
              id: e.applicationId,
              abbreviation: e.digitalOfferingName,
              applicationName: "",
              description: "",
            };
          }
        )
      : emptyApp;

    return val;
  };

  return (
    <Form id="installation-form" onSubmit={handleSubmit(saveChanges)}>
      <div className="row" style={{ margin: "10px" }}>
        <label className="col-sm-12">
          Select Installation that you want to add from the dropdown list
        </label>
      </div>

      <div className="col-sm-12">
        {complete ? (
          modalInstallation.isCreate ? (
            isEmpty(installations) && complete ? (
              <div className="row">
                <div className="col-sm-11 form-control ">
                  No available installation
                </div>
                {quickCreateInstallation}
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-11">{displaySelectOptions}</div>
                {quickCreateInstallation}
              </div>
            )
          ) : (
            <div className="form-control">{name}</div>
          )
        ) : (
          <div className="form-control">
            <SkeletonLine />
          </div>
        )}
      </div>

      <div className="col-sm-12">
        <NewTextField
          readOnly={true}
          label="Application"
          name="applicationName"
          inputRef={register({
            required: false,
          })}
        />
      </div>

      <div className="col-sm-12">
        <Autocomplete
          disabled
          multiple
          id="digitalProduct"
          options={applications}
          getOptionLabel={(option: any) => option.abbreviation}
          renderTags={(value: IApplication[]) =>
            value?.map((option: IApplication, index: any) => (
              <Chip label={option.abbreviation} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Digital Product"
              margin="normal"
              fullWidth
            />
          )}
          value={getAutoCompleteValues()}
        />
      </div>

      <div className="col-sm-12">
        <NewTextField
          error={errors.postal}
          readOnly={true}
          label="Device Id"
          name="deviceId"
          inputRef={register({
            required: false,
          })}
        />
      </div>
      <div className="col-sm-12">
        <NewTextField
          error={errors.postal}
          readOnly={true}
          label="IMEI"
          name="imei"
          inputRef={register({
            required: false,
          })}
        />
      </div>
      <div className="col-sm-12">
        <NewTextField
          error={errors.postal}
          readOnly={true}
          label="MAC"
          name="mac"
          inputRef={register({
            required: false,
          })}
        />
      </div>
    </Form>
  );
};

export default CreateInstallationModal;
