import React, { useState, createContext } from "react";

import { UserScreens } from "constants/enum";

export const UserContext = createContext<any>([
  {},
  () => {
    return;
  },
]);

export const UserProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [userScreen, setUserScreen] = useState(UserScreens.userList);
  const [updateUser, setUpdateUser] = useState({});
  const [associateUser, setAssociateUser] = useState({});
  const [currentPageNum, setCurrentPageNum] = useState(1);

  return (
    <UserContext.Provider
      value={{
        screenContext: [userScreen, setUserScreen],
        updateUserContext: [updateUser, setUpdateUser],
        associateUserContext: [associateUser, setAssociateUser],
        userCurrentPageContext: [currentPageNum, setCurrentPageNum],
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
