import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { IPageHeaderRightItemProps } from "./type";

const useStyles = makeStyles(theme => ({
  rightPanelItem: {
    display: "flex",
    alignItems: "center"
  },
  rightPanelItemContent: {
    margin: theme.spacing(2)
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "11px",
    textTransform: "uppercase"
  },
  value: {
    margin: `0 ${theme.spacing(1)}px`,
    color: theme.palette.secondary.main
  }
}));

const PageHeaderRightItem = ({
  label,
  value
}: IPageHeaderRightItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.rightPanelItem}>
      <Divider orientation="vertical" />
      <Box className={classes.rightPanelItemContent}>
        <Typography component="h6" className={classes.label}>
          {label}:
        </Typography>
        <Typography component="h6" className={classes.value}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};
export default PageHeaderRightItem;
