import { adalApiFetch } from "clientApp/configAdal";
import { endPoints } from "clientApp/endPoints";
import { IConfigUserUpdateForm } from "./type";
import Toaster from "components/Toaster";
import { convertResponseToJSON } from "utils";

const headerValues = { "content-type": "application/json" };

export const getConfigUser = (userId: string) => {
  return adalApiFetch(fetch, `${endPoints.configurationUsersV2}/Get/${userId}`, {
    method: "GET",
    headers: headerValues,
  }).then((res) => {
    return convertResponseToJSON(res);
  });
};

export const updateConfigUser = async (data: IConfigUserUpdateForm) => {
  return await adalApiFetch(
    fetch,
    `${endPoints.configurationUsersV2}/Update/${data.id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  )
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](
          "Error occured while processing your request!",
          "Error Saving"
        );
      } else {
        Toaster["success"]("Successfully Updated!");
      }
      return response;
    })
    .then((response) => response.json())
    .catch((err) => {
      console.error(err);
    });
};
