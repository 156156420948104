import React, { useContext } from "react";
import { Spinner as BootstrapSpinner } from "react-bootstrap";
import { SpinnerContext } from "provider/SpinnerProvider";

import styled from "styled-components";
const SpinnerContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 100000;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
`;

const SpinnerDiv = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  text-align: center;
  color: white;
  transform: translate(-50%, -50%);
`;

const Spinner = () => {
  const [isSpinnerVisible, ] = useContext(SpinnerContext);
  return (
    isSpinnerVisible && (
      <SpinnerContainer>
        <SpinnerDiv>
          <BootstrapSpinner
            animation="border"
            variant="primary"
            className="mb-3"
          />
          <h5 className="primary-color">Loading...</h5>
        </SpinnerDiv>
      </SpinnerContainer>
    )
  );
};

export default Spinner;
