import React, { useContext } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Divider from "@material-ui/core/Divider";
import { AuthContext } from "provider/AuthProvider";
import { authContext } from "clientApp/configAdal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2)
    },
    button: {
      display: "flex",
      margin: "10px auto"
    },
    logoutIcon: {
      marginRight: "10px"
    }
  })
);

export default function ProfilePopover() {
  const context: any = authContext;
  const { isAuthenticatedContext } = useContext(AuthContext);
  const [, setIsAuthenticated] = isAuthenticatedContext;

  const logout = () => {
    setIsAuthenticated(false);
    authContext.logout();
  };
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge color="secondary">
          <AccountCircleIcon fontSize="large" />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Typography className={classes.typography}>
          {context.account.name}
        </Typography>
        <Divider />
        <Button
          aria-describedby={id}
          variant="contained"
          color="primary"
          onClick={logout}
          className={classes.button}
        >
          <ExitToAppIcon className={classes.logoutIcon} />
          Logout
        </Button>
      </Popover>
    </div>
  );
}
