import React, { useEffect, useContext, useState, ReactNode } from "react";
import { useForm, ErrorMessage, ValidateResult } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Box, Grid, Paper } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import DialogModal from "components/DialogModal";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import Dropdown from "components/MUIDropdown";
import PaperHeading from "components/PaperHeading";
import NewTextField from "components/TextField";
import { FormErrorMessages } from "constants/enum";
import {
  CreateAccount as CreateAccountConst,
  Accounts as AccountsConst,
} from "constants/constant";

import { CountriesContext } from "provider/CountriesProvider";
import { ApplicationsContext } from "provider/ApplicationsProvider";
import { TypesContext } from "provider/TypesProvider";
import { SpinnerContext } from "provider/SpinnerProvider";

import { AccountsUpdatePath, AssetsRootPath } from "routes/paths";

import {
  getAccount,
  saveAccount,
} from "../../../../actions/accountsPageActions";

import { ILabelValue } from "components/type";
import { isEmpty } from "lodash";
import MetadataTable, {
  MetadataProps,
} from "../../../../components/MetadataTable";
import { saveBatchHierarchyMetadata } from "actions/generalActions";
import { coordinatesValidation } from "pages/Installation/InstallationDetails/Form/utils";
import { isValid } from "utils";

const getOptions = (items: any[], id: string, label: string): ILabelValue[] => {
  const data: ILabelValue[] = [];

  items.forEach((i) => {
    data.push({
      label: i[label],
      value: i[id],
    });
  });

  return data;
};

const errorMessageComponent = (
  messages: Record<string, ValidateResult> | undefined
): ReactNode =>
  messages &&
  Object.entries(messages).map(([type, message]) => (
    <p style={{ color: "red" }} key={type}>
      {message}
    </p>
  ));

const CreateAccount = () => {
  const history = useHistory();
  const [accountId, setAccountId] = useState("");

  const [countries, getCountries] = useContext(CountriesContext);
  const [, getApplications] = useContext(ApplicationsContext);
  const [, getTypes] = useContext(TypesContext);
  const [isSpinnerVisible, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [metadata, setMetadata] = useState<MetadataProps[]>([]);
  const [isModifyingMetadata, setIsModifyingMetadata] = useState(false);
  const [stateInvoice, setInvoiceState] = useState({
    invoiceToDifferentEmail: false,
    invoiceDifferentEmail: "",
  });

  const { register, handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      assetName: "",
      sapLocalSoldToId: "",
      sapCentralSoldToId: "",
      sapCentralSoldToName: "",
      salesOrganization: "",
      countryId: "",
      street: "",
      postalCode: "",
      city: "",
      state: "",
      latitude: "",
      longitude: "",
      cvrvat: "",
      differentEmail: "",
    },
    validateCriteriaMode: "all",
  });

  const { invoiceToDifferentEmail, invoiceDifferentEmail } = stateInvoice;

  useEffect(() => {
    getCountries();
    getApplications();
    getTypes();
  }, []);

  const resetForm = () => {
    setValue(AccountsConst.ASSETNAME_NAME, "");
    setValue(AccountsConst.SAP_LOCAL_SOLD_TO_NUMBER_NAME, "");
    setValue(AccountsConst.SAP_CENTRAL_SOLD_TO_NUMBER_NAME, "");
    setValue(AccountsConst.SAP_CENTRAL_SOLD_TO_NAME_NAME, "");
    setValue(AccountsConst.SALES_ORGANIZATION_NAME, "");
    setValue(AccountsConst.STREET_NAME, "");
    setValue(AccountsConst.POSTAL_NAME, "");
    setValue(AccountsConst.CITY_NAME, "");
    setValue(AccountsConst.STATE_NAME, "");
    setValue(AccountsConst.LATITUDE_NAME, "");
    setValue(AccountsConst.LONGITUDE_NAME, "");
    setValue(AccountsConst.CONTACT_NAME_NAME, "");
    setValue(AccountsConst.TELEPHONE_NUMBER_NAME, "");
    setValue(AccountsConst.EMAIL_ADDRESS_NAME, "");
    setValue(AccountsConst.INVOICE_TO_DIFFERENT_EMAIL_NAME, false);
    setValue(AccountsConst.INVOICE_EMAIL_NAME, "");
    setValue(AccountsConst.CVR_VAT_NAME, "");
    setMetadata([]);
  };

  const onSubmit = (data: any): void => {
    if (isModifyingMetadata) {
      return;
    }

    setIsSpinnerVisible(true);
    data.ownerAccountId = "";
    data.typeId = process.env.REACT_APP_TYPE_ACCOUNT_ID;

    if (!isValid(data.accountResponsibleEmailAddress)) {
      delete data.accountResponsibleEmailAddress;
    }

    let resultAccountId = "";
    saveAccount(data)
      .then((accountId = "") => {
        resultAccountId = accountId;
        return getAccount(accountId);
      })
      .then(() => {
        if (metadata.length > 0) {
          saveBatchHierarchyMetadata(resultAccountId, metadata);
        }
      })
      .then(() => {
        if (!isEmpty(resultAccountId) || resultAccountId) {
          setAccountId(resultAccountId);
          setShowConfirmation(true);
        }
        resetForm();
        setIsSpinnerVisible(false);
      })
      .catch((e) => {
        setIsSpinnerVisible(false);
        console.error(e);
      });
  };

  const handleChange = (e: any) => {
    setInvoiceState({
      ...stateInvoice,
      invoiceToDifferentEmail: e.target.checked,
      invoiceDifferentEmail: e.target.checked
        ? FormErrorMessages.fieldRequired
        : "",
    });
  };

  const metadataTableComponent = isSpinnerVisible ? (
    <></>
  ) : (
    <MetadataTable
      metadata={metadata}
      search={true}
      onChangeMetadata={(data: MetadataProps[]) => setMetadata(data)}
      onClickActionButton={(name) => {
        if (name === "Add" && !isModifyingMetadata) {
          setIsModifyingMetadata(true);
        } else {
          setIsModifyingMetadata(false);
        }
      }}
    />
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper>
            <Box p={3}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <PaperHeading label={CreateAccountConst.PAPER_HEADING_LABEL} />
                <Divider />
                <NewTextField
                  error={errors.assetName}
                  label={AccountsConst.ASSETNAME_LABEL}
                  name={AccountsConst.ASSETNAME_NAME}
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired,
                  })}
                />
                <NewTextField
                  error={errors.cvrvat}
                  label={AccountsConst.CVR_VAT_LABEL}
                  name={AccountsConst.CVR_VAT_NAME}
                  inputRef={register}
                />

                <NewTextField
                  error={errors.sapLocalSoldToId}
                  label={AccountsConst.SAP_LOCAL_SOLD_TO_NUMBER_LABEL}
                  name={AccountsConst.SAP_LOCAL_SOLD_TO_NUMBER_NAME}
                  maxLength={10}
                  inputRef={register}
                />
                <NewTextField
                  error={errors.sapCentralSoldToId}
                  label={AccountsConst.SAP_CENTRAL_SOLD_TO_NUMBER_LABEL}
                  name={AccountsConst.SAP_CENTRAL_SOLD_TO_NUMBER_NAME}
                  maxLength={10}
                  inputRef={register}
                />
                <NewTextField
                  error={errors.sapCentralSoldToName}
                  label={AccountsConst.SAP_CENTRAL_SOLD_TO_NAME_LABEL}
                  name={AccountsConst.SAP_CENTRAL_SOLD_TO_NAME_NAME}
                  inputRef={register}
                />
                <NewTextField
                  error={errors.salesOrganization}
                  label={AccountsConst.SALES_ORGANIZATION_LABEL}
                  name={AccountsConst.SALES_ORGANIZATION_NAME}
                  maxLength={3}
                  inputRef={register}
                />
                <Dropdown
                  label={AccountsConst.COUNTRY_LABEL}
                  name={AccountsConst.COUNTRY_NAME}
                  control={control}
                  data={getOptions(countries, "id", "countryName")}
                />
                <NewTextField
                  label={AccountsConst.STATE_LABEL}
                  name={AccountsConst.STATE_NAME}
                  inputRef={register}
                />
                <NewTextField
                  label={AccountsConst.CITY_LABEL}
                  name={AccountsConst.CITY_NAME}
                  inputRef={register}
                />
                <NewTextField
                  label={AccountsConst.STREET_LABEL}
                  name={AccountsConst.STREET_NAME}
                  inputRef={register}
                />

                <NewTextField
                  label={AccountsConst.POSTAL_LABEL}
                  name={AccountsConst.POSTAL_NAME}
                  inputRef={register}
                />

                <Grid container spacing={3}>
                  <Grid item md={6} xs={6}>
                    <NewTextField
                      label={AccountsConst.LATITUDE_LABEL}
                      name={AccountsConst.LATITUDE_NAME}
                      inputRef={register({
                        required: false,
                        validate: coordinatesValidation("lat"),
                      })}
                    />
                    <ErrorMessage errors={errors} name="latitude">
                      {({ messages }): ReactNode =>
                        errorMessageComponent(messages)
                      }
                    </ErrorMessage>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <NewTextField
                      label={AccountsConst.LONGITUDE_LABEL}
                      name={AccountsConst.LONGITUDE_NAME}
                      inputRef={register({
                        required: false,
                        validate: coordinatesValidation("long"),
                      })}
                    />
                    <ErrorMessage errors={errors} name="longitude">
                      {({ messages }): ReactNode =>
                        errorMessageComponent(messages)
                      }
                    </ErrorMessage>
                  </Grid>
                </Grid>
                <FloatingActionButtonList>
                  <FloatingActionButton type="submit">
                    <SaveIcon />
                    Save
                  </FloatingActionButton>
                </FloatingActionButtonList>
              </Form>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper>{metadataTableComponent}</Paper>

          <Box marginTop={4} mb={3}>
            <Paper>
              <Box p={3}>
                <Box mb={3}>
                  <PaperHeading
                    label={CreateAccountConst.ACCOUNT_RESPONSIBLE}
                  />
                  <Divider />
                  <NewTextField
                    label={AccountsConst.CONTACT_NAME_LABEL}
                    name={AccountsConst.CONTACT_NAME_NAME}
                    inputRef={register}
                  />
                  <NewTextField
                    label={AccountsConst.TELEPHONE_NUMBER_LABEL}
                    name={AccountsConst.TELEPHONE_NUMBER_NAME}
                    inputRef={register}
                  />
                  <NewTextField
                    label={AccountsConst.EMAIL_ADDRESS_LABEL}
                    name={AccountsConst.EMAIL_ADDRESS_NAME}
                    inputRef={register}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box marginTop={4} mb={3}>
            <Paper>
              <Box p={3}>
                <Box mb={3}>
                  <PaperHeading label={CreateAccountConst.INVOICE} />
                  <Divider />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={invoiceToDifferentEmail}
                        onChange={handleChange}
                        name={AccountsConst.INVOICE_TO_DIFFERENT_EMAIL_NAME}
                        inputRef={register}
                      />
                    }
                    label={AccountsConst.INVOICE_TO_DIFFERENT_EMAIL_LABEL}
                  />

                  <NewTextField
                    error={errors.differentEmail}
                    label={AccountsConst.INVOICE_EMAIL_LABEL}
                    name={AccountsConst.INVOICE_EMAIL_NAME}
                    inputRef={register({
                      required: invoiceDifferentEmail,
                    })}
                  />

                  <ErrorMessage
                    errors={errors}
                    name={AccountsConst.INVOICE_EMAIL_NAME}
                  >
                    {({ messages }): ReactNode =>
                      errorMessageComponent(messages)
                    }
                  </ErrorMessage>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>

      <DialogModal
        showCancelButton
        title={CreateAccountConst.DIALOG_TITLE}
        showModal={showConfirmation}
        message={CreateAccountConst.DIALOG_MESSAGE}
        onOk={(): void =>
          history.push(AccountsUpdatePath.replace(":id", accountId))
        }
        onHide={(): void => history.push(AssetsRootPath)}
      />
    </>
  );
};

export default CreateAccount;
