import { adalApiFetch } from "../clientApp/configAdal";
import { endPoints } from "../clientApp/endPoints";
import { IHierarchy, IPagination } from "../components/type";
import { IAccountForm } from "../pages/Accounts/CreateAccount/Form/type";
import { IAccountUpdateForm } from "../pages/Accounts/UpdateAccount/Form/type";
import Toaster from "components/Toaster";

const headerValues = { "content-type": "application/json" };
const TOASTR_ERROR_MESSAGE = "Error occured while processing your request!";

export const getHierarchies = async (
  page = 1,
  limit = 10,
  query?: any
): Promise<IPagination> => {
  return await adalApiFetch(
    fetch,
    `${endPoints.hierarchies}/GetAll?page=${page}&limit=${limit}${
      query ? `&assetname=${query}` : ""
    }`,
    {
      method: "GET",
      headers: headerValues,
    }
  )
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      throw e;
    });
};

export const getHierarchiesByAssetName = async (
  searchTerm: string
): Promise<IHierarchy[]> => {
  return await adalApiFetch(
    fetch,
    `${endPoints.hierarchies}/GetHierarchiesByAssetName/${searchTerm}`,
    {
      method: "GET",
      headers: headerValues,
    }
  )
    .then((res) => {
      if (!res.ok) {
        return res;
      } else {
        return res.json();
      }
    })
    .then((posts) => {
      return posts.hierarchies;
    })
    .catch((e) => {
      throw e;
    });
};

export const deleteAccount = (hierarchyId: string): Promise<boolean> => {
  return adalApiFetch(fetch, `${endPoints.hierarchies}/Delete/${hierarchyId}`, {
    method: "DELETE",
    headers: headerValues,
  })
    .then((res) => {
      if (res.ok) {
        Toaster["success"]("Successfully Deleted!");
      } else {
        if (res.status === 406) {
          Toaster["warning"](
            "Please check attached devices",
            "Delete not allowed"
          );
        } else {
          Toaster["error"](
            TOASTR_ERROR_MESSAGE,
            "Error deleting hierarchy"
          );
        }
      }

      return res.ok;
    })
    .catch((e) => {
      Toaster["error"](
        TOASTR_ERROR_MESSAGE,
        "Error deleting hierarchy"
      );
      throw e;
    });
};

export const CreateSection = async (SectionDetails: any) => {
  return await adalApiFetch(fetch, `${endPoints.hierarchies}/Create`, {
    method: "POST",
    headers: headerValues,
    body: JSON.stringify(SectionDetails),
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](
          TOASTR_ERROR_MESSAGE,
          "Error Saving"
        );
      } else {
        Toaster["success"]("Successfully Added!");
      }
      return response;
    })
    .then((response) => response.json())
    .catch((e) => {
      throw e;
    });
};

export const getAccount = async (accountId: string) => {
  return await adalApiFetch(
    fetch,
    `${endPoints.hierarchies}/Get/${accountId}`,
    {
      method: "GET",
      headers: headerValues,
    }
  ).then((res) => {
    return res.json();
  });
};

export const saveAccount = async (data: IAccountForm) => {
  return await adalApiFetch(fetch, `${endPoints.hierarchies}/Create`, {
    method: "POST",
    headers: headerValues,
    body: JSON.stringify(data),
  })
    .then(function (response) {
      if (!response.ok) {
        response.json().then((body: any) => {
          Toaster["error"](body.AssetName, "Error Saving");
          return response;
        });
      } else {
        Toaster["success"]("Successfully Added!");
        return response;
      }
    })
    .then((response) => response.json())
    .catch((e) => {
      console.error(e);
    });
};

export const updateAccount = async (data: IAccountUpdateForm) => {
  return await adalApiFetch(
    fetch,
    `${endPoints.hierarchies}/Update/${data.id}`,
    {
      method: "PUT",
      headers: headerValues,
      body: JSON.stringify(data),
    }
  )
    .then(function (response) {
      if (!response.ok) {
        response.json().then((body: any) => {
          Toaster["error"](body.AssetName, "Error Saving");
          return response;
        });
      } else {
        Toaster["success"]("Successfully Updated!");
        return response;
      }
    })
    .catch((e) => {
      throw e;
    });
};
