import { adalApiFetch } from "../clientApp/configAdal";
import { endPoints } from "../clientApp/endPoints";
import Toaster from "components/Toaster";
import {
  CreateInstallationProps,
  Device,
  UpdateInstallationProps
} from "../pages/Installation/type";
import { IHierarchy, IPagination } from "../components/type";

const headerValues = { "content-type": "application/json" };
const errorHeaderText = "Error occured while processing your request!";
const updateSuccessText = "Successfully Updated";

export const createNewInstallation = (
  installation: CreateInstallationProps
): Promise<any> => {
  return adalApiFetch(fetch, `${endPoints.installations}/Create`, {
    method: "POST",
    headers: headerValues,
    body: JSON.stringify(installation)
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](errorHeaderText, "Error Saving");

        throw new Error(response.statusText);
      } else {
        Toaster["success"]("Successfully Added!");
        return response;
      }
    })
    .then((response) => response.json())
    .catch((e) => {
      Toaster["error"](e, "Error Saving");
      throw e;
    });
};

export const updateInstallation = (
  installation: UpdateInstallationProps
): Promise<any> => {
  return adalApiFetch(
    fetch,
    `${endPoints.installations}/Update/${installation.id}`,
    {
      method: "PUT",
      headers: headerValues,
      body: JSON.stringify(installation)
    }
  )
    .then((response) => {
      if (!response.ok) {
        Toaster["error"](errorHeaderText, "Error Updating");

        throw new Error(response.statusText);
      } else {
        Toaster["success"]("Successfully Updated!");
        return response;
      }
    })
    .catch((e) => {
      Toaster["error"](e, "Error Updating");
      throw e;
    });
};

export const getAccountAndSection = (id = "") => {
  return adalApiFetch(
    fetch,
    `${endPoints.hierarchies}/GetAllByAccountAndSectionType${
      id ? `?hierarchyId=${id}` : ""
    }`,
    {
      method: "GET",
      headers: headerValues
    }
  ).then((res) => {
    return res.json();
  });
};

export const getAllInstallations = async (
  page = 1,
  limit = 10,
  query?: any
): Promise<IPagination> => {
  return await adalApiFetch(
    fetch,
    `${endPoints.installations}/GetAll?page=${page}&limit=${limit}${
      query ? `&searchquery=${query}` : ""
    }`,
    {
      method: "GET",
      headers: headerValues
    }
  )
    .then((res) => res.json())
    .then((i) => {
      return i;
    })
    .catch((e) => {
      throw e;
    });
};

export const getInstallation = async () => {
  return await adalApiFetch(
    fetch,
    `${endPoints.installations}/GetInstallationHierarchies`,
    {
      method: "GET",
      headers: headerValues
    }
  )
    .then((res) => res.json())
    .then((posts) => {
      return posts.installationHierarchies;
    });
};

export const getInstallationById = async (id: string) => {
  return await adalApiFetch(fetch, `${endPoints.installations}/Get/${id}`, {
    method: "GET",
    headers: headerValues
  })
    .then((res) => res.json())
    .catch((e) => {
      throw e;
    });
};

export const createInstallation = async (InstallationDetails: IHierarchy) => {
  return await adalApiFetch(fetch, `${endPoints.hierarchies}/Create`, {
    method: "POST",
    headers: headerValues,
    body: JSON.stringify(InstallationDetails)
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](errorHeaderText, "Error Saving");
      } else {
        Toaster["success"]("Successfully Added!");
      }

      return response;
    })
    .then((response) => response.json())
    .catch((e) => {
      throw e;
    });
};

export const editInstallation = async (
  id: number,
  SectionDetails: IHierarchy
) => {
  return await adalApiFetch(fetch, `${endPoints.hierarchies}/update/${id}`, {
    method: "PUT",
    headers: headerValues,
    body: JSON.stringify(SectionDetails)
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](errorHeaderText, "Error Updating Installation");
        throw Error(response.statusText);
      } else {
        Toaster["success"](updateSuccessText);
        return response;
      }
    })
    .catch(function (error) {
      Toaster["error"](errorHeaderText, "Error Updating Installation");
      throw error;
    });
};

export const patchInstallation = async (installationDetails: IHierarchy) => {
  return await adalApiFetch(
    fetch,
    `${endPoints.hierarchies}/PatchParentId/${installationDetails.id}`,
    {
      method: "PATCH",
      headers: headerValues,
      body: JSON.stringify(installationDetails)
    }
  )
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](
          errorHeaderText,
          "Error Adding Installation to Hierarchy"
        );
        throw Error(response.statusText);
      } else {
        Toaster["success"](updateSuccessText);
        return response;
      }
    })
    .catch(function (error) {
      Toaster["error"](
        errorHeaderText,
        "Error Adding Installation to Hierarchy"
      );
      throw error;
    });
};

export const patchUnAssignInstallation = async (
  installationDetails: IHierarchy
) => {
  return await adalApiFetch(
    fetch,
    `${endPoints.hierarchies}/UnassignHierarchy`,
    {
      method: "PATCH",
      headers: headerValues,
      body: JSON.stringify(installationDetails)
    }
  )
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](
          errorHeaderText,
          "Error unassigning or detaching installation"
        );
        throw Error(response.statusText);
      } else {
        Toaster["success"](updateSuccessText);
        return response;
      }
    })
    .catch(function (error) {
      Toaster["error"](
        errorHeaderText,
        "Error unassigning or detaching installation"
      );
      throw error;
    });
};

export const getDigitalOfferingsListByApplicationId = async (
  id: string
): Promise<any> => {
  return await adalApiFetch(
    fetch,
    `${endPoints.installations}/GetDigitalOfferingsListByApplicationId/${id}`,
    {
      method: "GET",
      headers: headerValues
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((digiOff) => {
      return digiOff.digitalOfferings;
    })
    .catch((e) => {
      throw e;
    });
};

export const getUnassignedDevices = async (
  searchQuery = ""
): Promise<Device[]> => {
  const injectSearchQuery =
    searchQuery !== "" ? `?searchquery=${searchQuery}` : "";
  return await adalApiFetch(
    fetch,
    `${endPoints.devices}/GetUnassignedDevices${injectSearchQuery}`,
    {
      method: "GET",
      headers: headerValues
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((dev) => {
      return dev.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const addReferences = async (
  installationId: string,
  deviceIds: string[] = []
) => {
  if (deviceIds.length > 0) {
    return await adalApiFetch(
      fetch,
      `${endPoints.installations}/${installationId}/references`,
      {
        method: "POST",
        headers: headerValues,
        body: JSON.stringify({ deviceIds: deviceIds })
      }
    )
      .then(function (response) {
        if (!response.ok) {
          Toaster["error"](errorHeaderText, "Error saving references");
        } else {
          Toaster["success"]("Successfully saved references!");
        }

        return response;
      })
      .catch((e) => {
        throw e;
      });
  }
};

export const getReferences = async (installationId: string) => {
  return await adalApiFetch(
    fetch,
    `${endPoints.installations}/${installationId}/references`,
    {
      method: "GET",
      headers: headerValues
    }
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        Toaster["error"](errorHeaderText, "Error saving references");
        return [];
      }
    })
    .then((r) => r.references)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

export const removeReferences = async (
  installationId: string,
  deviceIds: string[] = []
) => {
  if (deviceIds.length > 0) {
    return await adalApiFetch(
      fetch,
      `${endPoints.installations}/${installationId}/references`,
      {
        method: "DELETE",
        headers: headerValues,
        body: JSON.stringify({ deviceIds: deviceIds })
      }
    )
      .then(function (response) {
        if (!response.ok) {
          Toaster["error"](errorHeaderText, "Error removing references");
        }

        return response;
      })
      .catch((e) => {
        throw e;
      });
  }
};
