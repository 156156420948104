import React from "react";
import { renderRoutes } from "react-router-config";

import PropTypes from "prop-types";

import { AppProps } from "./types";

const App: React.FC<AppProps> = ({ route }) => {
  return (
    <div id="app">
      <main>{renderRoutes(route.routes /* , { relay: data } */)}</main>
    </div>
  );
};

App.propTypes = {
  route: PropTypes.any
};

export default App;
