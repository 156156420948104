import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { Controller } from "react-hook-form";

import { IMUIDropdownProps } from "./type";
import { ILabelValue } from "components/type";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const MUIDropdown = ({
  label,
  name,
  rules,
  readOnly,
  data,
  control,
  error,
  onChange,
  changed,
}: IMUIDropdownProps): JSX.Element => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FormControl error={error!} fullWidth style={{ margin: "8px 0" }}>
      <InputLabel id="select-label">{label}</InputLabel>
      <Controller
        rules={rules}
        name={name || ""}
        control={control}
        as={(props: any) => {
          return (
            <Select
              style={{
                backgroundColor: changed
                  ? "rgba(201, 232, 255, 0.5)"
                  : "transparent",
              }}
              disabled={readOnly}
              labelId="select-label"
              id="select"
              value={props.value}
              onChange={(e) => {
                props.onChange(e);
                handleChange(e);
              }}
            >
              {data.map((item: ILabelValue, index: number) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
      {error ? <FormHelperText>This field is required</FormHelperText> : null}
    </FormControl>
  );
};
export default MUIDropdown;
