import React, { useEffect, useContext, useState, ReactNode } from "react";
import { useForm, ErrorMessage, ValidateResult } from "react-hook-form";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { Form } from "react-bootstrap";
import SaveIcon from "@material-ui/icons/Save";
import Divider from "@material-ui/core/Divider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

import DialogModal from "components/DialogModal";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import Dropdown from "components/MUIDropdown";
import PaperHeading from "components/PaperHeading";
import NewTextField from "components/TextField";
import { IDropdown, IHierarchy, ILabelValue } from "components/type";

import { FormErrorMessages } from "constants/enum";
import {
  CreateSection as CreateSectionConst,
} from "constants/constant";

import { SectionUpdatePath, AssetsRootPath } from "routes/paths";

import { CountriesContext } from "provider/CountriesProvider";
import { TypesContext } from "provider/TypesProvider";
import { SpinnerContext } from "provider/SpinnerProvider";
import { SectionContext } from "provider/SectionProvider";
import { AccountContext } from "provider/AccountProvider";

import {
  createSection,
  getSectionById
} from "../../../../actions/sectionsPageActions";

import { getAccountAndSection } from "../../../../actions/installationsPageActions";
import { getAccount } from "../../../../actions/accountsPageActions";

import { Box, Paper } from "@material-ui/core";
import MetadataTable from "components/MetadataTable";
import { MetadataProps } from "components/MetadataTable/MetadataTable";
import { saveBatchHierarchyMetadata } from "actions/generalActions";
import { coordinatesValidation } from "pages/Installation/InstallationDetails/Form/utils";

const getOptions = (items: any[], id: string, label: string): ILabelValue[] => {
  const data: ILabelValue[] = [];

  items.forEach((i) => {
    data.push({
      label: i[label],
      value: i[id]
    });
  });

  return data;
};

const errorMessageComponent = (
  messages: Record<string, ValidateResult> | undefined
): ReactNode =>
  messages &&
  Object.entries(messages).map(([type, message]) => (
    <p style={{ color: "red" }} key={type}>
      {message}
    </p>
  ));

const CreateSection = () => {
  const history = useHistory();
  const [hierarchiesList, setHierarchyList] = useState<IDropdown[]>([]);
  const [immediateParentId, setParentId] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [, setResetValue] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [countries, getCountries] = useContext(CountriesContext);
  const [, getTypes] = useContext(TypesContext);
  const [isSpinnerVisible, setIsSpinnerVisible] = useContext(SpinnerContext);
  const { createSectionContext } = useContext(SectionContext);
  const { updateAccountContext } = useContext(AccountContext);
  const [quickCreateSection, setQuickCreateSection] = createSectionContext;
  const [, setAccount] = updateAccountContext;
  const [metadata, setMetadata] = useState<MetadataProps[]>([]);
  const [isModifyingMetadata, setIsModifyingMetadata] = useState(false);

  const { register, handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      assetName: "",
      customerNumber: "",
      countryId: "",
      street: "",
      postalCode: "",
      city: "",
      state: "",
      latitude: "",
      longitude: ""
    },
    validateCriteriaMode: "all"
  });

  useEffect(() => {
    getCountries();
    getTypes();
    getAccountAndSection().then((h) => {
      setHierarchyList(h.hierarchies);
    });
  }, []);

  useEffect(() => {
    return () => {
      setQuickCreateSection([]);
    };
  }, []);

  const resetForm = () => {
    setValue(CreateSectionConst.ASSET_NAME, "");
    setValue(CreateSectionConst.CUSTOMER_NUMBER, "");
    setValue(CreateSectionConst.STREET, "");
    setValue(CreateSectionConst.POSTAL_CODE, "");
    setValue(CreateSectionConst.CITY, "");
    setValue(CreateSectionConst.STATE, "");
    setValue(CreateSectionConst.COUNTRY_NAME, "");
    setValue(CreateSectionConst.LATITUDE_NAME, "");
    setValue(CreateSectionConst.LONGITUDE_NAME, "");
    setMetadata([]);
    setResetValue("reset");
  };

  const handleImmediateParentReadOnly = (): boolean => {
    const isReadOnly =
      quickCreateSection.isQuickCreate && isEmpty(immediateParentId)
        ? true
        : false;
    return isReadOnly;
  };

  const getImmediateParent = (): IDropdown[] => {
    const parentId =
      quickCreateSection.isQuickCreate && isEmpty(immediateParentId)
        ? quickCreateSection.parentId
        : immediateParentId;
    return hierarchiesList.filter((e) => e.value === parentId);
  };

  const parentIdValue =
    getImmediateParent().length > 0 && isEmpty(immediateParentId)
      ? getImmediateParent()[0].value
      : immediateParentId;

  const rootValue =
    getImmediateParent().length > 0 && getImmediateParent()[0].rootId !== null
      ? getImmediateParent()[0].rootId
      : parentIdValue;

  const onSubmit = (data: any): void => {
    if (isModifyingMetadata) {
      return;
    }

    setIsSpinnerVisible(true);
    data.ownerAccountId = "";
    data.typeId = process.env.REACT_APP_TYPE_SECTION_ID;

    const sendData: IHierarchy = {
      assetName: data.assetName,
      street: data.street,
      ownerAccountId: data.ownerAccountId,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      countryId: data.countryId,
      rootId: rootValue,
      parentId: parentIdValue,
      typeId: data.typeId,
      customerNumber: "",
      typeName: "",
      latitude: data.latitude,
      longitude: data.longitude
    };

    let secId = "";
    createSection(sendData)
      .then((e) => {
        secId = e;
        return getSectionById(e);
      })
      .then(() => {
        if (metadata.length > 0) {
          saveBatchHierarchyMetadata(secId, metadata);
        }
      })
      .then(() => secId)
      .then((e) => {
        setSectionId(e);
        setIsSpinnerVisible(false);
        resetForm();
        if (isEmpty(quickCreateSection)) {
          setShowConfirmation(true);
        }
        if (isEmpty(immediateParentId) && quickCreateSection.isQuickCreate) {
          getAccount(quickCreateSection.rootId).then((a) => {
            setAccount(a);
            history.goBack();
          });
        }
      })
      .catch((e) => {
        console.error(e);
        setIsSpinnerVisible(false);
      });
  };

  const metadataTableComponent = (
    <Paper>
      {isSpinnerVisible ? (
        <></>
      ) : (
        <MetadataTable
          metadata={metadata}
          search={true}
          onChangeMetadata={(data: MetadataProps[]) => setMetadata(data)}
          onClickActionButton={(name) => {
            if (name === "Add" && !isModifyingMetadata) {
              setIsModifyingMetadata(true);
            } else {
              setIsModifyingMetadata(false);
            }
          }}
        />
      )}
    </Paper>
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper>
            <Box p={3}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <PaperHeading label={CreateSectionConst.PAPER_HEADING_LABEL} />
                <Divider />
                <NewTextField
                  error={errors.assetName}
                  label={CreateSectionConst.ASSETNAME_LABEL}
                  name={CreateSectionConst.ASSETNAME_NAME}
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired
                  })}
                />
                {!isEmpty(hierarchiesList) ? (
                  <Autocomplete
                    id="immediateParent"
                    defaultValue={getImmediateParent()[0]}
                    disabled={handleImmediateParentReadOnly()}
                    disableClearable={true}
                    options={hierarchiesList}
                    getOptionLabel={(option) =>
                      !isEmpty(option) ? option.label : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Immediate Parent"
                        margin="normal"
                        fullWidth
                        name="parentId"
                      />
                    )}
                    onChange={(event: object, value: any) =>
                      setParentId(value.value)
                    }
                  />
                ) : (
                  <div>
                    <Skeleton variant="rect" height={40} />
                  </div>
                )}

                <Dropdown
                  label={CreateSectionConst.COUNTRY_LABEL}
                  name={CreateSectionConst.COUNTRY_NAME}
                  control={control}
                  data={getOptions(countries, "id", "countryName")}
                />
                <NewTextField
                  label={CreateSectionConst.STATE_LABEL}
                  name={CreateSectionConst.STATE_NAME}
                  inputRef={register}
                />
                <NewTextField
                  label={CreateSectionConst.CITY_LABEL}
                  name={CreateSectionConst.CITY_NAME}
                  inputRef={register}
                />
                <NewTextField
                  label={CreateSectionConst.STREET_LABEL}
                  name={CreateSectionConst.STREET_NAME}
                  inputRef={register}
                />

                <NewTextField
                  label={CreateSectionConst.POSTAL_LABEL}
                  name={CreateSectionConst.POSTAL_NAME}
                  inputRef={register}
                />

                <Grid container spacing={3}>
                  <Grid item md={6} xs={6}>
                    <NewTextField
                      label={CreateSectionConst.LATITUDE_LABEL}
                      name={CreateSectionConst.LATITUDE_NAME}
                      inputRef={register({
                        required: false,
                        validate: coordinatesValidation("lat")
                      })}
                    />
                    <ErrorMessage errors={errors} name="latitude">
                      {({ messages }): ReactNode =>
                        errorMessageComponent(messages)
                      }
                    </ErrorMessage>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <NewTextField
                      label={CreateSectionConst.LONGITUDE_LABEL}
                      name={CreateSectionConst.LONGITUDE_NAME}
                      inputRef={register({
                        required: false,
                        validate: coordinatesValidation("long")
                      })}
                    />
                    <ErrorMessage errors={errors} name="longitude">
                      {({ messages }): ReactNode =>
                        errorMessageComponent(messages)
                      }
                    </ErrorMessage>
                  </Grid>
                </Grid>

                <FloatingActionButtonList>
                  <FloatingActionButton type="submit">
                    <SaveIcon />
                    Save
                  </FloatingActionButton>
                </FloatingActionButtonList>
              </Form>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          {metadataTableComponent}
        </Grid>
      </Grid>

      <DialogModal
        showCancelButton
        title={CreateSectionConst.DIALOG_TITLE}
        showModal={showConfirmation}
        message={CreateSectionConst.DIALOG_MESSAGE}
        onOk={(): void =>
          history.push(SectionUpdatePath.replace(":id", sectionId))
        }
        onHide={(): void => history.push(AssetsRootPath)}
      />
    </>
  );
};

export default CreateSection;
