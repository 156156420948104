import React from "react";
import PaperHeading from "components/PaperHeading";
import MaterialTable, {
  Column,
  MTableAction,
  MTableEditRow
} from "material-table";

export type MetadataProps = {
  tag: string;
  code: string;
  value: string;
};

interface MetadataEditingProps extends MetadataProps {
  tableData: { id: number; editing: string };
}

const COLUMN_CONSTANTS = {
  TAG: { title: "Tag", field: "tag" },
  CODE: { title: "Code", field: "code" },
  VALUE: { title: "Value", field: "value" }
};

const METADATA_LABEL = "Metadata";

const CANNOT_BE_EMPTY = "Cannot be empty";
const SHOULD_BE_LESS_THAN_25_CHARS = "25 characters max";

const isMaxLength = (value = ""): boolean => value.length > 25;

const validateTag = (value = ""): true | string => {
  if (value === "") {
    return CANNOT_BE_EMPTY;
  }

  if (isMaxLength(value)) {
    return SHOULD_BE_LESS_THAN_25_CHARS;
  }

  return true;
};

const validateField = (value = ""): true | string => {
  if (value === "") {
    return CANNOT_BE_EMPTY;
  }

  return true;
};

const MetadataTable = ({
  metadata = [],
  disabled = false,
  search = false,
  size = 450,
  onChangeMetadata,
  onClickActionButton
}: {
  metadata?: MetadataProps[];
  disabled?: boolean;
  search?: boolean;
  size?: number;
  onChangeMetadata: (data: MetadataProps[]) => void;
  onClickActionButton?: (name?: "Add" | "Save" | "Cancel") => void;
}): JSX.Element => {
  const columns: Column<MetadataProps>[] = [
    {
      title: COLUMN_CONSTANTS.TAG.title,
      field: COLUMN_CONSTANTS.TAG.field,
      validate: (rowData): string | true => validateTag(rowData.tag)
    },
    {
      title: COLUMN_CONSTANTS.CODE.title,
      field: COLUMN_CONSTANTS.CODE.field,
      validate: (rowData): string | true => validateField(rowData.code)
    },
    {
      title: COLUMN_CONSTANTS.VALUE.title,
      field: COLUMN_CONSTANTS.VALUE.field,
      sorting: false,
      validate: (rowData): string | true => validateField(rowData.value)
    }
  ];

  const onRowAdd = (newData: MetadataProps): Promise<void> =>
    new Promise<void>((resolve, reject) => {
      const val = [...metadata, newData];

      onChangeMetadata(val);
      resolve();
    });

  const onRowUpdate = (newData: MetadataProps, oldData: any): Promise<void> =>
    new Promise<void>((resolve, reject) => {
      const old = oldData as MetadataEditingProps;
      const dataUpdate = [...metadata];
      const index = old.tableData.id;
      dataUpdate[index] = newData;

      onChangeMetadata([...dataUpdate]);
      resolve();
    });

  const onRowDelete = (oldData: MetadataProps): Promise<void> =>
    new Promise((resolve: any, reject: any) => {
      const old = oldData as MetadataEditingProps;
      const dataDelete = [...metadata];
      const index = old.tableData.id;
      dataDelete.splice(index, 1);

      onChangeMetadata([...dataDelete]);
      resolve();
    });

  const tableProps = disabled
    ? {
        options: {
          search: search,
          paging: false,
          toolbar: true,
          sorting: true,
          maxBodyHeight: `${size}px`
        }
      }
    : {
        options: {
          search: search,
          paging: false,
          toolbar: true,
          sorting: true,
          maxBodyHeight: `${size}px`,
          actionsColumnIndex: -1
        },
        editable: {
          onRowAdd: onRowAdd,
          onRowUpdate: onRowUpdate,
          onRowDelete: onRowDelete
        }
      };

  const onEnterKey = (e: any): void => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  const containerOverride = (props: any): JSX.Element => <div {...props}></div>;

  const editRowOverride = (props: any): JSX.Element => (
    <MTableEditRow onKeyDown={onEnterKey} {...props} />
  );

  const containsActionObject = (propsContainer: any): boolean => {
    let result = false;
    if (propsContainer?.action) {
      if (typeof propsContainer.action === "object") {
        result = true;
      }
    }

    return result;
  };

  const actionOverride = (props: any): JSX.Element => {
    const propsContainer = { ...props };

    if (containsActionObject(propsContainer)) {
      const onClickContainer = propsContainer.action.onClick;
      const tooltipName = propsContainer.action.tooltip;

      propsContainer.action.onClick = (e: any): void => {
        if (onClickActionButton) {
          onClickActionButton(tooltipName);
        }
        onClickContainer(e);
      };
    }

    return <MTableAction {...propsContainer} />;
  };

  return (
    <MaterialTable
      style={{ border: 0 }}
      title={<PaperHeading label={METADATA_LABEL} />}
      components={{
        Container: containerOverride,
        EditRow: editRowOverride,
        Action: actionOverride
      }}
      columns={columns}
      data={metadata}
      {...tableProps}
    />
  );
};

export default MetadataTable;
