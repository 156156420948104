import { adalApiFetch } from "../../clientApp/configAdal";
import { endPoints } from "../../clientApp/endPoints";

export const pullTypes = async () => {
  return await adalApiFetch(fetch, `${endPoints.types}/GetAll`, {
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  })
    .then(res => res.json())
    .then(posts => {
      return posts.types;
    });
};
