import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { Delete, Warning } from "@material-ui/icons";

import {
  DeviceFormPath,
  DeviceViewPath,
  AccountsUpdatePath
} from "routes/paths";
import MainBarDashboard from "components/MainBarDashboard";
import EnhancedPaginatedTable from "components/EnhancedPaginatedTable";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import ToggleButtonGroup from "components/ToggleButtonGroup";
import { IHeadCell } from "components/EnhancedPaginatedTable/type";
import DialogModal from "components/DialogModal";

import {
  cascadeDeleteDevice,
  getAllDevices,
  getMyDevices
} from "actions/devicePageActions";
import { DevicesTable as DevicesTableConst } from "constants/constant";

const DeviceList = () => {
  const history = useHistory();
  const goToDeviceForm = () => {
    history.push(DeviceFormPath);
  };

  const [selectedDevice, setSelectedDevice] = useState<{
    id: string;
    deviceName: string;
  }>({ id: "", deviceName: "" });
  const [showDialog, setShowDialog] = useState(false);
  const [isAllDevices, setIsAllDevices] = useState(true);
  const [query, setQuery] = useState("");
  const [refresh, setRefresh] = useState<number>();

  const onSearch = (keyword: string) => {
    setQuery(keyword);
  };

  useEffect(() => {
    setRefresh(Date.now());
  }, []);

  useEffect(() => {
    return;
  }, []);
  const breadcrumbs = [
    {
      label: "Devices"
    }
  ];

  const viewDevice = (obj: { [k: string]: any }) => {
    history.push(DeviceViewPath.replace(":deviceId", obj.id));
  };

  const viewAccount = (obj: { [k: string]: any }) => {
    history.push(AccountsUpdatePath.replace(":id", obj.rootAssetId));
  };

  const headCells: IHeadCell[] = [
    {
      id: "itemId",
      disablePadding: false,
      label: "ID",
      fieldName: "id",
      hidden: true
    },
    {
      id: "rootAssetId",
      disablePadding: false,
      label: "Root Asset ID",
      fieldName: "rootAssetId",
      hidden: true
    },
    {
      id: "deviceName",
      disablePadding: false,
      label: "Name",
      fieldName: "deviceName",
      isClickable: true,
      onClick: viewDevice,
      referenceIds: ["id"]
    },
    {
      id: "rootAssetName",
      disablePadding: false,
      label: "Account",
      fieldName: "rootAssetName",
      isClickable: true,
      onClick: viewAccount,
      referenceIds: ["rootAssetId"]
    },
    {
      id: "deviceId",
      disablePadding: false,
      label: "ID",
      fieldName: "deviceId"
    },
    {
      id: "status",
      disablePadding: false,
      label: "Status",
      fieldName: "status"
    },
    {
      id: "deviceProductName",
      disablePadding: false,
      label: "Product Name",
      fieldName: "deviceProductName"
    },
    {
      id: "deviceCategory",
      disablePadding: false,
      label: "Category",
      fieldName: "deviceCategory"
    },
    {
      id: "deviceType",
      disablePadding: false,
      label: "Type",
      fieldName: "deviceType"
    },
    {
      id: "actionId",
      disablePadding: false,
      label: "Actions",
      hasAction: true,
      actions: [
        {
          onSubmit: (e) => {
            setSelectedDevice({ id: e.id, deviceName: e.deviceName });
            setShowDialog(true);
          },
          icon: Delete,
          label: "Delete",
          referenceIds: ["id", "deviceName"]
        }
      ]
    }
  ];

  const handleChange = (newAlignment: string) => {
    switch (newAlignment) {
      case "allDevices": {
        setIsAllDevices(true);
        break;
      }
      case "myDevices": {
        setIsAllDevices(false);
        break;
      }
    }
  };

  const onConfirmDelete = (): void => {
    if (selectedDevice.id === "") {
      return;
    } else {
      cascadeDeleteDevice(selectedDevice.id)
        .then(() => {
          setRefresh(Date.now());
        })
        .catch((e) => {
          console.error(e);
        });
      setShowDialog(false);
    }
  };

  const dataButtonGroup = [
    {
      label: "All Devices",
      value: "allDevices"
    },
    {
      label: "My Devices",
      value: "myDevices"
    }
  ];

  const buttonGroup = (
    <ToggleButtonGroup
      onChange={handleChange}
      data={dataButtonGroup}
    ></ToggleButtonGroup>
  );

  return (
    <MainBarDashboard
      search
      searchPlaceholder="Search Devices"
      onSearch={onSearch}
      breadcrumbs={breadcrumbs}
    >
      <EnhancedPaginatedTable
        headCells={headCells}
        buttonGroup={buttonGroup}
        getData={isAllDevices ? getAllDevices : getMyDevices}
        query={query}
        refresh={refresh}
      />
      <FloatingActionButtonList>
        <FloatingActionButton onClick={goToDeviceForm}>
          <AddIcon />
          Device
        </FloatingActionButton>
      </FloatingActionButtonList>
      <DialogModal
        showCancelButton
        title={DevicesTableConst.DIALOG_TITLE}
        message={`${DevicesTableConst.DIALOG_MESSAGE_DEVICE} ${selectedDevice.deviceName}?`}
        showModal={showDialog}
        onOk={(): void => onConfirmDelete()}
        onHide={(): void => setShowDialog(false)}
        icon={<Warning fontSize="large" color="secondary" />}
      />
    </MainBarDashboard>
  );
};

export default DeviceList;
