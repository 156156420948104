import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/styles";
import AppBar from "./AppBar";
import Footer from "./Footer";
import Spinner from "./Spinner";

import { SpinnerProvider } from "provider/SpinnerProvider";

const useStyles = makeStyles(themeStyle => ({
  root: {
    display: "flex"
  },
  appBarSpacer: themeStyle.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100%",
    minHeight: "100vh",
    overflow: "auto",
    position: "relative"
  },
  container: {
    paddingTop: themeStyle.spacing(4),
    paddingBottom: themeStyle.spacing(12),
    minHeight: "calc(100% - 65px)"
  },
  paper: {
    padding: themeStyle.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 240
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#11497b",
      light: "#8cbbdd"
    },
    secondary: {
      main: "#545454",
      dark: "#171717",
      light: "#252525"
    },
    type: "light"
  }
});

const Dashboard = ({ children }: JSX.ElementChildrenAttribute): JSX.Element => {
  const classes = useStyles();

  return (
    <SpinnerProvider>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              {children}
            </Container>
            <Footer />
            <Spinner />
          </main>
        </div>
      </ThemeProvider>
    </SpinnerProvider>
  );
};
export default Dashboard;
