import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";

import { IFloatingActionButtonProps } from "./type";

const FloatingActionButton = ({
  children,
  onClick,
  type,
  disabled,
}: IFloatingActionButtonProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      fab: {
        margin: theme.spacing(1),
        "& svg": {
          marginRight: theme.spacing(1),
        },
      },
    })
  );
  const classes = useStyles();
  return (
    <Fab
      variant="extended"
      color="primary"
      aria-label="add"
      className={classes.fab}
      onClick={onClick ? onClick : undefined}
      type={type ? type : "button"}
      disabled={disabled}
    >
      {children}
    </Fab>
  );
};

export default FloatingActionButton;
