import React, { useContext, useState, useEffect, Fragment } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SaveIcon from "@material-ui/icons/Save";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import MainBarDashboard from "components/MainBarDashboard";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import NewTextField from "components/TextField";
import Dropdown from "components/MUIDropdown";
import DatePicker from "components/DatePicker";
import AutoComplete from "components/AutoComplete";
import List, { ICheckedItemList } from "components/List";
import { ILabelValue } from "components/type";
import MetadataTable, {
  MetadataProps
} from "../../../components/MetadataTable";
import {
  DeviceRootPath,
  DeviceFormPath,
  DeviceSubDeviceFormPath,
  DeviceCommDeviceFormPath,
  DeviceViewPath,
  InstallationsCreateWithDevicePath,
  InstallationsUpdateWithDevicePath
} from "routes/paths";
import { useQuery } from "routes/routes";
import { FormErrorMessages } from "constants/enum";
import {
  DeviceView as DeviceViewConst,
  AddressType as AddressTypeConst
} from "constants/constant";

import { DeviceContext } from "provider/DeviceProvider";
import { SpinnerContext } from "provider/SpinnerProvider";
import { SavedFormsContext } from "../../../provider/SavedFormsProvider";
import isEmpty from "lodash/isEmpty";

import {
  getOptions,
  getCheckedOptions,
  getAllChecked,
  validateAddressByAddressType
} from "utils";

import {
  saveDevice,
  getAllDevicesDropdown,
  getUnassignedInstallations,
  getAllInstallations,
  getSystemsByDeviceId,
  getAllUnassignedSubdevicesInInstallation,
  getDeviceCharacteristicsGENIInfo,
  saveBatchMetadata
} from "../../../actions/devicePageActions";

import { getInstallationById } from "../../../actions/installationsPageActions";
import { GENIInfo } from "./type";

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    marginBottom: theme.spacing(3)
  },
  tab: {
    minWidth: "auto"
  }
}));

const DeviceForm = (): JSX.Element => {
  const history = useHistory();
  const query = useQuery();
  const [formLimit, setFormLimit] = useState("both");
  const { url } = useRouteMatch();
  const breadcrumbs = [
    {
      label: "Devices",
      href: DeviceRootPath
    },
    {
      label: "Create",
      href: DeviceFormPath
    }
  ];
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    watch,
    triggerValidation
  } = useForm({
    defaultValues: {
      deviceProductName: "",
      installationId: "",
      parentDeviceId: "",
      systemId: "",
      deviceName: "",
      deviceCategory: "",
      deviceType: "",
      vendor: "",
      status: "",
      firmwareVersion: "",
      protocol: "",
      offering: "",
      address: "",
      addressType: "",
      sapProductNumber: "",
      gua: "",
      iccId: "",
      deviceTwinId: "",
      account: "",
      familyCode: "",
      deviceCharacteristicUnitTypeId: "",
      deviceCharacteristicUnitTypeVersionId: ""
    }
  });

  const values = watch();
  const [deviceProductNames, setDeviceProductNames] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [deviceCategories, setDeviceCategories] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [deviceTypes, setDeviceTypes] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [vendors, setVendors] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [unitTypes, setUnitTypes] = useState([
    {
      label: "",
      value: ""
    }
  ]);
  const [unitVersions, setUnitVersions] = useState([
    {
      label: "",
      value: ""
    }
  ]);

  const statuses = [
    {
      label: "Connected",
      value: "Connected"
    },
    {
      label: "Disconnected",
      value: "Disconnected"
    },
    {
      label: "Not Commissioned",
      value: "NotCommissioned"
    }
  ];

  const addressTypes = [
    {
      label: "MAC",
      value: AddressTypeConst.MAC
    },
    {
      label: "IMEI",
      value: AddressTypeConst.IMEI
    },
    {
      label: "Assigned ID",
      value: AddressTypeConst.AssignedId
    }
  ];

  const {
    deviceCharacteristicsContext,
    getDeviceCategoryByProductName: getDeviceCategoryByProductName
  } = useContext(DeviceContext);
  const [isSpinnerVisible, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [, getDeviceCharacteristics] = deviceCharacteristicsContext;
  const { savedFormInstallationContext } = useContext(SavedFormsContext);
  const [savedFormInstallation] = savedFormInstallationContext;

  const [deviceProductNamesParent, setDeviceProductNamesParent] = useState([]);
  const [deviceProductNamesSubDevice, setDeviceProductNamesSubDevice] =
    useState([]);
  const [deviceProductNamesSystem, setDeviceProductNamesSystem] = useState([]);
  const [deviceCharacteristicId, setDeviceCharacteristicId] = useState("");

  const [lastFirmwareUpdate, setLastFirmwareUpdate] =
    React.useState<Date | null>();

  const [systemSubDevices, setSystemSubDevices] = useState<ICheckedItemList[]>(
    []
  );
  const [systems, setSystems] = useState<ILabelValue[]>([]);

  const subDeviceOnlyKey = "subDeviceOnly";
  const commDeviceOnlyKey = "commDeviceOnly";

  const [metadata, setMetadata] = useState<MetadataProps[]>([]);
  const [isModifyingMetadata, setIsModifyingMetadata] = useState(false);

  const [autocompleteInstallationIdReset, setAutocompleteInstallationIdReset] =
    useState<number>();
  const [autocompleteParentDeviceIdReset, setAutocompleteParentDeviceIdReset] =
    useState<number>();
  const [
    autocompleteSystemInstallationIdReset,
    setAutocompleteSystemInstallationIdReset
  ] = useState<number>();
  const [parentDeviceIdDefaultValue, setParentDeviceIdDefaultValue] =
    useState<ILabelValue | null>(null);
  const [addressErrorExists, setAddressErrorExists] = useState<string>("");
  const [deviceTwinIdErrorExists, setDeviceTwinIdErrorExists] =
    useState<string>("");
  const [guaErrorExists, setGuaErrorExists] = useState<string>("");
  const updateParentDevice = (parentDeviceId: string): void => {
    if (parentDeviceId && parentDeviceId !== "") {
      getSystemsByDeviceId(`${parentDeviceId}`).then((sys) => {
        if (sys) {
          setSystems(getOptions(sys, "id", "deviceName"));
        } else {
          setSystems([]);
        }
      });
    } else {
      setSystems([]);
    }
  };

  const setFamilyCodeValue = (familyCode = 0): void => {
    const value = familyCode === 0 ? "" : familyCode.toString();

    setValue("familyCode", value);
  };

  const setUnitTypeDropdownValues = (
    unitTypesParam: { id: string; unitType: number }[] = []
  ): void => {
    const value: { label: string; value: string }[] = [];
    const nullString = "";

    unitTypesParam.forEach((x) =>
      value.push({ label: x.unitType.toString(), value: x.id })
    );

    if (unitTypesParam.length === 0) {
      value.push({ label: nullString, value: nullString });
    }

    setUnitTypes(value);
  };

  const setUnitTypeValue = (
    unitTypesParam: { id: string; unitType: number }[] = []
  ): void => {
    const nullString = "";

    if (unitTypesParam.length === 1) {
      setValue("deviceCharacteristicUnitTypeId", unitTypesParam[0].id);
    } else if (unitTypesParam.length === 0) {
      setValue("deviceCharacteristicUnitTypeId", nullString);
    } else {
      setValue("deviceCharacteristicUnitTypeId", "");
    }
  };

  const setUnitVersionDropdownValues = (
    unitVersionsParam: {
      id: string;
      unitVersion: string;
    }[] = []
  ): void => {
    const nullString = "";
    const value: { label: string; value: string }[] = [];

    unitVersionsParam.forEach((x) =>
      value.push({ label: x.unitVersion.toString(), value: x.id })
    );

    if (unitVersionsParam.length === 0) {
      value.push({ label: nullString, value: nullString });
    }

    setUnitVersions(value);
  };

  const setUnitVersionValue = (
    unitVersionsParam: {
      id: string;
      unitVersion: string;
    }[] = []
  ): void => {
    const nullString = "";

    if (unitVersionsParam.length === 1) {
      setValue(
        "deviceCharacteristicUnitTypeVersionId",
        unitVersionsParam[0].id
      );
    } else if (unitVersionsParam.length === 0) {
      setValue("deviceCharacteristicUnitTypeVersionId", nullString);
    } else {
      setValue("deviceCharacteristicUnitTypeVersionId", "");
    }
  };

  const setGENIValues = async (
    selectedDeviceCharacteristicsId = "",
    unitType = ""
  ): Promise<void> => {
    await getDeviceCharacteristicsGENIInfo(
      selectedDeviceCharacteristicsId,
      unitType
    ).then((geni: GENIInfo) => {
      setFamilyCodeValue(geni.familyCode);

      setUnitTypeDropdownValues(geni.unitTypes);
      setUnitTypeValue(geni.unitTypes);

      setUnitVersionDropdownValues(geni.unitVersions);
      setUnitVersionValue(geni.unitVersions);
    });
  };

  const loadDefaultDeviceDetails = (): void => {
    getDeviceCharacteristics(({ getAllDeviceType, getAllVendor }: any) => {
      const deviceCategoriesByProductName = getDeviceCategoryByProductName(
        values.deviceProductName
      );

      if (!isEmpty(getAllDeviceType)) {
        setDeviceTypes(getAllDeviceType);
      }

      if (!isEmpty(getAllVendor)) {
        setVendors(getAllVendor);
      }

      if (
        deviceCategoriesByProductName &&
        !isEmpty(deviceCategoriesByProductName)
      ) {
        setValue("deviceType", deviceCategoriesByProductName[0].assetType);

        setValue(
          "deviceCategory",
          deviceCategoriesByProductName[0].assetCategory
        );

        setValue("vendor", deviceCategoriesByProductName[0].vendor);

        if (tabValue === DeviceViewConst.FORM_TAB.NEW_SUB_DEV_TAB) {
          setGENIValues(deviceCategoriesByProductName[0].id);
        }

        setDeviceCharacteristicId(deviceCategoriesByProductName[0].id);
      }
    });
  };
  useEffect(() => {
    setIsSpinnerVisible(true);
    setAutocompleteInstallationIdReset(Date.now());
    setAutocompleteParentDeviceIdReset(Date.now());
    setAutocompleteSystemInstallationIdReset(Date.now());
    getDeviceCharacteristics(
      ({
        getAllDeviceCategory,
        getParentDeviceModalName,
        getSubDeviceModalName,
        getSystemDeviceModalName,
        getAllDeviceType,
        getAllVendor
      }: any) => {
        setDeviceCategories(
          getAllDeviceCategory.filter(
            (item: ILabelValue) => item.value !== "Communication Device"
          )
        );

        if (getParentDeviceModalName && !isEmpty(getParentDeviceModalName)) {
          setDeviceProductNamesParent(getParentDeviceModalName);
          setDeviceProductNames(getParentDeviceModalName);
          setValue("deviceProductName", getParentDeviceModalName[0].value);
        }

        if (getSubDeviceModalName && !isEmpty(getSubDeviceModalName)) {
          setDeviceProductNamesSubDevice(getSubDeviceModalName);
        }
        if (getSystemDeviceModalName && !isEmpty(getSystemDeviceModalName)) {
          setDeviceProductNamesSystem(getSystemDeviceModalName);
        }
        if (getAllDeviceType && !isEmpty(getAllDeviceType)) {
          setDeviceTypes(getAllDeviceType);
          setValue("deviceType", getAllDeviceType[0].value);
        }

        if (getAllVendor && !isEmpty(getAllVendor)) {
          setVendors(getAllVendor);
          setValue("vendor", getAllVendor[0].value);
        }

        if (url === DeviceSubDeviceFormPath) {
          setFormLimit(subDeviceOnlyKey);
          setTabValue(1);
          const val = `${query.get("parentDeviceId")}`;
          const lab = `${query.get("deviceName")}`;
          setParentDeviceIdDefaultValue({
            value: val,
            label: lab
          });
          updateParentDevice(val);
        } else if (url === DeviceCommDeviceFormPath) {
          setFormLimit(commDeviceOnlyKey);
          setTabValue(0);
        }
        setIsSpinnerVisible(false);
      }
    );
    loadDefaultDeviceDetails();
  }, []);

  useEffect(() => {
    if (tabValue === DeviceViewConst.FORM_TAB.NEW_COMM_DEV_TAB) {
      if (deviceProductNamesParent && !isEmpty(deviceProductNamesParent)) {
        setDeviceProductNames(deviceProductNamesParent);
        setValue("deviceProductName", deviceProductNamesParent[0]["value"]);
      }
    } else if (tabValue === DeviceViewConst.FORM_TAB.NEW_SUB_DEV_TAB) {
      if (
        deviceProductNamesSubDevice &&
        !isEmpty(deviceProductNamesSubDevice)
      ) {
        setDeviceProductNames(deviceProductNamesSubDevice);
        setValue("deviceProductName", deviceProductNamesSubDevice[0]["value"]);
        setValue("status", "");
      }
    } else if (tabValue === DeviceViewConst.FORM_TAB.NEW_SYSTEM_TAB) {
      if (deviceProductNamesSystem && !isEmpty(deviceProductNamesSystem)) {
        setDeviceProductNames(deviceProductNamesSystem);
        setValue("deviceProductName", deviceProductNamesSystem[0]["value"]);
        setValue("status", "");
      }
      setSystemSubDevices([]);
    }
  }, [tabValue]);

  useEffect(() => {
    setIsSpinnerVisible(true);
    loadDefaultDeviceDetails();
    setIsSpinnerVisible(false);
  }, [values.deviceProductName]);

  useEffect(() => {
    if (values.addressType) {
      triggerValidation("address");
    }
  }, [values.addressType]);

  useEffect(() => {
    if (values.address) {
      setAddressErrorExists("");
    }
  }, [values.address]);

  useEffect(() => {
    if (values.deviceTwinId) {
      setDeviceTwinIdErrorExists("");
    }
  }, [values.deviceTwinId]);

  useEffect(() => {
    if (values.gua) {
      setGuaErrorExists("");
    }
  }, [values.gua]);

  useEffect(() => {
    if (values.iccId) {
      triggerValidation("iccId");
    }
  }, [values.iccId]);

  const resetForm = (): void => {
    setValue("deviceName", "");
    if (tabValue === DeviceViewConst.FORM_TAB.NEW_COMM_DEV_TAB) {
      setValue("deviceProductName", deviceProductNamesParent[0]["value"]);
      setValue("status", statuses[0].value);
    } else if (tabValue === DeviceViewConst.FORM_TAB.NEW_SUB_DEV_TAB) {
      setValue("deviceProductName", deviceProductNamesSubDevice[0]["value"]);
      setValue("status", "");
    } else {
      setValue("deviceProductName", deviceProductNamesSystem[0]["value"]);
      setValue("status", "");
    }
    setValue("deviceTwinId", "");
    setValue("protocol", "");
    setValue("offering", "");
    setValue("addressType", "");
    setValue("address", "");
    setValue("sapProductNumber", "");
    setValue("gua", "");
    setValue("iccId", "");
    setValue("parentDeviceId", "");
    setValue("firmwareVersion", "");
    setValue("account", "");
    setValue("installationId", "");
    setLastFirmwareUpdate(null);
    setSystems([]);
    setSystemSubDevices([]);

    setAutocompleteInstallationIdReset(Date.now());
    setAutocompleteParentDeviceIdReset(Date.now());
    setAutocompleteSystemInstallationIdReset(Date.now());

    setMetadata([]);
  };

  const redirect = (deviceId: string): void => {
    if (savedFormInstallation.installationId) {
      const path = InstallationsUpdateWithDevicePath.replace(
        ":deviceId",
        deviceId
      ).replace(":installationId", savedFormInstallation.installationId);

      history.push(path);
    } else {
      history.push(
        InstallationsCreateWithDevicePath.replace(":deviceId", deviceId)
      );
    }
  };

  const getAdditionalDevicePropsToSubmit = (data: any) => {
    let dataContainer: any = {
      lastFirmwareUpdate: lastFirmwareUpdate,
      deviceCharacteristicId: deviceCharacteristicId
    };
    const nullString = "null";

    if (tabValue === DeviceViewConst.FORM_TAB.NEW_COMM_DEV_TAB) {
      dataContainer = {
        ...dataContainer,
        parentDeviceId: null,
        deviceType: 2,
        deviceCharacteristicUnitTypeId: null,
        deviceCharacteristicUnitTypeVersionId: null
      };
    } else if (tabValue === DeviceViewConst.FORM_TAB.NEW_SUB_DEV_TAB) {
      const selectedUnitTypeId =
        data.deviceCharacteristicUnitTypeId === nullString
          ? null
          : data.deviceCharacteristicUnitTypeId;
      const selectedUnitTypeVersionId =
        data.deviceCharacteristicUnitTypeVersionId === nullString
          ? null
          : data.deviceCharacteristicUnitTypeVersionId;

      dataContainer = {
        ...dataContainer,
        status: null,
        installationId: null,
        deviceType: 1,
        familyCode: data.familyCode === nullString ? null : data.familyCode,
        deviceCharacteristicUnitTypeId: selectedUnitTypeId,
        deviceCharacteristicUnitTypeVersionId: selectedUnitTypeVersionId
      };
    } else if (tabValue === DeviceViewConst.FORM_TAB.NEW_SYSTEM_TAB) {
      dataContainer = {
        ...dataContainer,
        status: null,
        deviceType: 3,
        deviceCharacteristicUnitTypeId: null,
        deviceCharacteristicUnitTypeVersionId: null
      };
    }

    return dataContainer;
  };

  const getSapProductNumberToSubmit = (data: any) => {
    let val = data.sapProductNumber;

    if (data.sapProductNumber === "") {
      val = null;
    }

    return val;
  };

  const getSubdeviceIdsToSubmit = () => {
    let dataContainer: any;

    const subDeviceIds = getAllChecked(systemSubDevices, "value");
    if (subDeviceIds && subDeviceIds.length > 0) {
      dataContainer = { subDeviceIds: subDeviceIds };
    }

    return dataContainer;
  };

  const saveMetadata = (deviceId = ""): Promise<any> => {
    if (metadata.length > 0) {
      return saveBatchMetadata(deviceId, metadata);
    }

    return new Promise<void>((resolve) => resolve());
  };

  const onSubmit = async (data: any): Promise<void> => {
    if (isModifyingMetadata) {
      return;
    }

    setIsSpinnerVisible(true);

    data = {
      ...data,
      ...getAdditionalDevicePropsToSubmit(data),
      ...getSubdeviceIdsToSubmit(),
      sapProductNumber: getSapProductNumberToSubmit(data),
      addressType: parseInt(data.addressType)
    };

    saveDevice(data)
      .then((res) => {
        if (res && res.Errors && res.Errors.Address) {
          setAddressErrorExists(res.Errors.Address);
          triggerValidation("address");
          setIsSpinnerVisible(false);
          return "";
        }

        if (res && res.DeviceTwinId) {
          setDeviceTwinIdErrorExists(res.DeviceTwinId[0]);
          triggerValidation("deviceTwinId");
          setIsSpinnerVisible(false);
          return "";
        }

        if (res && res.Gua) {
          setGuaErrorExists(res.Gua[0]);
          triggerValidation("gua");
          setIsSpinnerVisible(false);
          return "";
        }

        return res;
      })
      .then((devId = "") => {
        saveMetadata(devId)
          .then(() => {
            resetForm();
            const parentDeviceId = query.get("parentDeviceId");
            setIsSpinnerVisible(false);
            if (parentDeviceId) {
              history.push(DeviceViewPath.replace(":deviceId", parentDeviceId));
            } else if (formLimit === commDeviceOnlyKey) {
              redirect(devId);
            }
          })
          .catch(() => {
            resetForm();
            setIsSpinnerVisible(false);
          });
      })
      .catch(() => {
        setIsSpinnerVisible(false);
      });
  };

  const updateInstallation = (installationId: string): void => {
    if (installationId && installationId !== "") {
      if (tabValue === DeviceViewConst.FORM_TAB.NEW_SYSTEM_TAB) {
        getAllUnassignedSubdevicesInInstallation(`${installationId}`).then(
          (subDevices) => {
            setSystemSubDevices(
              getCheckedOptions(subDevices, "id", "deviceName")
            );
          }
        );
      }
      getInstallationById(installationId).then((ins) => {
        setValue("account", ins.rootAssetName);
      });
    } else {
      setValue("account", "");
    }
  };

  const GENIFields = (): JSX.Element => (
    <Grid item xs={12}>
      <NewTextField
        readOnly
        error={errors.familyCode}
        label="Family Code"
        name="familyCode"
        inputRef={register({ required: false })}
      />

      <Dropdown
        readOnly={unitTypes.length < 2}
        label="Unit Type"
        name="deviceCharacteristicUnitTypeId"
        data={unitTypes}
        control={control}
        error={errors.deviceCharacteristicUnitTypeId}
      />

      <Dropdown
        readOnly={unitVersions.length < 2}
        label="Unit Version"
        name="deviceCharacteristicUnitTypeVersionId"
        data={[...unitVersions].sort(
          (a, b) => parseInt(a.label) - parseInt(b.label)
        )}
        control={control}
        error={errors.deviceCharacteristicUnitTypeVersionId}
      />
    </Grid>
  );

  const firmwareSection = (): JSX.Element => (
    <Fragment>
      <NewTextField
        error={errors.firmwareVersion}
        label="Firmware Version"
        name="firmwareVersion"
        maxLength={8!}
        inputRef={register({
          required: false
        })}
      />
      <DatePicker
        label="Last Firmware Update"
        name="lastFirmwareUpdate"
        fullwith={true}
        control={control}
        value={lastFirmwareUpdate!}
        onChange={(date) => {
          setLastFirmwareUpdate(date);
        }}
      />
    </Fragment>
  );

  const accountAndInstallationSection = (tabValue: number): JSX.Element => {
    const commDeviceAutoCompleteProps = {
      disabled: formLimit === commDeviceOnlyKey,
      getData: getUnassignedInstallations,
      reset: autocompleteInstallationIdReset
    };

    const systemDeviceAutoCompleteProps = {
      getData: getAllInstallations,
      reset: autocompleteSystemInstallationIdReset
    };

    const isSystemTab = tabValue === DeviceViewConst.FORM_TAB.NEW_SYSTEM_TAB;
    const shouldShowSystemSubdevicesList =
      isSystemTab && systemSubDevices.length > 0;

    const autoCompleteProps = isSystemTab
      ? systemDeviceAutoCompleteProps
      : commDeviceAutoCompleteProps;

    return (
      <Box m={4} mt={-2}>
        <NewTextField
          error={errors.account}
          label="Account"
          name="account"
          inputRef={register({
            required: false
          })}
          readOnly
        />
        <AutoComplete
          {...autoCompleteProps}
          error={errors.installationId}
          label="Installation"
          name="installationId"
          labelName="installationName"
          valueName="id"
          inputRef={register({ required: false })}
          onChange={updateInstallation}
        />
        {shouldShowSystemSubdevicesList && (
          <List
            horizontal
            checkable
            data={systemSubDevices}
            setData={setSystemSubDevices}
            label="Assign Sub Devices to System"
          />
        )}
      </Box>
    );
  };

  const metadataTableComponent = (
    <Paper>
      {isSpinnerVisible ? (
        <></>
      ) : (
        <MetadataTable
          metadata={metadata}
          onChangeMetadata={(data: MetadataProps[]): void => setMetadata(data)}
          onClickActionButton={(name): void => {
            if (name === "Add" && !isModifyingMetadata) {
              setIsModifyingMetadata(true);
            } else {
              setIsModifyingMetadata(false);
            }
          }}
        />
      )}
    </Paper>
  );

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.tabs}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab
              label="New Communication Device"
              disabled={formLimit === subDeviceOnlyKey}
              classes={{
                root: classes.tab
              }}
            />
            <Tab
              label="New Sub Device"
              disabled={formLimit === commDeviceOnlyKey}
              classes={{
                root: classes.tab
              }}
            />
            <Tab
              label="New System"
              disabled={
                formLimit === subDeviceOnlyKey ||
                formLimit === commDeviceOnlyKey
              }
              classes={{
                root: classes.tab
              }}
            />
          </Tabs>

          <Grid container spacing={3}>
            {tabValue === DeviceViewConst.FORM_TAB.NEW_SUB_DEV_TAB && (
              <Grid item xs={12}>
                <Box m={4} mb={-6}>
                  <AutoComplete
                    error={errors.parentDeviceId}
                    label="Parent Device"
                    name="parentDeviceId"
                    labelName="deviceName"
                    valueName="id"
                    getData={getAllDevicesDropdown}
                    inputRef={register({ required: false })}
                    reset={autocompleteParentDeviceIdReset}
                    onChange={updateParentDevice}
                    defaultValue={parentDeviceIdDefaultValue}
                  />
                  {systems.length > 0 && (
                    <Dropdown
                      label="System"
                      name="systemId"
                      rules={{ required: false }}
                      data={systems}
                      control={control}
                      error={errors.systemId}
                    />
                  )}
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Box my={3} mx={4}>
                <NewTextField
                  error={errors.deviceName}
                  label="Name"
                  name="deviceName"
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired
                  })}
                />
                <Dropdown
                  label="Product Name"
                  name="deviceProductName"
                  rules={{ required: FormErrorMessages.fieldRequired }}
                  data={deviceProductNames}
                  control={control}
                  error={errors.deviceProductName}
                />
                {tabValue !== DeviceViewConst.FORM_TAB.NEW_COMM_DEV_TAB && (
                  <Dropdown
                    readOnly
                    label="Category"
                    name="deviceCategory"
                    rules={{ required: FormErrorMessages.fieldRequired }}
                    data={deviceCategories}
                    control={control}
                    error={errors.deviceCategory}
                  />
                )}
                <Dropdown
                  readOnly
                  label="Type"
                  name="deviceType"
                  rules={{ required: FormErrorMessages.fieldRequired }}
                  data={deviceTypes}
                  control={control}
                  error={errors.deviceType}
                />
                <Dropdown
                  readOnly
                  label="Vendor"
                  name="vendor"
                  rules={{ required: false }}
                  data={vendors}
                  control={control}
                  error={errors.vendor}
                />

                {tabValue === DeviceViewConst.FORM_TAB.NEW_COMM_DEV_TAB && (
                  <Dropdown
                    label="Status"
                    name="status"
                    rules={{ required: FormErrorMessages.fieldRequired }}
                    data={statuses}
                    control={control}
                    error={errors.status}
                  />
                )}

                {tabValue === DeviceViewConst.FORM_TAB.NEW_SUB_DEV_TAB &&
                  GENIFields()}
                {tabValue === DeviceViewConst.FORM_TAB.NEW_COMM_DEV_TAB &&
                  firmwareSection()}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box my={3} mx={4}>
                {tabValue === DeviceViewConst.FORM_TAB.NEW_COMM_DEV_TAB && (
                  <div>
                    <NewTextField
                      error={errors.protocol}
                      label="Protocol"
                      name="protocol"
                      inputRef={register({
                        required: false
                      })}
                    />
                    <NewTextField
                      error={errors.offering}
                      label="Offering"
                      name="offering"
                      inputRef={register({
                        required: false
                      })}
                    />
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={9}>
                        <NewTextField
                          error={errors.address}
                          label="Address"
                          name="address"
                          inputRef={register({
                            required: FormErrorMessages.fieldRequired,
                            validate: (value) => {
                              if (addressErrorExists !== "") {
                                return addressErrorExists;
                              }

                              return validateAddressByAddressType(
                                value,
                                values.addressType as string
                              );
                            }
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Dropdown
                          label="Type"
                          name="addressType"
                          rules={{ required: FormErrorMessages.fieldRequired }}
                          data={addressTypes}
                          control={control}
                          error={errors.addressType}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
                <NewTextField
                  error={errors.sapProductNumber}
                  label="SAP Product Number"
                  name="sapProductNumber"
                  inputRef={register({
                    required: false,
                    maxLength: {
                      value: 8,
                      message: FormErrorMessages.invalidSapNumber
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9]*$/i,
                      message: FormErrorMessages.invalidSapNumber
                    }
                  })}
                />
                <NewTextField
                  error={errors.gua}
                  label="GUA"
                  name="gua"
                  inputRef={register({
                    required: false,
                    validate: () => {
                      if (guaErrorExists !== "") {
                        return guaErrorExists;
                      }
                    }
                  })}
                />{" "}
                {tabValue === DeviceViewConst.FORM_TAB.NEW_SUB_DEV_TAB &&
                  firmwareSection()}
                {tabValue === DeviceViewConst.FORM_TAB.NEW_COMM_DEV_TAB && (
                  <div>
                    <NewTextField
                      error={errors.iccId}
                      label="ICCID"
                      name="iccId"
                      inputRef={register({
                        required: false,
                        minLength: {
                          value: 20,
                          message: FormErrorMessages.invalidIccId
                        },
                        maxLength: {
                          value: 20,
                          message: FormErrorMessages.invalidIccId
                        }
                      })}
                    />
                  </div>
                )}
                <NewTextField
                  error={errors.deviceTwinId}
                  label="Device Twin ID"
                  name="deviceTwinId"
                  inputRef={register({
                    required: false,
                    validate: () => {
                      if (deviceTwinIdErrorExists !== "") {
                        return deviceTwinIdErrorExists;
                      }
                    }
                  })}
                />
              </Box>
              {tabValue === DeviceViewConst.FORM_TAB.NEW_SUB_DEV_TAB && (
                <Box m={4} mt={-2}>
                  {metadataTableComponent}
                </Box>
              )}
            </Grid>

            {tabValue === DeviceViewConst.FORM_TAB.NEW_COMM_DEV_TAB && (
              <Grid item xs={6}>
                {accountAndInstallationSection(
                  DeviceViewConst.FORM_TAB.NEW_COMM_DEV_TAB
                )}
              </Grid>
            )}
            {tabValue === DeviceViewConst.FORM_TAB.NEW_SYSTEM_TAB && (
              <Grid item xs={6}>
                {accountAndInstallationSection(
                  DeviceViewConst.FORM_TAB.NEW_SYSTEM_TAB
                )}
              </Grid>
            )}
            {tabValue !== DeviceViewConst.FORM_TAB.NEW_SUB_DEV_TAB && (
              <Grid item xs={12} md={6}>
                <Box m={4} mt={-2}>
                  {metadataTableComponent}
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>

        <FloatingActionButtonList>
          <FloatingActionButton type="submit">
            <SaveIcon />
            Save
          </FloatingActionButton>
        </FloatingActionButtonList>
      </form>
    </MainBarDashboard>
  );
};

export default DeviceForm;
