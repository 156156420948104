export const AssetsRootPath = "/assets";
export const AccountsUpdatePath = "/assets/accounts/:id";
export const AccountsCreatePath = "/assets/accounts/create";
export const SectionCreatePath = "/assets/sections/create";
export const SectionUpdatePath = "/assets/sections/:id";
export const InstallationsCreatePath = "/assets/installations/create";
export const InstallationsCreateWithDevicePath =
  "/assets/installations/create/device/:deviceId";
export const InstallationsUpdatePath = "/assets/installations/:installationId";
export const InstallationsUpdateWithDevicePath =
  "/assets/installations/:installationId/device/:deviceId";
export const DigitalOfferingRootPath = "/digital-offering";
export const DigitalOfferingCreatePath = "/digital-offering/create";
export const DigitalOfferingViewPath = "/digital-offering/:digitalOfferingId";
export const SubscriptionsRootPath = "/subscriptions";
export const DeviceRootPath = "/devices";
export const DeviceFormPath = "/devices/create";
export const DeviceSubDeviceFormPath = "/devices/subdevice/create";
export const DeviceCommDeviceFormPath = "/devices/commdevice/create";
export const DeviceViewPath = "/devices/:deviceId";
export const UsersRootPath = "/users";
export const UsersCreatePath = "/users/create";
export const UsersUpdatePath = "/users/:userId";
export const UsersAssetsCreatePath = "/users/:userId/assets/create";
export const UsersAssetsUpdatePath = "/users/:userId/assets/:hierarchyId";
export const UsersAssetsRolesPath = "/users/:userId/assets/:hierarchyId/roles";
export const ServiceGroupRootPath = "/service-group";
export const AuthorizationRootPath = "/authorization";
export const AuthorizationOverviewPath = "/authorization/overview";
export const AdministrationPath = "/authorization/administration";
export const AdministrationFormPath =
  "/authorization/administration/user/create";
export const AdministrationViewPath =
  "/authorization/administration/user/:userId";
