import React from 'react';

import Layout from './DashboardLayout';

const DashboardLayout = (props: any) => {
  return (
    <Layout {...props} />
  );
};

export default DashboardLayout;
