import React, { ReactElement, useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

import MainBarDashboard from "components/MainBarDashboard";
import TextField from "components/TextField";
import AutoComplete from "components/AutoComplete";
import List, { ICheckedItemList } from "components/List";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import DialogModal from "components/DialogModal";
import PrivateComponent from "components/PrivateComponent";
import { disableLogic } from "../Administration";

import { ApplicationsContext } from "provider/ApplicationsProvider";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import SaveIcon from "@material-ui/icons/Save";

import { getAzureIds, saveConfigUser } from "./Actions";
import { getInternalRoles } from "../Actions";

import {
  getCheckedOptions,
  getAllChecked,
  objectPropertyConversion,
} from "utils";

import { FormErrorMessages, AccessErrorMessages } from "constants/enum";
import {
  VALIDATION_ERROR_MESSAGES,
  InternalRoles,
  InternalRolePermissions,
} from "constants/constant";

import { AdministrationPath } from "routes/paths";

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
  },
  tab: {
    minWidth: "auto",
  },
}));

const AdministrationForm = (): ReactElement => {
  const classes = useStyles();

  const breadcrumbs = [
    {
      label: "Administration",
      href: AdministrationPath,
    },
    {
      label: "Create User",
    },
  ];

  const [digitalProducts, setDigitalProducts] = useState<ICheckedItemList[]>(
    []
  );
  const [internalRoles, setInternalRoles] = useState<ICheckedItemList[]>([]);
  const [applications, getApplications] = useContext(ApplicationsContext);
  const [isInternalRolesSkeleton, setIsInternalRolesSkeleton] = useState<
    boolean
  >(true);
  const [isDigitalProductsSkeleton, setIsDigitalProductsSkeleton] = useState<
    boolean
  >(true);
  const [objectId, setObjectId] = useState<string>("");
  const [autocompleteUserIdReset, setAutocompleteUserIdReset] = useState<
    number
  >();
  const [showDigitalProducts, setShowDigitalProducts] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const resetInternalRoles = () => {
    getInternalRoles().then((roles) => {
      setIsInternalRolesSkeleton(false);
      setInternalRoles(getCheckedOptions(roles.roles, "roleId", "name"));
    });
  }

  useEffect(() => {
    getApplications();
    resetInternalRoles();
  }, [getApplications]);

  useEffect(() => {
    if (!isEmpty(applications)) {
      setIsDigitalProductsSkeleton(false);
      setDigitalProducts(getCheckedOptions(applications, "id", "abbreviation"));
    }
  }, [applications]);

  useEffect(() => {
    const checkedRoleLabels = getAllChecked(internalRoles, "label");
    if (
      checkedRoleLabels.length > 0 &&
      (checkedRoleLabels.includes(InternalRoles.APPLICATION_SUPPORT) ||
        checkedRoleLabels.includes(InternalRoles.GIC_ADMIN))
    ) {
      setShowDigitalProducts(true);
    } else {
      setShowDigitalProducts(false);
    }
  }, [internalRoles]);

  const {
    handleSubmit,
    register,
    errors,
    setValue,
    setError,
    clearError,
  } = useForm();

  const resetForm = () => {
    setIsDigitalProductsSkeleton(true);
    setIsInternalRolesSkeleton(true);
    setObjectId("");
    setValue("searchName", "");
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("email", "");
    setAutocompleteUserIdReset(Date.now());
    if (!isEmpty(applications)) {
      setIsDigitalProductsSkeleton(false);
      setDigitalProducts(getCheckedOptions(applications, "id", "abbreviation"));
    }
    resetInternalRoles();
  };

  const onSubmit = (data: any) => {
    setIsSaveDisabled(true);
    const checkedDigitalProducts = getAllChecked(digitalProducts, "value");
    if (showDigitalProducts && checkedDigitalProducts.length > 0) {
      data.applications = checkedDigitalProducts;
    } else if (showDigitalProducts) {
      setErrorDialog(true);
      setIsSaveDisabled(false);
      return;
    }
    const checkedRoles = objectPropertyConversion(
      getAllChecked(internalRoles),
      [
        { from: "label", to: "name" },
        { from: "value", to: "roleId" },
      ]
    );
    if (checkedRoles.length > 0) {
      data.roles = checkedRoles;
    }
    data.objectId = objectId;
    data.isAdmin = true;
    saveConfigUser(data).then((res) => {
      if (
        res &&
        res.ObjectId &&
        res.ObjectId[0] === "'Object Id' already exists."
      ) {
        setError("searchName", "manual", VALIDATION_ERROR_MESSAGES.USER_EXISTS);
      } else {
        resetForm();
      }
      setIsSaveDisabled(false);
    });
  };

  const selectAzureAd = (obj: { [k: string]: any }) => {
    clearError("searchName");
    clearError("firstName");
    clearError("lastName");
    clearError("email");
    setValue("firstName", obj.firstName);
    setValue("lastName", obj.lastName);
    setValue("email", obj.email);
    setObjectId(obj.objectId);
  };

  return (
    <MainBarDashboard breadcrumbs={breadcrumbs}>
      <PrivateComponent
        permissions={[InternalRolePermissions.MANAGE_CONFIG_USER]}
        errorMessage={AccessErrorMessages.noAccessToPage}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper className={classes.tabs}>
            <Tabs
              value={0}
              onChange={() => null}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="New User" />
            </Tabs>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box my={3} mx={4}>
                  <AutoComplete
                    error={errors.searchName}
                    label="Search Name"
                    name="searchName"
                    labelName="firstName"
                    labelNames={["firstName", "lastName"]}
                    valueName="objectId"
                    getData={getAzureIds}
                    inputRef={register({ required: false })}
                    onChangeObjParam={selectAzureAd}
                    reset={autocompleteUserIdReset}
                  />
                  <TextField
                    readOnly
                    error={errors.firstName}
                    label="First Name"
                    name="firstName"
                    inputRef={register({
                      required: FormErrorMessages.fieldRequired,
                    })}
                  />
                  <TextField
                    readOnly
                    error={errors.lastName}
                    label="Last Name"
                    name="lastName"
                    inputRef={register({
                      required: FormErrorMessages.fieldRequired,
                    })}
                  />
                  <TextField
                    readOnly
                    error={errors.email}
                    label="Email"
                    name="email"
                    inputRef={register({
                      required: FormErrorMessages.fieldRequired,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: FormErrorMessages.invalidEmail,
                      },
                    })}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box my={6} mx={4}>
                  <List
                    checkable
                    showSkeleton={isInternalRolesSkeleton}
                    data={internalRoles}
                    setData={setInternalRoles}
                    disableLogic={disableLogic}
                    label="Roles"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                {showDigitalProducts && (
                  <Box my={6} mx={4}>
                    <List
                      checkable
                      showSkeleton={isDigitalProductsSkeleton}
                      data={digitalProducts}
                      setData={setDigitalProducts}
                      label="Digital Products"
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Paper>
          <FloatingActionButtonList>
            <FloatingActionButton type="submit" disabled={isSaveDisabled}>
              <SaveIcon />
              Save
            </FloatingActionButton>
          </FloatingActionButtonList>
        </form>
      </PrivateComponent>
      <DialogModal
        title="Error!"
        message={`Selection of Digital Product is required for ${InternalRoles.APPLICATION_SUPPORT} and ${InternalRoles.GIC_ADMIN} roles.`}
        showModal={errorDialog}
        onOk={(): void => {return;}}
        onHide={(): void => {
          setErrorDialog(false);
        }}
        confirmButtonLabel="Ok"
      />
    </MainBarDashboard>
  );
};

export default AdministrationForm;
