import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { SubscriptionData } from "../type";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { toMonth, prependZeroToSingleDigits } from "../../../utils";

interface SubscriptionDetailsProps {
  subscription: SubscriptionData | undefined;
}

interface ReadOnlyFieldProps {
  label?: string;
  value?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: 0,
    },
    marginTopBottomZero: {
      marginTop: 0,
      marginBottom: 0,
    },
  })
);

const ReadOnlyField = ({
  label = "",
  value = "",
}: ReadOnlyFieldProps): JSX.Element => {
  return (
    <TextField
      fullWidth
      margin="normal"
      label={label}
      defaultValue={value}
      InputProps={{
        readOnly: true,
      }}
    />
  );
};

const getFormattedDateString = (d = "") => {
  const date = new Date(d);

  if (date.toString() !== "Invalid Date" && d !== null) {
    return `${date.getFullYear()}-${toMonth(
      date.getMonth()
    )}-${prependZeroToSingleDigits(date.getDate())}`;
  }
  return "";
};

const statusDisplayText = (status = "") => {
  return status;
};

const SubscriptionDetails = ({
  subscription,
}: SubscriptionDetailsProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid
        container
        spacing={2}
        item
        xs={12}
        md={6}
        className={classes.marginTopBottomZero}
      >
        <Grid item xs={12} md={12}>
          <ReadOnlyField
            label="Order Reference ID"
            value={subscription?.orderReferenceId}
          />
          <ReadOnlyField label="Account" value={subscription?.accountName} />
        </Grid>
        <Grid item xs={12} md={12}>
          <ReadOnlyField
            label="Installation"
            value={subscription?.installationName}
          />
          <ReadOnlyField
            label="Status"
            value={statusDisplayText(subscription?.status)}
          />
          <ReadOnlyField label="Reason" value={subscription?.reason} />
        </Grid>
        <Grid item xs={12} md={12}>
          <ReadOnlyField
            label="Subscription ID"
            value={subscription?.zuoraSubscriptionId}
          />
          <ReadOnlyField
            label="SAP Local Sold To ID"
            value={subscription?.sapLocalSoldToId}
          />
          <ReadOnlyField
            label="SAP Central Sold To ID"
            value={subscription?.sapCentralSoldToId}
          />
          <ReadOnlyField
            label="SAP Central Sold To Name"
            value={subscription?.sapCentralSoldToName}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        item
        xs={12}
        md={6}
        className={classes.marginTopBottomZero}
      >
        <Grid item xs={12} md={12}>
          <ReadOnlyField
            label="Digital Offering ID"
            value={subscription?.digitalOfferingId}
          />
          <ReadOnlyField
            label="Digital Offering"
            value={subscription?.digitalOfferingName}
          />
          <ReadOnlyField
            label="Activation Date"
            value={getFormattedDateString(subscription?.activationDate)}
          />
          <ReadOnlyField
            label="Deactivation Date"
            value={getFormattedDateString(subscription?.deactivationDate)}
          />
          <ReadOnlyField
            label="Timestamp"
            value={getFormattedDateString(subscription?.lastManualChangeDate)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubscriptionDetails;
