import React, { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Link from "@material-ui/core/Link";

import { IEnhancedTableProps, IHeadCell } from "./type";

const EnhancedTable = ({
  data,
  headCells,
  tableLabel,
  buttonGroup,
  pageIndex,
}: IEnhancedTableProps) => {
  const desc = <T extends unknown>(a: T, b: T, orderBy: keyof T) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const stableSort = <T extends unknown>(
    array: T[],
    cmp: (a: T, b: T) => number
  ) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  type Order = "asc" | "desc";

  const getSorting = <K extends keyof any>(
    order: Order,
    orderBy: K
  ): ((
    a: { [key in K]: number | string },
    b: { [key in K]: number | string }
  ) => number) => {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
      },
      paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
      },
      table: {
        minWidth: 750,
      },
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
      headerLabel: {
        fontWeight: "bold",
      },
      button: {
        margin: "auto !important",
      },
      link: {
        textAlign: "left",
      },
    })
  );

  interface EnhancedTableHeadProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: IHeadCell[];
  }

  const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
    const { classes, order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property: string) => (
      event: React.MouseEvent<unknown>
    ) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells
            .filter((headcell) => !headcell.hidden)
            .map((headCell, index) => (
              <TableCell
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  className={classes.headerLabel}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  };

  const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
      },
      highlight:
        theme.palette.type === "light"
          ? {
              color: theme.palette.secondary.main,
              backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
          : {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.dark,
            },
      title: {
        flex: "1 1 100%",
        padding: "5px 10px 0",
        color: theme.palette.primary.main,
        fontFamily: "GrundfosTheSans,Arial,sans-serif",
        fontSize: "25px",
      },
    })
  );

  const EnhancedTableToolbar = () => {
    const classesToolbar = useToolbarStyles();

    return (
      <Typography className={classesToolbar.title} variant="h6" id="tableTitle">
        {tableLabel}
      </Typography>
    );
  };

  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("digitalOfferingId");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (pageIndex != null) {
      setPage(pageIndex);
    }
  }, [pageIndex, data]);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, (data ? data.length : 0) - page * rowsPerPage);

  const bGroup = buttonGroup;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {bGroup && buttonGroup}
        {tableLabel && <EnhancedTableToolbar />}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data ? data.length : 0}
              headCells={headCells}
            />
            <TableBody>
              {!isEmpty(data) &&
                stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {headCells
                          .filter((headcell) => !headcell.hidden)
                          .map((cell, key) => {
                            const refOnClickParam = {};
                            if (cell.referenceIds) {
                              cell.referenceIds.map((refId: string) => {
                                return ((refOnClickParam as any)[refId] =
                                  row[refId]);
                              });
                            }
                            return (
                              <TableCell key={key}>
                                {cell.isClickable ? (
                                  <Link
                                    component="button"
                                    onClick={() => {
                                      cell.onClick &&
                                        cell.referenceIds &&
                                        cell.onClick(refOnClickParam);
                                    }}
                                    className={classes.link}
                                  >
                                    {row[cell.fieldName]}
                                  </Link>
                                ) : (
                                  row[cell.fieldName]
                                )}
                                {cell.hasAction ? (
                                  <ButtonGroup
                                    size="small"
                                    aria-label="small outlined button group"
                                  >
                                    {cell.actions.map(
                                      (action: any, key: number) => {
                                        const refOnClickParamActions = {};
                                        if (action.referenceIds) {
                                          action.referenceIds.map(
                                            (refId: string) => {
                                              return ((refOnClickParamActions as any)[
                                                refId
                                              ] = row[refId]);
                                            }
                                          );
                                        }
                                        return (
                                          <Button
                                            key={key}
                                            onClick={() =>
                                              action.onSubmit(
                                                refOnClickParamActions
                                              )
                                            }
                                            classes={{
                                              startIcon: classes.button,
                                            }}
                                            startIcon={<action.icon />}
                                          >
                                            {""}
                                          </Button>
                                        );
                                      }
                                    )}
                                  </ButtonGroup>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell
                    colSpan={
                      headCells.filter((headcell) => !headcell.hidden).length
                    }
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data ? data.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default EnhancedTable;
