import React, { useContext } from "react";
import isEmpty from "lodash/isEmpty";
import UserCreateForm from "./Form";
import { UserContext } from "provider/UserProvider";
import MainBarDashboard from "components/MainBarDashboard";

import { UsersRootPath } from "routes/paths";

const UserCreate = () => {
  const { updateUserContext } = useContext(UserContext);
  const [user] = updateUserContext;

  const getAccordionTitle = (): string => {
    if (user && !isEmpty(user)) {
      return "Update";
    } else {
      return "Create";
    }
  };

  const breadcrumbs = [
    {
      label: "Users",
      href: UsersRootPath
    },
    {
      label: getAccordionTitle().toString()
    }
  ];

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <UserCreateForm />
    </MainBarDashboard>
  );
};

export default UserCreate;
