import React from "react";
import { useHistory } from "react-router-dom";

import AddIcon from "@material-ui/icons/Add";

import MainBarDashboard from "components/MainBarDashboard";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import EnhancedPaginatedTable from "components/EnhancedPaginatedTable";
import { IHeadCell } from "components/EnhancedPaginatedTable/type";

import { RemoveRedEyeOutlined } from "@material-ui/icons";

import {
  DigitalOfferingCreatePath,
  DigitalOfferingViewPath
} from "routes/paths";

import { getDigitalOfferings } from "./Actions";

const DigitalOfferingList = () => {
  const history = useHistory();
  const breadcrumbs = [
    {
      label: "Digital Offerings"
    }
  ];

  const viewDigitalOffering = (obj: { [k: string]: any }) => {
    history.push(DigitalOfferingViewPath.replace(":digitalOfferingId", obj.id));
  };

  const headCells: IHeadCell[] = [
    {
      id: "id",
      disablePadding: false,
      label: "ID",
      fieldName: "id",
      hidden: true
    },
    {
      id: "applicationName",
      disablePadding: false,
      label: "Digital Product",
      fieldName: "applicationName"
    },
    {
      id: "digitalOfferingName",
      disablePadding: false,
      label: "Digital Offering",
      fieldName: "digitalOfferingName"
    },
    {
      id: "sapNumber",
      disablePadding: false,
      label: "SAP Product #",
      fieldName: "sapNumber"
    },
    {
      id: "actionId",
      disablePadding: false,
      label: "Action",
      hasAction: true,
      actions: [
        {
          onSubmit: viewDigitalOffering,
          icon: RemoveRedEyeOutlined,
          label: "Hierarchy",
          referenceIds: ["id"]
        }
      ]
    }
  ];

  const goToDigitalOfferingForm = () => {
    history.push(DigitalOfferingCreatePath);
  };

  const onSearch = (keyword: string) => {
    if (keyword) {
      console.log(keyword);
    }
  };

  return (
    <MainBarDashboard
      search
      searchPlaceholder="Search Digital Offering"
      onSearch={onSearch}
      breadcrumbs={breadcrumbs}
    >
      <EnhancedPaginatedTable
        getData={getDigitalOfferings}
        headCells={headCells}
      />
      <FloatingActionButtonList>
        <FloatingActionButton onClick={goToDigitalOfferingForm}>
          <AddIcon />
          Digital Offering
        </FloatingActionButton>
      </FloatingActionButtonList>
    </MainBarDashboard>
  );
};

export default DigitalOfferingList;
