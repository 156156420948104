import * as React from "react";
import "./styles.css";

const Loader = () => (
  <div className="loaderContainer clearfix">
    <div className="loader">
      <svg
        version="1.1"
        x="0px"
        y="0px"
        width="150"
        height="70px"
        viewBox="0 0 150 70"
      >
        <rect
          x="0"
          y="0"
          width="30"
          height="70"
          fill="#10477c"
          opacity="0.2"
          className={["bar", "bar1"].join(" ")}
        />
        <rect
          x="60"
          y="0"
          width="30"
          height="70"
          fill="#10477c"
          opacity="0.2"
          className={["bar", "bar2"].join(" ")}
        />
        <rect
          x="120"
          y="0"
          width="30"
          height="70"
          fill="#10477c"
          opacity="0.2"
          className={["bar", "bar3"].join(" ")}
        />
      </svg>
    </div>
  </div>
);

export default Loader;
