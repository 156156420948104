import React from "react";
import { renderRoutes } from "react-router-config";

import { IElementRouteAttribute } from "routes/type";
import { AuthorizationOverviewPath, AdministrationPath } from "routes/paths";

import Layout from "layouts";

import { InternalRolePermissions } from "constants/constant";

const Authorization = ({ route }: IElementRouteAttribute) => {
  const tabs = [
    {
      label: "Overview",
      path: AuthorizationOverviewPath,
    },
    {
      label: "Administration",
      path: AdministrationPath,
      permissions: [
        InternalRolePermissions.MANAGE_CONFIG_USER,
        InternalRolePermissions.VIEW_CONFIG_USER,
      ],
    },
  ];

  return (
    <Layout>
      {renderRoutes(route.routes, {
        tabs: tabs,
      })}
    </Layout>
  );
};

export default Authorization;
