import React, { useEffect, useState, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

import { ErrorMessage, Control } from "react-hook-form";

import isEmpty from "lodash/isEmpty";

import Dropdown from "../../../../../components/MUIDropdown";
import ExpansionPanel from "../../../../../components/ExpansionPanel";
import { ICountry, IDropdown } from "../../../../../components/type";

import NewTextField from "../../../../../components/TextField";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { FormErrorMessages } from "../../../../../constants/enum";
import { VALIDATION_ERROR_MESSAGES } from "../../../../../constants/constant";

import { getAccountAndSection } from "../../../../../actions/installationsPageActions";

import { RawInstallationData } from "../type";

import { getOptions, coordinatesValidation } from "../utils";
import { isValid } from "utils";

const useStyles = makeStyles(() => ({
  errorStyle: {
    color: "#f44336",
    fontSize: "0.75rem"
  }
}));

const useHierarchyList = () => {
  const [hierarchiesList, setHierarchyList] = useState<IDropdown[]>([]);

  useEffect(() => {
    getAccountAndSection().then((h) => {
      setHierarchyList(h.hierarchies);
    });
  }, []);

  return hierarchiesList;
};

const GeneralSection = ({
  errors,
  register,
  createInstallation,
  setCreateInstallation,
  savedFormInstallation,
  installationToBeUpdated,
  immediateParentId,
  onChangeParentId,
  countries,
  control,
  setValue,
  isFromDeviceCreation
}: {
  errors: any;
  register: any;
  createInstallation: any;
  setCreateInstallation: any;
  savedFormInstallation: any;
  installationToBeUpdated: RawInstallationData;
  immediateParentId: string;
  onChangeParentId: (
    value: any,
    rootValue: string | null,
    parentId: string | null
  ) => void;
  countries: ICountry[];
  control: Control<any>;
  setValue: any;
  isFromDeviceCreation: () => boolean;
}) => {
  const classes = useStyles();
  const [isStatusActive, setIsStatusActive] = useState<boolean | undefined>();

  const hierarchiesList = useHierarchyList();

  const getImmediateParent = (): IDropdown[] => {
    const parentValue =
      createInstallation.isQuickCreate && installationToBeUpdated === undefined
        ? createInstallation.parentId
        : installationToBeUpdated !== undefined && isEmpty(immediateParentId)
        ? installationToBeUpdated?.hierarchy.parentId
        : immediateParentId;

    return hierarchiesList.filter((e) => e.value === parentValue);
  };

  const handleImmediateParentReadOnly = (): boolean => {
    return isValid(installationToBeUpdated?.hierarchy.parentId);
  };

  const shouldReasonTextareaBeDisabled = () => {
    if (typeof isStatusActive === "boolean") {
      return false;
    }
    return true;
  };

  const isReasonRequired = (): boolean => {
    if (typeof isStatusActive === "boolean") {
      return !isStatusActive;
    }

    return false;
  };

  const reasonTextareaValidation = (value: string): boolean => {
    if (value === "") {
      return value === "" && !isReasonRequired();
    }

    return true;
  };

  const getImmediateParentBasedOnChange = (
    value: string | null | undefined = ""
  ): IDropdown[] => {
    const parentValue =
      createInstallation.isQuickCreate && installationToBeUpdated === undefined
        ? createInstallation.parentId
        : installationToBeUpdated !== undefined && isEmpty(value)
        ? installationToBeUpdated?.hierarchy.parentId
        : value;

    return hierarchiesList.filter((e) => e.value === parentValue);
  };

  const getParentIdValueBasedOnChange = (value: string | null) => {
    return getImmediateParentBasedOnChange(value).length > 0 && isEmpty(value)
      ? getImmediateParentBasedOnChange(value)[0].value
      : value;
  };

  const getRootValueBasedOnChange = (value = "") => {
    if (
      getImmediateParentBasedOnChange(value).length > 0 &&
      getImmediateParentBasedOnChange(value)[0].label.includes("Account")
    ) {
      return getImmediateParentBasedOnChange(value)[0].value;
    } else if (getImmediateParentBasedOnChange(value).length === 0) {
      return null;
    } else {
      return getImmediateParentBasedOnChange(value)[0].rootId;
    }
  };

  useEffect(() => {
    const immediateParentDropdownValue = getImmediateParent()[0];

    if (immediateParentDropdownValue) {
      const rootId = getRootValueBasedOnChange(
        immediateParentDropdownValue["value"]
      );
      const parentId = getParentIdValueBasedOnChange(
        immediateParentDropdownValue["value"]
      );
      onChangeParentId(parentId, rootId, parentId);
    }
  }, [hierarchiesList]);

  useEffect(() => {
    return () => {
      setCreateInstallation([]);
    };
  }, []);

  const setStatusDropdownState = (value: "0" | "1" | "") => {
    if (value === "") {
      setIsStatusActive(undefined);
    } else {
      setIsStatusActive(value === "1");
    }
  };

  useEffect(() => {
    if (isFromDeviceCreation()) {
      setStatusDropdownState(savedFormInstallation.status);
    } else {
      if (!isEmpty(installationToBeUpdated)) {
        setIsStatusActive(installationToBeUpdated.status);
      }
    }
  }, [installationToBeUpdated]);

  return (
    <ExpansionPanel label="General">
      <NewTextField
        error={errors.installationName}
        label="Installation Name"
        name="installationName"
        inputRef={register({
          required: FormErrorMessages.fieldRequired
        })}
      />
      {!isEmpty(hierarchiesList) ? (
        <Autocomplete
          id="immediateParent"
          defaultValue={getImmediateParent()[0]}
          disabled={handleImmediateParentReadOnly()}
          disableClearable={true}
          options={hierarchiesList}
          getOptionLabel={(option) => (!isEmpty(option) ? option.label : "")}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Immediate Parent"
              margin="normal"
              fullWidth
              name="parentId"
            />
          )}
          onChange={(event: object, value: any) =>
            onChangeParentId(
              value.value,
              getRootValueBasedOnChange(value.value),
              getParentIdValueBasedOnChange(value.value)
            )
          }
        />
      ) : (
        <div>
          <Skeleton variant="rect" height={40} />
        </div>
      )}
      <NewTextField
        label="SAP Ship-to ID"
        name="sapShipToId"
        maxLength={10}
        inputRef={register({
          required: false,
          pattern: {
            value: /^[0-9]+$/,
            message: VALIDATION_ERROR_MESSAGES.DIGITS_ONLY
          }
        })}
      />
      <ErrorMessage errors={errors} name="sapShipToId">
        {({ messages }): ReactNode =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <p className={classes.errorStyle} key={type}>
              {message}
            </p>
          ))
        }
      </ErrorMessage>
      <NewTextField label="Street" name="street" inputRef={register} />
      <NewTextField label="City" name="city" inputRef={register} />
      <NewTextField label="State" name="state" inputRef={register} />
      <NewTextField label="Postal Code" name="postalCode" inputRef={register} />
      <Dropdown
        label="Country"
        name="countryId"
        control={control}
        data={getOptions(countries, "id", "countryName")}
      />

      <Grid container spacing={3}>
        <Grid item md={6} xs={6}>
          <NewTextField
            label="Latitude"
            name="latitude"
            inputRef={register({
              required: false,
              validate: coordinatesValidation("lat")
            })}
          />
          <ErrorMessage errors={errors} name="latitude">
            {({ messages }): ReactNode =>
              messages &&
              Object.entries(messages).map(([type, message]) => (
                <p className={classes.errorStyle} key={type}>
                  {message}
                </p>
              ))
            }
          </ErrorMessage>
        </Grid>
        <Grid item md={6} xs={6}>
          <NewTextField
            label="Longitude"
            name="longitude"
            inputRef={register({
              required: false,
              validate: coordinatesValidation("long")
            })}
          />
          <ErrorMessage errors={errors} name="longitude">
            {({ messages }): ReactNode =>
              messages &&
              Object.entries(messages).map(([type, message]) => (
                <p className={classes.errorStyle} key={type}>
                  {message}
                </p>
              ))
            }
          </ErrorMessage>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={6} xs={6}>
          <Dropdown
            label="Status"
            name="status"
            control={control}
            data={[
              { value: "1", label: "Active" },
              { value: "0", label: "Not Active" }
            ]}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              setIsStatusActive(event.target.value === "1");
              setValue("reason", "");
            }}
            rules={{ required: false }}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          <TextField
            disabled={shouldReasonTextareaBeDisabled()}
            error={errors.reason !== undefined}
            helperText={
              errors.reason ? VALIDATION_ERROR_MESSAGES.REASON_REQUIRED : ""
            }
            fullWidth
            name="reason"
            label="Reason"
            multiline={true}
            variant="outlined"
            rows={1}
            rowsMax={5}
            inputProps={{ maxLength: 255 }}
            inputRef={register({
              validate: (value: string) =>
                reasonTextareaValidation(value) ||
                VALIDATION_ERROR_MESSAGES.REASON_REQUIRED
            })}
          />
        </Grid>
      </Grid>
    </ExpansionPanel>
  );
};

export default GeneralSection;
