import { adalApiFetch } from "../clientApp/configAdal";
import { endPoints } from "../clientApp/endPoints";
import Toaster from "components/Toaster";
import { IHierarchy } from "../components/type";

const headerValues = { "content-type": "application/json" };
const errorHeaderText = "Error occured while processing your request!";
const attachSuccessText = "Successfully Attached";

export const getSections = () => {
  return adalApiFetch(fetch, `${endPoints.hierarchies}/Sections/Orphans`, {
    method: "GET",
    headers: headerValues,
  }).then((res) => res.json());
};

export const getUserHierarchyExist = (id = "") => {
  return adalApiFetch(fetch, `${endPoints.userHierarchies}/${id}/exists`, {
    method: "GET",
    headers: headerValues,
  })
    .then((res) => res)
    .then((response) => response.text())
    .catch((e) => {
      throw e;
    });
};

export const patchSection = (sectionDetails: any) => {
  return adalApiFetch(fetch, `${endPoints.hierarchies}/section/attach`, {
    method: "PATCH",
    headers: headerValues,
    body: JSON.stringify(sectionDetails),
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](
          errorHeaderText,
          "Error Attaching Section to Hierarchy"
        );
        throw Error(response.statusText);
      } else {
        Toaster["success"](attachSuccessText);
        return response;
      }
    })
    .catch(function (error) {
      Toaster["error"](errorHeaderText, "Error Attaching Section to Hierarchy");
      throw error;
    });
};

export const getSectionById = (id: string) => {
  return adalApiFetch(fetch, `${endPoints.hierarchies}/Get/${id}`, {
    method: "GET",
    headers: headerValues,
  }).then((res) => {
    return res.json();
  });
};

export const createSection = (SectionDetails: IHierarchy) => {
  return adalApiFetch(fetch, `${endPoints.hierarchies}/Create`, {
    method: "POST",
    headers: headerValues,
    body: JSON.stringify(SectionDetails),
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](errorHeaderText, "Error Saving");
      } else {
        Toaster["success"]("Successfully Added!");
      }
      return response;
    })
    .then((response) => response.json())
    .catch((e) => {
      throw e;
    });
};

export const editSection = (id: number, SectionDetails: IHierarchy) => {
  return adalApiFetch(fetch, `${endPoints.hierarchies}/update/${id}`, {
    method: "PUT",
    headers: headerValues,
    body: JSON.stringify(SectionDetails),
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](errorHeaderText, "Error Updating Section");
        throw Error(response.statusText);
      } else {
        Toaster["success"]("Successfully Updated");
        return response;
      }
    })
    .catch(function (error) {
      Toaster["error"](errorHeaderText, "Error Updating Section");
      throw error;
    });
};

export const deleteSection = (id: number) => {
  return adalApiFetch(fetch, `${endPoints.hierarchies}/delete/${id}`, {
    method: "DELETE",
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](errorHeaderText, "Error Deleting");
        throw Error(response.statusText);
      } else {
        Toaster["success"]("Successfully Deleted");
        return response.json();
      }
    })
    .catch(function (error) {
      Toaster["error"](errorHeaderText, "Error Deleting Section");
    });
};
