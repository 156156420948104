export const AssetType = {
  account: "Account",
  section: "Section",
  installation: "Installation",
};

export const AccountTabKeys = {
  accountOverview: "accountOverview",
  createNewAccount: "createNewAccount",
  updateAccount: "updateAccount",
};

export const UserScreens = {
  userAccount: {
    key: "userAccount",
    label: "USER ACCOUNT",
  },
  userList: {
    key: "userList",
    label: "USER LIST",
  },
  userDetails: {
    key: "userDetails",
    label: "",
  },
};

export const FormErrorMessages = {
  fieldRequired: "This field is required",
  invalidEmail: "Invalid email address",
  invalidSapNumber: "Invalid SAP Number",
  invalidMACAddress: "Invalid MAC Address",
  invalidIMEIAddress: "Invalid IMEI Address",
  invalidAssignedId: "Invalid Assigned ID",
  invalidIccId: "Invalid ICCID",
};

export const DateFormatType = {
  long: "long",
  short: "short",
};

export const DigitalOffering = {
  label: "Digital Offering",
};

export const AccountId = { id: "91183644-6730-ea11-a601-281878e92c65" };

export const AccessErrorMessages = {
  noAccessToData: `You don't have access to view this data.`,
  noAccessToPage: `You don't have access to view this page.`,
};
