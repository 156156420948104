import React, { useState, createContext } from "react";

export const SubscriptionsContext = createContext<any>(null);

export const SubscriptionsProvider = ({
  children
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [subscriptions, setSubscriptions] = useState([]);

  return (
    <SubscriptionsContext.Provider
      value={{ subscriptionsContext: [subscriptions, setSubscriptions] }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
};
