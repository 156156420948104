import React, { useState, useEffect, useContext } from "react";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router";

import CreateUpdateTreeView from "components/CreateUpdateTreeView";
import MainBarDashboard from "components/MainBarDashboard";
import PageHeader from "components/PageHeader";
import PaperHeading from "components/PaperHeading";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

import NewSectionModal from "pages/Section/SectionModal";
import NewInstallationModal from "pages/Installation/InstallationModal";
import { getAccount } from "../../../actions/accountsPageActions";
import { getInstallationById } from "../../../actions/installationsPageActions";

import InstallationDetailsForm from "../InstallationDetails/Form";
import { RawInstallationData } from "../InstallationDetails/Form/type";

import { AssetsRootPath, InstallationsUpdatePath } from "routes/paths";

import { SpinnerContext } from "provider/SpinnerProvider";
import { InstallationContext } from "provider/InstallationProvider";
import { AccountContext } from "provider/AccountProvider";
import { CountriesContext } from "provider/CountriesProvider";
import { AccountOverview as AccountsOverviewConst } from "constants/constant";

const UpdateInstallation = () => {
  const { updateAccountContext } = useContext(AccountContext);
  const [, setAccount] = updateAccountContext;
  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const { updateInstallationContext } = useContext(InstallationContext);
  const [installation, setInstallation] = updateInstallationContext;
  const [countries, getCountries] = useContext(CountriesContext);
  const [isInit, setIsInit] = useState(true);
  const { installationId } = useParams<{ installationId: string }>();
  const breadcrumbs = [
    {
      label: AccountsOverviewConst.BREADCRUMBS_LABEL,
      href: AssetsRootPath,
    },
    {
      label: `Installation Details (${installationId})`,
      href: InstallationsUpdatePath,
    },
  ];

  useEffect(() => {
    if (installationId) {
      setIsSpinnerVisible(true);
      getInstallationById(installationId).then((ins: RawInstallationData) => {
        setInstallation(ins);
        setIsInit(false);
        getAccount(ins.hierarchyId).then((account) => {
          setAccount(account);
          setIsSpinnerVisible(false);
        });
      });
    }
  }, []);

  useEffect(() => {
    getCountries();
    return () => {
      setAccount("");
    };
  }, []);

  const editComponent = isInit ? (
    <div></div>
  ) : (
    <InstallationDetailsForm
      installationData={installation}
      countries={countries}
    >
      <Box mb={3}>
        <Paper>
          <Box p={3}>
            <PaperHeading label="Installation Hierarchy" />
            <CreateUpdateTreeView />
          </Box>
        </Paper>
      </Box>
    </InstallationDetailsForm>
  );

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <PageHeader
        title={!isEmpty(installation) ? installation!.installationName : ""}
        subTitle={!isEmpty(installation) ? "Installation" : ""}
      >
        {" "}
      </PageHeader>
      {installation?.isCreate ? null : editComponent}
      <NewSectionModal />
      <NewInstallationModal />
    </MainBarDashboard>
  );
};
export default UpdateInstallation;
