import { adalApiFetch } from "clientApp/configAdal";
import { endPoints } from "clientApp/endPoints";
import { IDropdown } from "components/type";
import Toaster from "components/Toaster";

const headerValues = { "content-type": "application/json" };

export const getAuthorizations = async (page = 1, limit = 10, query = "") => {
  let queryParam = "";
  if (query !== "") {
    queryParam = `&searchquery=${query}`;
  }
  return adalApiFetch(
    fetch,
    `${endPoints.userHierarchies}/Users?page=${page}&limit=${limit}${queryParam}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json"
      }
    }
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      Toaster["error"](
        "Error occured while processing your request!",
        response.statusText
      );
      return { data: [] };
    })
    .catch((e) => {
      throw e;
    });
};

export const getAssetNames = async (searchQuery = ""): Promise<IDropdown[]> => {
  const injectSearchQuery =
    searchQuery !== "" ? `?searchquery=${searchQuery}&limit=25` : "";
  return adalApiFetch(
    fetch,
    `${endPoints.hierarchies}/GetHierarchiesPolicyPath${injectSearchQuery}`,
    {
      method: "GET",
      headers: headerValues
    }
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      Toaster["error"](
        "Error occured while processing your request!",
        response.statusText
      );
      return { data: [] };
    })
    .then((posts) => {
      return posts.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const getPermissions = async (
  hierarchyId = "",
  userId = "",
  roleId = ""
) => {
  return adalApiFetch(
    fetch,
    `${endPoints.userHierarchyRolesV2}/${hierarchyId}/user/${userId}/role/${roleId}/permissions`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json"
      }
    }
  )
  .then((response) => {
    if (response.ok) {
      return response.json();
    }

    Toaster["error"](
      "Error occured while processing your request!",
      response.statusText
    );
    return [];
  })
    .catch((e) => {
      throw e;
    });
};
