import React, { Fragment, useContext, useEffect } from "react";
import Dropdown from "../../../../../components/MUIDropdown";
import { ILabelValue } from "../../../../../components/type";
import ExpansionPanel from "components/ExpansionPanel";
import InstallationSubscriptionDetailsSection from "./InstallationSubscriptionDetailsSection";

import { ApplicationsContext } from "provider/ApplicationsProvider";

import {
  DigitalOfferingsSectionProps,
  DigitalOfferingsSubscriptionsFields
} from "../type";
import { Typography, Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { isEmpty } from "lodash";

const getOptions = (
  items: any[],
  id: string,
  label: string,
  typeName?: string
): ILabelValue[] => {
  const data: ILabelValue[] = [];
  items.forEach((i) => {
    const typeNameContainer = typeName ? `(${i[typeName]})` : "";
    const optionLabel = `${i[label]} ${typeNameContainer}`;

    data.push({
      label: optionLabel,
      value: i[id]
    });
  });

  return data;
};

const DigitalOfferingsSection = ({
  control,
  errors,
  digitalOfferingOptions,
  currentValues = [],
  onChangeApplication,
  onChangeValues
}: DigitalOfferingsSectionProps): JSX.Element => {
  const [applications, getApplications] = useContext(ApplicationsContext);

  useEffect(() => {
    getApplications();
  }, [getApplications]);

  const getDisabledFieldsStatus = (id: string) => {
    const item = currentValues.find((x) => x.digitalOfferingId === id);

    if (item) {
      return false;
    }

    return true;
  };

  const onChangeDigitalOfferingFields = (
    subscriptionData: DigitalOfferingsSubscriptionsFields,
    digitalOfferingOptionId: string
  ) => {
    const subscriptionsInfoCopy = [...currentValues];

    let subscriptionDetails = subscriptionsInfoCopy.find(
      (x) => x.digitalOfferingId === digitalOfferingOptionId
    );
    const i = subscriptionsInfoCopy
      .map((x) => {
        return x.digitalOfferingId;
      })
      .indexOf(digitalOfferingOptionId);

    if (i > -1) {
      subscriptionsInfoCopy.splice(i, 1);
    }

    subscriptionDetails = {
      ...subscriptionDetails,
      ...subscriptionData,
      digitalOfferingId: digitalOfferingOptionId
    };
    subscriptionsInfoCopy.push(subscriptionDetails);

    onChangeValues(subscriptionsInfoCopy);
  };

  const onCheckDigitalOffering = (
    isChecked: boolean,
    digitalOfferingOptionId: string
  ) => {
    const subscriptionsInfoCopy = [...currentValues];

    let subscriptionDetails = subscriptionsInfoCopy.find(
      (x) => x.digitalOfferingId === digitalOfferingOptionId
    );

    if (subscriptionDetails) {
      subscriptionDetails.digitalOfferingStatus = isChecked;

      onChangeDigitalOfferingFields(
        subscriptionDetails,
        digitalOfferingOptionId
      );
    } else {
      subscriptionDetails = {
        activationDate: "",
        deactivationDate: "",
        digitalOfferingId: digitalOfferingOptionId,
        digitalOfferingStatus: isChecked
      };

      onChangeDigitalOfferingFields(
        subscriptionDetails,
        digitalOfferingOptionId
      );
    }
  };

  const isDigitalOfferingOptionChecked = (offeringId = ""): boolean => {
    const item = currentValues.find((o) => o.digitalOfferingId === offeringId);
    return item && item.digitalOfferingStatus ? true : false;
  };

  const hasActiveDigitalOffering = () => {
    const activeDigitalOffering = currentValues.find(
      (d) => d.digitalOfferingStatus === true
    );

    return activeDigitalOffering ? true : false;
  };

  return (
    <Fragment>
      <Dropdown
        label=""
        name="applicationId"
        control={control}
        data={getOptions(applications, "id", "applicationName")}
        rules={{ required: true }}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          onChangeApplication(event.target.value as string);
        }}
        error={errors.applicationId}
      />
      <Box my={4} mx={1}>
        <Typography color="textSecondary">Digital Offerings</Typography>
        {!hasActiveDigitalOffering() ? (
          <Grid item xs>
            <Typography color="error" variant="caption">
              No Digital Offering selected
            </Typography>
          </Grid>
        ) : (
          <></>
        )}

        {!isEmpty(digitalOfferingOptions) ? (
          digitalOfferingOptions.map((digitalOfferingOption, index) => (
            <ExpansionPanel
              label={digitalOfferingOption.digitalOfferingName}
              defaultExpanded={isDigitalOfferingOptionChecked(
                digitalOfferingOption.digitalOfferingId
              )}
              hasCheckbox={true}
              checked={isDigitalOfferingOptionChecked(
                digitalOfferingOption.digitalOfferingId
              )}
              onChangeChecked={(isChecked: boolean) =>
                onCheckDigitalOffering(
                  isChecked,
                  digitalOfferingOption.digitalOfferingId
                )
              }
              key={index}
            >
              <InstallationSubscriptionDetailsSection
                disabled={getDisabledFieldsStatus(
                  digitalOfferingOption.digitalOfferingId
                )}
                data={currentValues.find(
                  (x) =>
                    x.digitalOfferingId ===
                    digitalOfferingOption.digitalOfferingId
                )}
                onChangeData={(subscriptionData) =>
                  onChangeDigitalOfferingFields(
                    subscriptionData,
                    digitalOfferingOption.digitalOfferingId
                  )
                }
              />
            </ExpansionPanel>
          ))
        ) : (
          <Grid item xs>
            <Typography color="error" variant="caption">
              Application has no Digital Offering
            </Typography>
          </Grid>
        )}
      </Box>
    </Fragment>
  );
};

export default DigitalOfferingsSection;
