import { adalApiFetch } from "clientApp/configAdal";
import { endPoints } from "clientApp/endPoints";

export const pullUnitSystems = async () => {
  return await adalApiFetch(fetch, `${endPoints.unitSystems}/GetAll`, {
    method: "GET",
    headers: {
      "content-type": "application/json"
    }
  })
    .then(res => res.json())
    .then(posts => {
      return posts.unitSystems;
    });
};
