import { EnvConfig } from "./envConfig";

export class DevConfig extends EnvConfig {
	constructor(hostname = "") {
		super(hostname, process.env.REACT_APP_K_D_API_URL);
	}

	public getAssetEndpoint() {
		let val = super.getAssetEndpoint();

		if (
			this.hostname === process.env.REACT_APP_K_D_HOSTNAME ||
			this.hostname.includes("localhost")
		) {
			val = this.getValue(process.env.REACT_APP_K_D_API_URL);
		}

		return val;
	}
}
