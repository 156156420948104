import React, { Fragment, useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import MainBarDashboard from "components/MainBarDashboard";
import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import NewTextField from "components/TextField";
import Dropdown from "components/MUIDropdown";
import PageHeader from "components/PageHeader";
import PageHeaderDetailItem from "components/PageHeaderDetailItem";
import { IDigitalOffering } from "components/type";
import { DigitalOfferingRootPath } from "routes/paths";
import { FormErrorMessages } from "constants/enum";

import { ApplicationsContext } from "provider/ApplicationsProvider";
import { SpinnerContext } from "provider/SpinnerProvider";
import isEmpty from "lodash/isEmpty";
import { getOptions } from "utils";

import { getDigitalOffering, updateDigitalOffering } from "./Actions";

const useStyles = makeStyles(theme => ({
  tabs: {
    flexGrow: 1,
    marginBottom: theme.spacing(3)
  }
}));

const DigitalOfferingView = () => {
  const { digitalOfferingId } = useParams();
  const breadcrumbs = [
    {
      label: "Digital Offerings",
      href: DigitalOfferingRootPath
    },
    {
      label: `View (${digitalOfferingId})`
    }
  ];
  const classes = useStyles();

  const { register, handleSubmit, errors, control, setValue } = useForm();
  const [, setIsSpinnerVisible] = useContext(SpinnerContext);
  const [applications, getApplications] = useContext(ApplicationsContext);
  const [digitalOffering, setDigitalOffering] = useState<IDigitalOffering>();
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    getApplications();
    setIsSpinnerVisible(true);
    if (digitalOfferingId) {
      getDigitalOffering(digitalOfferingId).then(digitalOffering => {
        setDigitalOffering(digitalOffering);
        setIsSpinnerVisible(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(applications)) {
      setIsSpinnerVisible(false);
      setValue("applicationId", applications[0].id);
    }
  }, [applications]);

  useEffect(() => {
    if (digitalOffering) {
      setValue("applicationId", digitalOffering.applicationId);
      setValue("digitalOfferingName", digitalOffering.digitalOfferingName);
      setValue("sapNumber", digitalOffering.sapNumber);
      setValue("description", digitalOffering.description);
    }
  }, [digitalOffering]);

  const onSubmit = (data: any) => {
    if (digitalOffering) {
      setIsSpinnerVisible(true);
      data.id = digitalOffering.id;
      updateDigitalOffering(data).then(() => {
        if (digitalOfferingId) {
          getDigitalOffering(digitalOfferingId).then(digitalOffering => {
            setIsSpinnerVisible(false);
            setDigitalOffering(digitalOffering);
          });
        }
      });
    }
  };

  const switchToUpdate = () => {
    setIsUpdate(true);
  };

  return (
    <MainBarDashboard back breadcrumbs={breadcrumbs}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader
          title={
            !isEmpty(digitalOffering)
              ? digitalOffering!.digitalOfferingName
              : ""
          }
          subTitle={
            !isEmpty(digitalOffering)
              ? digitalOffering!.applicationAbbreviation
              : ""
          }
          details={
            <Fragment>
              <PageHeaderDetailItem
                label="SAP Number"
                value={
                  !isEmpty(digitalOffering)
                    ? `${digitalOffering!.sapNumber}`
                    : ""
                }
              />
            </Fragment>
          }
        >
          <Fragment></Fragment>
        </PageHeader>
        <Paper className={classes.tabs}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box m={4}>
                <Dropdown
                  readOnly={!isUpdate}
                  label="Digital Product"
                  name="applicationId"
                  rules={{ required: FormErrorMessages.fieldRequired }}
                  data={
                    !isEmpty(applications)
                      ? getOptions(applications, "id", "applicationName")
                      : [
                          {
                            label: "",
                            value: ""
                          }
                        ]
                  }
                  control={control}
                  error={errors.applicationId}
                />
                <NewTextField
                  readOnly={!isUpdate}
                  error={errors.digitalOfferingName}
                  label="Digital Offering Name"
                  name="digitalOfferingName"
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired
                  })}
                />
                <NewTextField
                  readOnly={!isUpdate}
                  error={errors.sapNumber}
                  label="SAP Product #"
                  name="sapNumber"
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired,
                    maxLength: {
                      value: 8,
                      message: FormErrorMessages.invalidSapNumber
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9]*$/i,
                      message: FormErrorMessages.invalidSapNumber
                    }
                  })}
                />
                <NewTextField
                  readOnly={!isUpdate}
                  error={errors.description}
                  label="Description"
                  name="description"
                  inputRef={register({
                    required: FormErrorMessages.fieldRequired
                  })}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <FloatingActionButtonList>
          {isUpdate && (
            <FloatingActionButton type="submit">
              <SaveIcon />
              Save
            </FloatingActionButton>
          )}
          {!isUpdate && (
            <FloatingActionButton onClick={switchToUpdate}>
              <EditIcon />
              Update
            </FloatingActionButton>
          )}
        </FloatingActionButtonList>
      </form>
    </MainBarDashboard>
  );
};

export default DigitalOfferingView;
