import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { ILabelValue } from "components/type";
import { IToggleButtonGroupProps } from "./type";
const useStyles = makeStyles(theme => ({
  root: {
    margin: "10px",
    marginBottom: 0
  },
  button: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`
  }
}));

const MUIToggleButtonGroup = ({
  data,
  onChange
}: IToggleButtonGroupProps): JSX.Element => {
  const classes = useStyles();

  const [selected, setSelected] = React.useState(data[0].value);
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setSelected(newAlignment);
      onChange(newAlignment);
    }
  };
  return (
    <ToggleButtonGroup
      size="small"
      value={selected}
      exclusive
      onChange={handleChange}
      className={classes.root}
    >
      {data.map((btn: ILabelValue, index: number) => (
        <ToggleButton
          key={index}
          value={btn.value}
          classes={{
            selected: classes.button
          }}
        >
          {btn.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
export default MUIToggleButtonGroup;
