import { apiUrl } from "clientApp/configAdal";

export const endPoints = {
  applications: `${apiUrl}/api/Applications`,
  configurationuserapplications: `${apiUrl}/api/configurationuserapplications`,
  configurationUsers: `${apiUrl}/api/ConfigurationUsers`,
  configurationUsersV2: `${apiUrl}/api/v2/ConfigurationUsers`,
  configurationUserRoles: `${apiUrl}/api/ConfigurationUserRoles`,
  countries: `${apiUrl}/api/Countries`,
  dateFormat: `${apiUrl}/api/DateFormats`,
  devices: `${apiUrl}/api/Devices`,
  devicesV2: `${apiUrl}/api/v2/devices`,
  digitalOfferings: `${apiUrl}/api/DigitalOfferings`,
  globalUsers: `${apiUrl}/api/GlobalUsers`,
  hierarchies: `${apiUrl}/api/Hierarchies`,
  hierarchyMetadata: `${apiUrl}/api/hierarchymetadata`,
  installations: `${apiUrl}/api/Installations`,
  languages: `${apiUrl}/api/Languages`,
  serviceGroups: `${apiUrl}/api/ServiceGroups`,
  timeFormats: `${apiUrl}/api/TimeFormats`,
  types: `${apiUrl}/api/Types`,
  timeZones: `${apiUrl}/api/TimeZones`,
  unitSystems: `${apiUrl}/api/UnitSystems`,
  deviceCharacteristics: `${apiUrl}/api/DeviceCharacteristics`,
  IOTSystems: `${apiUrl}/api/IOTSystems`,
  userHierarchies: `${apiUrl}/api/UserHierarchies`,
  userHierarchiesV2: `${apiUrl}/api/v2/UserHierarchies`,
  userHierarchyRoles: `${apiUrl}/api/UserHierarchyRoles`,
  userHierarchyRolesV2: `${apiUrl}/api/v2/UserHierarchyRoles`,
  subscriptions: `${apiUrl}/api/Subscriptions`,
  azureAd: `${apiUrl}/api/AzureAd`,
  globalUser: `${apiUrl}/api/globalusers`,
  externalUser: `${apiUrl}/api/externalusers`,
  policies: `${apiUrl}/api/Policies`,
};
