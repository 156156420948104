import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useForm, useFieldArray } from "react-hook-form";

import isEmpty from "lodash/isEmpty";

import FloatingActionButtonList from "components/FloatingActionButtonList";
import FloatingActionButton from "components/FloatingActionButton";
import ExpansionPanel from "components/ExpansionPanel";
import { ICountry, IApplication } from "components/type";

import SaveIcon from "@material-ui/icons/Save";

import Grid from "@material-ui/core/Grid";

import DigitalOfferingsSection from "./components/DigitalOfferingsSection";

import { SpinnerContext } from "provider/SpinnerProvider";
import { SavedFormsContext } from "provider/SavedFormsProvider";
import { InstallationContext } from "provider/InstallationProvider";
import { AccountContext } from "provider/AccountProvider";

import {
  DigitalOfferingItem,
  CreateInstallationProps,
  UpdateInstallationProps
} from "../../type";

import {
  getDigitalOfferingsListByApplicationId,
  getInstallationById,
  createNewInstallation,
  updateInstallation,
  addReferences,
  getReferences,
  removeReferences
} from "../../../../actions/installationsPageActions";

import { getAccount } from "../../../../actions/accountsPageActions";
import {
  getHierarchyMetadata,
  saveBatchHierarchyMetadata
} from "../../../../actions/generalActions";

import {
  InstallationDetailsFormProps,
  DigitalOfferingCheckboxProps,
  RawInstallationData,
  DigitalOfferingsSubscriptionsFields,
  DeviceReference
} from "./type";

import {
  AssetsRootPath,
  InstallationsCreatePath,
  DeviceCommDeviceFormPath
} from "routes/paths";

import { getDateString } from "../../../../utils";

import {
  getStatusToAddInSubmitData,
  getReasonToAddInSubmitData,
  isDeviceReferenceInList
} from "./utils";
import MetadataTable from "components/MetadataTable";
import { Paper } from "@material-ui/core";
import { MetadataProps } from "components/MetadataTable/MetadataTable";

import DeviceSection from "./components/DeviceSection";
import ReferencesSection from "./components/ReferencesSection";
import GeneralSection from "./components/GeneralSection";
import { updateMetadata } from "utils/utils";

const SUBMIT_FROM = {
  CREATE_DEVICE: "createDevice",
  SAVE: "save"
};

const InstallationDetailsForm = ({
  children,
  installationData,
  countries = []
}: InstallationDetailsFormProps): JSX.Element => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const installationToBeUpdated = installationData as RawInstallationData;
  const { register, handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      installationName: "",
      applicationId: "",
      latitude: "",
      longitude: "",
      deviceId: "",
      countryId: "",
      parentId: "",
      status: "",
      reason: "",
      digitalOfferings: []
    },
    validateCriteriaMode: "all"
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "digitalOfferings"
  });
  const [fieldsCleared, setFieldsCleared] = useState(false);
  const [appIdForLoadingOfferings, setAppIdForLoadingOfferings] = useState("");

  const [isSpinnerVisible, setIsSpinnerVisible] = useContext(SpinnerContext);
  const { savedFormInstallationContext } = useContext(SavedFormsContext);
  const { createInstallationContext } = useContext(InstallationContext);
  const [createInstallation, setCreateInstallation] = createInstallationContext;
  const [savedFormInstallation, setSavedFormInstallation] =
    savedFormInstallationContext;
  const { updateAccountContext } = useContext(AccountContext);
  const [, setAccount] = updateAccountContext;
  const [offeringsList, setOfferingsList] = useState<DigitalOfferingItem[]>([]);
  const [immediateParentId, setImmediateParentId] = useState("");
  const [parentId, setParentId] = useState("");
  const [rootValue, setRootValue] = useState<string | null>(null);
  const [submitFrom, setSubmitFrom] = useState("");
  const [digitalOfferingsSectionValues, setDigitalOfferingsSectionValues] =
    useState<DigitalOfferingsSubscriptionsFields[]>([]);
  const [metadata, setMetadata] = useState<MetadataProps[]>([]);
  const [initialStateMetadata, setInitialStateMetadata] = useState<
    MetadataProps[]
  >([]);
  const [hasPreviousMetadata, setHasPreviousMetadata] = useState(false);
  const [isModifyingMetadata, setIsModifyingMetadata] = useState(false);
  const [deviceIdToSubmit, setDeviceIdToSubmit] = useState<
    string | undefined
  >();
  const [shouldReloadDeviceSection, setShouldReloadDeviceSection] =
    useState<boolean>(false);
  const [deviceReferencesList, setDeviceReferencesList] = useState<
    DeviceReference[]
  >([]);
  const [deviceReferencesListForRemoval, setDeviceReferencesListForRemoval] =
    useState<DeviceReference[]>([]);
  const history = useHistory();

  const isFromDeviceCreation = (): boolean =>
    !isEmpty(savedFormInstallation) && !isEmpty(deviceId);

  const onChangeApplication = (value: string): void => {
    setIsSpinnerVisible(true);

    setDigitalOfferingsSectionValues([]);
    remove();
    setFieldsCleared(true);
    setAppIdForLoadingOfferings(value);
  };

  const setOfferings = (appId = ""): void => {
    if (appId !== "") {
      getDigitalOfferingsListByApplicationId(appId).then((res) => {
        setOfferingsList(res);
        setIsSpinnerVisible(false);
      });
    } else {
      setOfferingsList([]);
    }
  };

  useEffect(() => {
    if (fieldsCleared) {
      setOfferings(appIdForLoadingOfferings);
      setFieldsCleared(false);
    }
  }, [fieldsCleared]);

  const setApplicationIdFormValue = (
    applicationsInInstallation: IApplication[] = []
  ): void => {
    if (applicationsInInstallation.length > 0) {
      onChangeApplication(applicationsInInstallation[0].id);
      setValue("applicationId", applicationsInInstallation[0].id);
      return;
    }

    setValue("applicationId", "");
  };

  const initApplicationDataToBeUpdated = (): void => {
    const installationApps: IApplication[] =
      installationToBeUpdated.hierarchy.applications;

    setApplicationIdFormValue(installationApps);
  };

  const initCountryDataToBeUpdated = (): void => {
    if (countries.length > 0) {
      const country = countries.find(
        (c: ICountry) =>
          c.countryName === installationToBeUpdated.hierarchy.countryName
      );

      setValue("countryId", country ? country.id ?? "" : "");
    }
  };

  const initMetadata = (): void => {
    getHierarchyMetadata(installationToBeUpdated.hierarchyId).then(
      (m: MetadataProps[]) => {
        setHasPreviousMetadata(m.length > 0);
        setInitialStateMetadata(m);
        setMetadata(m);
      }
    );
  };

  const initReferences = (): void => {
    getReferences(installationToBeUpdated.id).then((r: DeviceReference[]) => {
      setDeviceReferencesList(r);
    });
  };

  const statusValue = (): string => {
    if (installationToBeUpdated.status === null) {
      return "";
    }

    return installationToBeUpdated.status ? "1" : "0";
  };

  const buildDigitalOfferingItems = (
    offeringsListParam: DigitalOfferingItem[]
  ): DigitalOfferingCheckboxProps[] => {
    const items: DigitalOfferingCheckboxProps[] = [];
    offeringsListParam.forEach((item: DigitalOfferingItem) => {
      if (installationToBeUpdated) {
        const value = installationToBeUpdated.digitalOfferings.find(
          (x) => x.id === item.id
        );

        items.push({
          activationDate: value?.activationDate,
          deactivationDate: value?.deactivationDate,
          digitalOfferingId: item.id,
          digitalOfferingName: item.digitalOfferingName,
          digitalOfferingStatus: value?.digitalOfferingStatus,
          lastManualChangeDate: value?.lastManualChangeDate,
          reason: value?.reason,
          subscriptionStatus: value?.subscriptionStatus,
          value: typeof value !== "undefined"
        });
      } else if (
        !isEmpty(savedFormInstallation) &&
        savedFormInstallation.digitalOfferings
      ) {
        items.push({
          activationDate:
            savedFormInstallation?.digitalOfferings.activationDate,
          deactivationDate:
            savedFormInstallation?.digitalOfferings.deactivationDate,
          digitalOfferingId: item.id,
          digitalOfferingName: item.digitalOfferingName,
          digitalOfferingStatus:
            savedFormInstallation?.digitalOfferings.digitalOfferingStatus,
          lastManualChangeDate:
            savedFormInstallation?.digitalOfferings.lastManualChangeDate,
          reason: savedFormInstallation?.digitalOfferings.reason,
          subscriptionStatus:
            savedFormInstallation?.digitalOfferings.subscriptionStatus,
          value: savedFormInstallation.digitalOfferings[item.id]
        });
      } else {
        items.push({
          digitalOfferingId: item.id,
          digitalOfferingName: item.digitalOfferingName,
          value: false
        });
      }
    });

    return items;
  };

  const buildDigitalOfferingToBeUpdated = (
    offeringsListParam: DigitalOfferingItem[]
  ): DigitalOfferingsSubscriptionsFields[] => {
    const items: DigitalOfferingsSubscriptionsFields[] = [];
    offeringsListParam.forEach((item: DigitalOfferingItem) => {
      if (installationToBeUpdated) {
        const value = installationToBeUpdated.digitalOfferings.find(
          (x) => x.id === item.id
        );

        items.push({
          digitalOfferingId: item.id,
          digitalOfferingStatus: value?.digitalOfferingStatus || false,
          reason: value?.reason,
          activationDate: value?.activationDate?.toString(),
          deactivationDate: value?.deactivationDate?.toString(),
          timestamp: value?.lastManualChangeDate?.toString(),
          subscriptionStatus: value?.subscriptionStatus
        });
      } else if (
        !isEmpty(savedFormInstallation) &&
        savedFormInstallation.digitalOfferings
      ) {
        items.push({
          digitalOfferingId: item.id,
          digitalOfferingStatus:
            savedFormInstallation?.digitalOfferings.digitalOfferingStatus,
          reason: savedFormInstallation?.digitalOfferings.reason,
          activationDate:
            savedFormInstallation?.digitalOfferings.activationDate,
          deactivationDate:
            savedFormInstallation?.digitalOfferings.deactivationDate,
          timestamp:
            savedFormInstallation?.digitalOfferings.lastManualChangeDate,
          subscriptionStatus:
            savedFormInstallation?.digitalOfferings.subscriptionStatus
        });
      } else {
        items.push({
          digitalOfferingId: item.id,
          digitalOfferingStatus: false
        });
      }
    });

    setDigitalOfferingsSectionValues(items);
    return items;
  };

  const initDataToBeUpdated = (): void => {
    const hierarchyParentId = installationToBeUpdated.hierarchy.parentId
      ? installationToBeUpdated.hierarchy.parentId
      : "";
    const hierarchyRootId = installationToBeUpdated.hierarchy.rootId
      ? installationToBeUpdated.hierarchy.rootId
      : "";

    setValue("installationName", installationToBeUpdated.installationName);
    setValue("sapShipToId", installationToBeUpdated.sapShipToId);
    setValue("street", installationToBeUpdated.hierarchy.street);
    setValue("city", installationToBeUpdated.hierarchy.city);
    setValue("state", installationToBeUpdated.hierarchy.state);
    setValue("postalCode", installationToBeUpdated.hierarchy.postalCode);
    setValue("latitude", `${installationToBeUpdated.latitude}`);
    setValue("longitude", `${installationToBeUpdated.longitude}`);
    setValue("parentId", hierarchyParentId);
    setParentId(hierarchyParentId);
    setRootValue(hierarchyRootId);

    setValue("status", `${statusValue()}`);
    setValue(
      "reason",
      `${installationToBeUpdated.reason ? installationToBeUpdated.reason : ""}`
    );

    initCountryDataToBeUpdated();
    initApplicationDataToBeUpdated();
    initMetadata();
    initReferences();
    buildDigitalOfferingToBeUpdated(offeringsList);
  };

  const initData = (): void => {
    if (!isEmpty(installationToBeUpdated)) {
      initDataToBeUpdated();
    }
  };

  const setFormValuesAfterDeviceCreation = (): void => {
    setValue("installationName", savedFormInstallation.installationName);
    setValue("sapShipToId", savedFormInstallation.sapShipToId);
    setValue("state", savedFormInstallation.state);
    setValue("postalCode", savedFormInstallation.postalCode);
    setValue("street", savedFormInstallation.street);
    setValue("city", savedFormInstallation.city);
    setValue("countryId", savedFormInstallation.countryId);
    setValue("latitude", savedFormInstallation.latitude);
    setValue("longitude", savedFormInstallation.longitude);
    setValue("applicationId", savedFormInstallation.applicationId);
    setValue("parentId", savedFormInstallation.parentId);
    setImmediateParentId(savedFormInstallation.parentId);
    setValue("status", savedFormInstallation.status);
    setValue("reason", savedFormInstallation.reason);
    if (savedFormInstallation.applicationId) {
      onChangeApplication(savedFormInstallation.applicationId);
      setDigitalOfferingsSectionValues(savedFormInstallation.digitalOfferings);
    }

    setValue("deviceId", savedFormInstallation.deviceId);
    setMetadata(savedFormInstallation.metadata);
    setHasPreviousMetadata(savedFormInstallation.hasPreviousMetadata);
    setDeviceReferencesList(savedFormInstallation.deviceReferencesList);
    setDeviceReferencesListForRemoval(
      savedFormInstallation.deviceReferencesListForRemoval
    );
  };

  useEffect(() => {
    if (isFromDeviceCreation()) {
      setFormValuesAfterDeviceCreation();
    } else {
      initData();
    }
  }, [installationToBeUpdated, deviceId]);

  const buildDigitalOfferingsFieldValues = (
    checkedOfferings: DigitalOfferingCheckboxProps[] = []
  ): DigitalOfferingsSubscriptionsFields[] => {
    return checkedOfferings.map((i) => {
      return {
        activationDate: `${i.activationDate || ""}`,
        deactivationDate: `${i.deactivationDate || ""}`,
        digitalOfferingId: i.digitalOfferingId,
        digitalOfferingStatus: i.digitalOfferingStatus || false,
        reason: i.reason,
        subscriptionStatus: i.subscriptionStatus || "",
        timestamp: `${i.lastManualChangeDate || ""}`
      };
    });
  };

  useEffect(() => {
    if (isEmpty(fields) && offeringsList.length > 0) {
      let items: DigitalOfferingCheckboxProps[] = [];

      items = buildDigitalOfferingItems(offeringsList);

      if (isEmpty(savedFormInstallation)) {
        const checkedOfferings = items.filter((x) => x.value);
        const checkedOfferingsWithFieldValues =
          buildDigitalOfferingsFieldValues(checkedOfferings);

        setDigitalOfferingsSectionValues(checkedOfferingsWithFieldValues);
      }

      append(items);
      setSavedFormInstallation({});
    }
  }, [offeringsList]);

  const resetForm = (): void => {
    remove();
    setValue("installationName", "");
    setValue("sapShipToId", "");
    setValue("street", "");
    setValue("city", "");
    setValue("state", "");
    setValue("postalCode", "");
    setValue("latitude", "");
    setValue("longitude", "");
    setValue("deviceId", "");
    setShouldReloadDeviceSection(true);
    setValue("applicationId", "");
    setValue("countryId", "");
    setValue("status", "");
    setValue("reason", "");
    setImmediateParentId("");
    setMetadata([]);
    setDeviceReferencesList([]);
    setDeviceReferencesListForRemoval([]);
    setHasPreviousMetadata(false);
    setSavedFormInstallation({});
  };

  const redirectToAssetsOverview = (): void => {
    history.push(AssetsRootPath);
  };

  const getExistingValuesFromDigitalOfferingsSectionFields =
    (): DigitalOfferingsSubscriptionsFields[] => {
      const items: DigitalOfferingsSubscriptionsFields[] = [];
      const offerings = installationToBeUpdated?.digitalOfferings || [];

      offerings.forEach((item) => {
        if (item.digitalOfferingStatus) {
          items.push({
            activationDate: getDateString(item.activationDate || "", true),
            deactivationDate: getDateString(item.deactivationDate || "", true),
            digitalOfferingId: item.id,
            digitalOfferingStatus: item?.digitalOfferingStatus || false,
            reason: item?.reason,
            timestamp: item?.lastManualChangeDate?.toString(),
            subscriptionStatus: item?.subscriptionStatus
          });
        }
      });
      return items;
    };

  const getEditedValuesFromDigitalOfferingsSectionFields = () => {
    return digitalOfferingsSectionValues.map((x) => {
      return {
        ...x,
        activationDate: getDateString(x.activationDate || "", true),
        deactivationDate: getDateString(x.deactivationDate || "", true),
        digitalOfferingStatus: x.digitalOfferingStatus
      };
    });
  };

  const digitalOfferingsSectionHasModifiedValues = (): boolean =>
    digitalOfferingsSectionValues.length !== 0;

  const getDigitalOfferingToAddInSubmitData =
    (): DigitalOfferingsSubscriptionsFields[] => {
      if (!digitalOfferingsSectionHasModifiedValues()) {
        return getExistingValuesFromDigitalOfferingsSectionFields();
      }

      return getEditedValuesFromDigitalOfferingsSectionFields();
    };

  const buildSubmitData = (data: any): CreateInstallationProps => {
    return {
      installationName: data.installationName,
      sapShipToId: data.sapShipToId,
      street: data.street,
      city: data.city,
      state: data.state,
      postalCode: data.postalCode,
      latitude: data.latitude,
      longitude: data.longitude,
      digitalOfferings: getDigitalOfferingToAddInSubmitData(),
      deviceId: deviceIdToSubmit,
      countryId: data.countryId,
      rootId: rootValue,
      parentId: parentId,
      status: getStatusToAddInSubmitData(data.status),
      reason: getReasonToAddInSubmitData(data.reason, data.status),
      typeId: process.env.REACT_APP_TYPE_INSTALLATION_ID
        ? process.env.REACT_APP_TYPE_INSTALLATION_ID
        : ""
    };
  };

  const getApplicationIdForInstallationBeingEdited = () => {
    return installationToBeUpdated?.hierarchy?.applications[0]?.id || "";
  };

  const saveFormValues = (data: any): void => {
    if (installationToBeUpdated) {
      data.installationId = installationToBeUpdated.id;
      data.hierarchyId = installationToBeUpdated.hierarchyId;
    }

    if (!digitalOfferingsSectionHasModifiedValues()) {
      data.applicationId = getApplicationIdForInstallationBeingEdited();
    }

    data.digitalOfferings = getDigitalOfferingToAddInSubmitData();
    data.parentId = parentId;
    data.metadata = metadata;
    data.hasPreviousMetadata = hasPreviousMetadata;
    data.deviceReferencesList = deviceReferencesList;
    data.deviceReferencesListForRemoval = deviceReferencesListForRemoval;
    setSavedFormInstallation(data);
  };

  const submitCreateInstallation = (data: CreateInstallationProps): void => {
    setIsSpinnerVisible(true);
    let createInstallationId = "";

    createNewInstallation(data)
      .then((insId = "") => {
        createInstallationId = insId;
        return getInstallationById(insId)
          .then((data: RawInstallationData) => data.hierarchyId)
          .catch(() => "");
      })
      .then((hierarchyId = "") => {
        if (metadata.length > 0) {
          saveBatchHierarchyMetadata(hierarchyId, metadata);
        }
      })
      .then(() =>
        addReferences(
          createInstallationId,
          deviceReferencesList.map((d) => d.id)
        )
      )
      .then(() => {
        resetForm();
        setIsSpinnerVisible(false);
        if (deviceId) {
          history.push(InstallationsCreatePath);
        }
        if (
          createInstallation.isQuickCreate &&
          installationToBeUpdated === undefined
        ) {
          getAccount(createInstallation.rootId).then((a) => {
            setAccount(a);
            history.goBack();
          });
        }
      })
      .catch(() => {
        setIsSpinnerVisible(false);
      });
  };

  const getInstallationIdToSend = (): string => {
    let val = "";
    if (installationToBeUpdated && installationToBeUpdated.id) {
      val = installationToBeUpdated.id as string;
    } else if (savedFormInstallation && savedFormInstallation.installationId) {
      val = savedFormInstallation.installationId as string;
    } else {
      val = "";
    }

    return val;
  };

  const getInstallationHierarchyIdToSend = (): string => {
    let val = "";

    if (installationToBeUpdated && installationToBeUpdated.hierarchyId) {
      val = installationToBeUpdated.hierarchyId as string;
    } else if (savedFormInstallation && savedFormInstallation.hierarchyId) {
      val = savedFormInstallation.hierarchyId as string;
    } else {
      val = "";
    }

    return val;
  };

  const submitUpdateInstallation = (data: CreateInstallationProps): void => {
    const sendUpdateData: UpdateInstallationProps =
      data as UpdateInstallationProps;

    sendUpdateData.id = getInstallationIdToSend();
    sendUpdateData.hierarchyId = getInstallationHierarchyIdToSend();

    setIsSpinnerVisible(true);
    updateInstallation(sendUpdateData)
      .then(() =>
        updateMetadata(
          metadata,
          initialStateMetadata,
          sendUpdateData.hierarchyId,
          hasPreviousMetadata
        )
      )
      .then(() =>
        removeReferences(
          sendUpdateData.id,
          deviceReferencesListForRemoval.map((d) => d.id)
        )
      )
      .then(() =>
        addReferences(
          sendUpdateData.id,
          deviceReferencesList.map((d) => d.id)
        )
      )
      .then(() => {
        resetForm();
        redirectToAssetsOverview();
        setIsSpinnerVisible(false);
      })
      .catch(() => {
        setIsSpinnerVisible(false);
      });
  };

  const submitInstallation = (data: any): void => {
    const submitData = buildSubmitData(data);
    if (installationToBeUpdated || savedFormInstallation.installationId) {
      submitUpdateInstallation(submitData);
    } else {
      submitCreateInstallation(submitData);
    }
  };

  const redirectToCreateDevice = (): void => {
    history.push(DeviceCommDeviceFormPath);
  };

  const hasDigitalOffering = () => {
    const activeDigitalOffering = digitalOfferingsSectionValues.find(
      (d) => d.digitalOfferingStatus === true
    );

    return activeDigitalOffering ? true : false;
  };

  const onSubmit = (data: any): void => {
    if (isModifyingMetadata || !hasDigitalOffering()) {
      return;
    } 
    else if (submitFrom === SUBMIT_FROM.CREATE_DEVICE) {
      saveFormValues(data);
      redirectToCreateDevice();
    } else {
      submitInstallation(data);
    }
  };

  const metadataTableComponent = (
    <Paper>
      {isSpinnerVisible ? (
        <></>
      ) : (
        <MetadataTable
          metadata={metadata}
          search={true}
          onChangeMetadata={(data: MetadataProps[]): void => setMetadata(data)}
          onClickActionButton={(name): void => {
            if (name === "Add" && !isModifyingMetadata) {
              setIsModifyingMetadata(true);
            } else {
              setIsModifyingMetadata(false);
            }
          }}
        />
      )}
    </Paper>
  );

  const installationHierarchyComponent = (
    <Grid item>
      {children}
      {metadataTableComponent}
    </Grid>
  );

  const getInstallationToBeUpdatedDeviceId = (): string | null =>
    installationToBeUpdated ? installationToBeUpdated.deviceId : null;

  const getDeviceId = (): string => {
    let res = "";

    if (isFromDeviceCreation()) {
      res = deviceId;
    } else {
      res = getInstallationToBeUpdatedDeviceId() ?? "";
    }

    return res;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <GeneralSection
            errors={errors}
            register={register}
            createInstallation={createInstallation}
            setCreateInstallation={setCreateInstallation}
            savedFormInstallation={savedFormInstallation}
            installationToBeUpdated={installationToBeUpdated}
            immediateParentId={immediateParentId}
            onChangeParentId={(
              immediateParentIdResult: React.SetStateAction<string>,
              rootValue: string | null,
              parentIdResult: string | null
            ): void => {
              setImmediateParentId(immediateParentIdResult);
              setRootValue(rootValue);
              setParentId(parentIdResult ?? "");
            }}
            countries={countries}
            control={control}
            setValue={setValue}
            isFromDeviceCreation={isFromDeviceCreation}
          />
          {children && installationHierarchyComponent}
          {!children && <Grid item>{metadataTableComponent}</Grid>}
        </Grid>
        <Grid item xs={12} md={6}>
          <ExpansionPanel label="Digital Product">
            <DigitalOfferingsSection
              control={control}
              errors={errors}
              digitalOfferingOptions={fields}
              currentValues={digitalOfferingsSectionValues}
              onChangeApplication={onChangeApplication}
              onChangeValues={(
                values: DigitalOfferingsSubscriptionsFields[] = []
              ): void => setDigitalOfferingsSectionValues(values)}
            />
          </ExpansionPanel>
          <DeviceSection
            deviceId={getDeviceId()}
            register={register}
            errors={errors}
            setValue={setValue}
            isUpdate={!isEmpty(installationToBeUpdated)}
            isFromDeviceCreation={isFromDeviceCreation()}
            onChange={(deviceIdGfFormat: string | undefined): void => {
              setShouldReloadDeviceSection(false);
              setDeviceIdToSubmit(deviceIdGfFormat);
            }}
            reload={shouldReloadDeviceSection}
            submit={(deviceIdGfFormat: string | undefined): void => {
              setDeviceIdToSubmit(deviceIdGfFormat);
              setSubmitFrom(SUBMIT_FROM.CREATE_DEVICE);
              handleSubmit(onSubmit);
            }}
          />
          <ReferencesSection
            deviceReferencesList={deviceReferencesList}
            onAdd={(item: DeviceReference): void => {
              if (isDeviceReferenceInList(item, deviceReferencesList))
                setDeviceReferencesList([...deviceReferencesList, item]);
            }}
            onRemove={(item: DeviceReference): void => {
              if (
                isDeviceReferenceInList(item, deviceReferencesListForRemoval)
              ) {
                setDeviceReferencesListForRemoval([
                  ...deviceReferencesListForRemoval,
                  item
                ]);
              }

              setDeviceReferencesList(
                deviceReferencesList.filter((r) => r.id !== item.id)
              );
            }}
          />
        </Grid>
      </Grid>
      <FloatingActionButtonList>
        <FloatingActionButton
          type="submit"
          onClick={(): void => setSubmitFrom(SUBMIT_FROM.SAVE)}
        >
          <SaveIcon />
          Save
        </FloatingActionButton>
      </FloatingActionButtonList>
    </form>
  );
};

export default InstallationDetailsForm;
