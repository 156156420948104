import { EnvConfig } from "./envConfig";

export class SandboxConfig extends EnvConfig {
	constructor(hostname = "") {
		super(hostname, process.env.REACT_APP_K_S_API_URL);
	}

	public getAssetEndpoint() {
		let val = super.getAssetEndpoint();

		if (this.hostname === process.env.REACT_APP_K_S_HOSTNAME) {
			val = this.getValue(process.env.REACT_APP_K_S_API_URL);
		}

		return val;
	}
}
