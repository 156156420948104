import React, { useState, createContext } from "react";
import isEmpty from "lodash/isEmpty";

import { pullDateFormats } from "./Actions";

import { CONTEXT_CACHE_EXPIRATION } from "constants/config";

export const DateFormatsContext = createContext<any>(null);

export const DateFormatsProvider = ({
  children
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [dateFormats, setDateFormats] = useState([]);
  const [dateTimeRefresh, setDateTimeRefresh] = useState(0);
  const getDateFormats = async () => {
    const currentDate = new Date();
    const dateTime = currentDate.getTime();
    if (isEmpty(dateFormats) || dateTimeRefresh < dateTime) {
      await pullDateFormats().then(dateFormats => {
        const currentDate = new Date();
        const dateTime = currentDate.getTime();
        setDateTimeRefresh(dateTime + CONTEXT_CACHE_EXPIRATION);
        setDateFormats(dateFormats);
      });
    }
  };

  return (
    <DateFormatsContext.Provider value={[dateFormats, getDateFormats]}>
      {children}
    </DateFormatsContext.Provider>
  );
};
