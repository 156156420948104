import { adalApiFetch } from "clientApp/configAdal";
import { endPoints } from "clientApp/endPoints";
import {
  IConfigurationUsersResult,
  IConfigurationUserDigitalProduct
} from "./type";
import Toaster from "components/Toaster";
import { convertResponseToJSON } from "utils";

const headerValues = { "content-type": "application/json" };
const TOASTR_ERROR_MESSAGE = "Error occured while processing your request!";

export const getConfigurationUsers = async (
  page = 1,
  limit = 10,
  searchQuery = ""
): Promise<IConfigurationUsersResult> => {
  const injectSearchQuery =
    searchQuery !== "" ? `&searchquery=${searchQuery}` : "";
  return adalApiFetch(
    fetch,
    `${endPoints.configurationUsers}/getall?page=${page + 1
    }&limit=${limit}${injectSearchQuery}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json"
      }
    }
  )
    .then((response) => {
      return convertResponseToJSON(response);
    })
    .catch((e) => {
      throw e;
    });
};

export const getDigitalProducts = async (
  userId: string
): Promise<IConfigurationUserDigitalProduct[]> => {
  return adalApiFetch(
    fetch,
    `${endPoints.configurationuserapplications}/getapplicationsbyuserid/${userId}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json"
      }
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data.digitalProductByUserId;
    })
    .catch((e) => {
      throw e;
    });
};

export const getRoles = async (
  userObjectIdId: string
): Promise<{ roleName: string }[]> => {
  return adalApiFetch(
    fetch,
    `${endPoints.configurationUserRoles}/user/${userObjectIdId}/roles`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json"
      }
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((e) => {
      throw e;
    });
};

export const getInternalRoles = (): Promise<any> => {
  return adalApiFetch(fetch, `${endPoints.configurationUserRoles}/Roles`, {
    method: "GET",
    headers: headerValues
  }).then((response) => response.json());
};

export const deleteConfigurationUser = (
  configUserId = ""
): Promise<boolean> => {
  return adalApiFetch(
    fetch,
    `${endPoints.configurationUsersV2}/Delete/${configUserId}`,
    {
      method: "DELETE",
      headers: headerValues
    }
  )
    .then((res) => {
      if (res.ok) {
        Toaster["success"]("Successfully Deleted!");
      } else {
        Toaster["error"](
          TOASTR_ERROR_MESSAGE,
          "Error deleting configuration user"
        );
      }

      return res.ok;
    })
    .catch((e) => {
      Toaster["error"](
        TOASTR_ERROR_MESSAGE,
        "Error deleting configuration user"
      );
      throw e;
    });
};
