import React, { createContext } from "react";

export const DigitalOfferingsContext = createContext<any>([
  {},
  () => {
    return;
  },
]);

export const DigitalOfferingsProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  return (
    <DigitalOfferingsContext.Provider value={{}}>
      {children}
    </DigitalOfferingsContext.Provider>
  );
};
