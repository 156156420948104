import { adalApiFetch } from "clientApp/configAdal";
import { endPoints } from "clientApp/endPoints";
import { IDropdown } from "components/type";
import { IConfigUserForm } from "./type";
import Toaster from "components/Toaster";

const headerValues = { "content-type": "application/json" };

export const getAzureIds = async (searchQuery = ""): Promise<IDropdown[]> => {
  const injectSearchQuery = searchQuery !== "" ? `?query=${searchQuery}` : "?query=aa";
  return adalApiFetch(fetch, `${endPoints.azureAd}/Users${injectSearchQuery}`, {
    method: "GET",
    headers: headerValues,
  })
    .then((res) => {
      return res.json();
    })
    .then((posts) => {
      return posts.activeDirectoryUsers;
    })
    .catch((e) => {
      throw e;
    });
};

export const saveConfigUser = async (data: IConfigUserForm) => {
  return adalApiFetch(fetch, `${endPoints.configurationUsersV2}/Create`, {
    method: "POST",
    headers: headerValues,
    body: JSON.stringify(data),
  })
    .then(function (response) {
      if (!response.ok) {
        Toaster["error"](
          "Error occured while processing your request!",
          "Error Saving"
        );
      } else {
        Toaster["success"]("Successfully Added!");
      }
      return response;
    })
    .then((response) => response.json())
    .catch((err) => {
      console.error(err);
    });
};
