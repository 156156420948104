import React, { useContext } from "react";
import { ModalContext } from "provider/ModalProvider";
import DialogModal from "components/DialogModal";
import { InstallationContext } from "provider/InstallationProvider";
import CreateInstallationModal from "./ModalInstallation";

interface InstallationModal {
  reload?: () => void;
}

const InstallationModal = (props: InstallationModal) => {
  const { installationModalContext } = useContext(ModalContext);
  const [
    showInstallationModal,
    setShowInstallationModal,
  ] = installationModalContext;

  const { modalInstallationContext } = useContext(InstallationContext);
  const [modalInstallation] = modalInstallationContext;

  const handleOk = (): void => {
    return;
  };

  return (
    <DialogModal
      showCancelButton={modalInstallation.isCreate ? true : false}
      showOkButton={modalInstallation.isCreate ? true : false}
      title={
        modalInstallation.isCreate
          ? "Creating Installation"
          : "Installation Details"
      }
      content={<CreateInstallationModal />}
      showModal={showInstallationModal}
      formId="installation-form"
      onOk={(): void => handleOk()}
      onHide={(): void => setShowInstallationModal(false)}
    />
  );
};

export default InstallationModal;
