import React from "react";
import TextField from "@material-ui/core/TextField";

import { ITextFieldProps } from "./type";

const NewTextField = ({
  error,
  label,
  name,
  inputRef,
  readOnly,
  maxLength,
  autoFocus,
}: ITextFieldProps): JSX.Element => {
  return (
    <TextField
      autoFocus={autoFocus}
      error={error!}
      helperText={error ? error.message : ""}
      label={label}
      style={{ margin: "8px 0" }}
      fullWidth
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      name={name}
      inputRef={inputRef}
      InputProps={{
        readOnly: readOnly!,
      }}
      inputProps={{
        maxLength: maxLength,
      }}
      disabled={readOnly}
    />
  );
};
export default NewTextField;
