import React, { ReactNode } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { RouteConfig } from "react-router-config";
import { PrivateRoute } from "../components";
import App from "./App/App";
import Accounts from "../pages/Accounts";
import InstallationDetails from "pages/Installation/InstallationDetails";
import Subscriptions from "pages/Subscriptions";
import SubscriptionsOverview from "pages/Subscriptions/SubscriptionsOverview";
import DigitalOffering, {
  DigitalOfferingList,
  DigitalOfferingForm,
  DigitalOfferingView
} from "../pages/DigitalOffering";
import Devices, { DeviceList, DeviceForm, DeviceView } from "../pages/Devices";
import Users, {
  UserList,
  UserDetails,
  UserCreate,
  UserAssetRoles
} from "../pages/Users";
import Authorization, {
  AuthorizationOverview,
  Administration,
  AdministrationForm,
  AdministrationView
} from "../pages/Authorization";
import ServiceGroup from "../pages/ServiceGroup";
import UpdateAccount from "../pages/Accounts/UpdateAccount";
import CreateAccount from "../pages/Accounts/CreateAccount";
import CreateSection from "../pages/Section/CreateSection";
import AccountOverview from "../pages/Accounts/AccountOverview";
import UpdateSection from "pages/Section/UpdateSection";
import UpdateInstallation from "pages/Installation/UpdateInstallation";
import UserAssetAssociation from "pages/Users/UserAssetAssociation";
import {
  AssetsRootPath,
  AccountsUpdatePath,
  AccountsCreatePath,
  SectionUpdatePath,
  SectionCreatePath,
  InstallationsCreatePath,
  InstallationsUpdatePath,
  InstallationsCreateWithDevicePath,
  DigitalOfferingRootPath,
  DigitalOfferingCreatePath,
  DigitalOfferingViewPath,
  DeviceRootPath,
  DeviceFormPath,
  DeviceViewPath,
  DeviceSubDeviceFormPath,
  DeviceCommDeviceFormPath,
  UsersRootPath,
  UsersCreatePath,
  UsersUpdatePath,
  UsersAssetsCreatePath,
  UsersAssetsUpdatePath,
  UsersAssetsRolesPath,
  ServiceGroupRootPath,
  AuthorizationRootPath,
  AuthorizationOverviewPath,
  AdministrationPath,
  AdministrationFormPath,
  AdministrationViewPath,
  SubscriptionsRootPath,
  InstallationsUpdateWithDevicePath
} from "./paths";

const privateRoute = (props: any) => (
  <PrivateRoute component={App} props={props} />
);
privateRoute.displayName = "PrivateRoute";

const redirect = (to = AssetsRootPath, from = "/"): JSX.Element => {
  return <Redirect from={from} to={to} />;
};
redirect.displayName = "Redirect";

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const NoMatch404 = function NoMatch(): JSX.Element {
  const location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
};

export default [
  {
    path: "/",
    render: (props): ReactNode => privateRoute(props),
    routes: [
      {
        exact: true,
        path: "/",
        render: (): ReactNode => redirect(AssetsRootPath, "/")
      },
      {
        component: Accounts,
        path: AssetsRootPath,
        routes: [
          {
            component: CreateAccount,
            exact: true,
            path: AccountsCreatePath
          },
          {
            component: UpdateAccount,
            exact: true,
            path: AccountsUpdatePath
          },
          {
            component: CreateSection,
            exact: true,
            path: SectionCreatePath
          },
          {
            component: UpdateSection,
            exact: true,
            path: SectionUpdatePath
          },
          {
            component: InstallationDetails,
            exact: true,
            path: InstallationsCreateWithDevicePath
          },
          {
            component: InstallationDetails,
            exact: true,
            path: InstallationsUpdateWithDevicePath
          },
          {
            component: InstallationDetails,
            exact: true,
            path: InstallationsCreatePath
          },
          {
            component: UpdateInstallation,
            exact: true,
            path: InstallationsUpdatePath
          },
          {
            component: AccountOverview,
            exact: true,
            path: AssetsRootPath
          },
          { component: NoMatch404, path: "*" }
        ]
      },
      {
        component: DigitalOffering,
        path: DigitalOfferingRootPath,
        routes: [
          {
            component: DigitalOfferingList,
            exact: true,
            path: DigitalOfferingRootPath
          },
          {
            component: DigitalOfferingForm,
            exact: true,
            path: DigitalOfferingCreatePath
          },
          {
            component: DigitalOfferingView,
            exact: true,
            path: DigitalOfferingViewPath
          }
        ]
      },
      {
        component: Subscriptions,
        path: SubscriptionsRootPath,
        routes: [
          {
            component: SubscriptionsOverview,
            exact: true,
            path: SubscriptionsRootPath
          }
        ]
      },
      {
        component: Devices,
        path: DeviceRootPath,
        routes: [
          {
            component: DeviceList,
            exact: true,
            path: DeviceRootPath
          },
          {
            component: DeviceForm,
            exact: true,
            path: DeviceFormPath
          },
          {
            component: DeviceForm,
            exact: true,
            path: DeviceSubDeviceFormPath
          },
          {
            component: DeviceForm,
            exact: true,
            path: DeviceCommDeviceFormPath
          },
          {
            component: DeviceView,
            exact: true,
            path: DeviceViewPath
          }
        ]
      },
      {
        component: Users,
        path: UsersRootPath,
        routes: [
          {
            component: UserList,
            exact: true,
            path: UsersRootPath
          },
          {
            component: UserCreate,
            exact: true,
            path: UsersCreatePath
          },
          {
            component: UserDetails,
            exact: true,
            path: UsersUpdatePath
          },
          {
            component: UserAssetAssociation,
            exact: true,
            path: UsersAssetsCreatePath
          },
          {
            component: UserAssetAssociation,
            exact: true,
            path: UsersAssetsUpdatePath
          },
          {
            component: UserAssetRoles,
            exact: true,
            path: UsersAssetsRolesPath
          }
        ]
      },
      {
        component: ServiceGroup,
        exact: true,
        path: ServiceGroupRootPath
      },
      {
        component: Authorization,
        path: AuthorizationRootPath,
        routes: [
          {
            exact: true,
            path: AuthorizationRootPath,
            render: (): ReactNode => redirect(AuthorizationOverviewPath)
          },
          {
            component: AuthorizationOverview,
            exact: true,
            path: AuthorizationOverviewPath
          },
          {
            component: Administration,
            exact: true,
            path: AdministrationPath
          },
          {
            component: AdministrationForm,
            exact: true,
            path: AdministrationFormPath
          },
          {
            component: AdministrationView,
            exact: true,
            path: AdministrationViewPath
          }
        ]
      },
      { component: NoMatch404, path: "*" }
    ]
  }
] as RouteConfig[];
